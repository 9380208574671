:root {
  --neutral--200: #f7f9fc;
  --neutral--600: #6f7182;
  --neutral--800: #0b0e2c;
  --accent--primary-1: #1476ff;
  --color-primary-hover: #004cff;
  --neutral--100: white;
  --neutral--300: #e9eaf3;
  --general--shadow-01: rgba(25, 93, 194, .07);
  --general--shadow-02: rgba(11, 22, 44, .05);
  --secondary--color-1: #1045cc;
  --secondary--color-2: #f4f7ff;
  --secondary--color-3: #f6f1ff;
  --secondary--color-4: #9240fb;
  --secondary--color-5: #fff7e8;
  --neutral--700: #303350;
  --neutral--500: #989aad;
  --neutral--400: #cacbd7;
  --system--green-400: #13a570;
  --system--blue-400: #086cd9;
  --system--blue-300: #1d88fe;
  --system--blue-200: #8fc3ff;
  --system--blue-100: #eaf4ff;
  --system--green-300: #05c168;
  --system--green-200: #7fdca4;
  --system--green-100: #e4faed;
  --system--red-400: #dc2b2b;
  --system--300: #ff5a65;
  --system--red-200: #ffbec2;
  --system--red-100: #ffeff0;
  --system--orange-400: #d5691b;
  --system--orange-300: #ff9e2c;
  --system--orange-200: #ffd19b;
  --system--orange-100: #fff3e4;
  --button-shadow--color-2: rgba(2, 87, 251, .2);
  --button-shadow--white-01: rgba(20, 20, 43, .04);
  --secondary--color-11: #edfbee;
  --general--shadow-03: rgba(11, 22, 44, .1);
  --secondary--color-6: #ffb016;
  --secondary--color-7: #fff4f4;
  --secondary--color-8: #ff414c;
  --secondary--color-9: #eef9ff;
  --secondary--color-10: #68caff;
  --secondary--color-12: #45c74e;
  --button-shadow--white-03: rgba(20, 20, 43, .1);
  --general--shadow-04: rgba(20, 20, 43, .14);
  --general--shadow-05: rgba(20, 20, 43, .16);
  --general--shadow-06: rgba(20, 20, 43, .24);
  --button-shadow--color-01: rgba(74, 58, 255, .06);
  --button-shadow--white-02: rgba(20, 20, 43, .06);
  --button-shadow--color-3: rgba(74, 58, 255, .08);
}

.w-commerce-commercecartwrapper {
  display: inline-block;
  position: relative;
}

.w-commerce-commercecartopenlink {
  color: #fff;
  cursor: pointer;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #3898ec;
  border-width: 0;
  border-radius: 0;
  align-items: center;
  padding: 9px 15px;
  text-decoration: none;
  display: flex;
}

.w-commerce-commercecartopenlinkcount {
  height: 18px;
  min-width: 18px;
  color: #3898ec;
  text-align: center;
  background-color: #fff;
  border-radius: 9px;
  margin-left: 8px;
  padding-left: 6px;
  padding-right: 6px;
  font-size: 11px;
  font-weight: 700;
  line-height: 18px;
  display: inline-block;
}

.w-commerce-commercecartcontainerwrapper {
  z-index: 1001;
  background-color: rgba(0, 0, 0, .8);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.w-commerce-commercecartcontainerwrapper--cartType-modal {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.w-commerce-commercecartcontainerwrapper--cartType-leftSidebar {
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  display: flex;
}

.w-commerce-commercecartcontainerwrapper--cartType-rightSidebar {
  flex-direction: row;
  justify-content: flex-end;
  align-items: stretch;
  display: flex;
}

.w-commerce-commercecartcontainerwrapper--cartType-leftDropdown {
  background-color: rgba(0, 0, 0, 0);
  position: absolute;
  top: 100%;
  bottom: auto;
  left: 0;
  right: auto;
}

.w-commerce-commercecartcontainerwrapper--cartType-rightDropdown {
  background-color: rgba(0, 0, 0, 0);
  position: absolute;
  top: 100%;
  bottom: auto;
  left: auto;
  right: 0;
}

.w-commerce-commercecartcontainer {
  width: 100%;
  max-width: 480px;
  min-width: 320px;
  background-color: #fff;
  flex-direction: column;
  display: flex;
  overflow: auto;
  box-shadow: 0 5px 25px rgba(0, 0, 0, .25);
}

.w-commerce-commercecartheader {
  border-bottom: 1px solid #e6e6e6;
  flex: none;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  display: flex;
  position: relative;
}

.w-commerce-commercecartheading {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  padding-right: 0;
}

.w-commerce-commercecartcloselink {
  width: 16px;
  height: 16px;
}

.w-commerce-commercecartformwrapper {
  flex-direction: column;
  flex: 1;
  display: flex;
}

.w-commerce-commercecartform {
  flex-direction: column;
  flex: 1;
  justify-content: flex-start;
  display: flex;
}

.w-commerce-commercecartlist {
  -webkit-overflow-scrolling: touch;
  flex: 1;
  padding: 12px 24px;
  overflow: auto;
}

.w-commerce-commercecartitem {
  align-items: flex-start;
  padding-top: 12px;
  padding-bottom: 12px;
  display: flex;
}

.w-commerce-commercecartitemimage {
  width: 60px;
  height: 0%;
}

.w-commerce-commercecartiteminfo {
  flex-direction: column;
  flex: 1;
  margin-left: 16px;
  margin-right: 16px;
  display: flex;
}

.w-commerce-commercecartproductname {
  font-weight: 700;
}

.w-commerce-commercecartoptionlist {
  margin-bottom: 0;
  padding-left: 0;
  text-decoration: none;
  list-style-type: none;
}

.w-commerce-commercecartquantity {
  height: 38px;
  width: 60px;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-bottom: 10px;
  padding: 8px 6px 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecartquantity::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecartquantity::placeholder {
  color: #999;
}

.w-commerce-commercecartquantity:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecartfooter {
  border-top: 1px solid #e6e6e6;
  flex-direction: column;
  flex: none;
  padding: 16px 24px 24px;
  display: flex;
}

.w-commerce-commercecartlineitem {
  flex: none;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 16px;
  display: flex;
}

.w-commerce-commercecartordervalue {
  font-weight: 700;
}

.w-commerce-commercecartapplepaybutton {
  color: #fff;
  cursor: pointer;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  height: 38px;
  min-height: 30px;
  background-color: #000;
  border-width: 0;
  border-radius: 2px;
  align-items: center;
  margin-bottom: 8px;
  padding: 0;
  text-decoration: none;
  display: flex;
}

.w-commerce-commercecartapplepayicon {
  width: 100%;
  height: 50%;
  min-height: 20px;
}

.w-commerce-commercecartquickcheckoutbutton {
  color: #fff;
  cursor: pointer;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  height: 38px;
  background-color: #000;
  border-width: 0;
  border-radius: 2px;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
  padding: 0 15px;
  text-decoration: none;
  display: flex;
}

.w-commerce-commercequickcheckoutgoogleicon,
.w-commerce-commercequickcheckoutmicrosofticon {
  margin-right: 8px;
  display: block;
}

.w-commerce-commercecartcheckoutbutton {
  color: #fff;
  cursor: pointer;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  text-align: center;
  background-color: #3898ec;
  border-width: 0;
  border-radius: 2px;
  align-items: center;
  padding: 9px 15px;
  text-decoration: none;
  display: block;
}

.w-commerce-commercecartemptystate {
  flex: 1;
  justify-content: center;
  align-items: center;
  padding-top: 100px;
  padding-bottom: 100px;
  display: flex;
}

.w-commerce-commercecarterrorstate {
  background-color: #ffdede;
  flex: none;
  margin: 0 24px 24px;
  padding: 10px;
}

.w-layout-grid {
  grid-row-gap: 16px;
  grid-column-gap: 16px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.w-commerce-commercecheckoutformcontainer {
  width: 100%;
  min-height: 100vh;
  background-color: #f5f5f5;
  padding: 20px;
}

.w-commerce-commercelayoutmain {
  flex: 0 800px;
  margin-right: 20px;
}

.w-commerce-commercecheckoutcustomerinfowrapper {
  margin-bottom: 20px;
}

.w-commerce-commercecheckoutblockheader {
  background-color: #fff;
  border: 1px solid #e6e6e6;
  justify-content: space-between;
  align-items: baseline;
  padding: 4px 20px;
  display: flex;
}

.w-commerce-commercecheckoutblockcontent {
  background-color: #fff;
  border-bottom: 1px solid #e6e6e6;
  border-left: 1px solid #e6e6e6;
  border-right: 1px solid #e6e6e6;
  padding: 20px;
}

.w-commerce-commercecheckoutlabel {
  margin-bottom: 8px;
}

.w-commerce-commercecheckoutemailinput {
  height: 38px;
  width: 100%;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-bottom: 0;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutemailinput::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutemailinput::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutemailinput:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutshippingaddresswrapper {
  margin-bottom: 20px;
}

.w-commerce-commercecheckoutshippingfullname {
  height: 38px;
  width: 100%;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutshippingfullname::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingfullname::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingfullname:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutshippingstreetaddress {
  height: 38px;
  width: 100%;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutshippingstreetaddress::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingstreetaddress::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingstreetaddress:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutshippingstreetaddressoptional {
  height: 38px;
  width: 100%;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutshippingstreetaddressoptional::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingstreetaddressoptional::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingstreetaddressoptional:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutrow {
  margin-left: -8px;
  margin-right: -8px;
  display: flex;
}

.w-commerce-commercecheckoutcolumn {
  flex: 1;
  padding-left: 8px;
  padding-right: 8px;
}

.w-commerce-commercecheckoutshippingcity {
  height: 38px;
  width: 100%;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutshippingcity::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingcity::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingcity:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutshippingstateprovince {
  height: 38px;
  width: 100%;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutshippingstateprovince::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingstateprovince::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingstateprovince:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutshippingzippostalcode {
  height: 38px;
  width: 100%;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutshippingzippostalcode::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingzippostalcode::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingzippostalcode:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutshippingcountryselector {
  height: 38px;
  width: 100%;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-bottom: 0;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutshippingcountryselector::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingcountryselector::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingcountryselector:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutshippingmethodswrapper {
  margin-bottom: 20px;
}

.w-commerce-commercecheckoutshippingmethodslist {
  border-left: 1px solid #e6e6e6;
  border-right: 1px solid #e6e6e6;
}

.w-commerce-commercecheckoutshippingmethoditem {
  background-color: #fff;
  border-bottom: 1px solid #e6e6e6;
  flex-direction: row;
  align-items: baseline;
  margin-bottom: 0;
  padding: 16px;
  font-weight: 400;
  display: flex;
}

.w-commerce-commercecheckoutshippingmethoddescriptionblock {
  flex-direction: column;
  flex-grow: 1;
  margin-left: 12px;
  margin-right: 12px;
  display: flex;
}

.w-commerce-commerceboldtextblock {
  font-weight: 700;
}

.w-commerce-commercecheckoutshippingmethodsemptystate {
  text-align: center;
  background-color: #fff;
  border-bottom: 1px solid #e6e6e6;
  border-left: 1px solid #e6e6e6;
  border-right: 1px solid #e6e6e6;
  padding: 64px 16px;
}

.w-commerce-commercecheckoutpaymentinfowrapper {
  margin-bottom: 20px;
}

.w-commerce-commercecheckoutcardnumber {
  height: 38px;
  width: 100%;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  cursor: text;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutcardnumber::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutcardnumber::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutcardnumber:focus,
.w-commerce-commercecheckoutcardnumber.-wfp-focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutcardexpirationdate {
  height: 38px;
  width: 100%;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  cursor: text;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutcardexpirationdate::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutcardexpirationdate::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutcardexpirationdate:focus,
.w-commerce-commercecheckoutcardexpirationdate.-wfp-focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutcardsecuritycode {
  height: 38px;
  width: 100%;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  cursor: text;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutcardsecuritycode::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutcardsecuritycode::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutcardsecuritycode:focus,
.w-commerce-commercecheckoutcardsecuritycode.-wfp-focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutbillingaddresstogglewrapper {
  flex-direction: row;
  display: flex;
}

.w-commerce-commercecheckoutbillingaddresstogglecheckbox {
  margin-top: 4px;
}

.w-commerce-commercecheckoutbillingaddresstogglelabel {
  margin-left: 8px;
  font-weight: 400;
}

.w-commerce-commercecheckoutbillingaddresswrapper {
  margin-top: 16px;
  margin-bottom: 20px;
}

.w-commerce-commercecheckoutbillingfullname {
  height: 38px;
  width: 100%;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutbillingfullname::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingfullname::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingfullname:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutbillingstreetaddress {
  height: 38px;
  width: 100%;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutbillingstreetaddress::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingstreetaddress::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingstreetaddress:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutbillingstreetaddressoptional {
  height: 38px;
  width: 100%;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutbillingstreetaddressoptional::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingstreetaddressoptional::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingstreetaddressoptional:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutbillingcity {
  height: 38px;
  width: 100%;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutbillingcity::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingcity::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingcity:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutbillingstateprovince {
  height: 38px;
  width: 100%;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutbillingstateprovince::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingstateprovince::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingstateprovince:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutbillingzippostalcode {
  height: 38px;
  width: 100%;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutbillingzippostalcode::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingzippostalcode::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingzippostalcode:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutbillingcountryselector {
  height: 38px;
  width: 100%;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-bottom: 0;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutbillingcountryselector::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingcountryselector::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingcountryselector:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutorderitemswrapper {
  margin-bottom: 20px;
}

.w-commerce-commercecheckoutsummaryblockheader {
  background-color: #fff;
  border: 1px solid #e6e6e6;
  justify-content: space-between;
  align-items: baseline;
  padding: 4px 20px;
  display: flex;
}

.w-commerce-commercecheckoutorderitemslist {
  margin-bottom: -20px;
}

.w-commerce-commercecheckoutorderitem {
  margin-bottom: 20px;
  display: flex;
}

.w-commerce-commercecheckoutorderitemdescriptionwrapper {
  flex-grow: 1;
  margin-left: 16px;
  margin-right: 16px;
}

.w-commerce-commercecheckoutorderitemquantitywrapper {
  white-space: pre-wrap;
  display: flex;
}

.w-commerce-commercecheckoutorderitemoptionlist {
  margin-bottom: 0;
  padding-left: 0;
  text-decoration: none;
  list-style-type: none;
}

.w-commerce-commercelayoutsidebar {
  flex: 0 0 320px;
  position: -webkit-sticky;
  position: sticky;
  top: 20px;
}

.w-commerce-commercecheckoutordersummarywrapper {
  margin-bottom: 20px;
}

.w-commerce-commercecheckoutsummarylineitem,
.w-commerce-commercecheckoutordersummaryextraitemslistitem {
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 8px;
  display: flex;
}

.w-commerce-commercecheckoutsummarytotal {
  font-weight: 700;
}

.w-commerce-commercecheckoutdiscounts {
  background-color: #fff;
  border: 1px solid #e6e6e6;
  flex-wrap: wrap;
  padding: 20px;
  display: flex;
}

.w-commerce-commercecheckoutdiscountslabel {
  flex-basis: 100%;
  margin-bottom: 8px;
}

.w-commerce-commercecheckoutdiscountsinput {
  height: 38px;
  width: auto;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  min-width: 0;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  flex: 1;
  margin-bottom: 0;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutdiscountsinput::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutdiscountsinput::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutdiscountsinput:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutdiscountsbutton {
  color: #fff;
  cursor: pointer;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  height: 38px;
  background-color: #3898ec;
  border-width: 0;
  border-radius: 3px;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;
  margin-left: 8px;
  padding: 9px 15px;
  text-decoration: none;
  display: flex;
}

.w-commerce-commercecheckoutplaceorderbutton {
  color: #fff;
  cursor: pointer;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  text-align: center;
  background-color: #3898ec;
  border-width: 0;
  border-radius: 3px;
  align-items: center;
  margin-bottom: 20px;
  padding: 9px 15px;
  text-decoration: none;
  display: block;
}

.w-commerce-commercecheckouterrorstate {
  background-color: #ffdede;
  margin-top: 16px;
  margin-bottom: 16px;
  padding: 10px 16px;
}

.w-checkbox {
  margin-bottom: 5px;
  padding-left: 20px;
  display: block;
}

.w-checkbox:before {
  content: " ";
  grid-area: 1 / 1 / 2 / 2;
  display: table;
}

.w-checkbox:after {
  content: " ";
  clear: both;
  grid-area: 1 / 1 / 2 / 2;
  display: table;
}

.w-checkbox-input {
  float: left;
  margin: 4px 0 0 -20px;
  line-height: normal;
}

.w-checkbox-input--inputType-custom {
  width: 12px;
  height: 12px;
  border: 1px solid #ccc;
  border-radius: 2px;
}

.w-checkbox-input--inputType-custom.w--redirected-checked {
  background-color: #3898ec;
  background-image: url('https://d3e54v103j8qbb.cloudfront.net/static/custom-checkbox-checkmark.589d534424.svg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border-color: #3898ec;
}

.w-checkbox-input--inputType-custom.w--redirected-focus {
  box-shadow: 0 0 3px 1px #3898ec;
}

.w-commerce-commerceaddtocartform {
  margin: 0 0 15px;
}

.w-commerce-commerceaddtocartoptionpillgroup {
  margin-bottom: 10px;
  display: flex;
}

.w-commerce-commerceaddtocartoptionpill {
  color: #000;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #000;
  margin-right: 10px;
  padding: 8px 15px;
}

.w-commerce-commerceaddtocartoptionpill.w--ecommerce-pill-selected {
  color: #fff;
  background-color: #000;
}

.w-commerce-commerceaddtocartoptionpill.w--ecommerce-pill-disabled {
  color: #666;
  cursor: not-allowed;
  background-color: #e6e6e6;
  border-color: #e6e6e6;
  outline-style: none;
}

.w-commerce-commerceaddtocartquantityinput {
  height: 38px;
  width: 60px;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-bottom: 10px;
  padding: 8px 6px 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commerceaddtocartquantityinput::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commerceaddtocartquantityinput::placeholder {
  color: #999;
}

.w-commerce-commerceaddtocartquantityinput:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commerceaddtocartbutton {
  color: #fff;
  cursor: pointer;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #3898ec;
  border-width: 0;
  border-radius: 0;
  align-items: center;
  padding: 9px 15px;
  text-decoration: none;
  display: flex;
}

.w-commerce-commerceaddtocartbutton.w--ecommerce-add-to-cart-disabled {
  color: #666;
  cursor: not-allowed;
  background-color: #e6e6e6;
  border-color: #e6e6e6;
  outline-style: none;
}

.w-commerce-commercebuynowbutton {
  color: #fff;
  cursor: pointer;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #3898ec;
  border-width: 0;
  border-radius: 0;
  align-items: center;
  margin-top: 10px;
  padding: 9px 15px;
  text-decoration: none;
  display: inline-block;
}

.w-commerce-commercebuynowbutton.w--ecommerce-buy-now-disabled {
  color: #666;
  cursor: not-allowed;
  background-color: #e6e6e6;
  border-color: #e6e6e6;
  outline-style: none;
}

.w-commerce-commerceaddtocartoutofstock {
  background-color: #ddd;
  margin-top: 10px;
  padding: 10px;
}

.w-commerce-commerceaddtocarterror {
  background-color: #ffdede;
  margin-top: 10px;
  padding: 10px;
}

.w-commerce-commerceorderconfirmationcontainer {
  width: 100%;
  min-height: 100vh;
  background-color: #f5f5f5;
  padding: 20px;
}

.w-commerce-commercelayoutcontainer {
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

.w-commerce-commercecheckoutcustomerinfosummarywrapper {
  margin-bottom: 20px;
}

.w-commerce-commercecheckoutsummaryitem,
.w-commerce-commercecheckoutsummarylabel {
  margin-bottom: 8px;
}

.w-commerce-commercecheckoutsummaryflexboxdiv {
  flex-direction: row;
  justify-content: flex-start;
  display: flex;
}

.w-commerce-commercecheckoutsummarytextspacingondiv {
  margin-right: .33em;
}

.w-commerce-commercecheckoutshippingsummarywrapper,
.w-commerce-commercecheckoutpaymentsummarywrapper {
  margin-bottom: 20px;
}

.w-commerce-commercepaypalcheckoutformcontainer {
  width: 100%;
  min-height: 100vh;
  background-color: #f5f5f5;
  padding: 20px;
}

.w-commerce-commercepaypalcheckouterrorstate {
  background-color: #ffdede;
  margin-top: 16px;
  margin-bottom: 16px;
  padding: 10px 16px;
}

.w-form-formradioinput--inputType-custom {
  width: 12px;
  height: 12px;
  border: 1px solid #ccc;
  border-radius: 50%;
}

.w-form-formradioinput--inputType-custom.w--redirected-focus {
  box-shadow: 0 0 3px 1px #3898ec;
}

.w-form-formradioinput--inputType-custom.w--redirected-checked {
  border-width: 4px;
  border-color: #3898ec;
}

@media screen and (max-width: 767px) {
  .w-commerce-commercelayoutmain {
    flex-basis: auto;
    margin-right: 0;
  }

  .w-commerce-commercelayoutsidebar {
    flex-basis: auto;
  }

  .w-commerce-commercelayoutcontainer {
    flex-direction: column;
    align-items: stretch;
  }
}

@media screen and (max-width: 479px) {
  .w-commerce-commercecartcontainerwrapper--cartType-modal {
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
  }

  .w-commerce-commercecartcontainerwrapper--cartType-leftDropdown,
  .w-commerce-commercecartcontainerwrapper--cartType-rightDropdown {
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    display: flex;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .w-commerce-commercecartquantity,
  .w-commerce-commercecheckoutemailinput,
  .w-commerce-commercecheckoutshippingfullname,
  .w-commerce-commercecheckoutshippingstreetaddress,
  .w-commerce-commercecheckoutshippingstreetaddressoptional {
    font-size: 16px;
  }

  .w-commerce-commercecheckoutrow {
    flex-direction: column;
  }

  .w-commerce-commercecheckoutshippingcity,
  .w-commerce-commercecheckoutshippingstateprovince,
  .w-commerce-commercecheckoutshippingzippostalcode,
  .w-commerce-commercecheckoutshippingcountryselector,
  .w-commerce-commercecheckoutcardnumber,
  .w-commerce-commercecheckoutcardexpirationdate,
  .w-commerce-commercecheckoutcardsecuritycode,
  .w-commerce-commercecheckoutbillingfullname,
  .w-commerce-commercecheckoutbillingstreetaddress,
  .w-commerce-commercecheckoutbillingstreetaddressoptional,
  .w-commerce-commercecheckoutbillingcity,
  .w-commerce-commercecheckoutbillingstateprovince,
  .w-commerce-commercecheckoutbillingzippostalcode,
  .w-commerce-commercecheckoutbillingcountryselector,
  .w-commerce-commercecheckoutdiscountsinput,
  .w-commerce-commerceaddtocartquantityinput {
    font-size: 16px;
  }
}

body {
  background-color: var(--neutral--200);
  color: var(--neutral--600);
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.857em;
}

h1 {
  color: var(--neutral--800);
  margin-top: 0;
  margin-bottom: 16px;
  font-size: 42px;
  font-weight: 700;
  line-height: 1.286em;
}

h2 {
  color: var(--neutral--800);
  margin-top: 0;
  margin-bottom: 16px;
  font-size: 32px;
  font-weight: 700;
  line-height: 1.5em;
}

h3 {
  color: var(--neutral--800);
  margin-top: 0;
  margin-bottom: 8px;
  font-size: 24px;
  font-weight: 700;
  line-height: 1.417em;
}

h4 {
  color: var(--neutral--800);
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 22px;
  font-weight: 700;
  line-height: 1.273em;
}

h5 {
  color: var(--neutral--800);
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.333em;
}

h6 {
  color: var(--neutral--800);
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.375em;
}

p {
  margin-bottom: 16px;
}

a {
  color: var(--accent--primary-1);
  text-decoration: underline;
  transition: color .3s;
}

a:hover {
  color: var(--color-primary-hover);
}

ul,
ol {
  margin-top: 0;
  margin-bottom: 10px;
  padding-left: 40px;
}

li {
  margin-bottom: 8px;
  padding-left: 8px;
}

img {
  max-width: 100%;
  display: inline-block;
}

label {
  color: var(--neutral--800);
  margin-bottom: 12px;
  font-weight: 700;
  display: block;
}

strong {
  color: var(--neutral--800);
  font-weight: 700;
}

blockquote {
  background-color: var(--accent--primary-1);
  color: var(--neutral--100);
  text-align: center;
  border: 0 solid #000;
  border-radius: 24px;
  margin-top: 14px;
  margin-bottom: 32px;
  padding: 86px 64px;
  font-size: 18px;
  line-height: 30px;
}

figure {
  margin-top: 24px;
  margin-bottom: 48px;
}

figcaption {
  text-align: center;
  margin-top: 16px;
}

.grid-2-columns {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: center;
  display: grid;
}

.grid-2-columns.form {
  grid-column-gap: 22px;
  grid-row-gap: 28px;
}

.grid-2-columns._2-col-mbl._1fr---auto {
  grid-template-columns: 1fr auto;
}

.grid-2-columns.template-page-sidebar {
  grid-column-gap: 40px;
  grid-template-columns: .38fr 1fr;
  align-items: start;
}

.grid-2-columns.gap-18px {
  grid-column-gap: 18px;
  grid-row-gap: 18px;
}

.grid-2-columns.reports-modules {
  grid-template-columns: 1.25fr 1fr;
}

.grid-2-columns.products-top-modules {
  grid-template-columns: 1.45fr 1fr;
  margin-bottom: 24px;
}

.grid-2-columns.products-bottom-modules {
  grid-template-columns: .55fr 1fr;
}

.grid-2-columns.contact-single-main-grid {
  grid-column-gap: 48px;
  grid-template-columns: .62fr 1fr;
}

.grid-2-columns._1-5fr---1fr {
  grid-template-columns: 1.5fr 1fr;
}

.grid-2-columns._2-1fr---1fr {
  grid-template-columns: 2.1fr 1fr;
}

.grid-2-columns._1-2fr---1fr {
  grid-template-columns: 1.2fr 1fr;
}

.grid-2-columns._2-2fr---1fr {
  grid-template-columns: 2.25fr 1fr;
}

.grid-2-columns._1-3fr---1fr {
  grid-template-columns: 1.3fr 1fr;
}

.grid-2-columns._46fr---1fr {
  grid-template-columns: .46fr 1fr;
}

.grid-2-columns._42fr---1fr {
  grid-template-columns: .42fr 1fr;
}

.grid-2-columns._7fr---1fr {
  grid-template-columns: .7fr 1fr;
}

.grid-2-columns.gap-column-28px {
  grid-column-gap: 28px;
}

.style---main-wrapper {
  background-color: var(--neutral--200);
}

.module {
  border: 1px solid var(--neutral--300);
  background-color: var(--neutral--100);
  box-shadow: 0 2px 10px 0 var(--general--shadow-01);
  border-radius: 20px;
}

.module.upload-box {
  width: 100%;
  padding: 26px;
  text-decoration: none;
}

.module.no-records-available {
  text-align: center;
  padding: 74px 56px;
}

.module.action-completed {
  text-align: center;
  padding: 84px 56px;
}

.module.there-s-been-an-error {
  text-align: center;
  padding: 54px 56px 74px;
}

.module.no-records-found {
  text-align: center;
  padding: 74px 56px;
}

.module.notifications-alert {
  text-align: center;
  padding: 50px 56px 74px;
}

.module.pages-link {
  border-radius: 30px;
  text-decoration: none;
  overflow: hidden;
}

.module.reports-top-details {
  width: 100%;
  border-radius: 24px;
  align-items: center;
  padding: 20px 24px;
  display: flex;
}

.module.graph-large {
  border-radius: 30px;
  margin-bottom: 24px;
  padding: 28px 36px 56px;
}

.module.graph {
  border-radius: 30px;
  padding-top: 28px;
  padding-left: 32px;
  padding-right: 32px;
}

.module.users-by-device {
  border-radius: 30px;
  padding-top: 28px;
  padding-left: 36px;
  padding-right: 36px;
}

.module.recent-contacts {
  border-radius: 30px;
  padding: 28px 34px 20px;
}

.module.total-sales {
  border-radius: 30px;
  padding: 36px 34px;
}

.module.recent-orders {
  padding: 28px 34px 32px;
}

.module.popular-categories {
  padding: 28px 36px 68px;
}

.module.products-table {
  border-radius: 30px;
  margin-bottom: 24px;
  overflow: hidden;
}

.module.tasks-top-module {
  grid-column-gap: 16px;
  border-radius: 24px;
  align-items: flex-start;
  padding: 30px 48px 30px 24px;
  display: flex;
  position: relative;
}

.module.team-progress,
.module.tasks-reports {
  border-radius: 30px;
  padding: 20px 36px 32px;
}

.module.due-tasks {
  grid-column-gap: 14px;
  border-radius: 24px;
  padding: 44px 32px 28px;
  display: flex;
  position: relative;
}

.module.contacts-card {
  border-radius: 30px;
  flex: 1;
  padding: 36px;
}

.module.contact-single-details {
  border-radius: 30px;
  margin-bottom: 24px;
  padding: 32px 32px 36px;
}

.module.company-icon-small {
  max-height: 54px;
  max-width: 54px;
  min-height: 54px;
  min-width: 54px;
  box-shadow: 0 2px 3px 0 var(--general--shadow-02);
  border-radius: 10px;
  margin-right: 8px;
  padding: 14px;
}

.module.assigned-to {
  border-radius: 30px;
  margin-bottom: 24px;
  padding: 28px 32px 36px;
}

.module.about-company {
  border-radius: 30px;
  padding: 28px 32px 36px;
  margin: 10px;
}

.module.company-icon-large {
  max-height: 68px;
  max-width: 68px;
  min-height: 68px;
  min-width: 68px;
  box-shadow: 0 3px 5px 0 var(--general--shadow-02);
  border-radius: 10px;
  margin-right: 12px;
  padding: 14px;
}

.module.add-note {
  border-radius: 22px;
  margin-bottom: 48px;
}

.module.companies-card {
  border-radius: 30px;
  flex: 1;
  padding: 36px;
}

.module.border-radius-30px {
  border-radius: 30px;
}

.module.contact-activity-large {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  border-radius: 22px;
  align-items: flex-start;
  padding: 36px 36px 24px;
  display: flex;
}

.module.contact-activity-small {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  justify-content: space-between;
  align-items: flex-start;
  padding: 36px;
  display: flex;
}

.module.company-single-about {
  border-radius: 30px;
  margin-bottom: 24px;
  padding: 48px;
}

.module.company-news {
  padding: 28px 46px;
}

.module.company-details {
  border-radius: 30px;
  padding: 36px;
  position: -webkit-sticky;
  position: sticky;
  top: 24px;
}

.module.projects {
  border-radius: 30px;
  flex-direction: column;
  padding: 36px;
  display: flex;
}

.module.features-tasks {
  grid-column-gap: 12px;
  grid-row-gap: 0px;
  border-radius: 16px;
  grid-template-rows: auto;
  grid-template-columns: 18px 1fr;
  grid-auto-columns: 1fr;
  padding: 16px 36px;
  display: grid;
}

.module.board-tasks {
  padding: 24px 28px;
}

.module.crypto-top-module {
  border-radius: 30px;
  padding: 36px;
}

.module.crypto-news,
.module.crypto-trending {
  border-radius: 30px;
  padding: 28px 36px;
}

.module.dashboard-invoice {
  padding: 36px;
}

.module.client-information {
  border-radius: 24px;
  padding: 28px 36px;
}

.module.changelog {
  border-radius: 30px;
  padding: 36px 48px;
}

.module.file-manager-top-modules {
  padding: 32px 32px 40px;
}

.module.amount-due {
  border-radius: 24px;
  padding: 28px 36px;
}

.module.support-form {
  border-radius: 30px;
  padding: 48px 48px 56px;
}

.module.support-contact-link {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 24px 40px;
  text-decoration: none;
  transition: none;
  display: flex;
}

.module.monthly-customers {
  border-radius: 30px;
  padding: 28px 36px;
}

.module.monthly-pageviews {
  border-radius: 30px;
  padding: 28px 36px 52px;
}

.module.chat-box {
  max-width: 680px;
  border-radius: 30px;
  margin-left: auto;
  margin-right: auto;
  display: none;
}

.module.chat-box.lily {
  display: block;
}

.module.account {
  width: 100%;
  max-width: 724px;
  border-radius: 26px;
  padding: 40px 46px 70px;
}

.module.team-settings {
  width: 100%;
  max-width: 724px;
  border-radius: 30px;
  overflow: hidden;
}

.module.billing {
  width: 100%;
  max-width: 724px;
  border-radius: 30px;
  padding: 28px 46px 54px;
}

.module.billing-startup-plan-info {
  border-radius: 16px;
  flex: 0 auto;
  padding: 18px 36px 18px 26px;
}

.module.billing-extra-users-info {
  grid-column-gap: 14px;
  grid-row-gap: 16px;
  border-radius: 16px;
  grid-template-rows: auto;
  grid-template-columns: 56px 1fr;
  grid-auto-columns: 1fr;
  padding: 24px 36px 24px 30px;
  display: grid;
}

.module.payment-method {
  grid-column-gap: 14px;
  grid-row-gap: 14px;
  align-items: flex-start;
  padding: 32px;
  display: flex;
}

.module.border-radius-12px {
  border-radius: 12px;
}

.module.notifications-settings {
  width: 100%;
  max-width: 724px;
  border-radius: 30px;
  padding-top: 28px;
  padding-left: 46px;
  padding-right: 30px;
}

.module.api-settings {
  width: 100%;
  max-width: 724px;
  border-radius: 30px;
  padding-left: 48px;
  padding-right: 48px;
  overflow: hidden;
}

.module.tabs-v2-menu-container {
  grid-column-gap: 4px;
  border-radius: 24px;
  flex-direction: column;
  padding: 28px;
  display: flex;
  position: -webkit-sticky;
  position: sticky;
  top: 24px;
}

.module.skills {
  border-radius: 30px;
  margin-bottom: 24px;
  padding: 28px 48px 48px;
}

.module.experience {
  border-radius: 30px;
  padding: 28px 48px 48px;
}

.module.social-feed {
  border-radius: 30px;
  padding: 28px 48px;
}

.module.profile-v1-details {
  border-radius: 30px;
  padding: 36px;
  position: -webkit-sticky;
  position: sticky;
  top: 24px;
}

.module.profile-v2-details {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  border-radius: 30px;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  padding: 36px;
  display: grid;
  position: -webkit-sticky;
  position: sticky;
  top: 24px;
}

.module.about-me-v2 {
  margin-top: 24px;
  margin-bottom: 48px;
  padding: 36px 46px 12px;
}

.module.profile-v3-details {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  border-radius: 30px;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  margin-top: -72px;
  position: -webkit-sticky;
  position: sticky;
  top: 24px;
  overflow: hidden;
}

.module.pricing-v2 {
  height: 100%;
  flex-direction: column;
  padding: 36px;
  text-decoration: none;
  display: flex;
}

.module.pricing-v1 {
  width: 100%;
  height: 100%;
  border-radius: 30px;
  flex-direction: column;
  padding: 36px;
  text-decoration: none;
  display: flex;
}

.module.pricing-v1.featured {
  border-width: 2px;
  border-color: var(--accent--primary-1);
}

.module.pricing-v3 {
  border-radius: 30px;
  flex-direction: column;
  padding: 36px;
  text-decoration: none;
  display: flex;
}

.module.pricing-single-top {
  max-width: 756px;
  grid-column-gap: 52px;
  grid-row-gap: 24px;
  border-radius: 30px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  margin-bottom: 24px;
  margin-left: auto;
  margin-right: auto;
  padding: 40px 48px;
  display: grid;
}

.module.pricing-single-description {
  max-width: 756px;
  border-radius: 30px;
  margin-left: auto;
  margin-right: auto;
  padding: 48px 48px 32px;
}

.module.template-pages-nav {
  border-radius: 14px;
  padding: 30px 24px 24px;
  position: -webkit-sticky;
  position: sticky;
  top: 24px;
}

.module.template-pages {
  border-radius: 30px;
  padding: 28px 48px 60px;
}

.module.notifications {
  max-width: 644px;
  margin-left: auto;
  margin-right: auto;
  padding: 32px 48px 38px;
}

.module.privacy-policy {
  max-width: 712px;
  border-radius: 30px;
  margin-left: auto;
  margin-right: auto;
  padding: 48px 48px 32px;
}

.module.not-found {
  width: 100%;
  max-width: 882px;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  border-radius: 26px;
  justify-content: space-between;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;
  padding: 80px 60px;
  display: flex;
}

.module.utility-page-form {
  width: 100%;
  max-width: 586px;
  border-radius: 30px;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding: 48px 48px 56px;
  display: flex;
}

.module.password-protected {
  width: 100%;
  max-width: 566px;
  border-radius: 26px;
  margin-left: auto;
  margin-right: auto;
  padding: 94px 40px;
}

.module.integrations {
  border-radius: 30px;
  flex-direction: column;
  align-items: flex-start;
  padding: 36px;
  text-decoration: none;
  transition: none;
  display: flex;
}

.module.integration-single {
  max-width: 740px;
  border-radius: 30px;
  margin-top: 44px;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
}

.module.help-center-categories {
  height: 100%;
  border-radius: 30px;
  flex-direction: column;
  padding: 40px 36px;
  text-decoration: none;
  transition: none;
  display: flex;
}

.module.help-center-category-nav {
  border-radius: 14px;
  padding: 30px 24px 24px;
  position: -webkit-sticky;
  position: sticky;
  top: 24px;
}

.module.help-center-article-link {
  border-radius: 30px;
  padding: 44px 48px 32px;
  text-decoration: none;
  transition: none;
}

.module.help-center-article {
  justify-content: center;
  padding: 62px 40px 0;
  display: flex;
}

.module.homepage-features {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-rows: auto;
  grid-template-columns: 60px 1fr;
  grid-auto-columns: 1fr;
  align-items: center;
  padding: 24px 34px 32px;
  display: grid;
}

.module.homepage-features.middle {
  margin-left: 24px;
  margin-right: 24px;
}

.module.features-image-left {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  border-radius: 36px;
  grid-template-rows: auto;
  grid-template-columns: 1.44fr 1fr;
  grid-auto-columns: 1fr;
  align-items: center;
  margin-bottom: 32px;
  display: grid;
  overflow: hidden;
}

.module.features-image-right {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1.22fr;
  grid-auto-columns: 1fr;
  display: grid;
  overflow: hidden;
}

.module.homepage-pages-image-container {
  border-radius: 30px;
  margin-bottom: 24px;
  padding: 32px;
}

.module.search-results {
  height: 100%;
  padding: 40px 28px 48px;
  text-decoration: none;
  transition: none;
}

.style---color-block {
  min-height: 164px;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  position: relative;
}

.style---color-block-content {
  padding: 24px 24px 32px;
}

.style---color-block-name {
  color: var(--neutral--800);
  margin-bottom: 8px;
  font-size: 20px;
  font-weight: 700;
  line-height: 22px;
}

.style---color-block-hex {
  line-height: 20px;
}

.grid-4-columns {
  grid-column-gap: 28px;
  grid-row-gap: 28px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.bg-accent-1 {
  background-color: var(--accent--primary-1);
}

.bg-secondary-1 {
  background-color: var(--secondary--color-1);
}

.bg-secondary-2 {
  background-color: var(--secondary--color-2);
}

.bg-secondary-3 {
  background-color: var(--secondary--color-3);
}

.bg-secondary-4 {
  background-color: var(--secondary--color-4);
}

.bg-secondary-5 {
  background-color: var(--secondary--color-5);
}

.bg-neutral-800 {
  background-color: var(--neutral--800);
}

.bg-neutral-700 {
  background-color: var(--neutral--700);
}

.bg-neutral-600 {
  background-color: var(--neutral--600);
}

.bg-neutral-500 {
  background-color: var(--neutral--500);
}

.bg-neutral-400 {
  background-color: var(--neutral--400);
}

.bg-neutral-300 {
  background-color: var(--neutral--300);
}

.bg-neutral-200 {
  background-color: var(--neutral--200);
}

.bg-neutral-200.bottom-58 {
  z-index: -1;
  position: absolute;
  top: 0%;
  bottom: 58%;
  left: 0%;
  right: 0%;
}

.bg-neutral-100 {
  background-color: var(--neutral--100);
}

.color-accent-1 {
  color: var(--accent--primary-1);
}

.color-neutral-800 {
  color: var(--neutral--800);
}

.color-neutral-700 {
  color: var(--neutral--700);
}

.color-neutral-600 {
  color: var(--neutral--600);
}

.color-neutral-500 {
  color: var(--neutral--500);
}

.color-neutral-200 {
  color: var(--neutral--200);
}

.color-neutral-100 {
  color: var(--neutral--100);
}

.bg-green-400 {
  background-color: var(--system--green-400);
}

.bg-blue-400 {
  background-color: var(--system--blue-400);
}

.bg-blue-300 {
  background-color: var(--system--blue-300);
}

.bg-blue-200 {
  background-color: var(--system--blue-200);
}

.bg-blue-100 {
  background-color: var(--system--blue-100);
}

.bg-green-300 {
  background-color: var(--system--green-300);
}

.color-green-300 {
  color: var(--system--green-300);
}

.bg-green-200 {
  background-color: var(--system--green-200);
}

.bg-green-100 {
  background-color: var(--system--green-100);
}

.bg-red-400 {
  background-color: var(--system--red-400);
}

.bg-red-300 {
  background-color: var(--system--300);
}

.color-red-300 {
  color: var(--system--300);
}

.bg-red-200 {
  background-color: var(--system--red-200);
}

.bg-red-100 {
  background-color: var(--system--red-100);
}

.bg-orange-400 {
  background-color: var(--system--orange-400);
}

.bg-orange-300 {
  background-color: var(--system--orange-300);
}

.bg-orange-200 {
  background-color: var(--system--orange-200);
}

.bg-orange-100 {
  background-color: var(--system--orange-100);
}

.style---block-sub-heading {
  margin-bottom: 32px;
}

.style---block-sub-heading.border {
  border-bottom: 1px solid var(--neutral--400);
  margin-bottom: 64px;
  padding-bottom: 40px;
}

.divider {
  height: 1px;
  background-color: var(--neutral--300);
  margin-top: 80px;
  margin-bottom: 80px;
}

.divider._64px {
  margin-top: 64px;
  margin-bottom: 64px;
}

.divider._40px {
  margin-top: 40px;
  margin-bottom: 40px;
}

.divider._24px {
  margin-top: 24px;
  margin-bottom: 24px;
}

.divider.bg-neutral-300 {
  background-color: var(--neutral--300);
}

.divider._20px {
  margin-top: 20px;
  margin-bottom: 20px;
}

.divider.top-28px---bottom-36px {
  margin-top: 28px;
  margin-bottom: 36px;
}

.divider.top-28px---bottom-40px {
  margin-top: 28px;
  margin-bottom: 40px;
}

.divider.top-20px---bottom-44px {
  margin-top: 20px;
  margin-bottom: 44px;
}

.divider._32px {
  margin-top: 32px;
  margin-bottom: 32px;
}

.divider.top-32px---bottom-24px {
  margin-top: 32px;
  margin-bottom: 24px;
}

.divider.bg-neutral-400 {
  background-color: var(--neutral--400);
}

.divider.top-32px---bottom-48px {
  margin-top: 32px;
  margin-bottom: 48px;
}

.divider.top-28px---bottom-20px {
  margin-top: 28px;
  margin-bottom: 20px;
}

.divider._28px {
  margin-top: 28px;
  margin-bottom: 28px;
}

.divider.top-20px---bottom-52px {
  margin-top: 20px;
  margin-bottom: 52px;
}

.divider.top-40px---bottom-18px {
  margin-top: 40px;
  margin-bottom: 18px;
}

.divider._48px {
  margin-top: 48px;
  margin-bottom: 48px;
}

.divider.top-28px---bottom-56px {
  margin-top: 28px;
  margin-bottom: 56px;
}

.divider._18px {
  margin-top: 18px;
  margin-bottom: 18px;
}

.divider._56px {
  margin-top: 56px;
  margin-bottom: 56px;
}

.divider._0 {
  margin-top: 0;
  margin-bottom: 0;
}

.style---typography-block-grid {
  grid-column-gap: 40px;
  grid-template-rows: auto;
  grid-template-columns: minmax(auto, 320px) 1fr;
}

.style---content-block {
  margin-bottom: 100px;
}

.mg-bottom-8px {
  margin-bottom: 8px;
}

.mg-bottom-16px {
  margin-bottom: 16px;
}

.mg-bottom-24px {
  margin-bottom: 24px;
}

.mg-bottom-32px {
  margin-bottom: 32px;
}

.mg-bottom-40px {
  margin-bottom: 40px;
}

.mg-bottom-48px {
  margin-bottom: 48px;
}

.mg-bottom-56px {
  margin-bottom: 56px;
}

.mg-bottom-64px {
  margin-bottom: 64px;
}

.mg-top-8px {
  margin-top: 8px;
}

.mg-top-24px {
  margin-top: 24px;
}

.mg-top-48px {
  margin-top: 48px;
}

.mg-left-16px {
  margin-left: 16px;
}

.mg-right-8px {
  margin-right: 8px;
}

.mg-right-16px {
  margin-right: 16px;
}

.mg-right-24px {
  margin-right: 24px;
}

.text-200 {
  font-size: 14px;
  line-height: 1.143em;
}

.text-200.medium {
  font-weight: 500;
}

.text-200.bold {
  font-weight: 700;
}

.text-200.text-uppercase {
  letter-spacing: .06em;
}

.text-200.chat-details-text {
  color: var(--neutral--500);
  font-weight: 500;
}

.display-1 {
  color: var(--neutral--800);
  font-size: 78px;
  font-weight: 500;
  line-height: 1.077em;
}

.grid-1-column {
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.grid-1-column.dropdown-link-column {
  grid-column-gap: 0px;
  grid-row-gap: 12px;
  justify-items: start;
}

.grid-1-column.gap-row-12px {
  grid-row-gap: 12px;
}

.gap-16px {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
}

.gap-column-8px {
  grid-column-gap: 8px;
}

.gap-column-32px {
  grid-column-gap: 32px;
}

.gap-column-24px {
  grid-column-gap: 24px;
}

.gap-column-40px {
  grid-column-gap: 40px;
}

.gap-column-48px {
  grid-column-gap: 48px;
}

.gap-row-16px {
  grid-row-gap: 16px;
}

.gap-row-24px {
  grid-row-gap: 24px;
}

.gap-row-48px {
  grid-row-gap: 48px;
}

.gap-row-80px {
  grid-row-gap: 80px;
}

.display-2 {
  color: var(--neutral--800);
  font-size: 54px;
  font-weight: 700;
  line-height: 1.222em;
}

.display-2.color-neutral-100 {
  color: var(--neutral--100);
}

.display-3 {
  color: var(--neutral--800);
  font-size: 44px;
  font-weight: 700;
  line-height: 1.182em;
}

.display-4 {
  color: var(--neutral--800);
  font-size: 22px;
  font-weight: 700;
  line-height: 1.455em;
}

.style---style-grid-wrapper {
  grid-row-gap: 180px;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
}

.paragraph-large {
  font-size: 18px;
  line-height: 1.778em;
}

.paragraph-small {
  font-size: 12px;
  line-height: 1.833em;
}

.text-400 {
  font-size: 18px;
  line-height: 1.3em;
}

.text-400.medium {
  font-weight: 500;
}

.text-400.bold {
  font-weight: 700;
}

.text-400.text-uppercase {
  letter-spacing: .06em;
}

.text-300 {
  font-size: 16px;
  line-height: 1.125em;
}

.text-300.medium {
  font-weight: 500;
}

.text-300.medium.link {
  color: var(--neutral--600);
  text-decoration: none;
}

.text-300.medium.link:hover {
  color: var(--accent--primary-1);
}

.text-300.bold {
  font-weight: 700;
}

.text-300.text-uppercase {
  letter-spacing: .06em;
}

.text-100 {
  font-size: 12px;
  line-height: 1.167em;
}

.text-100.medium {
  font-weight: 500;
}

.text-100.bold {
  font-weight: 700;
}

.text-100.text-uppercase {
  letter-spacing: .06em;
}

.text-uppercase {
  letter-spacing: .1em;
  text-transform: uppercase;
}

.grid-3-columns {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.grid-3-columns.style---buttons-grid {
  grid-column-gap: 70px;
  grid-template-columns: auto auto 1fr;
}

.grid-3-columns.gap-row-48px {
  grid-row-gap: 48px;
}

.grid-3-columns.reports-graphs {
  margin-bottom: 24px;
}

.grid-3-columns.gap-col-20px---row-28px {
  grid-column-gap: 20px;
  grid-row-gap: 28px;
}

.shadow-01 {
  box-shadow: 0 2px 10px 0 var(--general--shadow-01);
}

.style---heading {
  background-color: var(--accent--primary-1);
  box-shadow: 0 2px 6px 0 var(--general--shadow-02);
  background-image: url('../images/styleguide-top-pattern-2-dashboardly-webflow-template.svg'), url('../images/styleguide-top-pattern-1-dashboardly-webflow-template.svg');
  background-position: 104% -100%, -9% 360%;
  background-repeat: no-repeat, no-repeat;
  background-size: auto, auto;
  align-items: center;
  margin-bottom: 64px;
  padding-top: 72px;
  padding-bottom: 72px;
  display: flex;
}

.inner-container._600px {
  max-width: 600px;
}

.inner-container.center {
  margin-left: auto;
  margin-right: auto;
}

.inner-container._460px {
  max-width: 460px;
}

.inner-container._500px {
  max-width: 500px;
}

.inner-container._520px {
  max-width: 520px;
}

.inner-container._760px {
  max-width: 760px;
}

.inner-container._434px {
  max-width: 434px;
}

.inner-container._430px {
  max-width: 430px;
}

.inner-container._894px {
  max-width: 894px;
}

.inner-container._694px {
  max-width: 694px;
}

.inner-container._372px {
  max-width: 372px;
}

.inner-container._554px {
  max-width: 554px;
}

.inner-container._142px {
  max-width: 142px;
}

.inner-container._166px {
  max-width: 166px;
}

.inner-container._474px {
  max-width: 474px;
}

.inner-container._514px {
  max-width: 514px;
}

.inner-container._468px {
  max-width: 468px;
}

.inner-container._55px {
  max-width: 55px;
}

.inner-container._580px {
  max-width: 580px;
}

.inner-container._342px {
  max-width: 342px;
}

.inner-container._64px {
  max-width: 64px;
}

.inner-container._54px {
  max-width: 54px;
}

.inner-container._322px {
  max-width: 322px;
}

.inner-container._370px {
  max-width: 370px;
}

.inner-container._444px {
  max-width: 444px;
}

.inner-container._416px {
  max-width: 416px;
}

.inner-container._478px {
  max-width: 478px;
}

.inner-container._458px {
  max-width: 458px;
}

.inner-container._472px {
  max-width: 472px;
}

.inner-container._60px {
  max-width: 60px;
}

.inner-container._594px {
  max-width: 594px;
}

.inner-container._552px {
  max-width: 552px;
}

.inner-container._544px {
  max-width: 544px;
}

.inner-container._360px {
  max-width: 360px;
}

.style---content-heading {
  background-color: var(--neutral--100);
  box-shadow: 0 2px 6px 0 var(--general--shadow-02);
  border-radius: 24px;
  align-items: center;
  margin-bottom: 60px;
  padding: 48px;
  display: flex;
}

.style---heading-icon-wrapper {
  background-color: var(--accent--primary-1);
  border-radius: 24px;
  margin-right: 18px;
}

.mg-bottom-0 {
  margin-bottom: 0;
}

.mg-bottom-0.mg-right-8px {
  display: flex;
}

.border-radius-16px {
  border-radius: 16px;
}

.border-radius-24px {
  z-index: -1;
  border-radius: 24px;
}

.style---card-wrapper {
  border: 1px solid var(--neutral--300);
  background-color: var(--neutral--100);
  box-shadow: 0 2px 7px 0 var(--general--shadow-01);
  border-radius: 24px;
}

.style---components-wrapper {
  background-color: var(--neutral--100);
  border-radius: 24px;
}

.style---bg-white {
  padding: 48px;
}

.style-buttons-dark {
  background-color: var(--neutral--800);
  border-bottom-right-radius: 24px;
  border-bottom-left-radius: 24px;
  padding: 48px;
}

.btn-primary {
  background-color: var(--accent--primary-1);
  box-shadow: 0 4px 10px 0 var(--button-shadow--color-2);
  color: var(--neutral--100);
  text-align: center;
  transform-style: preserve-3d;
  border-radius: 44px;
  justify-content: center;
  padding: 18px 22px;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.143em;
  text-decoration: none;
  transition: background-color .3s, transform .3s, color .3s;
}

.btn-primary:hover {
  background-color: var(--color-primary-hover);
  color: var(--neutral--100);
  transform: scale3d(1.06, 1.06, 1.01);
}

.btn-primary.small {
  padding: 12px 18px;
  font-size: 12px;
  line-height: 1.167em;
}

.btn-primary.large {
  padding: 20px 32px;
  font-size: 16px;
  line-height: 1.125em;
}

.btn-primary.white {
  background-color: var(--neutral--100);
  color: var(--accent--primary-1);
}

.btn-primary.white:hover {
  background-color: var(--secondary--color-2);
  color: var(--accent--primary-1);
}

.btn-primary.button-row {
  margin-right: 14px;
}

.btn-primary.download-app {
  display: flex;
}

.btn-primary.hover-none {
  transform-style: preserve-3d;
  transition: none;
}

.btn-primary.hover-none:hover {
  background-color: var(--accent--primary-1);
  transform: none;
}

.btn-primary.notifications-button {
  border: 1px solid var(--accent--primary-1);
  padding: 12px 28px;
}

.timer {
  border: 1px solid var(--neutral--300);
  background-color: var(--system--green-100);
  box-shadow: 0 4px 10px 0 var(--button-shadow--white-01);
  color: var(--neutral--800);
  text-align: center;
  transform-style: preserve-3d;
  border-radius: 48px;
  padding: 18px 22px;
  font-size: 14px;
  line-height: 1.143em;
  transition: border-color .3s, transform .3s, background-color .3s, color .3s;
  display: flex;
  grid-column-gap: 6px;
  padding: 14px 24px;
  font-weight: 700;
  position: absolute;
  top: 0;
  /* Adjust these values as needed to align the timer */
  right: 5px;
  z-index: 10;
}

.position-relative {
  position: relative;
}

.btn-secondary {
  border: 1px solid var(--neutral--300);
  background-color: var(--neutral--100);
  box-shadow: 0 4px 10px 0 var(--button-shadow--white-01);
  color: var(--neutral--800);
  text-align: center;
  transform-style: preserve-3d;
  border-radius: 48px;
  padding: 18px 22px;
  font-size: 14px;
  line-height: 1.143em;
  transition: border-color .3s, transform .3s, background-color .3s, color .3s;
}

.btn-secondary:hover {
  border-color: var(--accent--primary-1);
  background-color: var(--accent--primary-1);
  color: var(--neutral--100);
  transform: scale3d(1.06, 1.06, 1.01);
}

.btn-secondary.small {
  padding: 12px 18px;
  font-size: 12px;
  line-height: 1.167em;
}

.btn-secondary.small.profile-v3-edit-button {
  position: absolute;
  bottom: 24px;
  right: 24px;
}

.btn-secondary.large {
  padding: 20px 32px;
  font-size: 16px;
  line-height: 1.125em;
}

.btn-secondary.white {
  color: var(--neutral--100);
  background-color: rgba(0, 0, 0, 0);
}

.btn-secondary.white:hover {
  border-color: var(--neutral--100);
  background-color: var(--neutral--100);
  color: var(--neutral--800);
}

.btn-secondary.tab-button {
  grid-column-gap: 6px;
  padding: 14px 24px;
  font-weight: 700;
  display: flex;
}

.btn-secondary.tab-button.w--current {
  border-color: var(--accent--primary-1);
  background-color: var(--accent--primary-1);
  box-shadow: 0 4px 10px 0 var(--button-shadow--color-2);
  color: var(--neutral--100);
}

.btn-secondary.tab-button.w--current:hover {
  background-color: #004cff;
  border-color: #004cff;
}

.btn-secondary.gray {
  background-color: var(--neutral--200);
  box-shadow: 0 2px 10px rgba(25, 93, 194, .07);
}

.btn-secondary.gray:hover {
  background-color: var(--accent--primary-1);
}

.btn-secondary.settings-v3-tab-button {
  grid-column-gap: 4px;
  align-items: center;
  padding: 12px 18px;
  font-size: 12px;
  line-height: 1.167em;
  display: flex;
}

.btn-secondary.settings-v3-tab-button.w--current {
  border-color: var(--accent--primary-1);
  background-color: var(--accent--primary-1);
  color: var(--neutral--100);
  font-weight: 700;
}

.btn-secondary.settings-v3-tab-button.w--current:hover {
  border-color: var(--color-primary-hover);
  background-color: var(--color-primary-hover);
}

.btn-secondary.profile-tab-button {
  grid-column-gap: 6px;
  align-items: center;
  padding: 12px 18px;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.143em;
  display: flex;
}

.btn-secondary.profile-tab-button.w--current {
  border-color: var(--accent--primary-1);
  background-color: var(--accent--primary-1);
  color: var(--neutral--100);
}

.btn-secondary.profile-tab-button.w--current:hover {
  border-color: var(--color-primary-hover);
  background-color: var(--color-primary-hover);
}

.btn-secondary.pricing-tab-button {
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.btn-secondary.pricing-tab-button.w--current {
  border-color: var(--accent--primary-1);
  background-color: var(--accent--primary-1);
  box-shadow: 0 4px 10px 0 var(--button-shadow--color-2);
  color: var(--neutral--100);
}

.btn-secondary.pricing-tab-button.w--current:hover {
  border-color: var(--color-primary-hover);
  background-color: var(--color-primary-hover);
}

.btn-secondary.hover-none {
  transform-style: preserve-3d;
  transition: none;
}

.btn-secondary.hover-none:hover {
  border-color: var(--neutral--300);
  background-color: var(--neutral--100);
  color: var(--neutral--800);
  transform: none;
}

.btn-secondary.notifications-button {
  align-items: center;
  padding: 10px 18px;
  display: flex;
}

.btn-secondary.sign-in-button {
  padding-top: 14px;
  padding-bottom: 14px;
  text-decoration: none;
}

.btn-secondary.sign-in-button:hover {
  border-color: var(--secondary--color-2);
  background-color: var(--secondary--color-2);
  color: var(--neutral--800);
}

.btn-secondary.integrations-tab-button {
  grid-column-gap: 6px;
  padding: 14px 24px;
  font-weight: 500;
  display: flex;
}

.btn-secondary.integrations-tab-button.w--current {
  border-color: var(--accent--primary-1);
  background-color: var(--accent--primary-1);
  box-shadow: 0 4px 10px 0 var(--button-shadow--color-2);
  color: var(--neutral--100);
}

.btn-secondary.integrations-tab-button.w--current:hover {
  border-color: var(--color-primary-hover);
  background-color: var(--color-primary-hover);
}

.btn-secondary.homepage-pages-tab-button {
  grid-column-gap: 6px;
  align-items: center;
  padding: 14px 24px;
  font-weight: 700;
  display: flex;
}

.btn-secondary.homepage-pages-tab-button.w--current {
  border-color: var(--accent--primary-1);
  background-color: var(--accent--primary-1);
  box-shadow: 0 4px 10px 0 var(--button-shadow--color-2);
  color: var(--neutral--100);
}

.btn-secondary.homepage-pages-tab-button.w--current:hover {
  border-color: var(--color-primary-hover);
  background-color: var(--color-primary-hover);
}

.badge-primary {
  background-color: var(--accent--primary-1);
  color: var(--neutral--100);
  border-radius: 40px;
  padding: 9px 10px;
  font-size: 12px;
  line-height: 1.167em;
  display: inline-block;
}

.badge-primary.white {
  background-color: var(--neutral--100);
  color: var(--accent--primary-1);
}

.badge-primary.light {
  background-color: var(--secondary--color-2);
  color: var(--accent--primary-1);
}

.badge-primary.small {
  padding: 5px 10px;
}

.badge-primary.large {
  padding: 10px 16px;
  font-size: 16px;
  line-height: 1.125em;
}

.badge-primary.medium {
  font-weight: 500;
}

.badge-primary.bold {
  font-weight: 700;
}

.badge-primary.link {
  transform-style: preserve-3d;
  transition: transform .3s, background-color .3s, color .3s;
}

.badge-primary.link:hover {
  background-color: var(--color-primary-hover);
  color: var(--neutral--100);
  transform: scale3d(1.06, 1.06, 1.01);
}

.badge-secondary {
  border: 1px solid var(--neutral--300);
  background-color: var(--neutral--100);
  box-shadow: 0 2px 12px 0 var(--general--shadow-02);
  color: var(--neutral--800);
  text-align: center;
  border-radius: 40px;
  padding: 7px 10px;
  font-size: 12px;
  line-height: 1.167em;
  text-decoration: none;
  display: inline-block;
}

.badge-secondary.white {
  border-color: var(--neutral--100);
  box-shadow: none;
  color: var(--neutral--100);
  background-color: rgba(0, 0, 0, 0);
}

.badge-secondary.light {
  background-color: var(--neutral--200);
  box-shadow: none;
  border-width: 0;
}

.badge-secondary.small {
  padding: 5px 10px;
}

.badge-secondary.large {
  padding: 12px 16px;
  font-size: 16px;
  line-height: 1.125em;
}

.badge-secondary.medium {
  font-weight: 500;
}

.badge-secondary.bold {
  font-weight: 700;
}

.badge-secondary.gray {
  border-color: var(--neutral--200);
  background-color: var(--neutral--200);
  box-shadow: none;
  color: var(--neutral--600);
  white-space: nowrap;
  padding-top: 9px;
  padding-bottom: 9px;
  font-weight: 500;
}

.badge-secondary.blue {
  border-color: var(--system--blue-100);
  background-color: var(--system--blue-100);
  box-shadow: none;
  color: var(--accent--primary-1);
}

.badge-secondary.green {
  border-color: var(--secondary--color-11);
  background-color: var(--secondary--color-11);
  box-shadow: none;
  color: var(--system--green-400);
}

.badge-secondary.orange {
  border-color: var(--system--orange-100);
  background-color: var(--system--orange-100);
  box-shadow: none;
  color: var(--system--orange-400);
}

.badge-secondary.purple {
  border-color: var(--secondary--color-3);
  background-color: var(--secondary--color-3);
  box-shadow: none;
  color: var(--secondary--color-4);
}

.badge-secondary.red {
  border-color: var(--system--red-100);
  background-color: var(--system--red-100);
  box-shadow: none;
  color: var(--system--red-400);
}

.badge-secondary.figma-file-badge {
  padding: 18px 26px;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.125em;
}

.input {
  min-height: 52px;
  border: 1px solid var(--neutral--300);
  background-color: var(--neutral--100);
  box-shadow: 0 2px 12px 0 var(--general--shadow-02);
  border-radius: 52px;
  margin-bottom: 0;
  padding: 8px 20px;
  font-size: 14px;
  line-height: 1.143em;
  transition: box-shadow .3s, color .3s, border-color .3s;
}

.input:hover {
  border-color: var(--neutral--500);
  box-shadow: 0 2px 12px 0 var(--general--shadow-03);
}

.input:focus,
.input.-wfp-focus {
  border-color: var(--accent--primary-1);
  color: var(--neutral--800);
}

.input.small {
  min-height: 38px;
  box-shadow: none;
  border-radius: 38px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 12px;
  line-height: 1.167em;
}

.input.large {
  min-height: 80px;
  border-radius: 80px;
  padding: 22px 24px;
}

.input.mg-bottom-16px {
  margin-bottom: 16px;
}

.input.dashboard-top-search-bar {
  min-height: 50px;
  color: var(--neutral--800);
  padding: 14px 18px 14px 44px;
}

.input.dashboard-top-search-bar::-ms-input-placeholder {
  color: var(--neutral--600);
}

.input.dashboard-top-search-bar::placeholder {
  color: var(--neutral--600);
}

.input.chat-search-bar {
  min-height: 44px;
  box-shadow: none;
  color: var(--neutral--800);
  padding: 0 18px 0 44px;
  font-weight: 500;
}

.input.chat-search-bar::-ms-input-placeholder {
  color: var(--neutral--600);
}

.input.chat-search-bar::placeholder {
  color: var(--neutral--600);
}

.input.chat-type-message {
  min-height: 44px;
  box-shadow: none;
  color: var(--neutral--800);
  padding: 14px 13px;
  font-weight: 500;
}

.input.chat-type-message::-ms-input-placeholder {
  color: var(--neutral--600);
}

.input.chat-type-message::placeholder {
  color: var(--neutral--600);
}

.input.token-input {
  min-height: 42px;
  background-color: var(--neutral--200);
  box-shadow: none;
  padding: 0 48px 0 12px;
}

.input.token-input::-ms-input-placeholder {
  color: var(--neutral--500);
}

.input.token-input::placeholder {
  color: var(--neutral--500);
}

.input.dropdown-input {
  min-height: auto;
  box-shadow: none;
  color: var(--neutral--600);
  background-color: rgba(0, 0, 0, 0);
  border-style: none;
  padding: 0;
}

.input.dropdown-wrapper {
  align-items: center;
  display: flex;
}

.input.mg-bottom-24px {
  margin-bottom: 24px;
}

.input.help-center-search-bar {
  min-height: 54px;
  box-shadow: none;
  color: var(--neutral--800);
  padding: 0 44px 0 20px;
  font-weight: 500;
}

.input.help-center-search-bar::-ms-input-placeholder {
  color: var(--neutral--600);
}

.input.help-center-search-bar::placeholder {
  color: var(--neutral--600);
}

.input.cart-quantity-input {
  width: 50px;
  min-height: 38px;
  border-radius: 8px;
  padding: 0 12px;
}

.position-relative {
  position: relative;
}

.text-area {
  min-height: 140px;
  border: 1px solid var(--neutral--300);
  background-color: var(--neutral--100);
  box-shadow: 0 2px 12px 0 var(--general--shadow-02);
  border-radius: 22px;
  margin-bottom: 0;
  padding: 24px;
  font-size: 14px;
  line-height: 1.143em;
  transition: box-shadow .3s, color .3s, border-color .3s;
}

.text-area:hover {
  border-color: var(--neutral--400);
  box-shadow: 0 2px 12px 0 var(--general--shadow-03);
}

.text-area:focus {
  border-color: var(--accent--primary-1);
  color: var(--neutral--800);
}

.text-area.small {
  min-height: 102px;
  font-size: 12px;
  line-height: 1.167em;
}

.text-area.small::-ms-input-placeholder {
  font-size: 16px;
  line-height: 18px;
}

.text-area.small::placeholder {
  font-size: 16px;
  line-height: 18px;
}

.text-area.add-note-text-area {
  min-height: 90px;
  box-shadow: none;
  border-style: none none solid;
  border-radius: 0;
  padding: 0;
}

.text-area.add-note-text-area::-ms-input-placeholder {
  color: var(--neutral--600);
}

.text-area.add-note-text-area::placeholder {
  color: var(--neutral--600);
}

.checkbox-field-wrapper {
  align-items: center;
  margin-bottom: 0;
  padding-left: 0;
  font-size: 18px;
  line-height: 22px;
  display: flex;
}

.checkbox-field-wrapper.large {
  font-size: 20px;
  line-height: 24px;
}

.checkbox-field-wrapper.small {
  font-size: 16px;
  line-height: 20px;
}

.checkbox-field-wrapper.mg-bottom-24px {
  margin-bottom: 24px;
}

.checkbox {
  width: auto;
  height: auto;
  min-height: 18px;
  min-width: 18px;
  border-width: .6px;
  border-color: var(--neutral--300);
  background-color: var(--neutral--100);
  border-radius: 5px;
  margin-top: 0;
  margin-left: 0;
  transition: border-color .3s, background-color .3s;
  box-shadow: 0 2px 10px rgba(25, 93, 194, .07), inset 0 0 3px rgba(11, 22, 44, .1);
}

.checkbox:hover {
  border-color: var(--accent--primary-1);
}

.checkbox.w--redirected-checked {
  border-color: var(--accent--primary-1);
  background-color: var(--accent--primary-1);
  background-image: url('../images/check-form-brix-templates-webflow.svg');
  background-size: 64%;
  border-radius: 5px;
  margin-top: 0;
  margin-left: 0;
}

.checkbox.w--redirected-focus {
  box-shadow: none;
}

.checkbox.large {
  min-height: 22px;
  min-width: 22px;
}

.checkbox.small {
  width: 12px;
  height: 12px;
  min-height: 12px;
  min-width: 12px;
  border-radius: 3.6px;
}

.radio-button-field-wrapper {
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 24px;
  padding-left: 0;
  font-size: 18px;
  line-height: 24px;
  display: flex;
}

.radio-button-field-wrapper.large {
  font-size: 20px;
  line-height: 26px;
}

.radio-button-field-wrapper.small {
  font-size: 16px;
  line-height: 22px;
}

.radio-button {
  width: auto;
  height: auto;
  min-height: 18px;
  min-width: 18px;
  border-width: .6px;
  border-color: var(--neutral--300);
  background-color: var(--neutral--100);
  box-shadow: 0 2px 6px 0 var(--general--shadow-01);
  margin-top: 0;
  margin-left: 0;
  transition: border-color .3s, border-width .3s, background-color .3s;
}

.radio-button:hover {
  border-color: var(--accent--primary-1);
}

.radio-button.w--redirected-checked {
  border-width: 4px;
  border-color: var(--accent--primary-1);
}

.radio-button.w--redirected-focus {
  box-shadow: none;
}

.radio-button.large {
  min-height: 22px;
  min-width: 22px;
}

.radio-button.large.w--redirected-checked {
  border-width: 5px;
}

.radio-button.small {
  min-height: 14px;
  min-width: 14px;
}

.style---avatars-container {
  grid-column-gap: 26px;
  grid-row-gap: 32px;
  flex-wrap: wrap;
  grid-template-rows: auto;
  grid-template-columns: minmax(min-content, max-content) minmax(min-content, max-content) minmax(min-content, max-content) minmax(min-content, max-content) minmax(min-content, max-content) minmax(min-content, max-content);
  justify-content: space-between;
  align-items: end;
  display: flex;
}

.avatar-circle {
  width: 100%;
  border-radius: 50%;
  overflow: hidden;
  transform: translate(0);
}

.avatar-circle._01 {
  width: 32px;
  height: 32px;
  max-height: 32px;
  max-width: 32px;
  min-height: 32px;
  min-width: 32px;
}

.avatar-circle._01.avatar-letters {
  font-size: 12px;
  line-height: 1.167em;
}

.avatar-circle._02 {
  width: 40px;
  height: 40px;
  max-height: 40px;
  max-width: 40px;
  min-height: 40px;
  min-width: 40px;
}

.avatar-circle._02.avatar-letters {
  font-size: 14px;
  line-height: 1.143em;
}

.avatar-circle._03 {
  width: 48px;
  height: 48px;
  max-height: 48px;
  max-width: 48px;
  min-height: 48px;
  min-width: 48px;
}

.avatar-circle._03.avatar-letters {
  font-size: 16px;
  line-height: 1.125em;
}

.avatar-circle._04 {
  width: 80px;
  height: 80px;
  max-height: 80px;
  max-width: 80px;
  min-height: 80px;
  min-width: 80px;
}

.avatar-circle._04.avatar-letters {
  font-size: 18px;
  line-height: 1.111em;
}

.avatar-circle._05 {
  width: 120px;
  height: 120px;
  max-height: 120px;
  max-width: 120px;
  min-height: 120px;
  min-width: 120px;
}

.avatar-circle._05.avatar-letters {
  font-size: 32px;
  line-height: 1.5em;
}

.avatar-circle._06 {
  width: 160px;
  height: 160px;
  max-height: 160px;
  max-width: 160px;
  min-height: 160px;
  min-width: 160px;
}

.avatar-circle._06.avatar-letters {
  font-size: 42px;
  line-height: 1.286em;
}

.avatar-circle.avatar-letters {
  background-color: var(--secondary--color-2);
  color: var(--accent--primary-1);
  justify-content: center;
  align-items: center;
  font-weight: 700;
  display: flex;
}

.style---social-square-icons-grid {
  grid-column-gap: 24px;
  grid-template-rows: auto;
  grid-template-columns: repeat(auto-fit, 36px);
}

.social-icon-font {
  font-family: Social Icons Font, sans-serif;
  line-height: 21px;
}

.social-icon-font.button-left {
  margin-right: 6px;
}

.social-icon-square {
  width: 20px;
  height: 20px;
  min-height: 20px;
  min-width: 20px;
  transform-style: preserve-3d;
  background-color: rgba(0, 0, 0, 0);
  justify-content: center;
  align-items: center;
  text-decoration: none;
  transition: transform .3s, color .3s;
  display: flex;
}

.social-icon-square:hover {
  color: var(--color-primary-hover);
  transform: scale3d(1.08, 1.08, 1.01);
}

.line-rounded-icon {
  font-family: Line Rounded Icons, sans-serif;
}

.line-rounded-icon.link-icon-right {
  margin-left: 6px;
  display: inline-block;
}

.line-rounded-icon.link-icon-right.mg-left-4px {
  margin-left: 4px;
}

.line-rounded-icon.link-icon-left {
  margin-right: 6px;
  display: inline-block;
}

.line-rounded-icon.dropdown-arrow {
  margin-left: 8px;
}

.line-rounded-icon.dropdown-arrow.profile-dropdown {
  color: var(--neutral--500);
  margin-left: 12px;
}

.line-rounded-icon.dropdown-arrow.module-dropdown {
  color: var(--neutral--500);
  line-height: 1em;
}

.line-rounded-icon.dropdown-arrow.dashboard-dropdown-top-icon {
  margin-left: auto;
  line-height: 1em;
}

.line-rounded-icon.success-message-check {
  color: var(--accent--primary-1);
  margin-bottom: 24px;
  font-size: 64px;
  line-height: 1em;
}

.line-rounded-icon.sidebar-dropdown-arrow {
  margin-left: auto;
}

.line-rounded-icon.dashboard-top-search-bar-icon {
  z-index: 1;
  color: var(--neutral--500);
  align-items: center;
  font-size: 18px;
  line-height: 1em;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 18px;
}

.line-rounded-icon.dashboard-top-dropdown-icon {
  color: var(--neutral--800);
  margin-right: 8px;
  font-size: 18px;
  line-height: 1em;
}

.line-rounded-icon.breadcrumb-divider {
  margin-left: 14px;
  margin-right: 14px;
}

.line-rounded-icon.breadcrumb-divider._8px {
  margin-left: 8px;
  margin-right: 8px;
}

.line-rounded-icon.chat-search-bar-icon {
  z-index: 1;
  color: var(--neutral--500);
  align-items: center;
  font-size: 18px;
  line-height: 1em;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 18px;
}

.line-rounded-icon.link {
  cursor: pointer;
  transition: color .3s;
}

.line-rounded-icon.link:hover {
  color: var(--accent--primary-1);
}

.line-rounded-icon.help-center-search-bar-icon {
  z-index: 1;
  color: var(--neutral--500);
  align-items: center;
  font-size: 18px;
  line-height: 1em;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 20px;
}

.line-rounded-icon.search-results-icon {
  color: var(--neutral--500);
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 18px;
}

.style---line-icons-grid {
  grid-column-gap: 24px;
  grid-template-rows: auto;
  grid-template-columns: repeat(auto-fit, 20px);
}

.line-square-icon {
  font-family: Line Square Icons, sans-serif;
}

.line-square-icon.alert-banner-close-icon {
  cursor: pointer;
  align-items: center;
  font-size: 16px;
  line-height: 1em;
  transition: opacity .3s;
  display: flex;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: auto;
  right: 24px;
}

.line-square-icon.alert-banner-close-icon:hover {
  opacity: .55;
}

.line-square-icon.alert-banner-close-icon.white {
  color: var(--neutral--100);
}

.filled-icons {
  font-family: Filled Icons, sans-serif;
}

.rich-text img {
  border-radius: 22px;
}

.rich-text h3 {
  margin-bottom: 12px;
}

.rich-text figure {
  width: 100%;
  max-width: 100%;
}

.rich-text ul {
  margin-top: 24px;
  margin-bottom: 18px;
}

.rich-text ol {
  margin-bottom: 40px;
}

.rich-text h6 {
  margin-top: 32px;
}

.rich-text h5 {
  margin-bottom: 12px;
}

.rich-text h4 {
  margin-bottom: 16px;
}

.buttons-row {
  align-items: center;
  display: flex;
}

.buttons-row.center {
  justify-content: center;
}

.width-100 {
  width: 100%;
}

.container-default {
  max-width: 1092px;
  /* padding-left: 12px;
  padding-right: 12px; */
}

.container-default.order-confirmation {
  display: block;
}

.section {
  padding-top: 180px;
  padding-bottom: 180px;
}

.banner-wrapper {
  border: 1px solid var(--neutral--300);
  background-color: var(--neutral--100);
  box-shadow: 0 2px 10px 0 var(--general--shadow-01);
  color: var(--neutral--800);
  border-radius: 12px;
  position: relative;
  overflow: hidden;
}

.banner-wrapper.info {
  border-color: var(--accent--primary-1);
  background-color: var(--accent--primary-1);
}

.banner-wrapper.success {
  border-color: var(--system--green-300);
  background-color: var(--system--green-300);
}

.banner-wrapper.caution {
  border-color: var(--system--orange-300);
  background-color: var(--system--orange-300);
}

.banner-wrapper.error {
  border-color: var(--system--300);
  background-color: var(--system--300);
}

.alert-banner-padding {
  width: 100%;
  align-items: center;
  padding: 20px 16px;
  display: flex;
}

.link-wrapper {
  color: var(--neutral--800);
  transition: none;
  display: inline-block;
}

.link-wrapper:hover,
.link-wrapper.color-primary {
  color: var(--accent--primary-1);
}

.link-wrapper.white {
  color: var(--neutral--100);
}

.link-wrapper.white:hover {
  color: var(--secondary--color-2);
}

.link-wrapper.color-accent-1 {
  color: var(--accent--primary-1);
}

.link-text {
  transition: color .3s;
  display: inline-block;
}

.heading-h1-size {
  color: var(--neutral--800);
  font-size: 42px;
  font-weight: 700;
  line-height: 1.286em;
}

.heading-h2-size {
  color: var(--neutral--800);
  font-size: 32px;
  font-weight: 700;
  line-height: 1.5em;
}

.heading-h2-size.color-neutral-100 {
  color: var(--neutral--100);
}

.heading-h3-size {
  color: var(--neutral--800);
  font-size: 24px;
  font-weight: 700;
  line-height: 1.417em;
}

.heading-h3-size.color-neutral-100 {
  color: var(--neutral--100);
}

.heading-h4-size {
  color: var(--neutral--800);
  font-size: 22px;
  font-weight: 700;
  line-height: 1.273em;
  text-decoration: none;
}

.heading-h5-size {
  color: var(--neutral--800);
  font-size: 18px;
  font-weight: 700;
  line-height: 1.333em;
}

.heading-h5-size.color-neutral-100 {
  color: var(--neutral--100);
}

.heading-h6-size {
  color: var(--neutral--800);
  font-size: 16px;
  font-weight: 700;
  line-height: 1.375em;
}

.heading-h6-size.medium {
  font-weight: 500;
}

.header-wrapper {
  background-color: var(--neutral--100);
  padding-top: 32px;
  padding-bottom: 32px;
}

.header-wrapper.border-bottom-solid {
  border-bottom: 1px solid var(--neutral--300);
}

.header-content-wrapper {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.header-nav-menu-wrapper.homepage {
  align-items: center;
  display: flex;
}

.header-nav-menu-list {
  z-index: 1;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 0;
  padding-left: 0;
  list-style-type: none;
  display: flex;
}

.header-nav-list-item {
  margin-bottom: 0;
  padding-left: 32px;
}

.dropdown-wrapper {
  z-index: 500;
}

.dropdown-wrapper.sidebar-dropdown {
  width: 100%;
  color: var(--neutral--700);
}

.dropdown-wrapper.module-dropdown {
  z-index: 1;
  border: 1px solid var(--neutral--300);
  background-color: var(--neutral--100);
  border-radius: 38px;
  padding: 10px 10px 10px 14px;
}

.dropdown-wrapper.dashboard-top-dropdown {
  z-index: 0;
  width: 100%;
  max-width: 228px;
  min-width: 228px;
  border: 1px solid var(--neutral--300);
  background-color: var(--neutral--100);
  box-shadow: 0 2px 10px 0 var(--general--shadow-01);
  border-radius: 50px;
  padding: 12px 12px 12px 18px;
}

.dropdown-toggle {
  z-index: 500;
  align-items: center;
  padding: 0;
  transition: color .3s;
  display: flex;
}

.dropdown-toggle:hover {
  color: var(--accent--primary-1);
}

.dropdown-toggle.sidebar-dropdown {
  width: 100%;
  margin-bottom: 4px;
  padding: 12px;
}

.dropdown-toggle.sidebar-dropdown:hover {
  color: var(--accent--primary-1);
}

.dropdown-column-wrapper {
  border: 1px solid var(--neutral--300);
  background-color: var(--neutral--100);
  box-shadow: 0 2px 12px 0 var(--general--shadow-02);
  border-radius: 20px;
  overflow: hidden;
  transform: translate(-50%);
}

.dropdown-column-wrapper.sidebar-dropdown {
  box-shadow: none;
  transform-origin: 50% 0;
  border-style: none;
  border-radius: 0;
  padding-top: 8px;
  position: static;
  transform: none;
}

.dropdown-column-wrapper.dashboard-nav-profile {
  width: 100%;
  min-width: 202px;
  box-shadow: 0 8px 28px 0 var(--general--shadow-03);
  border-radius: 20px;
  padding: 24px;
  transform: translate(-14%);
}

.dropdown-column-wrapper.module-dropdown {
  padding: 20px;
  top: 34px;
  right: 0;
  transform: none;
}

.dropdown-column-wrapper.dashboard-top-dropdown {
  padding: 20px;
  top: 42px;
  left: 0;
  transform: none;
}

.dropdown-column-wrapper.text-single-dropdown {
  z-index: 1;
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0);
  border-style: none;
  border-radius: 0;
  padding-top: 16px;
  top: 24px;
  transform: none;
}

.dropdown-column-wrapper.text-single-dropdown.reports {
  background-color: var(--neutral--100);
  border-style: solid;
  border-radius: 12px;
  padding: 24px;
}

.dropdown-column-wrapper.messages-dropdown {
  border-radius: 20px;
  padding: 16px;
  left: 50%;
  transform: none;
}

.dropdown-link {
  width: 100%;
  color: var(--neutral--600);
  padding: 0;
  line-height: 20px;
}

.dropdown-link:hover {
  color: var(--accent--primary-1);
}

.dropdown-link.sidebar-link {
  color: var(--neutral--700);
  border-radius: 48px;
  margin-bottom: 4px;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 38px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.125em;
}

.dropdown-link.sidebar-link:hover {
  color: var(--accent--primary-1);
}

.dropdown-link.sidebar-link.w--current {
  background-color: var(--secondary--color-2);
  color: var(--accent--primary-1);
}

.dropdown-link.sidebar-link.last {
  margin-bottom: 0;
}

.dropdown-link.sidebar-link.hover-none:hover {
  color: var(--neutral--700);
}

.dropdown-link.text-400.medium {
  color: var(--neutral--800);
}

.dropdown-link.text-400.medium:hover {
  color: var(--accent--primary-1);
}

.dropdown-pd {
  padding: 40px;
}

.sidebar-logo-wrapper {
  max-width: 170px;
  transform-style: preserve-3d;
  margin-right: 24px;
  transition: transform .3s, color .3s;
  position: relative;
}

.sidebar-logo-wrapper:hover {
  transform: scale3d(1.06, 1.06, 1.01);
}

.hamburger-menu-wrapper {
  transform-style: preserve-3d;
  padding: 0;
}

.hamburger-menu-wrapper.w--open {
  background-color: rgba(0, 0, 0, 0);
}

.hamburger-menu-wrapper.sidebar {
  transform-style: preserve-3d;
}

.hamburger-menu-bar {
  width: 52px;
  height: 4px;
  background-color: var(--neutral--800);
  border-radius: 20px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.header-right-side {
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.btn-circle-primary {
  width: 64px;
  height: 64px;
  min-height: 64px;
  min-width: 64px;
  background-color: var(--accent--primary-1);
  box-shadow: 0 4px 10px 0 var(--button-shadow--color-2);
  color: var(--neutral--100);
  transform-style: preserve-3d;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  font-size: 26px;
  text-decoration: none;
  transition: transform .3s, background-color .3s, color .3s;
  display: flex;
}

.btn-circle-primary:hover {
  background-color: var(--secondary--color-1);
  color: var(--neutral--100);
  transform: scale3d(1.1, 1.1, 1.01);
}

.btn-circle-primary.small {
  width: 44px;
  height: 44px;
  min-height: 44px;
  min-width: 44px;
  font-size: 22px;
}

.btn-circle-primary.small.chat-icon {
  padding-top: 2px;
  padding-right: 2px;
}

.btn-circle-primary.large {
  width: 88px;
  height: 88px;
  min-height: 88px;
  min-width: 88px;
  font-size: 30px;
  line-height: 32px;
}

.btn-circle-primary.white {
  background-color: var(--neutral--100);
  color: var(--accent--primary-1);
}

.btn-circle-primary.white:hover {
  background-color: var(--secondary--color-2);
  color: var(--accent--primary-1);
}

.btn-circle-secondary {
  width: 64px;
  height: 64px;
  min-height: 64px;
  min-width: 64px;
  border: 1px solid var(--neutral--300);
  background-color: var(--neutral--100);
  box-shadow: 0 2px 10px 0 var(--general--shadow-01);
  color: var(--neutral--600);
  transform-style: preserve-3d;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  font-size: 26px;
  line-height: 1em;
  text-decoration: none;
  transition: border-color .3s, transform .3s, background-color .3s, color .3s;
  display: flex;
}

.btn-circle-secondary:hover {
  border-color: var(--accent--primary-1);
  background-color: var(--accent--primary-1);
  color: var(--neutral--100);
  transform: scale3d(1.1, 1.1, 1.01);
}

.btn-circle-secondary.small {
  width: 34px;
  height: 34px;
  max-height: 34px;
  max-width: 34px;
  min-height: 34px;
  min-width: 34px;
  font-size: 16px;
}

.btn-circle-secondary.large {
  width: 88px;
  height: 88px;
  min-height: 88px;
  min-width: 88px;
  font-size: 30px;
  line-height: 32px;
}

.btn-circle-secondary.white {
  border-color: var(--neutral--100);
  box-shadow: none;
  color: var(--neutral--100);
  background-color: rgba(0, 0, 0, 0);
}

.btn-circle-secondary.white:hover {
  border-color: var(--accent--primary-1);
  background-color: var(--accent--primary-1);
}

.btn-circle-secondary.table-button {
  width: 34px;
  height: 34px;
  min-height: 34px;
  min-width: 34px;
  box-shadow: 0 2px 10px 0 var(--general--shadow-01);
  color: var(--neutral--600);
  font-size: 14px;
  line-height: 1em;
}

.btn-circle-secondary.table-button:hover {
  color: var(--neutral--100);
}

.utility-page-wrap {
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  padding-top: 100px;
  padding-bottom: 100px;
  display: flex;
}

.utility-page-content {
  width: 100%;
  max-width: 640px;
  text-align: center;
  flex-direction: column;
  display: flex;
}

.utility-page-content.max-width-100 {
  max-width: 100%;
}

.utility-page-form {
  width: 100%;
  max-width: 800px;
  flex-direction: column;
  align-items: stretch;
  display: flex;
}

.utility-page-form.max-width-100 {
  max-width: 100%;
}

.hidden-on-desktop {
  display: none;
}

.social-media-grid-top {
  flex: 1;
  grid-template-rows: auto;
  grid-template-columns: repeat(auto-fit, 20px);
  justify-content: start;
  justify-items: start;
}

.flex-horizontal {
  justify-content: center;
  align-items: center;
  display: flex;
}

.flex-horizontal.flex-wrap {
  flex-wrap: wrap;
}

.success-message {
  background-color: rgba(0, 0, 0, 0);
  padding: 0;
}

.success-message.utility-page-success-message {
  background-color: var(--secondary--color-2);
  color: var(--accent--primary-1);
  border-radius: 8px;
  padding: 20px 28px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.125em;
}

.error-message {
  background-color: var(--system--red-100);
  color: var(--system--300);
  text-align: center;
  border-radius: 16px;
  margin-top: 20px;
  padding: 20px 24px;
}

.error-message.password {
  width: 100%;
  max-width: 566px;
  margin-left: auto;
  margin-right: auto;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.sticky-top {
  position: -webkit-sticky;
  position: sticky;
  top: 24px;
}

.opacity-80 {
  opacity: .8;
}

.fit-cover {
  object-fit: cover;
}

.fit-cover.position-left {
  object-position: 0% 50%;
}

.overflow-hidden {
  overflow: hidden;
}

.text-decoration-none {
  text-decoration: none;
}

.text-decoration-none.color-transition-none {
  transition: none;
}

.mg-bottom-160px {
  margin-bottom: 160px;
}

.empty-state {
  border: 1px solid var(--neutral--300);
  background-color: var(--neutral--100);
  box-shadow: 0 2px 12px 0 var(--general--shadow-02);
  text-align: center;
  border-radius: 11px;
  padding: 26px 24px;
}

.gap-0 {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.overflow-visible {
  overflow: visible;
}

.mg-bottom-4px {
  margin-bottom: 4px;
}

.height-100 {
  height: 100%;
}

._404-not-found {
  color: var(--accent--primary-1);
  margin-top: 18px;
  margin-bottom: 6px;
  font-size: 124px;
  font-weight: 500;
  line-height: 1.161em;
}

.template-pages---nav-item-wrapper {
  margin-bottom: 0;
  padding-left: 0;
}

.template-pages---sidebar-navigation {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  margin-bottom: 0;
  padding-left: 0;
  list-style-type: none;
  display: grid;
}

.template-pages---nav-item-link {
  background-color: var(--neutral--100);
  color: var(--neutral--600);
  border-radius: 8px;
  padding: 12px;
  font-size: 16px;
  line-height: 1.125em;
  text-decoration: none;
  transition: background-color .3s, color .3s;
  display: flex;
}

.template-pages---nav-item-link:hover {
  background-color: var(--neutral--200);
  color: var(--neutral--600);
}

.template-pages---nav-item-link.w--current {
  background-color: var(--neutral--200);
  color: var(--neutral--700);
  font-weight: 500;
}

.template-pages---phography-wrapper {
  transform-style: preserve-3d;
  border-radius: 12px;
  transition: transform .3s, color .3s;
  overflow: hidden;
}

.template-pages---phography-wrapper:hover {
  transform: scale3d(1.04, 1.04, 1.01);
}

.bg-secondary-6 {
  background-color: var(--secondary--color-6);
}

.bg-secondary-7 {
  background-color: var(--secondary--color-7);
}

.bg-secondary-8 {
  background-color: var(--secondary--color-8);
}

.bg-secondary-9 {
  background-color: var(--secondary--color-9);
}

.bg-secondary-10 {
  background-color: var(--secondary--color-10);
}

.bg-secondary-11 {
  background-color: var(--secondary--color-11);
}

.bg-secondary-12 {
  background-color: var(--secondary--color-12);
}

.display-inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.flex.gap-col-30px {
  grid-column-gap: 30px;
}

.flex.click-inputs-container {
  grid-column-gap: 112px;
}

.flex.align-end {
  align-items: flex-end;
}

.flex.align-center {
  align-items: center;
}

.flex.children-wrap {
  flex-wrap: wrap;
}

.flex.gap-column-12px {
  grid-column-gap: 12px;
}

.flex.justify-space-between {
  justify-content: space-between;
}

.flex.gap-6px {
  grid-column-gap: 6px;
  grid-row-gap: 6px;
}

.flex.justify-end {
  justify-content: flex-end;
}

.flex.align-start {
  align-items: flex-start;
}

.flex.gap-12px {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
}

.flex.gap-18px {
  grid-column-gap: 18px;
  grid-row-gap: 18px;
}

.flex.justify-center {
  justify-content: center;
}

.upload-box-inner-container {
  background-color: var(--secondary--color-2);
  text-align: center;
  border-radius: 16px;
  padding: 34px 40px 64px;
}

.upload-box-icon {
  max-width: 158px;
  margin-bottom: -8px;
}

.mg-bottom-12px {
  margin-bottom: 12px;
}

.no-records-available-illustration {
  max-width: 180px;
  margin-bottom: 4px;
}

.action-completed-illustration {
  max-width: 180px;
  margin-bottom: 24px;
}

.theres-been-an-error-illustration,
.no-records-found-illustration {
  max-width: 186px;
  margin-bottom: 4px;
}

.notification-alert-illustration {
  max-width: 186px;
}

.close-button-popup-module {
  z-index: 1;
  cursor: pointer;
  transform-style: preserve-3d;
  font-family: Line Rounded Icons, sans-serif;
  font-size: 20px;
  line-height: 1em;
  transition: transform .3s;
  position: absolute;
  top: 20px;
  right: 20px;
}

.close-button-popup-module:hover {
  transform: scale3d(1.25, 1.25, 1.01);
}

.popup-wrapper {
  z-index: 504;
  background-color: rgba(11, 14, 44, .5);
  justify-content: center;
  align-items: center;
  padding: 24px;
  display: none;
  position: fixed;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.popup-top-icon {
  margin-bottom: 14px;
}

.popup-left-icon {
  margin-right: 18px;
}

.popup-top-image {
  margin-bottom: 24px;
}

.popup-left-image {
  margin-right: 40px;
}

.toggle-button-wrapper {
  max-height: 24px;
  max-width: 48px;
  min-height: 24px;
  min-width: 48px;
  background-color: var(--neutral--400);
  cursor: pointer;
  border-radius: 32px;
  align-items: center;
  padding: 2px;
  transition: box-shadow .3s;
  display: flex;
}

.toggle-button-wrapper:hover {
  box-shadow: 0 4px 10px 0 var(--button-shadow--white-03);
}

.toggle-button-wrapper.active {
  background-color: var(--accent--primary-1);
}

.toggle-button-inner-circle {
  max-height: 20px;
  max-width: 20px;
  min-height: 20px;
  min-width: 20px;
  box-shadow: 0 2px 12px 0 var(--general--shadow-02), inset 0 -1px 3px 0 rgba(11, 22, 44, .1);
  background-color: #fcfcfc;
  border-radius: 100000000px;
  transition: transform .3s;
}

.toggle-button-inner-circle.active {
  transform: translate(24px);
}

.mg-bottom-auto {
  margin-bottom: auto;
}

.dashly-custom-icon {
  font-family: Dashboardly Custom Icons, sans-serif;
}

.dashly-custom-icon.sidebar-link-icon {
  margin-right: 10px;
  font-size: 20px;
  line-height: 1em;
}

.dashly-custom-icon.dashboard-nav-profile-icon {
  color: var(--accent--primary-1);
  margin-right: 8px;
  font-size: 18px;
  line-height: 1em;
}

.dashly-custom-icon.dashboard-nav-profile-icon.color-secondary-8 {
  color: var(--secondary--color-8);
}

.dashly-custom-icon.table-sort-icon {
  margin-left: 6px;
  font-size: 10px;
  line-height: 1em;
}

.dashly-custom-icon.edit-icon {
  cursor: pointer;
  line-height: 1em;
  transition: color .3s;
}

.dashly-custom-icon.edit-icon:hover {
  color: var(--accent--primary-1);
}

.dashly-custom-icon.delete-icon {
  cursor: pointer;
  line-height: 1em;
  transition: color .3s;
}

.dashly-custom-icon.delete-icon:hover {
  color: var(--system--red-400);
}

.dashly-custom-icon.tasks-top-modules-detail-icon {
  cursor: pointer;
  font-size: 18px;
  line-height: 1em;
  transition: color .3s;
  position: absolute;
  top: 14px;
  right: 26px;
}

.dashly-custom-icon.tasks-top-modules-detail-icon:hover {
  color: var(--accent--primary-1);
}

.dashly-custom-icon.square-button {
  max-height: 26px;
  max-width: 26px;
  min-height: 26px;
  min-width: 26px;
  border: 1px solid var(--neutral--300);
  background-color: var(--neutral--200);
  color: var(--neutral--600);
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  line-height: 1em;
  text-decoration: none;
  display: flex;
}

.dashly-custom-icon.square-button:hover {
  color: var(--accent--primary-1);
}

.dashly-custom-icon.square-button.color-neutral-800 {
  color: var(--neutral--800);
}

.dashly-custom-icon.square-button.color-neutral-800:hover {
  color: var(--accent--primary-1);
}

.dashly-custom-icon.color-accent-1.mg-right-8px {
  margin-top: 1px;
}

.dashly-custom-icon.link-icon-left {
  margin-right: 6px;
  display: inline-block;
}

.dashly-custom-icon.link-icon-left.rotate-180deg {
  display: inline-block;
}

.dashly-custom-icon.link {
  cursor: pointer;
  transform-style: preserve-3d;
  text-decoration: none;
  transition: transform .3s, color .3s;
  display: inline-block;
}

.dashly-custom-icon.link:hover {
  color: var(--accent--primary-1);
  transform: scale3d(1.06, 1.06, 1.01);
}

.dashly-custom-icon.text-format-button {
  color: var(--neutral--800);
  cursor: pointer;
  font-size: 16px;
  line-height: 1em;
  transition: color .3s;
}

.dashly-custom-icon.text-format-button:hover {
  color: var(--accent--primary-1);
}

.dashly-custom-icon.view-icon {
  cursor: pointer;
  line-height: 1em;
  transition: color .3s;
}

.dashly-custom-icon.view-icon:hover {
  color: var(--accent--primary-1);
}

.dashly-custom-icon.download-icon {
  cursor: pointer;
  line-height: 1em;
  transition: color .3s;
}

.dashly-custom-icon.download-icon:hover {
  color: var(--accent--primary-1);
}

.dashly-custom-icon.link-icon {
  cursor: pointer;
  line-height: 1em;
  transition: color .3s;
}

.dashly-custom-icon.link-icon:hover {
  color: var(--accent--primary-1);
}

.dashly-custom-icon.details-icon {
  cursor: pointer;
  line-height: 1em;
  transition: color .3s;
}

.dashly-custom-icon.details-icon:hover {
  color: var(--accent--primary-1);
}

.dashly-custom-icon.chat-box-icon {
  cursor: pointer;
  font-size: 24px;
  line-height: 1em;
  transition: color .3s;
}

.dashly-custom-icon.chat-box-icon:hover {
  color: var(--accent--primary-1);
}

.dashly-custom-icon.token-input-copy-button {
  max-height: 30px;
  max-width: 30px;
  min-height: 30px;
  min-width: 30px;
  border: 1px solid var(--neutral--300);
  background-color: var(--neutral--100);
  box-shadow: 0 4px 10px 0 var(--button-shadow--white-01);
  cursor: pointer;
  border-radius: 99999px;
  justify-content: center;
  align-items: center;
  line-height: 1em;
  transition: color .3s;
  display: flex;
  position: absolute;
  top: 6px;
  right: 12px;
}

.dashly-custom-icon.token-input-copy-button:hover {
  color: var(--accent--primary-1);
}

.sidebar-wrapper {
  z-index: 500;
  width: 100%;
  height: 100vh;
  max-width: 275px;
  border-right: 1px solid var(--neutral--300);
  background-color: var(--neutral--100);
  box-shadow: 0 2px 12px 0 var(--general--shadow-02);
  padding-left: 28px;
  padding-right: 28px;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
}

.sidebar-logo-icon-wrapper {
  width: 100%;
  max-width: 30px;
  min-width: 30px;
  cursor: pointer;
  transform-style: preserve-3d;
  justify-content: center;
  align-items: center;
  transition: transform .3s;
  display: none;
}

.sidebar-logo-icon-wrapper:hover {
  transform: scale3d(1.1, 1.1, 1.01);
}

.sidebar-collapse-icon-inner-container {
  width: 100%;
  max-width: 20px;
  min-height: 10px;
  cursor: pointer;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.sidebar-logo-section-container {
  min-height: 74px;
  border-bottom: 1px solid var(--neutral--300);
  justify-content: space-between;
  align-items: center;
  padding-top: 22px;
  padding-bottom: 22px;
  display: flex;
}

.sidebar-collapse-icon-bar {
  width: 100%;
  height: 2.5px;
  max-height: 2.5px;
  min-height: 2.5px;
  min-width: 20px;
  background-color: var(--neutral--800);
  border-radius: 99999px;
}

.sidebar-nav-menu-list {
  z-index: 1;
  width: 100%;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  margin-bottom: 0;
  padding-left: 0;
  list-style-type: none;
  transition: transform .3s;
  display: flex;
}

.sidebar-nav-menu-list.mg-bottom-160px {
  margin-bottom: 160px;
}

.sidebar-nav-list-item {
  width: 100%;
  margin-bottom: 0;
  padding-left: 0;
  font-size: 16px;
  line-height: 1.125em;
}

.sidebar-menu-wrapper {
  width: 100%;
  padding-top: 28px;
  padding-bottom: 40px;
}

.sidebar-dropdown-text-container {
  align-items: center;
  text-decoration: none;
  transition: none;
  display: flex;
}

.sidebar-mobile-icon {
  display: none;
}

.sidebar-nav-menu {
  width: 100%;
}

.sidebar-collapse-icon-container {
  min-width: 20px;
  justify-content: center;
  align-items: center;
  transition: transform .3s;
  display: flex;
}

.sidebar-collapse-icon-container:hover {
  transform: scale(1.2);
}

.dashboard-main {
  align-items: flex-start;
  display: flex;
}

.sidebar-spacer {
  width: 275px;
  max-width: 275px;
  padding: 0;
}

.dashboard-content {
  flex: 1;
}

.dashboard-nav-container {
  align-items: center;
  padding-top: 14px;
  padding-bottom: 14px;
  display: flex;
}

.dashboard-top-search-bar-wrapper {
  width: 100%;
  max-width: 300px;
  margin-bottom: 0;
  position: relative;
}

.dashboard-nav-main-container {
  background-color: var(--neutral--100);
  box-shadow: 0 2px 12px 0 var(--general--shadow-02);
}

.dashboard-nav-right-content {
  align-items: center;
  margin-left: auto;
  display: flex;
}

.dashboard-nav-left-content {
  width: 60%;
  align-items: center;
  margin-right: 32px;
  display: flex;
}

.dashboard-nav-notification-container {
  max-height: 24px;
  max-width: 24px;
  min-height: 24px;
  min-width: 24px;
  color: var(--neutral--800);
  cursor: pointer;
  transform-style: preserve-3d;
  justify-content: center;
  align-items: flex-end;
  margin-left: 16px;
  margin-right: 12px;
  font-size: 20px;
  line-height: 1em;
  text-decoration: none;
  transition: transform .3s;
  display: flex;
  position: relative;
}

.dashboard-nav-notification-container:hover {
  color: var(--neutral--800);
  transform: scale3d(1.1, 1.1, 1.01);
}

.dashboard-nav-notification-dot {
  z-index: 1;
  max-height: 6px;
  max-width: 6px;
  min-height: 6px;
  min-width: 6px;
  background-color: var(--system--300);
  border-radius: 99999px;
  position: absolute;
  top: 0;
  right: 0;
}

.dashboard-profile-dropdown-link-wrapper {
  color: var(--neutral--600);
  align-items: center;
  margin-bottom: 16px;
  text-decoration: none;
  display: flex;
}

.dashboard-profile-dropdown-link-wrapper:hover {
  color: var(--accent--primary-1);
}

.dashboard-profile-dropdown-link-wrapper.mg-bottom-0 {
  margin-bottom: 0;
}

.dashboard-profile-dropdown-link-wrapper.transition-none {
  transition: none;
}

.color-secondary-8 {
  color: var(--secondary--color-8);
}

.position-relative---z-index-1 {
  z-index: 1;
  position: relative;
}

.sidebar-menu-collapsed-overlay {
  z-index: 99999999;
  display: none;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: -140%;
  right: -28px;
}

.dashboard-main-content {
  min-height: 92vh;
  padding-top: 48px;
  padding-bottom: 64px;
}

.dashboard-main-content.pd-top-0 {
  padding-top: 0;
}

.dashboard-main-content.utility-page {
  flex-direction: column;
  /* justify-content: center; */
  align-items: stretch;
  padding-top: 64px;
  display: flex;
min-height: 75vh;
}

.dashboard-main-content.pd-top-32px {
  padding-top: 32px;
}

.dashboard-main-content._404 {
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  display: flex;
}

.pages-link-image-wrapper {
  overflow: hidden;
}

.pages-link-image {
  width: 100%;
}

.pages-link-text-wrapper {
  padding: 32px 32px 40px;
}

.dashboard-footer-wrapper {
  text-align: center;
}

.dashboard-footer-inner-wrapper {
  border-top: 1px solid var(--neutral--300);
  padding-top: 24px;
  padding-bottom: 24px;
}

.mg-right-6px {
  margin-right: 6px;
}

.font-size-10px {
  font-size: 10px;
}

.reports-top-details-icon {
  margin-right: 12px;
}

.reports-top-details-container {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  flex-wrap: wrap;
  grid-template-rows: auto;
  grid-template-columns: repeat(auto-fit, minmax(248px, 1fr));
  grid-auto-columns: 1fr;
  justify-content: space-between;
  margin-bottom: 24px;
  display: grid;
}

.mg-right-10px {
  margin-right: 10px;
}

.mg-left-auto {
  margin-left: auto;
}

.recent-contacts-details-container {
  width: 100%;
  align-items: center;
  display: flex;
}

.mg-right-12px {
  margin-right: 12px;
}

.recent-orders-product-image {
  max-width: 60px;
  border-radius: 10px;
  margin-right: 10px;
}

.recent-orders-details-container {
  width: 100%;
  align-items: center;
  display: flex;
}

.mg-bottom-6px {
  margin-bottom: 6px;
}

.order-status-left-container {
  grid-column-gap: 14px;
  align-items: center;
  display: flex;
}

.order-status-right-container {
  grid-column-gap: 24px;
  flex: 1;
  justify-content: space-between;
  display: flex;
}

.orders-status-row {
  grid-column-gap: 48px;
  grid-row-gap: 16px;
  flex-wrap: wrap;
  align-items: center;
  display: flex;
}

.products-table-row {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  border-top: 1px solid var(--neutral--300);
  grid-template-rows: auto;
  grid-template-columns: 90px 2fr 1.5fr 1fr 1fr .75fr 74px;
  grid-auto-columns: 1fr;
  align-items: center;
  padding: 20px 36px;
  display: grid;
}

.products-table-row.header {
  background-color: var(--neutral--200);
}

.cursor-pointer {
  cursor: pointer;
}

.products-table-image {
  max-width: 32px;
  border-radius: 6px;
  margin-right: 14px;
}

.products-table-top-section {
  grid-column-gap: 32px;
  justify-content: space-between;
  align-items: center;
  padding: 24px 36px 28px;
  display: flex;
}

.progress-bar {
  width: 100%;
  max-height: 8px;
  min-height: 8px;
  background-color: var(--neutral--300);
  border-radius: 40px;
  position: relative;
}

.inner-progress-bar {
  background-color: var(--system--green-300);
  border-radius: 40px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 0 2px 9px rgba(20, 202, 116, .2);
}

.inner-progress-bar._50 {
  width: 50%;
}

.inner-progress-bar._20 {
  width: 20%;
}

.inner-progress-bar._80 {
  width: 80%;
}

.inner-progress-bar._60 {
  width: 60%;
}

.inner-progress-bar._32 {
  width: 32%;
}

.inner-progress-bar._90 {
  width: 90%;
}

.inner-progress-bar._30 {
  width: 30%;
}

.team-progress-row {
  grid-column-gap: 32px;
  grid-row-gap: 16px;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.team-progress-left-container {
  min-width: 144px;
  grid-column-gap: 8px;
  align-items: center;
  display: flex;
}

.team-progress-right-container {
  min-width: 250px;
  flex: 1;
}

.mg-bottom-20px {
  margin-bottom: 20px;
}

.mg-right-2px {
  margin-right: 2px;
}

.tasks-reports-container {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  flex-wrap: wrap;
  justify-content: space-between;
  display: flex;
}

.avatar-circle-outline-container {
  background-color: var(--neutral--100);
  box-shadow: 0 2px 10px 0 var(--general--shadow-01);
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.avatar-circle-outline-container._36px {
  max-height: 36px;
  max-width: 36px;
  min-height: 36px;
  min-width: 36px;
}

.avatar-circle-outline-container.mg-right--8px {
  margin-right: -8px;
}

.avatar-circle-outline-container.profile-v2-avatar {
  max-height: 88px;
  max-width: 88px;
  min-height: 88px;
  min-width: 88px;
  margin-right: 16px;
  padding: 4px;
}

.due-tasks-module-buttons-container {
  grid-column-gap: 8px;
  display: flex;
  position: absolute;
  top: 28px;
  right: 28px;
}

.tabs-menu-top {
  grid-column-gap: 16px;
  margin-bottom: 32px;
  display: flex;
}

.mg-bottom-10px {
  margin-bottom: 10px;
}

.contacts-modules-container {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  grid-template-rows: auto;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-auto-columns: 1fr;
  display: grid;
}

.table-item-mobile-caption {
  display: none;
}

.contacts-table-top-section {
  grid-column-gap: 32px;
  justify-content: space-between;
  align-items: center;
  padding: 28px 36px;
  display: flex;
}

.contacts-table-row {
  display: grid;
  grid-template-columns: 18% 9% 14% 14% 12% 17% auto;
  width: 100%;
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  align-items: center;
  padding: 16px;
  border-top: 1px solid var(--neutral--300);
}

.contacts-table-row.header {
  background-color: var(--neutral--200);
}

.add-note-top-section {
  border-bottom: 1px solid var(--neutral--300);
  padding-top: 24px;
  padding-left: 24px;
  padding-right: 24px;
}

.companies-table-top-section {
  grid-column-gap: 32px;
  justify-content: space-between;
  align-items: center;
  padding: 28px 36px;
  display: flex;
}

.companies-table-row {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  border-top: 1px solid var(--neutral--300);
  grid-template-rows: auto;
  grid-template-columns: .75fr .5fr .35fr .5fr .5fr 78px;
  grid-auto-columns: 1fr;
  align-items: center;
  padding: 16px 36px;
  display: grid;
}

.companies-table-row.header {
  background-color: var(--neutral--200);
}

.companies-modules-container {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  grid-template-rows: auto;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-auto-columns: 1fr;
  display: grid;
}

.companies-card-logo {
  box-shadow: 0 3px 4.8px 0 var(--general--shadow-02);
  border-radius: 16px;
  margin-right: 16px;
}

.add-note-top-link {
  grid-column-gap: 4px;
  grid-row-gap: 8px;
  border-bottom: 1px solid var(--neutral--300);
  color: var(--neutral--600);
  flex-wrap: wrap;
  margin-bottom: -1px;
  padding-bottom: 24px;
  padding-left: 18px;
  padding-right: 18px;
  font-weight: 500;
  line-height: 1.143em;
  text-decoration: none;
  transition: border-color .3s, color .3s;
  display: flex;
}

.add-note-top-link:hover {
  border-bottom-color: var(--neutral--700);
  color: var(--neutral--700);
}

.add-note-top-link.active {
  border-bottom-width: 1.5px;
  border-bottom-color: var(--accent--primary-1);
  color: var(--accent--primary-1);
}

.add-note-top-section-links-container {
  grid-column-gap: 10px;
  justify-content: center;
  display: flex;
}

.add-note-text-area-container {
  padding-top: 26px;
  padding-left: 22px;
  padding-right: 22px;
}

.add-note-text-area-menu {
  grid-column-gap: 24px;
  grid-row-gap: 16px;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 14px;
  padding-bottom: 14px;
  display: flex;
}

.text-format-buttons-container {
  align-items: center;
  display: flex;
}

.text-format-buttons-divider {
  max-width: 1px;
  min-height: 26px;
  min-width: 1px;
  background-color: var(--neutral--400);
  margin-left: 22px;
  margin-right: 22px;
}

.font-size-18px {
  font-size: 18px;
}

.modules-list-bg-line {
  z-index: -1;
  min-width: 6px;
  background-color: var(--neutral--300);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 56px;
}

.company-single-top-banner {
  width: 100%;
  object-fit: cover;
  margin-top: -48px;
  margin-bottom: -32px;
}

.company-single-about-text h4 {
  margin-bottom: 16px;
}

.company-single-about-text p {
  margin-top: 12px;
  margin-bottom: 0;
}

.company-single-about-text ul {
  margin-top: 24px;
  margin-bottom: 18px;
  padding-left: 32px;
}

.company-single-about-text li {
  margin-bottom: 12px;
}

.company-single-about-logo {
  box-shadow: 0 2px 12px 0 var(--general--shadow-02);
  border-radius: 16px;
  margin-right: 24px;
}

.company-news-image-wrapper {
  max-width: 64px;
  border-radius: 8px;
  margin-right: 24px;
  overflow: hidden;
}

.news-details-divider {
  max-width: 1px;
  min-height: 20px;
  min-width: 1px;
  background-color: var(--neutral--300);
  margin-left: 12px;
  margin-right: 12px;
}

.mg-top-auto {
  margin-top: auto;
}

.features-tasks-title-container {
  width: 100%;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  color: var(--neutral--800);
  cursor: pointer;
  grid-template-rows: auto;
  grid-template-columns: 1fr auto;
  grid-auto-columns: 1fr;
  justify-content: space-between;
  align-items: center;
  transition: color .3s;
  display: grid;
}

.features-tasks-title-container:hover {
  color: var(--accent--primary-1);
}

.features-tasks-body-inner-container {
  border-top: 1px solid var(--neutral--300);
  margin-top: 16px;
  margin-bottom: 12px;
  padding-top: 24px;
}

.invoices-table-row {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  border-top: 1px solid var(--neutral--300);
  grid-template-rows: auto;
  grid-template-columns: .75fr .5fr .35fr .5fr .5fr 150px;
  grid-auto-columns: 1fr;
  align-items: center;
  padding: 12px 36px 8px;
  display: grid;
}

.invoices-table-row.header {
  background-color: var(--neutral--200);
  padding-top: 16px;
  padding-bottom: 16px;
}

.invoices-table-row.last {
  padding-bottom: 12px;
}

.invoices-table-top-section {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: space-between;
  align-items: center;
  padding: 20px 36px 16px;
  display: grid;
}

.text-break-no-wrap {
  white-space: nowrap;
}

.download-file-link {
  width: 100%;
  background-color: var(--neutral--200);
  color: var(--neutral--800);
  border-radius: 10px;
  align-items: flex-start;
  padding: 20px 20px 16px;
  line-height: 1.571em;
  text-decoration: none;
  display: flex;
}

.crypto-top-modules-grid {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  grid-template-rows: auto;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  grid-auto-columns: 1fr;
  margin-bottom: 24px;
  display: grid;
}

.crypto-table-top-section {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: space-between;
  align-items: center;
  padding: 20px 36px;
  display: grid;
}

.crypto-table-row {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  border-top: 1px solid var(--neutral--300);
  grid-template-rows: auto;
  grid-template-columns: 56px 1fr 1fr .85fr 1.25fr 1.25fr;
  grid-auto-columns: 1fr;
  align-items: center;
  padding: 10px 36px;
  display: grid;
}

.crypto-table-row.header {
  background-color: var(--neutral--200);
  padding-top: 16px;
  padding-bottom: 16px;
}

.trending-tokens-grid {
  grid-column-gap: 6px;
  grid-row-gap: 6px;
  grid-template-rows: auto;
  grid-template-columns: 2.5fr 1fr 1.25fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.logs-table-top-section {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: space-between;
  align-items: center;
  padding: 20px 36px 16px;
  display: grid;
}

.logs-table-row {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  border-top: 1px solid var(--neutral--300);
  grid-template-rows: auto;
  grid-template-columns: 160px 1.25fr .35fr .25fr 86px;
  grid-auto-columns: 1fr;
  align-items: center;
  padding: 20px 36px;
  display: grid;
}

.logs-table-row.header {
  background-color: var(--neutral--200);
  padding-top: 16px;
  padding-bottom: 16px;
}

.mg-left-14px {
  margin-left: 14px;
}

.list-dot {
  max-height: 6px;
  max-width: 6px;
  min-height: 6px;
  min-width: 6px;
  background-color: var(--accent--primary-1);
  border-radius: 100%;
  margin-right: 16px;
}

.list-dot.analytics-information {
  max-height: 10px;
  max-width: 10px;
  min-height: 10px;
  min-width: 10px;
  margin-right: 8px;
}

.list-dot.bg-secondary-6 {
  background-color: var(--secondary--color-6);
}

.list-dot.bg-green-300 {
  background-color: var(--system--green-300);
}

.list-dot.bg-neutral-600 {
  background-color: var(--neutral--600);
}

.line-height-1-571em {
  line-height: 1.571em;
}

.invoice-details-container {
  grid-column-gap: 40px;
  background-color: var(--accent--primary-1);
  background-image: url('../images/top-right-invoices-bg-dashboardly-webflow-template.svg'), url('../images/middle-invoices-bg-dashboardly-webflow-template.svg'), url('../images/top-left-invoices-bg-dashboardly-webflow-template.svg');
  background-position: 124% 240%, 50% -34%, -20% 230%;
  background-repeat: no-repeat, no-repeat, no-repeat;
  background-size: 42%, 52%, 40%;
  border-radius: 24px;
  justify-content: space-between;
  margin-bottom: 48px;
  padding: 40px 32px 30px;
  display: flex;
}

.rotate-180deg {
  transform: rotate(180deg);
}

.reports-top-module-info-container {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
  display: flex;
}

.file-manager-table-row {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  border-top: 1px solid var(--neutral--300);
  grid-template-rows: auto;
  grid-template-columns: 1fr 80px .5fr .25fr 86px;
  grid-auto-columns: 1fr;
  align-items: center;
  padding: 20px 36px;
  display: grid;
}

.file-manager-table-row.header {
  background-color: var(--neutral--200);
  border-top-style: none;
  padding-top: 16px;
  padding-bottom: 16px;
}

.invoices-details-table-row {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  border-bottom: 1px solid var(--neutral--300);
  grid-template-rows: auto;
  grid-template-columns: 1fr .75fr .6fr .5fr;
  grid-auto-columns: 1fr;
  align-items: center;
  padding-top: 28px;
  padding-bottom: 28px;
  display: grid;
}

.invoices-details-table-row.header {
  padding-top: 0;
  padding-bottom: 24px;
}

.invoices-details-table-row.last {
  border-bottom-style: none;
}

.total-amount-container {
  grid-column-gap: 42px;
  background-color: var(--neutral--200);
  border-radius: 14px;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 32px;
  padding: 18px 24px;
  display: flex;
}

.total-amount-container.billing {
  grid-column-gap: 52px;
  margin-bottom: 0;
}

.form {
  margin-bottom: 0;
}

.form.min-h-460px {
  min-height: 460px;
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.form.mg-bottom-24px {
  margin-bottom: 24px;
}

.form.min-h-116px {
  min-height: 116px;
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.form.min-h-188px {
  min-height: 188px;
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.form.min-h-256px {
  min-height: 256px;
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.width-210px {
  width: 210px;
}

.width-104px {
  width: 104px;
}

.monthly-pageviews-details-top-container {
  grid-column-gap: 24px;
  grid-row-gap: 8px;
  margin-right: 24px;
  display: flex;
}

.pageviews-header-grid {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-rows: auto;
  grid-template-columns: auto 1fr;
  grid-auto-columns: 1fr;
  align-items: center;
  display: grid;
}

.chat-page-container {
  margin-bottom: 64px;
  display: flex;
}

.chat-page-left-side {
  width: 100%;
  max-width: 366px;
  min-height: 92vh;
  border-top: 1px solid var(--neutral--300);
  border-right: 1px solid var(--neutral--300);
  border-bottom: 1px solid var(--neutral--300);
  background-color: var(--neutral--100);
  box-shadow: 0 2px 10px 0 var(--general--shadow-01);
  border-bottom-right-radius: 30px;
}

.chat-page-right-side {
  width: 100%;
  min-height: 92vh;
  padding-top: 48px;
  padding-left: 48px;
  padding-right: 48px;
}

.chat-left-side-top-container {
  grid-column-gap: 16px;
  border-bottom: 1px solid var(--neutral--300);
  justify-content: space-between;
  align-items: center;
  padding: 28px 34px 24px;
  display: flex;
}

.chat-left-side-bottom-container {
  padding: 20px 36px 48px;
}

.chat-preview-container {
  grid-column-gap: 16px;
  cursor: pointer;
  transform-style: preserve-3d;
  margin-bottom: 40px;
  transition: transform .3s, opacity .3s;
  display: flex;
}

.chat-preview-container:hover {
  opacity: .85;
  transform: scale3d(1.03, 1.03, 1.01);
}

.chat-preview-container.last {
  margin-bottom: 64px;
}

.chat-preview-box-top-container {
  width: 100%;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  grid-template-rows: auto;
  grid-template-columns: 1fr auto;
  grid-auto-columns: 1fr;
  margin-bottom: 6px;
  display: grid;
}

.mg-right-4px {
  margin-right: 4px;
}

.chat-box-section {
  padding-left: 36px;
  padding-right: 36px;
}

.chat-box-section.header {
  grid-column-gap: 32px;
  border-bottom: 1px solid var(--neutral--300);
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
}

.chat-box-section.body {
  padding-top: 40px;
  padding-bottom: 24px;
}

.chat-box-section.bottom {
  border-top: 1px solid var(--neutral--300);
  align-items: center;
  padding-top: 28px;
  padding-bottom: 28px;
  display: flex;
}

.chat-message-incoming {
  max-width: 350px;
  background-color: var(--neutral--200);
  color: var(--neutral--800);
  border-radius: 10px;
  padding: 18px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.571em;
}

.chat-message-incoming.link {
  text-decoration: none;
}

.chat-message-incoming.link:hover {
  color: var(--accent--primary-1);
}

.chat-message-container {
  grid-column-gap: 16px;
  margin-bottom: 14px;
  display: flex;
}

.chat-message-container.right {
  justify-content: flex-end;
}

.chat-message-outgoing {
  max-width: 306px;
  background-color: var(--accent--primary-1);
  box-shadow: 0 4px 10px 0 var(--button-shadow--color-2);
  color: var(--neutral--100);
  border-radius: 10px;
  padding: 18px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.571em;
}

.chat-lightbox-image-wrapper {
  max-width: 366px;
  border-radius: 28px;
  overflow: hidden;
}

.chat-lightbox-image-wrapper.mg-bottom-16px {
  width: 100%;
}

.font-size-24px {
  font-size: 24px;
}

.chat-box-input-wrapper {
  width: 100%;
  max-width: 428px;
  margin-bottom: 0;
  margin-right: 14px;
}

.position-relative---z-index-2 {
  z-index: 2;
  position: relative;
}

.settings-v1-tab-menu {
  border-bottom: 1px solid var(--neutral--400);
  margin-bottom: 32px;
  display: flex;
}

.settings-v1-tab-link {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  border-bottom: 1px solid var(--neutral--400);
  color: var(--neutral--600);
  background-color: rgba(0, 0, 0, 0);
  align-items: center;
  margin-bottom: -1px;
  padding: 0 18px 20px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.125em;
  transition: border-color .3s, color .3s;
  display: flex;
}

.settings-v1-tab-link:hover {
  border-bottom-color: var(--neutral--800);
  color: var(--neutral--800);
}

.settings-v1-tab-link.w--current {
  border-bottom-color: var(--accent--primary-1);
  color: var(--accent--primary-1);
  background-color: rgba(0, 0, 0, 0);
}

.popup {
  border: 1px solid var(--neutral--300);
  background-color: var(--neutral--100);
  box-shadow: 0 2px 10px 0 var(--general--shadow-01);
  border-radius: 20px;
}

.popup.simple-popup {
  width: 100%;
  max-width: 520px;
  text-align: center;
  border-radius: 30px;
  padding: 58px 48px;
  position: relative;
  overflow: hidden;
}

.popup.popup-icon-top {
  max-width: 468px;
  text-align: center;
  border-radius: 30px;
  padding: 54px;
  position: relative;
  overflow: hidden;
}

.popup.popup-icon-top.pd-sides-50px {
  padding-left: 50px;
  padding-right: 50px;
}

.popup.popup-icon-left {
  max-width: 558px;
  border-radius: 30px;
  align-items: flex-start;
  padding: 54px 44px 50px 40px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.popup.popup-image-top {
  max-width: 454px;
  text-align: center;
  border-radius: 30px;
  padding: 40px 46px 56px;
  position: relative;
  overflow: hidden;
}

.popup.popup-image-left {
  max-width: 706px;
  border-radius: 30px;
  align-items: flex-start;
  padding: 60px 68px 56px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.team-settings-table-row {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  border-top: 1px solid var(--neutral--300);
  grid-template-rows: auto;
  grid-template-columns: 1.25fr .5fr .75fr 80px;
  grid-auto-columns: 1fr;
  align-items: center;
  padding: 16px 46px;
  display: grid;
}

.team-settings-table-row.header {
  background-color: var(--neutral--200);
}

.team-settings-table-top-section {
  grid-column-gap: 32px;
  justify-content: space-between;
  align-items: center;
  padding: 22px 36px 28px;
  display: flex;
}

.mg-left-4px {
  margin-left: 4px;
}

.plan-information-modules-container {
  grid-column-gap: 26px;
  grid-row-gap: 16px;
  margin-bottom: 26px;
  display: flex;
}

.plan-information-table-row {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  border-bottom: 1px solid var(--neutral--300);
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: center;
  padding-top: 18px;
  padding-bottom: 18px;
  padding-right: 28px;
  display: grid;
}

.plan-information-table-row.header {
  padding-top: 0;
}

.plan-information-table-row.last {
  border-bottom-style: none;
  margin-bottom: 24px;
  padding-bottom: 0;
}

.pd-left-0 {
  padding-left: 0;
}

.billing-invoices-table-row {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  border-top: 1px solid var(--neutral--300);
  grid-template-rows: auto;
  grid-template-columns: 1fr .35fr .6fr 68px;
  grid-auto-columns: 1fr;
  align-items: center;
  padding: 20px 30px;
  display: grid;
}

.billing-invoices-table-row.header {
  background-color: var(--neutral--200);
  border-top-style: none;
  padding-top: 16px;
  padding-bottom: 16px;
}

.notifications-settings-table-row {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  border-top: 1px solid var(--neutral--300);
  grid-template-rows: auto;
  grid-template-columns: 1fr 74px 74px;
  grid-auto-columns: 1fr;
  align-items: center;
  padding-top: 24px;
  padding-bottom: 24px;
  display: grid;
}

.notifications-settings-table-row.header {
  border-top-style: none;
  padding-top: 0;
  padding-bottom: 28px;
}

.api-table-top-section {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: space-between;
  align-items: center;
  padding-top: 24px;
  padding-bottom: 24px;
  display: grid;
}

.api-settings-table-row {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  border-top: 1px solid var(--neutral--300);
  grid-template-rows: auto;
  grid-template-columns: 1.25fr 1.25fr 68px;
  grid-auto-columns: 1fr;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
  display: grid;
}

.api-settings-table-row.header {
  border-bottom: 1px solid var(--neutral--300);
  background-color: var(--neutral--200);
  margin-left: -48px;
  margin-right: -48px;
  padding: 16px 48px;
}

.api-settings-table-row.first {
  border-top-style: none;
}

.token-input-container {
  z-index: 0;
  margin-bottom: 0;
  position: relative;
}

.token-input-overlay {
  z-index: 0;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.settings-v2-main-grid {
  grid-column-gap: 48px;
  grid-row-gap: 16px;
  grid-template-rows: auto;
  grid-template-columns: .38fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.settings-v2-tab-link {
  width: 100%;
  grid-column-gap: 10px;
  background-color: var(--neutral--100);
  color: var(--neutral--600);
  align-items: center;
  padding: 12px;
  font-size: 16px;
  line-height: 1.125em;
  display: flex;
}

.settings-v2-tab-link:hover {
  color: var(--neutral--800);
}

.settings-v2-tab-link.w--current {
  background-color: var(--secondary--color-2);
  color: var(--accent--primary-1);
  border-radius: 6px;
  font-weight: 500;
}

.settings-v3-tab-menu {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  justify-content: center;
  margin-bottom: 48px;
  padding-top: 20px;
  display: flex;
}

.settings-v3-content-grid {
  grid-column-gap: 48px;
  grid-row-gap: 16px;
  grid-template-rows: auto;
  grid-template-columns: .38fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.profile-v1-main-grid {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  grid-template-rows: auto;
  grid-template-columns: 2.2fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.profile-v1-bg-banner {
  min-height: 124px;
  margin-bottom: -8%;
}

.profile-v1-profile-picture-container {
  max-height: 128px;
  max-width: 128px;
  min-height: 128px;
  min-width: 128px;
  background-color: var(--neutral--100);
  border-radius: 999999px;
  margin-right: 18px;
  padding: 4px;
  overflow: hidden;
}

.profile-v1-bio-container {
  padding-bottom: 32px;
  padding-left: 48px;
  padding-right: 48px;
}

.profile-v1-name-and-button-container {
  width: 100%;
  grid-column-gap: 24px;
  grid-row-gap: 12px;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 4px;
  display: flex;
}

.profile-about-rich-text h4 {
  margin-bottom: 16px;
}

.profile-about-rich-text p {
  margin-bottom: 20px;
}

.profile-experience-company-icon {
  max-height: 52px;
  max-width: 52px;
  min-height: 52px;
  min-width: 52px;
  border-radius: 12px;
  margin-right: 14px;
  box-shadow: 0 2px 2.4px rgba(11, 22, 44, .05);
}

.profile-experience-text {
  max-width: 510px;
  margin-bottom: 0;
  margin-left: 66px;
}

.social-feed-lightbox-image-container {
  width: 100%;
  border-radius: 16px;
  overflow: hidden;
}

.social-feed-bottom-link {
  grid-column-gap: 6px;
  cursor: pointer;
  align-items: center;
  display: flex;
}

.profile-v2-top-container {
  min-height: 274px;
  border-radius: 30px;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 24px;
  padding: 36px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.profile-v2-bg-banner-image {
  z-index: -1;
  min-height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.profile-v2-bg-banner-gradient {
  z-index: -1;
  opacity: .6;
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, .84));
  position: absolute;
  top: 24%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.profile-v2-name-container {
  grid-column-gap: 12px;
  grid-row-gap: 24px;
  justify-content: space-between;
  display: flex;
}

.profile-v3-details-container {
  padding: 28px 36px 36px;
}

.profile-v3-bg-banner {
  min-height: 260px;
  display: flex;
}

.mg-top-0 {
  margin-top: 0;
}

.pricing-single-text h3 {
  margin-bottom: 12px;
}

.pricing-single-text h4 {
  margin-bottom: 16px;
}

.pricing-single-text a {
  text-decoration: none;
}

.pricing-single-text strong {
  font-weight: 400;
}

.mg-right-20px {
  margin-left: 20px;
}

.notifications-number {
  max-height: 18px;
  max-width: 18px;
  min-height: 18px;
  min-width: 18px;
  background-color: var(--secondary--color-8);
  color: var(--neutral--100);
  border-radius: 9999px;
  justify-content: center;
  align-items: center;
  margin-left: 4px;
  font-size: 10px;
  font-weight: 700;
  line-height: 1.2em;
  display: flex;
}

.text-50 {
  font-size: 10px;
  line-height: 1.2em;
}

.text-50.medium {
  font-weight: 500;
}

.text-50.bold {
  font-weight: 700;
}

.text-50.text-uppercase {
  letter-spacing: .06em;
}

.notification-dot {
  max-height: 6px;
  max-width: 6px;
  min-height: 6px;
  min-width: 6px;
  background-color: var(--secondary--color-8);
  border-radius: 99999px;
  position: absolute;
  right: 0;
}

.notifications-link-container {
  width: 100%;
  grid-column-gap: 12px;
  border-bottom: 1px solid var(--neutral--300);
  justify-content: space-between;
  align-items: center;
  padding-bottom: 18px;
  text-decoration: none;
  transition: none;
  display: flex;
  position: relative;
}

.notifications-link-container.last {
  border-bottom-style: none;
  padding-bottom: 0;
}

.notifications-header-container {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.read-notification {
  transform-origin: 50% 0;
}

.top-bar-simple-logo {
  max-width: 200px;
  transform-style: preserve-3d;
  transition-property: transform;
}

.top-bar-simple-logo:hover {
  transform: scale3d(1.06, 1.06, 1.01);
}

.top-bar-simple {
  padding-top: 30px;
  padding-bottom: 30px;
}

.sign-up-page-main-container {
  min-height: 100vh;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1.04fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.sign-up-page-left-side {
  justify-content: center;
  align-items: center;
  padding: 80px 48px;
  display: flex;
  position: relative;
}

.sign-up-page-right-side {
  justify-content: center;
  align-items: center;
  padding: 38px 48px;
  display: flex;
  position: relative;
}

.sign-up-page-right-side-bg {
  z-index: -1;
  background-color: var(--accent--primary-1);
  opacity: .1;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.sign-up-page-logo {
  transform-style: preserve-3d;
  transition-property: transform;
}

.sign-up-page-logo:hover {
  transform: scale3d(1.06, 1.06, 1.01);
}

.sign-up-page-logo-container {
  justify-content: center;
  padding-left: 48px;
  padding-right: 48px;
  display: flex;
  position: absolute;
  top: 38px;
  left: 0;
  right: 0;
}

.integration-single-bg-banner {
  min-height: 190px;
  margin-bottom: 30px;
  display: flex;
}

.integration-single-inner-content {
  padding-bottom: 48px;
  padding-left: 54px;
  padding-right: 54px;
}

.integration-single-logo {
  max-height: 104px;
  max-width: 104px;
  min-height: 104px;
  min-width: 104px;
  background-color: var(--neutral--100);
  border-radius: 24px;
  margin-top: -74px;
  margin-right: 32px;
  padding: 4px;
  overflow: hidden;
}

.order-option-item {
  padding-left: 0;
}

.discounts-wrapper {
  border-width: 1px 0 0;
  border-top-color: #eff0f6;
  margin-top: 32px;
  padding: 32px 0 0;
}

.checkout-block-header {
  background-color: rgba(0, 0, 0, 0);
  border-width: 0 0 1px;
  border-bottom-color: #eff0f6;
  padding: 0 0 15px;
}

.card {
  border: 1px solid var(--neutral--300);
  background-color: var(--neutral--100);
  border-radius: 24px;
  box-shadow: 0 2px 7px rgba(20, 20, 43, .06);
}

.card.checkout-block {
  margin-bottom: 28px;
  padding: 32px 32px 48px;
}

.card.checkout-block.order-summary {
  padding-bottom: 40px;
}

.card.checkout-block.last {
  margin-bottom: 0;
}

.checkbox-field-wrapper-2 {
  align-items: center;
  margin-bottom: 24px;
  padding-left: 0;
  font-size: 18px;
  line-height: 22px;
  display: flex;
}

.apple-pay-btn {
  border-radius: 90px;
}

.apple-pay-btn.order-summary {
  height: 50px;
}

.heading-h2-size-2 {
  color: #211f54;
  font-size: 38px;
  font-weight: 700;
  line-height: 50px;
}

.shipping-list {
  border: 0 solid #000;
}

.radio-button-2 {
  width: auto;
  height: auto;
  min-height: 24px;
  min-width: 24px;
  background-color: #fff;
  border-color: #dcddeb;
  margin-top: 0;
  margin-left: 0;
  margin-right: 10px;
  transition: border-color .3s, border-width .3s, background-color .3s;
  box-shadow: 0 2px 6px rgba(20, 20, 43, .06);
}

.radio-button-2:hover {
  border-color: #4a3aff;
}

.radio-button-2.w--redirected-checked {
  border-width: 6px;
  border-color: #4a3aff;
}

.radio-button-2.w--redirected-focus {
  box-shadow: none;
}

.radio-button-2.small {
  min-height: 22px;
  min-width: 22px;
}

.order-item-bold {
  color: #211f54;
  font-weight: 700;
}

.order-item-list {
  margin-bottom: 0;
}

.checkout-form {
  min-height: auto;
  background-color: rgba(0, 0, 0, 0);
  padding: 0;
}

.checkout-block-content {
  background-color: rgba(0, 0, 0, 0);
  border: 0 solid #000;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
}

.checkout-col-right {
  position: static;
}

.order-list-title {
  color: #211f54;
  margin-bottom: 8px;
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
}

.order-summary-price {
  color: #211f54;
}

.order-item {
  border-bottom: 1px solid #eff0f6;
  margin-top: 18px;
  margin-bottom: 18px;
  padding-top: 18px;
  padding-bottom: 18px;
}

.shipping-method {
  border: 0 solid #000;
  border-bottom: 1px solid #eff0f6;
  padding: 31px 0;
}

.order-item-image {
  border-radius: 14px;
}

._2-col-grid {
  grid-column-gap: 28px;
  grid-row-gap: 28px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: center;
  display: grid;
}

._2-col-grid.checkout-page {
  grid-template-columns: 1fr .4fr;
  align-items: start;
}

.shipping-price,
.order-list-price {
  color: #211f54;
  font-weight: 700;
}

.checkout-col-left {
  margin-right: 0;
}

.help-center-top-section {
  z-index: 0;
  min-height: 354px;
  background-color: var(--accent--primary-1);
  background-image: url('../images/help-center-bottom-right-pattern-dashboardly-webflow-template.svg'), url('../images/help-center-bottom-left-pattern-dashboardly-webflow-template.svg');
  background-position: 104% 100%, -120px 180px;
  background-repeat: no-repeat, no-repeat;
  background-size: auto, auto;
  margin-bottom: 48px;
  padding-top: 58px;
  position: relative;
}

.help-center-top-section-text-container {
  grid-column-gap: 24px;
  justify-content: space-between;
  display: flex;
}

.help-center-top-section---bottom-line {
  z-index: -1;
  min-height: 68px;
  background-color: var(--neutral--200);
  position: absolute;
  bottom: -1px;
  left: 0;
  right: 0;
}

.help-center-nav-link {
  grid-column-gap: 8px;
  color: var(--neutral--600);
  border-radius: 8px;
  padding: 12px;
  font-size: 16px;
  line-height: 1.125em;
  text-decoration: none;
  display: flex;
}

.help-center-nav-link:hover {
  color: var(--neutral--700);
}

.help-center-nav-link.w--current {
  background-color: var(--neutral--200);
  color: var(--neutral--800);
}

.container-large {
  max-width: 1268px;
  padding-left: 24px;
  padding-right: 24px;
}

.logo-wrapper {
  max-width: 194px;
  transform-style: preserve-3d;
  margin-right: 24px;
  transition: transform .3s, color .3s;
  position: relative;
}

.logo-wrapper:hover {
  transform: scale3d(1.06, 1.06, 1.01);
}

.cart-button {
  transform-style: preserve-3d;
  background-color: rgba(0, 0, 0, 0);
  padding: 0;
  transition-property: transform;
  position: relative;
}

.cart-button:hover {
  transform: scale3d(1.06, 1.06, 1.01);
}

.cart-quantity {
  background-color: var(--accent--primary-1);
  color: var(--neutral--100);
  justify-content: center;
  align-items: center;
  margin-left: 0;
  padding-left: 0;
  padding-right: 0;
  font-size: 10px;
  line-height: 1.11em;
  display: flex;
  position: absolute;
  bottom: -6px;
  left: -6px;
}

.homepage-header-button-wrapper.last {
  margin-left: 12px;
}

.homepage-header-button-wrapper.first {
  margin-left: 24px;
}

.homepage-top-section {
  padding-top: 280px;
  padding-bottom: 280px;
  position: relative;
  overflow: hidden;
}

.homepage-top-section-bg-half {
  z-index: -1;
  width: 44%;
  max-width: 614px;
  background-color: var(--neutral--200);
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.homepage-top-section-main-image-wrapper {
  width: 60%;
  max-width: 874px;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  right: -80px;
}

.screens-text-container {
  max-height: 364px;
  max-width: 364px;
  min-height: 364px;
  min-width: 364px;
  background-image: url('../images/screens-text-bg-dashboardly-webflow-template.png');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 28%;
  right: 14%;
}

.features-text-right-container {
  padding: 64px 68px 64px 46px;
}

.preview-section-image-left {
  object-fit: cover;
  object-position: 0% 0%;
  border-top-left-radius: 8px;
  margin-top: 40px;
  box-shadow: 0 8px 28px rgba(11, 22, 44, .1);
}

.features-text-left-section {
  padding: 68px 80px 68px 68px;
}

.homepage-pages-tabs-menu {
  max-width: 726px;
  grid-column-gap: 14px;
  grid-row-gap: 14px;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 64px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.homepage-pages-image-wrapper {
  box-shadow: 0 2px 12px 0 var(--general--shadow-02);
  border-radius: 28px;
  overflow: hidden;
}

.cta-container {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  background-color: var(--accent--primary-1);
  box-shadow: 0 4px 10px 0 var(--button-shadow--color-2);
  border-radius: 36px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1.06fr;
  grid-auto-columns: 1fr;
  display: grid;
  overflow: hidden;
}

.cta-text-container {
  padding: 82px 94px 82px 84px;
  overflow: hidden;
}

.pd-bottom-100px {
  padding-bottom: 100px;
}

.cta-mobile-image {
  display: none;
}

.homepage-footer-section {
  background-color: var(--neutral--200);
  padding-top: 28px;
  padding-bottom: 28px;
}

.text-left {
  text-align: left;
}

.move-x-10px {
  transform: translate(10px);
}

.mg-left-8px {
  margin-left: 8px;
}

.text-capitalize {
  text-transform: capitalize;
}

.dashboard-cart-main-container {
  flex: none;
}

.mg-top-16px {
  margin-top: 16px;
}

.link-white {
  color: var(--neutral--100);
  transition: opacity .4s, color .3s;
}

.link-white:hover {
  opacity: .7;
  color: var(--neutral--100);
}

.link-no-decoration {
  text-decoration: none;
  transition: opacity .3s, color .3s;
}

.link-no-decoration:hover {
  opacity: .65;
}

.section-base {
  max-width: 1079px;
  justify-content: space-between;
  align-items: stretch;
  margin: 70px auto 50px;
  display: flex;
}

.section-base.paragraph, .section-base.cards {
  margin-top: 0;
  margin-bottom: 120px;
}

.section-base.flex {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.leftside {
  max-width: 1079px;
  flex: 1;
  margin-left: auto;
  margin-right: auto;
}

.rightside {
  margin-bottom: 0;
}

.h2 {
  font-size: 42px;
  line-height: 120%;
}

.text-span {
  color: var(--accent--primary-1);
}

.text-block {
  color: var(--neutral--700);
  margin-left: 18px;
  font-size: 19px;
  font-weight: 500;
  line-height: 120%;
}

.text-block.card {
  color: var(--neutral--800);
  margin-left: 0;
}

.text-block.c {
  margin-left: 0;
}

.div-block-flex {
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.div-block-flex.right {
  justify-content: center;
  align-items: center;
  margin-top: 18px;
  padding-top: 0;
}

.div-card {
  width: 330px;
  height: 335px;
  border-radius: 20px;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  display: flex;
  box-shadow: 4px 8px 16px rgba(203, 203, 203, .25);
}

.text-block-bold {
  color: var(--neutral--800);
  font-size: 23px;
  font-weight: 500;
  line-height: 135%;
}

.text-block-bold.number {
  color: var(--secondary--color-4);
  letter-spacing: -.5px;
  margin-top: 35%;
  margin-left: 38%;
  font-size: 40px;
  line-height: 135%;
  position: absolute;
}

.text-block-bold.number.small {
  margin-top: -67%;
  margin-left: 36%;
}

.div-block-3 {
  position: relative;
}

.h3 {
  letter-spacing: 0;
  font-size: 23px;
  font-weight: 500;
}

.text-span-2 {
  color: var(--accent--primary-1);
  text-decoration: underline;
}

.paragraph {
  letter-spacing: 0;
  margin-top: 14px;
  margin-bottom: 0;
  font-size: 23px;
  line-height: 135%;
}

.circle {
  margin-right: 8px;
}

.h4 {
  color: var(--neutral--800);
  letter-spacing: -1px;
  font-size: 26px;
  font-weight: 500;
}

.feedback-card {
  max-width: 537px;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.feedback-card.card.inline {
  max-width: 520px;
  padding: 18px 18px 24px 28px;
  position: relative;
}

.label {
  color: var(--neutral--500);
  letter-spacing: -1px;
  font-size: 19px;
  font-weight: 400;
}

.div-block-5 {
  justify-content: space-between;
  align-self: stretch;
  align-items: stretch;
  display: flex;
}

.div-footer {
  height: 110px;
}

.paragraph-2, .paragraph-3 {
  margin-top: 8px;
  padding-right: 28px;
  font-size: 23px;
  line-height: 135%;
}

.image-2 {
  margin-top: -18px;
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
}

.image-3 {
  margin-top: -20px;
}

.div-block-6 {
  position: relative;
}

.text-block-2 {
  margin-top: -47px;
  position: absolute;
}

@media screen and (min-width: 1440px) {
  .grid-2-columns.form {
    grid-column-gap: 32px;
    grid-row-gap: 32px;
  }

  .style---typography-block-grid {
    grid-template-columns: minmax(auto, 380px) .8fr;
  }

  .body {
    letter-spacing: 0;
    font-size: 23px;
    font-weight: 400;
    line-height: 135%;
  }

  .section-base {
    max-width: 1317px;
    justify-content: space-between;
    align-items: stretch;
    margin: 70px auto 50px;
    display: flex;
  }

  .section-base.paragraph {
    align-items: stretch;
    margin-top: 0;
    margin-left: auto;
    margin-right: auto;
    padding-top: 0;
  }

  .leftside {
    flex: 1;
  }

  .leftside.paragraph {
    max-width: 1317px;
    flex: 1;
  }

  .h2 {
    letter-spacing: -1px;
    font-size: 42px;
    font-weight: 700;
    line-height: 120%;
  }

  .text-span {
    color: var(--accent--primary-1);
  }

  .div-wrapper {
    margin-top: 32px;
  }

  .text-block {
    color: var(--neutral--700);
    margin-left: 18px;
    line-height: 120%;
  }

  .div-block-flex {
    margin-left: 0;
    display: flex;
  }

  .div-block-flex.right {
    justify-content: center;
    align-items: center;
    margin-top: 18px;
  }

  .div-block-2 {
    display: flex;
  }

  .div-card {
    width: 330px;
    height: 335px;
    background-color: #fff;
    border-radius: 20px;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    margin-top: 0;
    padding-bottom: 0;
    padding-right: 0;
    display: flex;
    box-shadow: 4px 8px 16px rgba(203, 203, 203, .25);
  }

  .text-block-bold {
    color: var(--neutral--800);
    text-align: left;
    letter-spacing: -.5px;
    margin-left: 0;
    font-weight: 500;
  }

  .text-block-bold.number {
    color: var(--secondary--color-4);
    justify-content: center;
    align-items: flex-end;
    margin-top: 29%;
    margin-left: 38%;
    margin-right: auto;
    font-size: 40px;
    display: inline-block;
    position: absolute;
    top: 0%;
    bottom: 0%;
    left: 0%;
    right: 0%;
  }

  .text-block-bold.number.small {
    margin-top: 21%;
    margin-left: 36%;
  }

  .image {
    margin-top: -10px;
    position: static;
  }

  .div-block-3 {
    position: relative;
  }

  .h3 {
    font-size: 23px;
    font-weight: 500;
  }

  .text-span-2 {
    color: var(--accent--primary-1);
    -webkit-text-stroke-width: 0px;
    text-decoration: underline;
  }

  .paragraph {
    margin-top: 14px;
  }

  .circle {
    margin-right: 8px;
  }

  .feedback-card.card.inline {
    max-width: 620px;
    padding: 18px 18px 24px 28px;
  }

  .paragraph-2, .paragraph-3 {
    margin-top: 8px;
    padding-right: 28px;
  }

  .image-2 {
    margin-top: -18px;
  }

  .image-3 {
    margin-top: -20px;
  }
}

@media screen and (min-width: 1920px) {
  .checkout-col-right {
    position: -webkit-sticky;
    position: sticky;
    top: 24px;
  }

  .div-card {
    padding-top: 0;
  }

  .text-block-bold.number {
    margin-top: 30%;
    margin-left: 38%;
  }

  .image-2 {
    margin-top: -18px;
  }
}

@media screen and (max-width: 991px) {
  h2 {
    margin-bottom: 12px;
    font-size: 30px;
  }

  .grid-2-columns._1-col-tablet {
    grid-template-columns: 1fr;
  }

  .grid-2-columns.template-page-sidebar {
    grid-template-columns: .5fr 1fr;
  }

  .grid-2-columns.contact-single-main-grid {
    grid-column-gap: 24px;
  }

  .grid-2-columns._7fr---1fr._1-col-tablet {
    grid-template-columns: 1fr;
  }

  .module.reports-top-details {
    max-width: none;
  }

  .module.company-details {
    position: static;
  }

  .module.tabs-v2-menu-container {
    position: static;
    top: 0;
  }

  .module.profile-v1-details,
  .module.profile-v2-details,
  .module.profile-v3-details,
  .module.template-pages-nav,
  .module.help-center-category-nav {
    top: 100px;
  }

  .module.help-center-article-link {
    padding-left: 40px;
    padding-right: 40px;
  }

  .module.homepage-features.middle {
    margin-left: 16px;
    margin-right: 16px;
    padding-left: 24px;
    padding-right: 24px;
  }

  .module.features-image-left {
    border-radius: 30px;
    grid-template-columns: 1fr 1fr;
  }

  .module.features-image-right,
  .grid-4-columns {
    grid-template-columns: 1fr 1fr;
  }

  .style---block-sub-heading {
    margin-bottom: 24px;
  }

  .style---typography-block-grid {
    grid-template-columns: minmax(auto, 250px) 1fr;
  }

  .mg-bottom-56px {
    margin-bottom: 48px;
  }

  .display-1 {
    font-size: 68px;
  }

  .gap-row-80px {
    grid-row-gap: 56px;
  }

  .display-3 {
    font-size: 32px;
  }

  .style---style-grid-wrapper {
    grid-row-gap: 140px;
  }

  .grid-3-columns {
    grid-template-columns: 1fr 1fr;
  }

  .grid-3-columns._3-col-tablet,
  .grid-3-columns.reports-graphs {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .style---heading {
    min-height: auto;
    background-position: 120% -80%, -20% 160%;
    margin-bottom: 60px;
    padding-top: 116px;
    padding-bottom: 116px;
  }

  .inner-container._100-tablet {
    max-width: 100%;
  }

  .style---content-heading {
    margin-bottom: 60px;
    padding: 32px;
  }

  .style---bg-white {
    padding-left: 36px;
    padding-right: 36px;
  }

  .style---avatars-container {
    grid-column-gap: 32px;
  }

  .line-square-icon.alert-banner-close-icon {
    padding-top: 18px;
    padding-bottom: 18px;
  }

  .section {
    padding-top: 118px;
    padding-bottom: 118px;
  }

  .heading-h2-size {
    font-size: 30px;
  }

  .header-wrapper {
    padding-top: 24px;
    padding-bottom: 24px;
  }

  .header-nav-menu-wrapper {
    border-top: 1px solid var(--neutral--300);
    border-bottom: 1px solid var(--neutral--300);
    background-color: var(--neutral--100);
    padding: 24px;
  }

  .header-nav-menu-list {
    flex-direction: column;
    align-items: flex-start;
  }

  .header-nav-list-item {
    margin-bottom: 12px;
    padding-left: 0;
  }

  .dropdown-column-wrapper {
    box-shadow: none;
    border-width: 0;
    border-radius: 0;
  }

  .dropdown-column-wrapper.module-dropdown,
  .dropdown-column-wrapper.dashboard-top-dropdown {
    border-width: 1px;
    border-radius: 20px;
  }

  .dropdown-column-wrapper.homepage {
    width: 100%;
    position: static;
    transform: none;
  }

  .dropdown-pd {
    padding: 20px 0 14px;
  }

  .hamburger-menu-wrapper {
    cursor: pointer;
    margin-left: 24px;
    transition: transform .3s;
  }

  .hamburger-menu-wrapper:hover {
    transform: scale3d(1.1, 1.1, 1.01);
  }

  .hamburger-menu-wrapper.w--open {
    background-color: rgba(0, 0, 0, 0);
  }

  .hamburger-menu-wrapper.sidebar {
    margin-left: 0;
    transition: transform .3s;
  }

  .hamburger-menu-wrapper.sidebar:hover {
    transform: scale3d(1.2, 1.2, 1.01);
  }

  .hamburger-menu-bar {
    width: 20px;
    height: 3px;
  }

  .hamburger-menu-bar.sidebar {
    width: 20px;
    height: 2.5px;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .error-message.password {
    margin-left: 32px;
    margin-right: 32px;
  }

  .sticky-top.static-tablet {
    position: static;
    top: 0;
  }

  .mg-bottom-160px {
    margin-bottom: 100px;
  }

  .template-pages---phography-wrapper {
    border-radius: 10px;
  }

  .sidebar-wrapper {
    max-height: 78px;
    max-width: 64px;
    min-height: 78px;
    min-width: 64px;
    box-shadow: none;
    border-right-style: none;
    justify-content: flex-start;
    align-items: center;
    padding: 0 0 0 24px;
    display: flex;
    bottom: auto;
    overflow: visible;
  }

  .sidebar-logo-section-container {
    display: none;
  }

  .sidebar-nav-menu-list {
    flex-direction: column;
    align-items: flex-start;
  }

  .sidebar-nav-menu-list.mg-bottom-160px {
    margin-bottom: 80px;
  }

  .sidebar-nav-list-item {
    margin-bottom: 12px;
    padding-left: 0;
  }

  .sidebar-menu-wrapper {
    background-color: var(--neutral--100);
    padding: 24px 24px 200px;
  }

  .sidebar-mobile-icon {
    display: none;
  }

  .sidebar-nav-menu {
    height: 110vh;
    min-height: 110vh;
    min-width: 260px;
    background-color: var(--neutral--100);
    margin-top: 64px;
    padding-bottom: 64px;
    overflow: auto;
  }

  .sidebar-spacer {
    width: 64px;
    display: none;
  }

  .dashboard-nav-main-container {
    z-index: 499;
    padding-left: 64px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }

  .reports-top-details-container {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    display: grid;
  }

  .invoices-table-row {
    grid-template-columns: .75fr .5fr .35fr .5fr .5fr 80px;
  }

  .logs-table-row {
    grid-template-columns: 140px 1.25fr .35fr .25fr 64px;
  }

  .chat-page-left-side {
    max-width: 300px;
  }

  .chat-page-right-side {
    padding-left: 32px;
    padding-right: 32px;
  }

  .team-settings-table-row {
    padding-left: 40px;
    padding-right: 40px;
  }

  .settings-v2-main-grid,
  .settings-v3-content-grid {
    grid-column-gap: 32px;
    grid-row-gap: 32px;
    grid-template-columns: 1fr;
  }

  .profile-v1-name-and-button-container {
    grid-column-gap: 12px;
  }

  .profile-v3-details-container {
    padding-left: 28px;
    padding-right: 28px;
  }

  .integration-single-inner-content {
    padding-left: 40px;
    padding-right: 40px;
  }

  .card.checkout-block {
    padding-left: 24px;
    padding-right: 24px;
  }

  .heading-h2-size-2 {
    font-size: 30px;
    line-height: 46px;
  }

  .homepage-header-button-wrapper {
    display: flex;
  }

  .homepage-header-button-wrapper.last {
    margin-left: 0;
  }

  .homepage-header-button-wrapper.first {
    margin-bottom: 24px;
    margin-left: 0;
  }

  .homepage-top-section {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .homepage-top-section-bg-half {
    width: 100%;
    max-width: none;
    top: 50%;
    left: 0;
  }

  .homepage-top-section-main-image-wrapper {
    width: 100%;
    margin-top: 64px;
    position: static;
  }

  .screens-text-container {
    top: auto;
    bottom: 15%;
    right: 25%;
  }

  .features-text-right-container {
    padding-left: 40px;
    padding-right: 40px;
  }

  .preview-section-image-left {
    margin-top: 0;
  }

  .features-text-left-section {
    padding: 64px 40px;
  }

  .cta-container {
    grid-template-columns: 1fr 1fr;
  }

  .cta-text-container {
    padding: 48px 56px;
  }

  .section-base {
    max-width: 688px;
  }

  .leftside.paragraph {
    margin-top: 14px;
  }

  .h2 {
    font-size: 36px;
  }

  .text-block {
    font-size: 16px;
  }

  .text-block.c {
    font-size: 14px;
  }

  .div-block-flex {
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 4px;
  }

  .div-card {
    width: 230px;
    height: 235px;
    padding-top: 0;
  }

  .text-block-bold {
    font-size: 16px;
  }

  .text-block-bold.number {
    margin-top: 37%;
    margin-left: 40%;
    font-size: 33px;
  }

  .text-block-bold.number.small {
    margin-top: -64%;
    margin-left: 38%;
  }

  .image {
    font-size: 14px;
  }

  .h3 {
    font-size: 18px;
  }

  .paragraph {
    margin-top: 8px;
    font-size: 17px;
  }

  .feedback-card.card.inline {
    max-width: 336px;
    padding-bottom: 24px;
    padding-left: 16px;
  }

  .label {
    font-size: 17px;
  }

  .paragraph-2 {
    margin-bottom: 0;
    font-size: 16px;
  }
}

@media screen and (max-width: 767px) {
  h1 {
    margin-bottom: 10px;
    font-size: 38px;
  }

  h2 {
    font-size: 26px;
  }

  h3 {
    font-size: 22px;
  }

  h4 {
    font-size: 20px;
  }

  h5 {
    font-size: 16px;
  }

  h6 {
    font-size: 14px;
  }

  blockquote {
    border-radius: 18px;
    padding-left: 32px;
    padding-right: 32px;
  }

  .grid-2-columns {
    grid-template-columns: 1fr;
  }

  .grid-2-columns.form {
    grid-row-gap: 20px;
  }

  .grid-2-columns._2-col-mbl {
    grid-template-columns: 1fr 1fr;
  }

  .grid-2-columns._2-col-mbl._1-5fr---1fr {
    grid-template-columns: 1.5fr 1fr;
  }

  .grid-2-columns.template-page-sidebar {
    grid-template-columns: 1fr;
  }

  .grid-2-columns.gap-18px._2-columns-mbl {
    grid-template-columns: 1fr 1fr;
  }

  .grid-2-columns.reports-modules {
    grid-row-gap: 24px;
    grid-template-columns: 1fr;
  }

  .grid-2-columns.products-top-modules,
  .grid-2-columns.products-bottom-modules,
  .grid-2-columns.contact-single-main-grid,
  .grid-2-columns._1-5fr---1fr,
  .grid-2-columns._2-1fr---1fr,
  .grid-2-columns._1-2fr---1fr,
  .grid-2-columns._2-2fr---1fr,
  .grid-2-columns._1-3fr---1fr,
  .grid-2-columns._46fr---1fr,
  .grid-2-columns._42fr---1fr,
  .grid-2-columns._7fr---1fr {
    grid-template-columns: 1fr;
  }

  .module.no-records-available,
  .module.action-completed {
    padding: 56px 32px;
  }

  .module.there-s-been-an-error {
    padding-bottom: 54px;
    padding-left: 32px;
    padding-right: 32px;
  }

  .module.no-records-found,
  .module.notifications-alert {
    padding: 54px 32px;
  }

  .module.pages-link {
    border-radius: 20px;
  }

  .module.graph-large {
    border-radius: 24px;
    padding-bottom: 48px;
  }

  .module.graph,
  .module.users-by-device,
  .module.recent-contacts {
    border-radius: 24px;
  }

  .module.total-sales,
  .module.products-table {
    border-radius: 20px;
  }

  .module.team-progress,
  .module.tasks-reports,
  .module.contacts-card {
    border-radius: 24px;
  }

  .module.contact-single-details,
  .module.assigned-to,
  .module.about-company {
    border-radius: 20px;
  }

  .module.add-note {
    margin-bottom: 40px;
  }

  .module.companies-card {
    border-radius: 24px;
  }

  .module.border-radius-30px {
    border-radius: 20px;
  }

  .module.contact-activity-large {
    padding-left: 24px;
    padding-right: 24px;
  }

  .module.contact-activity-small {
    padding: 24px;
  }

  .module.company-single-about {
    border-radius: 20px;
    padding-left: 36px;
    padding-right: 36px;
  }

  .module.company-news {
    padding-left: 32px;
    padding-right: 32px;
  }

  .module.company-details,
  .module.projects,
  .module.crypto-top-module,
  .module.crypto-news,
  .module.crypto-trending,
  .module.client-information {
    border-radius: 20px;
  }

  .module.changelog {
    border-radius: 20px;
    padding-left: 32px;
    padding-right: 32px;
  }

  .module.amount-due {
    border-radius: 20px;
  }

  .module.support-form {
    border-radius: 20px;
    padding: 40px 40px 48px;
  }

  .module.monthly-customers,
  .module.monthly-pageviews {
    border-radius: 24px;
  }

  .module.chat-box {
    border-radius: 20px;
  }

  .module.account {
    border-radius: 20px;
    padding-bottom: 56px;
    padding-left: 32px;
    padding-right: 32px;
  }

  .module.team-settings {
    border-radius: 20px;
  }

  .module.billing {
    border-radius: 20px;
    padding-bottom: 48px;
    padding-left: 32px;
    padding-right: 32px;
  }

  .module.billing-startup-plan-info {
    padding-right: 26px;
  }

  .module.billing-extra-users-info {
    padding: 18px 26px;
  }

  .module.notifications-settings {
    border-radius: 20px;
    padding-left: 32px;
    padding-right: 32px;
  }

  .module.api-settings {
    border-radius: 20px;
    padding-left: 0;
    padding-right: 0;
  }

  .module.tabs-v2-menu-container {
    border-radius: 20px;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
    position: static;
    overflow: hidden;
  }

  .module.skills,
  .module.experience {
    border-radius: 20px;
    padding-bottom: 40px;
    padding-left: 32px;
    padding-right: 32px;
  }

  .module.social-feed {
    border-radius: 20px;
    padding-left: 32px;
    padding-right: 32px;
  }

  .module.profile-v1-details {
    border-radius: 20px;
    padding-left: 32px;
    padding-right: 32px;
    position: static;
  }

  .module.profile-v2-details {
    border-radius: 20px;
    position: static;
    top: 0;
  }

  .module.about-me-v2 {
    margin-bottom: 40px;
    padding-left: 32px;
    padding-right: 32px;
  }

  .module.profile-v3-details {
    border-radius: 20px;
    position: static;
    top: 0;
  }

  .module.pricing-v1,
  .module.pricing-v3 {
    border-radius: 20px;
    padding-left: 32px;
    padding-right: 32px;
  }

  .module.pricing-single-top {
    grid-row-gap: 32px;
    border-radius: 20px;
    grid-template-columns: 1fr;
    padding-left: 32px;
    padding-right: 32px;
  }

  .module.pricing-single-description {
    border-radius: 20px;
    padding-left: 32px;
    padding-right: 32px;
  }

  .module.template-pages-nav {
    position: static;
    top: 0;
  }

  .module.template-pages {
    border-radius: 20px;
    padding-left: 24px;
    padding-right: 24px;
  }

  .module.notifications {
    padding-left: 32px;
    padding-right: 32px;
  }

  .module.privacy-policy {
    border-radius: 20px;
    padding-left: 32px;
    padding-right: 32px;
  }

  .module.not-found {
    grid-row-gap: 100px;
    text-align: center;
    border-radius: 20px;
    flex-direction: column;
    align-items: center;
    padding: 48px 40px;
  }

  .module.utility-page-form {
    border-radius: 20px;
    padding-left: 32px;
    padding-right: 32px;
  }

  .module.password-protected {
    border-radius: 20px;
    padding: 64px 32px;
  }

  .module.integrations {
    border-radius: 20px;
    padding-left: 32px;
    padding-right: 32px;
  }

  .module.integration-single {
    border-radius: 20px;
  }

  .module.help-center-categories {
    border-radius: 20px;
    padding-left: 32px;
    padding-right: 32px;
  }

  .module.help-center-category-nav {
    margin-bottom: 32px;
    position: static;
  }

  .module.help-center-article-link {
    border-radius: 20px;
    padding-left: 32px;
    padding-right: 32px;
  }

  .module.homepage-features {
    width: 100%;
    grid-template-columns: 1fr 1fr;
  }

  .module.homepage-features.middle {
    margin: 24px 0;
  }

  .module.features-image-left {
    border-radius: 20px;
    grid-template-columns: 1fr;
  }

  .module.features-image-right {
    grid-template-columns: 1fr;
  }

  .module.homepage-pages-image-container {
    border-radius: 20px;
  }

  .style---color-block {
    border-top-left-radius: 18px;
    border-top-right-radius: 18px;
  }

  .style---block-sub-heading.border {
    margin-bottom: 48px;
    padding-bottom: 24px;
  }

  .divider {
    margin-top: 64px;
    margin-bottom: 64px;
  }

  .divider._64px {
    margin-top: 48px;
    margin-bottom: 48px;
  }

  .divider.top-20px---bottom-52px {
    margin-bottom: 40px;
  }

  .divider._48px {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .divider.top-28px---bottom-56px {
    margin-bottom: 40px;
  }

  .style---typography-block-grid {
    grid-row-gap: 28px;
    grid-template-columns: 1fr;
  }

  .style---content-block {
    margin-bottom: 64px;
  }

  .mg-bottom-40px {
    margin-bottom: 32px;
  }

  .mg-bottom-48px,
  .mg-bottom-56px {
    margin-bottom: 40px;
  }

  .mg-bottom-64px {
    margin-bottom: 48px;
  }

  .mg-top-48px {
    margin-top: 40px;
  }

  .mg-right-24px.mg-right-16px-mbl {
    margin-right: 8px;
  }

  .text-200.text-uppercase {
    letter-spacing: .06em;
  }

  .text-200.chat-details-text {
    display: none;
  }

  .display-1 {
    font-size: 52px;
  }

  .grid-1-column.gap-column-24px {
    grid-row-gap: 20px;
  }

  .display-2 {
    font-size: 40px;
  }

  .display-3 {
    font-size: 24px;
  }

  .display-4 {
    font-size: 20px;
  }

  .style---style-grid-wrapper {
    grid-row-gap: 120px;
  }

  .text-400.text-uppercase,
  .text-300.text-uppercase {
    letter-spacing: .06em;
  }

  .text-100.medium.mg-bottom-8px.mg-bottom-4px-mbl {
    margin-bottom: 4px;
  }

  .grid-3-columns {
    grid-template-columns: 1fr;
  }

  .grid-3-columns.style---buttons-grid {
    grid-template-columns: auto;
  }

  .grid-3-columns._3-col-tablet,
  .grid-3-columns.reports-graphs {
    grid-template-columns: 1fr;
  }

  .style---heading {
    margin-bottom: 56px;
    padding: 104px 24px;
  }

  .inner-container._322px {
    text-align: center;
  }

  .inner-container._100-mbl {
    max-width: 100%;
  }

  .inner-container._80px-mbl {
    max-width: 80px;
  }

  .style---content-heading {
    border-radius: 18px;
    margin-bottom: 36px;
    padding-top: 24px;
    padding-bottom: 24px;
  }

  .style---heading-icon-wrapper {
    max-width: 58px;
    border-radius: 16px;
  }

  .style---card-wrapper {
    border-radius: 18px;
  }

  .style---bg-white {
    padding: 34px 24px;
  }

  .input {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .input.dropdown-wrapper {
    padding-top: 0;
    padding-bottom: 0;
  }

  .text-area {
    border-radius: 18px;
  }

  .checkbox-field-wrapper.mg-bottom-24px {
    margin-bottom: 20px;
  }

  .style---avatars-container {
    grid-column-gap: 32px;
    grid-row-gap: 32px;
    grid-template-rows: auto;
    grid-template-columns: repeat(auto-fit, 160px);
    grid-auto-columns: 1fr;
    justify-content: flex-start;
    display: grid;
  }

  .avatar-circle._04 {
    width: 72px;
    height: 72px;
    max-height: 72px;
    max-width: 72px;
    min-height: 72px;
    min-width: 72px;
  }

  .avatar-circle._05 {
    width: 100px;
    height: 100px;
    max-height: 100px;
    max-width: 100px;
    min-height: 100px;
    min-width: 100px;
  }

  .avatar-circle._06 {
    width: 140px;
    height: 140px;
    max-height: 140px;
    max-width: 140px;
    min-height: 140px;
    min-width: 140px;
  }

  .line-rounded-icon.dropdown-arrow.profile-dropdown {
    margin-left: 0;
  }

  .line-rounded-icon.success-message-check {
    font-size: 56px;
  }

  .style---line-icons-grid {
    grid-template-columns: repeat(auto-fit, 18px);
  }

  .container-default {
    padding-left: 20px;
    padding-right: 20px;
  }

  .section {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .heading-h1-size {
    font-size: 38px;
  }

  .heading-h2-size {
    font-size: 26px;
  }

  .heading-h3-size {
    font-size: 22px;
  }

  .heading-h4-size {
    font-size: 20px;
  }

  .heading-h5-size {
    font-size: 16px;
  }

  .heading-h6-size {
    font-size: 14px;
  }

  .btn-circle-primary {
    width: 56px;
    height: 56px;
    min-height: 56px;
    min-width: 56px;
    font-size: 22px;
    line-height: 24px;
  }

  .btn-circle-primary.large {
    width: 72px;
    height: 72px;
    min-height: 72px;
    min-width: 72px;
    font-size: 26px;
    line-height: 28px;
  }

  .btn-circle-secondary {
    width: 56px;
    height: 56px;
    min-height: 56px;
    min-width: 56px;
    font-size: 22px;
    line-height: 24px;
  }

  .btn-circle-secondary.large {
    width: 72px;
    height: 72px;
    min-height: 72px;
    min-width: 72px;
    font-size: 26px;
    line-height: 28px;
  }

  .utility-page-wrap {
    min-height: 100vh;
  }

  .hidden-on-desktop.show-on-mbl {
    display: block;
  }

  .flex-horizontal.vertical-left-mbl {
    flex-direction: column;
    align-items: flex-start;
  }

  .error-message.password {
    margin-left: 24px;
    margin-right: 24px;
  }

  .text-center.text-left-mbl {
    text-align: left;
  }

  .sticky-top.static-mbl {
    position: static;
  }

  .mg-bottom-160px {
    margin-bottom: 80px;
  }

  ._404-not-found {
    font-size: 120px;
  }

  .flex.click-inputs-container {
    grid-column-gap: 64px;
  }

  .flex.vertical-left-mbl {
    flex-direction: column;
    align-items: flex-start;
  }

  .flex.vertical-center-mbl {
    flex-direction: column;
    align-items: center;
  }

  .upload-box-inner-container {
    padding: 24px 32px 48px;
  }

  .upload-box-icon {
    max-width: 120px;
  }

  .no-records-available-illustration {
    max-width: 140px;
  }

  .action-completed-illustration {
    max-width: 140px;
    margin-bottom: 20px;
  }

  .theres-been-an-error-illustration,
  .no-records-found-illustration,
  .notification-alert-illustration {
    max-width: 140px;
  }

  .close-button-popup-module {
    max-width: 18px;
  }

  .popup-wrapper {
    padding-left: 20px;
    padding-right: 20px;
  }

  .popup-top-icon,
  .popup-left-icon {
    max-width: 64px;
  }

  .popup-top-image {
    max-width: 140px;
    margin-bottom: 20px;
  }

  .popup-left-image {
    max-width: 140px;
    margin-right: 32px;
  }

  .dashly-custom-icon.chat-box-icon {
    font-size: 18px;
  }

  .dashly-custom-icon.chat-box-icon.mg-right-24px {
    margin-right: 12px;
  }

  .sidebar-logo {
    max-width: 90%;
  }

  .sidebar-wrapper {
    padding-left: 20px;
  }

  .dashboard-nav-container {
    min-height: 78px;
  }

  .dashboard-top-search-bar-wrapper {
    display: none;
  }

  .dashboard-nav-left-content {
    flex: 1;
  }

  .reports-top-details-container {
    grid-auto-columns: 1fr;
  }

  .products-table-row {
    grid-row-gap: 0px;
    grid-template-columns: 1fr;
    padding: 0;
  }

  .products-table-row.header {
    display: none;
  }

  .products-table-top-section {
    padding-left: 24px;
    padding-right: 24px;
  }

  .tasks-reports-container {
    grid-column-gap: 16px;
  }

  .avatar-circle-outline-container.profile-v2-avatar {
    max-height: 72px;
    max-width: 72px;
    min-height: 72px;
    min-width: 72px;
  }

  .tabs-menu-top {
    margin-bottom: 24px;
  }

  .contacts-modules-container {
    grid-template-columns: 1fr;
  }

  .table-item {
    width: 100%;
    min-height: 64px;
    grid-column-gap: 24px;
    border-bottom: 1px solid var(--neutral--300);
    grid-template-rows: auto;
    grid-template-columns: .75fr 1fr;
    grid-auto-columns: 1fr;
    display: grid;
    overflow: hidden;
  }

  .table-item.last {
    margin-bottom: 32px;
  }

  .table-item.last.mg-bottom-0 {
    border-bottom-style: none;
    margin-bottom: 0;
  }

  .table-item.last.border-bottom-solid {
    border-bottom-style: solid;
    margin-bottom: 48px;
    padding-right: 0;
  }

  .table-item.notification-settings-first-item {
    padding-top: 24px;
    padding-bottom: 24px;
  }

  .table-item-mobile-caption {
    background-color: var(--neutral--200);
    align-items: center;
    padding-left: 24px;
    display: flex;
  }

  .table-item-mobile-caption.notification-settings-mobile-caption {
    background-color: rgba(0, 0, 0, 0);
    padding-left: 0;
  }

  .contacts-table-top-section {
    padding-left: 24px;
    padding-right: 24px;
  }

  .contacts-table-row {
    grid-row-gap: 0px;
    grid-template-columns: 1fr;
    padding: 0;
  }

  .contacts-table-row.header,
  .hidden-on-mbl {
    display: none;
  }

  .companies-table-top-section {
    padding-left: 24px;
    padding-right: 24px;
  }

  .companies-table-row {
    grid-row-gap: 0px;
    grid-template-columns: 1fr;
    padding: 0;
  }

  .companies-table-row.header {
    display: none;
  }

  .companies-modules-container {
    grid-template-columns: 1fr;
  }

  .add-note-top-link {
    padding-left: 12px;
    padding-right: 12px;
  }

  .add-note-top-section-links-container {
    justify-content: space-between;
  }

  .company-single-top-banner {
    margin-bottom: -24px;
  }

  .company-single-about-text ul {
    padding-left: 24px;
  }

  .company-single-about-logo {
    max-width: 80px;
    border-radius: 13px;
  }

  .features-tasks-title-container {
    grid-row-gap: 12px;
    grid-template-columns: 1fr;
  }

  .invoices-table-row {
    grid-row-gap: 0px;
    grid-template-columns: 1fr;
    padding: 0;
  }

  .invoices-table-row.header {
    display: none;
  }

  .invoices-table-row.last {
    padding-bottom: 0;
  }

  .invoices-table-top-section {
    padding-left: 24px;
    padding-right: 24px;
  }

  .crypto-top-modules-grid {
    grid-template-columns: 1fr;
  }

  .crypto-table-top-section {
    padding-left: 24px;
    padding-right: 24px;
  }

  .crypto-table-row {
    grid-row-gap: 0px;
    grid-template-columns: 1fr;
    padding: 0;
  }

  .crypto-table-row.header {
    display: none;
  }

  .trending-tokens-grid {
    grid-template-columns: 2.75fr 1fr 1.25fr;
  }

  .logs-table-top-section {
    padding-left: 24px;
    padding-right: 24px;
  }

  .logs-table-row {
    grid-row-gap: 0px;
    grid-template-columns: 1fr;
    padding: 0;
  }

  .logs-table-row.header {
    display: none;
  }

  .invoice-details-container {
    background-position: 100% 0, 50% 140px, -100px -80px;
    background-size: 30%, 52%, 40%;
    margin-bottom: 40px;
  }

  .file-manager-table-row {
    grid-row-gap: 0px;
    grid-template-columns: 1fr;
    padding: 0;
  }

  .file-manager-table-row.header {
    display: none;
  }

  .file-manager-table-row.first {
    border-top-style: none;
  }

  .invoices-details-table-row {
    grid-row-gap: 0px;
    border-style: solid;
    border-top-width: 1px;
    border-top-color: var(--neutral--300);
    border-right-width: 1px;
    border-right-color: var(--neutral--300);
    border-left-width: 1px;
    border-left-color: var(--neutral--300);
    border-radius: 8px;
    grid-template-columns: 1fr;
    margin-bottom: 24px;
    padding: 0;
    overflow: hidden;
  }

  .invoices-details-table-row.header {
    display: none;
  }

  .invoices-details-table-row.last {
    border-bottom-style: solid;
  }

  .form.min-h-460px {
    min-height: 438px;
  }

  .monthly-pageviews-details-top-container {
    flex-wrap: wrap;
  }

  .chat-page-container {
    flex-direction: column;
  }

  .chat-page-left-side {
    max-width: none;
    min-height: auto;
    border-bottom-right-radius: 0;
  }

  .chat-preview-container {
    grid-row-gap: 8px;
    text-align: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0;
  }

  .chat-preview-container.last {
    margin-bottom: 0;
  }

  .chat-preview-box-top-container {
    grid-template-columns: 1fr;
    margin-bottom: 0;
  }

  .chat-lightbox-image-wrapper {
    border-radius: 20px;
  }

  .chat-previews-main-container {
    grid-column-gap: 16px;
    justify-content: space-between;
    margin-bottom: 32px;
    display: flex;
  }

  .settings-v1-tab-link {
    flex-flow: column wrap;
    justify-content: center;
  }

  .popup.simple-popup,
  .popup.popup-icon-top {
    border-radius: 20px;
    padding: 48px 32px;
  }

  .popup.popup-icon-top.pd-sides-50px {
    padding-left: 32px;
    padding-right: 32px;
  }

  .popup.popup-icon-left {
    border-radius: 20px;
    padding: 48px 32px;
  }

  .popup.popup-image-top {
    border-radius: 20px;
    padding-left: 32px;
    padding-right: 32px;
  }

  .popup.popup-image-left {
    border-radius: 20px;
    padding: 48px 32px;
  }

  .team-settings-table-row {
    grid-row-gap: 0px;
    grid-template-columns: 1fr;
    padding: 0;
  }

  .team-settings-table-row.header {
    display: none;
  }

  .team-settings-table-top-section {
    padding-left: 24px;
    padding-right: 24px;
  }

  .plan-information-modules-container {
    flex-flow: column wrap;
  }

  .plan-information-table-row {
    grid-row-gap: 0px;
    border-style: solid;
    border-top-width: 1px;
    border-top-color: var(--neutral--300);
    border-right-width: 1px;
    border-right-color: var(--neutral--300);
    border-left-width: 1px;
    border-left-color: var(--neutral--300);
    border-radius: 8px;
    grid-template-columns: 1fr;
    margin-bottom: 24px;
    padding: 0;
    overflow: hidden;
  }

  .plan-information-table-row.header {
    display: none;
  }

  .plan-information-table-row.last {
    border-bottom-style: solid;
  }

  .billing-invoices-table-row {
    grid-row-gap: 0px;
    grid-template-columns: 1fr;
    padding: 0;
  }

  .billing-invoices-table-row.header {
    display: none;
  }

  .billing-invoices-table-row.first {
    border-top-style: none;
  }

  .notifications-settings-table-row {
    grid-row-gap: 0px;
    grid-template-columns: 1fr;
    margin-bottom: 48px;
    padding: 0;
  }

  .notifications-settings-table-row.header {
    display: none;
  }

  .notifications-settings-table-row.first {
    border-top-style: none;
  }

  .api-table-top-section {
    padding-left: 32px;
    padding-right: 32px;
  }

  .api-settings-table-row {
    grid-row-gap: 0px;
    grid-template-columns: 1fr;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
  }

  .api-settings-table-row.header {
    display: none;
  }

  .api-settings-table-row.first {
    border-top-style: solid;
  }

  .token-input-container {
    margin-right: 24px;
  }

  .settings-v2-main-grid {
    grid-template-columns: 1fr;
  }

  .settings-v2-tab-link {
    grid-row-gap: 8px;
    flex-direction: column;
  }

  .settings-v3-tab-menu {
    flex-wrap: wrap;
    margin-bottom: 32px;
  }

  .settings-v3-content-grid,
  .profile-v1-main-grid {
    grid-template-columns: 1fr;
  }

  .profile-v1-bg-banner {
    margin-bottom: -10%;
    display: flex;
  }

  .profile-v1-bio-container {
    padding-left: 32px;
    padding-right: 32px;
  }

  .profile-about-rich-text p {
    margin-bottom: 16px;
  }

  .profile-v2-top-container {
    border-radius: 20px;
    padding-left: 32px;
    padding-right: 32px;
  }

  .profile-v3-details-container {
    padding-left: 32px;
    padding-right: 32px;
  }

  .mg-right-20px.mg-right-12px-mbl {
    margin-left: 12px;
  }

  .sign-up-page-main-container {
    grid-template-columns: 1fr;
  }

  .sign-up-page-left-side {
    flex-direction: column;
    align-items: flex-start;
    padding-top: 48px;
    padding-left: 24px;
    padding-right: 24px;
  }

  .sign-up-page-right-side {
    padding-top: 80px;
    padding-bottom: 0;
  }

  .sign-up-page-logo-container {
    justify-content: flex-start;
    margin-bottom: 64px;
    padding-left: 0;
    padding-right: 0;
    position: static;
  }

  .integration-single-inner-content {
    padding-left: 32px;
    padding-right: 32px;
  }

  .integration-single-logo {
    max-height: 80px;
    max-width: 80px;
    min-height: 80px;
    min-width: 80px;
    margin-right: 24px;
  }

  .heading-h2-size-2 {
    font-size: 26px;
    line-height: 38px;
  }

  ._2-col-grid,
  ._2-col-grid.checkout-page {
    grid-template-columns: 1fr;
  }

  .help-center-top-section {
    background-position: 120% 100%, -30% 100%;
    background-size: 30%, 30%;
  }

  .help-center-top-section-text-container {
    grid-row-gap: 48px;
    flex-direction: column;
    align-items: center;
  }

  .container-large {
    padding-left: 20px;
    padding-right: 20px;
  }

  .homepage-top-section {
    padding-top: 64px;
    padding-bottom: 80px;
  }

  .homepage-top-section-bg-half {
    top: 60%;
  }

  .screens-text-container {
    max-height: 300px;
    max-width: 240px;
    min-height: 300px;
    min-width: 240px;
    bottom: 10%;
  }

  .features-text-right-container {
    padding-top: 48px;
    padding-left: 32px;
    padding-right: 32px;
  }

  .width-90 {
    width: 90%;
  }

  .preview-section-image-left {
    max-width: 90%;
  }

  .features-text-left-section {
    padding-top: 48px;
    padding-left: 32px;
    padding-right: 32px;
  }

  .homepage-pages-tabs-menu {
    justify-content: center;
    margin-bottom: 56px;
  }

  .homepage-pages-image-wrapper {
    border-radius: 20px;
  }

  .cta-container {
    border-radius: 20px;
    grid-template-columns: 1fr;
  }

  .cta-text-container {
    padding-bottom: 80px;
    padding-left: 32px;
    padding-right: 32px;
  }

  .cta-mobile-image {
    max-width: 90%;
    margin-right: -1px;
    display: block;
  }

  .section-base {
    max-width: 520px;
  }

  .section-base.flex {
    flex-flow: column;
    grid-template-columns: 1fr;
  }

  .h2 {
    font-size: 28px;
  }

  .div-wrapper {
    margin-top: 20px;
  }

  .text-block {
    margin-top: 4px;
    font-size: 14px;
  }

  .div-block-flex {
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 8px;
  }

  .text-block-bold.number {
    margin-top: 37%;
    margin-left: 40%;
  }

  .feedback-card.card.inline {
    max-width: 100%;
    margin-bottom: 24px;
  }

  .div-footer {
    height: 24px;
  }

  .image-4 {
    margin-top: 0;
    padding-top: 0;
  }
}

@media screen and (max-width: 479px) {
  h1 {
    font-size: 34px;
  }

  .grid-2-columns._2-col-mbl._1-col-mbp {
    grid-template-columns: 1fr;
  }

  .module.upload-box {
    padding: 20px;
  }

  .module.no-records-available,
  .module.action-completed,
  .module.there-s-been-an-error,
  .module.no-records-found,
  .module.notifications-alert {
    padding-top: 48px;
    padding-bottom: 48px;
  }

  .module.graph-large {
    border-radius: 16px;
    padding-left: 24px;
    padding-right: 24px;
  }

  .module.graph {
    border-radius: 16px;
    padding-left: 25px;
    padding-right: 25px;
  }

  .module.users-by-device,
  .module.recent-contacts {
    border-radius: 16px;
    padding-left: 24px;
    padding-right: 24px;
  }

  .module.total-sales,
  .module.recent-orders,
  .module.popular-categories {
    padding-left: 24px;
    padding-right: 24px;
  }

  .module.products-table {
    border-radius: 16px;
  }

  .module.team-progress,
  .module.tasks-reports {
    border-radius: 16px;
    padding-left: 24px;
    padding-right: 24px;
  }

  .module.due-tasks {
    padding-left: 24px;
    padding-right: 24px;
  }

  .module.contacts-card {
    border-radius: 16px;
    padding: 24px;
  }

  .module.contact-single-details,
  .module.assigned-to,
  .module.about-company {
    border-radius: 16px;
    padding-left: 24px;
    padding-right: 24px;
  }

  .module.add-note {
    border-radius: 16px;
  }

  .module.companies-card {
    border-radius: 16px;
    padding: 24px;
  }

  .module.border-radius-30px {
    border-radius: 16px;
  }

  .module.contact-activity-large {
    border-radius: 16px;
    flex-direction: column;
    padding: 24px;
  }

  .module.contact-activity-small {
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
  }

  .module.company-single-about {
    border-radius: 16px;
    margin-top: -24px;
    padding: 40px 24px;
  }

  .module.company-news {
    padding-left: 24px;
    padding-right: 24px;
  }

  .module.company-details,
  .module.projects {
    border-radius: 16px;
    padding-left: 24px;
    padding-right: 24px;
  }

  .module.features-tasks,
  .module.board-tasks {
    padding-left: 24px;
    padding-right: 24px;
  }

  .module.crypto-top-module,
  .module.crypto-news,
  .module.crypto-trending {
    border-radius: 16px;
    padding-left: 24px;
    padding-right: 24px;
  }

  .module.dashboard-invoice {
    padding-left: 24px;
    padding-right: 24px;
  }

  .module.client-information,
  .module.changelog {
    border-radius: 16px;
    padding-left: 24px;
    padding-right: 24px;
  }

  .module.file-manager-top-modules {
    padding-left: 24px;
    padding-right: 24px;
  }

  .module.amount-due {
    border-radius: 16px;
    padding-left: 24px;
    padding-right: 24px;
  }

  .module.support-form {
    border-radius: 16px;
    padding: 32px 24px 40px;
  }

  .module.support-contact-link {
    text-align: center;
    flex-direction: column-reverse;
    align-items: center;
  }

  .module.monthly-customers,
  .module.monthly-pageviews {
    border-radius: 16px;
    padding-left: 24px;
    padding-right: 24px;
  }

  .module.chat-box {
    border-radius: 15px;
  }

  .module.account {
    border-radius: 16px;
    padding-left: 24px;
    padding-right: 24px;
  }

  .module.team-settings {
    border-radius: 16px;
  }

  .module.billing {
    border-radius: 16px;
    padding-left: 24px;
    padding-right: 24px;
  }

  .module.billing-startup-plan-info,
  .module.billing-extra-users-info {
    padding-left: 18px;
    padding-right: 18px;
  }

  .module.payment-method {
    flex-direction: column;
    padding-left: 24px;
    padding-right: 24px;
  }

  .module.notifications-settings {
    border-radius: 16px;
    padding-left: 24px;
    padding-right: 24px;
  }

  .module.api-settings {
    border-radius: 16px;
  }

  .module.tabs-v2-menu-container {
    grid-column-gap: 4px;
    grid-row-gap: 6px;
    border-radius: 16px;
    flex-direction: column;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
  }

  .module.skills,
  .module.experience,
  .module.social-feed,
  .module.profile-v1-details {
    border-radius: 16px;
    padding-left: 24px;
    padding-right: 24px;
  }

  .module.profile-v2-details {
    border-radius: 16px;
  }

  .module.about-me-v2 {
    padding-left: 24px;
    padding-right: 24px;
  }

  .module.profile-v3-details {
    border-radius: 16px;
  }

  .module.pricing-v1,
  .module.pricing-v3,
  .module.pricing-single-top,
  .module.pricing-single-description {
    border-radius: 16px;
    padding-left: 24px;
    padding-right: 24px;
  }

  .module.template-pages {
    border-radius: 16px;
    padding-bottom: 48px;
  }

  .module.notifications {
    padding-left: 24px;
    padding-right: 24px;
  }

  .module.privacy-policy {
    border-radius: 16px;
    padding-left: 24px;
    padding-right: 24px;
  }

  .module.not-found {
    border-radius: 16px;
    padding: 40px 24px;
  }

  .module.utility-page-form {
    border-radius: 16px;
    padding-bottom: 48px;
    padding-left: 24px;
    padding-right: 24px;
  }

  .module.password-protected {
    border-radius: 16px;
    padding: 48px 24px;
  }

  .module.integrations {
    border-radius: 16px;
    padding: 24px;
  }

  .module.integration-single {
    border-radius: 16px;
  }

  .module.help-center-categories {
    border-radius: 16px;
    padding: 32px 24px;
  }

  .module.help-center-article-link {
    border-radius: 16px;
    padding-top: 40px;
    padding-left: 24px;
    padding-right: 24px;
  }

  .module.features-image-left {
    border-radius: 16px;
    margin-bottom: 24px;
  }

  .module.homepage-pages-image-container {
    border-radius: 16px;
    padding: 24px;
  }

  .grid-4-columns {
    grid-template-columns: 1fr;
  }

  .divider._64px {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .mg-bottom-24px {
    margin-bottom: 20px;
  }

  .mg-bottom-56px {
    margin-bottom: 32px;
  }

  .mg-bottom-64px {
    margin-bottom: 40px;
  }

  .mg-top-48px {
    margin-top: 32px;
  }

  .display-1 {
    font-size: 44px;
  }

  .display-2 {
    font-size: 34px;
  }

  .text-400 {
    font-size: 16px;
  }

  .grid-3-columns {
    grid-template-columns: 1fr;
  }

  .style---heading {
    background-position: 170% -280%, -80% 300%;
    margin-bottom: 48px;
    padding-top: 66px;
    padding-bottom: 66px;
  }

  .style---content-heading {
    padding-left: 22px;
    padding-right: 22px;
  }

  .style---heading-icon-wrapper {
    width: 58px;
    border-radius: 16px;
    margin-right: 14px;
  }

  .border-radius-24px {
    border-radius: 16px;
  }

  .btn-primary {
    width: 100%;
  }

  .btn-primary.button-row {
    margin-bottom: 16px;
    margin-right: 0;
  }

  .btn-secondary {
    width: 100%;
  }

  .btn-secondary.small.profile-v3-edit-button,
  .btn-secondary.tab-button,
  .btn-secondary.width-auto-mbp,
  .btn-secondary.settings-v3-tab-button,
  .btn-secondary.profile-tab-button,
  .btn-secondary.integrations-tab-button,
  .btn-secondary.homepage-pages-tab-button {
    width: auto;
  }

  .badge-secondary.figma-file-badge {
    padding: 12px 22px;
    font-size: 14px;
  }

  .input {
    min-height: 48px;
  }

  .input::-ms-input-placeholder {
    font-size: 16px;
    line-height: 16px;
  }

  .input::placeholder {
    font-size: 16px;
    line-height: 16px;
  }

  .input.dropdown-wrapper {
    max-height: 52px;
    padding-top: 0;
    padding-bottom: 0;
  }

  .checkbox-field-wrapper {
    font-size: 16px;
    line-height: 20px;
  }

  .checkbox-field-wrapper.large {
    font-size: 18px;
    line-height: 22px;
  }

  .checkbox-field-wrapper.mg-bottom-24px {
    margin-bottom: 16px;
  }

  .radio-button-field-wrapper {
    font-size: 16px;
    line-height: 22px;
  }

  .radio-button-field-wrapper.large {
    font-size: 18px;
    line-height: 24px;
  }

  .radio-button {
    min-height: 22px;
    min-width: 22px;
  }

  .radio-button.large {
    min-height: 26px;
    min-width: 26px;
  }

  .social-icon-square {
    border-radius: 6px;
    font-size: 14px;
  }

  .line-rounded-icon.dropdown-arrow.profile-dropdown {
    margin-left: 0;
  }

  .line-rounded-icon.success-message-check {
    margin-bottom: 16px;
    font-size: 40px;
  }

  .line-square-icon.alert-banner-close-icon {
    font-size: 14px;
    right: 16px;
  }

  .rich-text h2 {
    margin-top: 24px;
  }

  .buttons-row {
    flex-direction: column;
  }

  .container-default {
    padding-left: 16px;
    padding-right: 16px;
  }

  .section {
    padding-top: 96px;
    padding-bottom: 96px;
  }

  .alert-banner-padding {
    padding-left: 16px;
    padding-right: 34px;
  }

  .heading-h1-size {
    font-size: 34px;
  }

  .header-nav-list-item {
    width: 100%;
    text-align: left;
  }

  .dropdown-wrapper.dashboard-top-dropdown {
    max-width: 100%;
  }

  .utility-page-wrap {
    padding-top: 56px;
    padding-bottom: 56px;
  }

  .social-media-grid-top {
    grid-template-columns: repeat(auto-fit, 30px);
  }

  .success-message.utility-page-success-message {
    font-size: 14px;
  }

  .mg-bottom-160px {
    margin-bottom: 60px;
  }

  ._404-not-found {
    font-size: 100px;
  }

  .flex.click-inputs-container {
    grid-row-gap: 64px;
    flex-direction: column;
  }

  .flex.align-center.vertical-left-mbp {
    flex-direction: column;
    align-items: flex-start;
  }

  .flex.vertical-mbp {
    flex-direction: column;
  }

  .flex.vertical-left-mbp {
    flex-direction: column;
    align-items: flex-start;
  }

  .upload-box-inner-container {
    padding-left: 24px;
    padding-right: 24px;
  }

  .no-records-available-illustration {
    max-width: 120px;
  }

  .action-completed-illustration {
    max-width: 120px;
    margin-bottom: 16px;
  }

  .theres-been-an-error-illustration,
  .no-records-found-illustration,
  .notification-alert-illustration {
    max-width: 120px;
  }

  .close-button-popup-module {
    top: 16px;
    right: 16px;
  }

  .popup-wrapper {
    padding-left: 16px;
    padding-right: 16px;
  }

  .popup-top-icon {
    max-width: 56px;
  }

  .popup-left-icon {
    max-width: 56px;
    margin-bottom: 16px;
    margin-right: 0;
  }

  .popup-top-image {
    margin-bottom: 16px;
  }

  .popup-left-image {
    margin-bottom: 20px;
    margin-right: 0;
  }

  .mg-bottom-auto {
    margin-bottom: auto;
  }

  .dashly-custom-icon.chat-box-icon.mg-right-24px {
    margin-right: 8px;
  }

  .sidebar-logo {
    max-width: 86%;
  }

  .sidebar-wrapper {
    max-width: 48px;
    min-width: 48px;
    padding-left: 16px;
  }

  .sidebar-nav-list-item {
    width: 100%;
    text-align: left;
  }

  .dashboard-nav-container {
    margin-left: -16px;
  }

  .dashboard-nav-main-container {
    padding-left: 52px;
  }

  .dashboard-nav-left-content {
    margin-right: 16px;
  }

  .hidden-on-mbp {
    display: none;
  }

  .pages-link-text-wrapper {
    padding: 24px 24px 32px;
  }

  .reports-top-details-container {
    grid-template-columns: 1fr;
  }

  .mg-left-auto.mg-left-0-mbp {
    margin-left: 0;
  }

  .recent-contacts-details-container,
  .recent-orders-details-container {
    grid-row-gap: 8px;
    flex-direction: column;
    align-items: flex-start;
  }

  .order-status-right-container {
    grid-row-gap: 16px;
    flex-direction: column;
    align-items: flex-start;
  }

  .orders-status-row {
    grid-row-gap: 24px;
  }

  .products-table-top-section {
    grid-column-gap: 32px;
    grid-row-gap: 16px;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
  }

  .team-progress-right-container {
    min-width: 100%;
  }

  .mg-bottom-20px {
    margin-bottom: 16px;
  }

  .due-tasks-module-buttons-container {
    top: 16px;
    right: 16px;
  }

  .table-item-mobile-caption {
    padding-right: 8px;
  }

  .contacts-table-top-section,
  .companies-table-top-section {
    grid-column-gap: 32px;
    grid-row-gap: 16px;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
  }

  .add-note-top-link {
    padding-bottom: 16px;
    padding-left: 4px;
    padding-right: 0;
  }

  .add-note-top-section-links-container {
    grid-column-gap: 4px;
  }

  .company-single-top-banner {
    margin-bottom: auto;
  }

  .company-single-about-logo {
    margin-bottom: 16px;
    margin-right: 0;
  }

  .company-news-image-wrapper {
    margin-bottom: 24px;
    margin-right: 0;
  }

  .invoices-table-top-section,
  .crypto-table-top-section {
    grid-template-rows: auto;
    grid-template-columns: 1fr auto;
    grid-auto-columns: 1fr;
  }

  .mg-bottom-6px-mbp {
    margin-bottom: 6px;
  }

  .logs-table-top-section {
    grid-template-rows: auto;
    grid-template-columns: 1fr auto;
    grid-auto-columns: 1fr;
  }

  .mg-left-8px-mbp {
    margin-left: 8px;
  }

  .invoice-details-container {
    border-radius: 16px;
    padding-left: 24px;
    padding-right: 24px;
  }

  .form.min-h-460px {
    min-height: 634px;
  }

  .form.min-h-116px {
    min-height: 114px;
  }

  .form.min-h-188px {
    min-height: 182px;
  }

  .monthly-pageviews-details-top-container {
    margin-bottom: 16px;
    margin-right: 0;
  }

  .pageviews-header-grid {
    grid-template-columns: 1fr;
  }

  .chat-left-side-top-container,
  .chat-left-side-bottom-container,
  .chat-box-section {
    padding-left: 24px;
    padding-right: 24px;
  }

  .chat-box-section.header {
    grid-row-gap: 24px;
    flex-direction: column;
  }

  .chat-lightbox-image-wrapper {
    border-radius: 16px;
  }

  .chat-previews-main-container {
    grid-column-gap: 8px;
  }

  .settings-v1-tab-link {
    padding-bottom: 16px;
    padding-left: 8px;
    padding-right: 8px;
    font-size: 12px;
  }

  .popup.simple-popup,
  .popup.popup-icon-top {
    padding-bottom: 40px;
    padding-left: 24px;
    padding-right: 24px;
  }

  .popup.popup-icon-top.pd-sides-50px {
    padding-left: 24px;
    padding-right: 24px;
  }

  .popup.popup-icon-left {
    flex-direction: column;
    padding-bottom: 40px;
    padding-left: 24px;
    padding-right: 24px;
  }

  .popup.popup-image-top {
    padding-left: 24px;
    padding-right: 24px;
  }

  .popup.popup-image-left {
    flex-direction: column;
    padding-bottom: 40px;
    padding-left: 24px;
    padding-right: 24px;
  }

  .team-settings-table-top-section {
    grid-column-gap: 32px;
    grid-row-gap: 16px;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
  }

  .api-table-top-section {
    grid-template-rows: auto;
    grid-template-columns: 1fr;
    grid-auto-columns: 1fr;
    padding-left: 24px;
    padding-right: 24px;
  }

  .settings-v2-tab-link {
    grid-column-gap: 10px;
    grid-row-gap: 8px;
    flex-direction: row;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
  }

  .settings-v3-tab-menu {
    justify-content: flex-start;
  }

  .profile-v1-bg-banner {
    margin-bottom: -20%;
  }

  .profile-v1-bio-container {
    padding-left: 24px;
    padding-right: 24px;
  }

  .profile-v1-name-and-button-container {
    text-align: center;
    justify-content: center;
    margin-top: 24px;
    margin-bottom: 0;
  }

  .profile-about-rich-text h4 {
    margin-bottom: 10px;
  }

  .profile-about-rich-text p {
    margin-bottom: 12px;
  }

  .profile-experience-text {
    margin-top: 24px;
    margin-left: 0;
  }

  .profile-v2-top-container {
    min-height: 360px;
    border-radius: 16px;
  }

  .profile-v2-name-container {
    flex-direction: column;
  }

  .profile-v3-details-container {
    padding-left: 24px;
    padding-right: 24px;
  }

  .sign-up-page-logo {
    max-width: 160px;
  }

  .sign-up-page-logo-container {
    margin-bottom: 48px;
  }

  .integration-single-logo {
    border-radius: 20px;
    margin-bottom: 24px;
    margin-right: 0;
  }

  .checkbox-field-wrapper-2 {
    font-size: 16px;
    line-height: 20px;
  }

  .heading-h2-size-2 {
    font-size: 26px;
    line-height: 34px;
  }

  .radio-button-2 {
    min-height: 22px;
    min-width: 22px;
  }

  .order-item-content {
    margin-left: 0;
  }

  .order-list-title {
    margin-bottom: 4px;
  }

  .order-item {
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 20px;
    padding-top: 22px;
    padding-bottom: 22px;
  }

  .order-item-image {
    margin-bottom: 20px;
  }

  .order-list-price {
    margin-top: 11px;
  }

  .checkout-column {
    margin-bottom: 24px;
  }

  .help-center-top-section {
    background-position: 120% 98%, -30% 92%;
    padding-top: 48px;
  }

  .container-large {
    padding-left: 16px;
    padding-right: 16px;
  }

  .homepage-top-section {
    padding-top: 48px;
  }

  .homepage-top-section-bg-half {
    top: 72%;
  }

  .screens-text-container {
    max-width: 200px;
    min-width: 200px;
    bottom: 4%;
    right: 20%;
  }

  .features-text-right-container,
  .features-text-left-section {
    padding-top: 40px;
    padding-left: 24px;
    padding-right: 24px;
  }

  .homepage-pages-tabs-menu {
    margin-bottom: 48px;
  }

  .homepage-pages-image-wrapper,
  .cta-container {
    border-radius: 16px;
  }

  .cta-text-container {
    padding-bottom: 64px;
    padding-left: 24px;
    padding-right: 24px;
  }

  .section-base {
    max-width: 288px;
    flex-flow: column;
    margin-top: 42px;
  }

  .section-base.paragraph {
    margin-bottom: 60px;
  }

  .rightside {
    margin-top: 34px;
    margin-left: auto;
    margin-right: auto;
  }

  .div-wrapper {
    margin-top: 16px;
  }

  .text-block {
    margin-left: 10px;
  }

  .div-block-flex {
    justify-content: flex-start;
    align-items: center;
  }

  .div-block-flex.right {
    margin-top: 8px;
  }

  .text-block-bold {
    margin-top: 0;
  }

  .text-block-bold.number {
    margin-top: 37%;
    margin-left: 40%;
  }
}

#w-node-e90828c6-a1ed-c618-5478-321adcea7d44-4fbdfc36,
#w-node-_54510bde-3327-172c-398d-9369c48fcf0d-4fbdfc36 {
  align-self: start;
}

#w-node-_4e4e77a9-3ec6-a1ad-ee96-217d3d4360ec-b698cd49 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-fecb9bc8-0afa-83bb-a64d-73a33c6910e6-4fbdfc36,
#w-node-fecb9bc8-0afa-83bb-a64d-73a33c6910ed-4fbdfc36,
#w-node-fecb9bc8-0afa-83bb-a64d-73a33c6910f4-4fbdfc36 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: start;
}

#w-node-_2cc0215a-8cb3-e986-04c3-6691dc874e3c-b698cd49 {
  align-self: stretch;
}

#w-node-_4bfe39ce-56ff-eb1f-860c-e451464ba2aa-b698cd49 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_73cbd300-2d0d-8106-725d-8db356af75fc-b698cd49 {
  align-self: stretch;
}

#w-node-_1e5ca29b-430b-124e-69da-665e6c092793-4fbdfc36,
#w-node-_1e5ca29b-430b-124e-69da-665e6c092797-4fbdfc36,
#w-node-_1e5ca29b-430b-124e-69da-665e6c0927ed-4fbdfc36,
#w-node-_1e5ca29b-430b-124e-69da-665e6c0927f1-4fbdfc36,
#w-node-_31d145cc-1b45-dd1a-9ce3-e655462e74af-4fbdfc36,
#w-node-_31d145cc-1b45-dd1a-9ce3-e655462e74b3-4fbdfc36,
#w-node-c206dbad-3d35-89cc-24e6-b75e35d32014-4fbdfc36,
#w-node-_0bc8a5c1-3b6e-a9a6-3fb5-09d330d4c9d4-4fbdfc36,
#w-node-d24c7840-a60f-0f07-57ec-1760258fe282-4fbdfc36,
#w-node-d24c7840-a60f-0f07-57ec-1760258fe286-4fbdfc36,
#w-node-_9e3332a1-8916-65d8-2303-010e6dc33be0-4fbdfc36,
#w-node-_9e3332a1-8916-65d8-2303-010e6dc33be4-4fbdfc36,
#w-node-c22d985b-e94b-d20d-b5bf-370aad8f7523-4fbdfc36,
#w-node-c22d985b-e94b-d20d-b5bf-370aad8f7527-4fbdfc36,
#w-node-_1e74a2c4-ab0b-9d1d-3387-c90d056ef380-4fbdfc36,
#w-node-_1e74a2c4-ab0b-9d1d-3387-c90d056ef384-4fbdfc36,
#w-node-_438b3bbd-9d20-3007-6c12-fd188fcc5316-4fbdfc36,
#w-node-_438b3bbd-9d20-3007-6c12-fd188fcc531a-4fbdfc36,
#w-node-e334614c-3df2-6089-bd41-b62574d7f445-4fbdfc36,
#w-node-e334614c-3df2-6089-bd41-b62574d7f449-4fbdfc36,
#w-node-_616204ce-37c8-abfe-dbf6-2ae96d50118c-4fbdfc36,
#w-node-_616204ce-37c8-abfe-dbf6-2ae96d501190-4fbdfc36,
#w-node-_44ae1715-18f7-606a-22d5-382644c9fd0f-4fbdfc36,
#w-node-_44ae1715-18f7-606a-22d5-382644c9fd13-4fbdfc36,
#w-node-_37077c1b-45de-dbed-eeff-a69a8372f8c4-4fbdfc36,
#w-node-_37077c1b-45de-dbed-eeff-a69a8372f8c8-4fbdfc36,
#w-node-dfaaccc8-7e74-fe73-566a-0a0e6a92de3d-4fbdfc36,
#w-node-dfaaccc8-7e74-fe73-566a-0a0e6a92de41-4fbdfc36,
#w-node-_08a7f8c5-9c4d-e081-fe17-2f15534d658b-4fbdfc36,
#w-node-_08a7f8c5-9c4d-e081-fe17-2f15534d658f-4fbdfc36,
#w-node-_9438565e-7c73-df95-b591-7311725c58f0-4fbdfc36,
#w-node-_9438565e-7c73-df95-b591-7311725c58f4-4fbdfc36,
#w-node-c2e865d4-a35e-a841-84f2-d7c6393276c7-4fbdfc36,
#w-node-c2e865d4-a35e-a841-84f2-d7c6393276cb-4fbdfc36,
#w-node-_1bbd4439-21f7-11c8-fccd-d07e0679579e-4fbdfc36,
#w-node-_1bbd4439-21f7-11c8-fccd-d07e067957a2-4fbdfc36,
#w-node-e456bce0-0218-8cda-dabd-f7bc8a7e613d-4fbdfc36,
#w-node-e456bce0-0218-8cda-dabd-f7bc8a7e6141-4fbdfc36,
#w-node-a72e7f92-efac-047f-3df4-94929d0a63d6-4fbdfc36,
#w-node-a72e7f92-efac-047f-3df4-94929d0a63da-4fbdfc36,
#w-node-_6c7ab7e9-a20e-213d-53e9-892ecdebb732-4fbdfc36,
#w-node-_6c7ab7e9-a20e-213d-53e9-892ecdebb736-4fbdfc36,
#w-node-fcfd143f-4e71-e8e4-0e96-33764bd888a2-4fbdfc36,
#w-node-fcfd143f-4e71-e8e4-0e96-33764bd888a6-4fbdfc36,
#w-node-_9074c193-9344-eb51-0e01-c8dfc3085acc-4fbdfc36,
#w-node-_9074c193-9344-eb51-0e01-c8dfc3085ad0-4fbdfc36,
#w-node-b9a3f294-8f3a-b573-aa4e-e113937b7637-4fbdfc36,
#w-node-b9a3f294-8f3a-b573-aa4e-e113937b763b-4fbdfc36,
#w-node-ae8c63c7-3f9a-08cb-06b3-e7d8760c290c-4fbdfc36,
#w-node-ae8c63c7-3f9a-08cb-06b3-e7d8760c2910-4fbdfc36,
#w-node-_5d8b71a0-286e-3d22-1624-482bf3b69814-4fbdfc36,
#w-node-_5d8b71a0-286e-3d22-1624-482bf3b69818-4fbdfc36,
#w-node-f2706a72-144c-9855-dcae-ef11ff342948-4fbdfc36,
#w-node-f2706a72-144c-9855-dcae-ef11ff34294c-4fbdfc36,
#w-node-_59abd388-bd2b-6524-f1bb-71f8dcdf2506-4fbdfc36,
#w-node-_59abd388-bd2b-6524-f1bb-71f8dcdf250a-4fbdfc36,
#w-node-_6cb3d531-85e2-f8a6-8ed8-4e4157b23977-4fbdfc36,
#w-node-_6cb3d531-85e2-f8a6-8ed8-4e4157b2397b-4fbdfc36,
#w-node-_63d4dc04-8713-d82e-73ed-abb317e598b3-4fbdfc36,
#w-node-_63d4dc04-8713-d82e-73ed-abb317e598b7-4fbdfc36,
#w-node-d1355ce6-2438-122f-8b04-77558d458b65-4fbdfc36,
#w-node-d1355ce6-2438-122f-8b04-77558d458b69-4fbdfc36,
#w-node-df933f8f-2904-6aff-6878-0ed3f697dbc8-4fbdfc36,
#w-node-df933f8f-2904-6aff-6878-0ed3f697dbcc-4fbdfc36,
#w-node-e86f0349-faae-feb9-2a46-69a5f8111d28-4fbdfc36,
#w-node-e86f0349-faae-feb9-2a46-69a5f8111d2c-4fbdfc36,
#w-node-e16fe015-e62b-3c12-5af2-dc2eaba84da0-4fbdfc36,
#w-node-e16fe015-e62b-3c12-5af2-dc2eaba84da4-4fbdfc36,
#w-node-_082f3d72-6dff-5c47-bd9d-5785c7f77f57-4fbdfc36,
#w-node-_082f3d72-6dff-5c47-bd9d-5785c7f77f5b-4fbdfc36,
#w-node-_9b9fff68-37b6-a5e7-502e-b50f1724af15-4fbdfc36,
#w-node-_9b9fff68-37b6-a5e7-502e-b50f1724af19-4fbdfc36,
#w-node-_93f041b1-2e48-3a45-a2c8-e555d6182035-4fbdfc36,
#w-node-_93f041b1-2e48-3a45-a2c8-e555d6182039-4fbdfc36,
#w-node-b5c71bab-c48c-6222-373f-e37fc2b11ab4-4fbdfc36,
#w-node-b5c71bab-c48c-6222-373f-e37fc2b11ab8-4fbdfc36,
#w-node-_08f3e78f-d8f7-0268-3414-f4fca09340bd-4fbdfc36,
#w-node-_08f3e78f-d8f7-0268-3414-f4fca09340c1-4fbdfc36,
#w-node-_2839e690-f25f-57f0-f065-fca6b3740768-4fbdfc36,
#w-node-_2839e690-f25f-57f0-f065-fca6b374076c-4fbdfc36,
#w-node-_58b3015f-6366-e899-cf33-770af16e725c-4fbdfc36,
#w-node-_58b3015f-6366-e899-cf33-770af16e7260-4fbdfc36,
#w-node-_04793135-390a-5d11-3a84-2b142430b0f2-4fbdfc36,
#w-node-_04793135-390a-5d11-3a84-2b142430b0f6-4fbdfc36,
#w-node-_62b657dc-ac49-eb57-b824-a20887849c59-4fbdfc36,
#w-node-_62b657dc-ac49-eb57-b824-a20887849c5d-4fbdfc36 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_93f5a6e4-94ce-4066-49da-d199107198c7-b698cd49 {
  align-self: stretch;
}

#w-node-_4de4dbfd-9631-be06-e088-36af417d3167-4fbdfca2,
#w-node-_4de4dbfd-9631-be06-e088-36af417d316f-4fbdfca2,
#w-node-_4de4dbfd-9631-be06-e088-36af417d3177-4fbdfca2,
#w-node-_24956602-6408-7560-88c4-86768a57eeac-4fbdfca3,
#w-node-_24956602-6408-7560-88c4-86768a57eeb4-4fbdfca3,
#w-node-_24956602-6408-7560-88c4-86768a57eebc-4fbdfca3,
#w-node-cce77e42-9f8f-3474-0a1f-2c8715730b48-4fbdfca4,
#w-node-cce77e42-9f8f-3474-0a1f-2c8715730b50-4fbdfca4,
#w-node-cce77e42-9f8f-3474-0a1f-2c8715730b58-4fbdfca4,
#w-node-daa09b0d-608d-0dce-80c9-d826b7fcb720-4fbdfca4,
#w-node-_43b344e8-ae11-6dae-b180-8ba5398cdb55-4fbdfca4,
#w-node-_98bf4d71-446d-5bde-5a2e-89d9ecce6368-4fbdfca4,
#w-node-_2fb0be03-9d4e-8357-0bae-8bf4bcd76acc-4fbdfca4,
#w-node-b6ee72e6-ef7f-7637-29e2-9df9ae8ae129-4fbdfca4,
#w-node-_23676796-8bbf-3dcd-dde7-16910c33cf16-4fbdfca4,
#w-node-_8b1c3c5d-c218-ac34-fc3e-add660714352-4fbdfca4,
#w-node-_5fbe6643-c70b-56a2-3cc6-28a54aa322b9-4fbdfca4,
#w-node-_8c379277-0cdb-7f34-67df-32d7cfb20b1a-4fbdfca4,
#w-node-_3b1d6f70-f325-2b99-21ec-90c124500e04-4fbdfca4,
#w-node-_0fa869f4-5a10-a3e4-2c00-3d6137703b25-4fbdfca4,
#w-node-a9be9133-0796-6069-1023-980254146981-4fbdfca4,
#w-node-c988fafa-8882-da8f-843c-05b8462ce228-4fbdfca4,
#w-node-_02bdca57-1914-caec-b825-d13811189185-4fbdfcb9,
#w-node-_02bdca57-1914-caec-b825-d1381118918d-4fbdfcb9,
#w-node-_02bdca57-1914-caec-b825-d13811189195-4fbdfcb9,
#w-node-d0969461-87fd-5ff4-cf48-2209d48a565b-4fbdfcba,
#w-node-d0969461-87fd-5ff4-cf48-2209d48a5663-4fbdfcba,
#w-node-d0969461-87fd-5ff4-cf48-2209d48a566b-4fbdfcba,
#w-node-cd2d0b81-0235-156e-07b3-e5b0a7792b46-4fbdfcbb,
#w-node-cd2d0b81-0235-156e-07b3-e5b0a7792b4e-4fbdfcbb,
#w-node-cd2d0b81-0235-156e-07b3-e5b0a7792b56-4fbdfcbb,
#w-node-b6b04046-e28f-4566-08ef-8cb8195c07b9-4fbdfcbb,
#w-node-e02faa81-1d59-44f6-8324-0f317efc7a3a-4fbdfcbb,
#w-node-dc034d5e-1317-4c88-c71c-95c4f692ad91-4fbdfcbb,
#w-node-_2f557190-6855-3b60-0400-559a8d8f2ed9-4fbdfcbb,
#w-node-_4a8a7d3c-b193-d2a6-cbde-d4838f7d8d3b-4fbdfcbb,
#w-node-ad6c70e3-9ba9-1737-51fb-2c13eccb955b-4fbdfcbb,
#w-node-a8bc3871-37ae-8540-2bd3-6fa23fcfde7b-4fbdfcbc,
#w-node-feb27f08-37df-170d-a431-9dd8cccdef94-4fbdfcbc,
#w-node-_50c39f46-7d08-bc08-02d5-67e10df54bde-4fbdfcbd {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_50c39f46-7d08-bc08-02d5-67e10df54be3-4fbdfcbd,
#w-node-_50c39f46-7d08-bc08-02d5-67e10df54be4-4fbdfcbd {
  justify-self: end;
}

#w-node-_50c39f46-7d08-bc08-02d5-67e10df54bef-4fbdfcbd,
#w-node-_50c39f46-7d08-bc08-02d5-67e10df54bf1-4fbdfcbd {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_50c39f46-7d08-bc08-02d5-67e10df54c32-b698cd5f {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-f13cf1e3-4476-2ee8-8308-82f0f9a1676c-b698cd5f {
  grid-area: span 1 / span 1 / span 1 / span 1;
  align-self: stretch;
}

#w-node-ab65a4c2-4183-9e83-c954-9f60156d732e-b698cd5f {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_2756c834-43f0-8f5e-cb4b-884fa10d3bab-b698cd5f {
  grid-area: span 1 / span 1 / span 1 / span 1;
  align-self: stretch;
}

#w-node-_69b65eee-d5d6-6c9f-6eca-76327e42d8f5-b698cd5f {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_50c39f46-7d08-bc08-02d5-67e10df54c45-4fbdfcbd,
#w-node-_50c39f46-7d08-bc08-02d5-67e10df54c47-4fbdfcbd {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_50c39f46-7d08-bc08-02d5-67e10df54c4c-b698cd5f {
  align-self: end;
  justify-self: end;
}

#w-node-_50c39f46-7d08-bc08-02d5-67e10df54c53-4fbdfcbd,
#w-node-_50c39f46-7d08-bc08-02d5-67e10df54c55-4fbdfcbd {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_50c39f46-7d08-bc08-02d5-67e10df54c5a-b698cd5f {
  align-self: end;
  justify-self: end;
}

#w-node-_50c39f46-7d08-bc08-02d5-67e10df54c61-4fbdfcbd,
#w-node-_50c39f46-7d08-bc08-02d5-67e10df54c63-4fbdfcbd {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_50c39f46-7d08-bc08-02d5-67e10df54c68-b698cd5f {
  align-self: end;
  justify-self: end;
}

#w-node-_6feb9651-d900-c08c-c36e-0d671655095a-b698cd5f {
  grid-area: span 1 / span 1 / span 1 / span 1;
  align-self: stretch;
}

#w-node-_6feb9651-d900-c08c-c36e-0d671655095e-b698cd5f {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_6feb9651-d900-c08c-c36e-0d6716550971-b698cd5f {
  grid-area: span 1 / span 1 / span 1 / span 1;
  align-self: stretch;
}

#w-node-_6feb9651-d900-c08c-c36e-0d6716550975-4fbdfcbd,
#w-node-cc2ca80f-30ab-8def-27be-6e890e8715bd-4fbdfcbd {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_823a688c-ce82-26ae-9825-8a7f10dcc115-b698cd5f {
  grid-area: span 1 / span 1 / span 1 / span 1;
  align-self: stretch;
}

#w-node-_201e90b1-680d-a287-cbb0-7929d464a944-b698cd5f {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_9975c6b2-045f-bd47-7f76-cd29a5560f75-b698cd5f {
  grid-area: span 1 / span 1 / span 1 / span 1;
  align-self: stretch;
}

#w-node-_9975c6b2-045f-bd47-7f76-cd29a5560f79-b698cd5f {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-c1d6bd61-f145-c532-ef5b-d773446c6dfa-b698cd62 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-c1d6bd61-f145-c532-ef5b-d773446c6dff-4fbdfcc0,
#w-node-c1d6bd61-f145-c532-ef5b-d773446c6e00-4fbdfcc0 {
  justify-self: end;
}

#w-node-c1d6bd61-f145-c532-ef5b-d773446c6e0b-4fbdfcc0,
#w-node-c1d6bd61-f145-c532-ef5b-d773446c6e0d-4fbdfcc0,
#w-node-e344e492-6b82-041d-1912-3b676c8c63b6-4fbdfcc0,
#w-node-_341e2a37-426d-6758-e8de-00d4fdd1f167-4fbdfcc0,
#w-node-fb624b5d-ae7c-2323-f9a1-5126cdea3153-4fbdfcc0,
#w-node-_71dbdc1f-326d-0f2f-8e57-a6bd7c09e31c-4fbdfcc3,
#w-node-d6c24f10-a61e-d64a-df04-65bda324c3f3-4fbdfcc3,
#w-node-e320761e-b838-868c-d79d-34ea70276675-4fbdfcc3,
#w-node-ff0d33c9-5c1c-1178-9e47-4760de7b70da-4fbdfcc3,
#w-node-_529d1767-77d0-3a0f-7628-c972922c1530-4fbdfcc3 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-e2761a19-2604-6ac8-3949-21c9b2079b15-4fbdfcc5,
#w-node-e2761a19-2604-6ac8-3949-21c9b2079b1a-4fbdfcc5 {
  justify-self: end;
}

#w-node-e2761a19-2604-6ac8-3949-21c9b2079b20-4fbdfcc5,
#w-node-e2761a19-2604-6ac8-3949-21c9b2079b34-4fbdfcc5,
#w-node-e2761a19-2604-6ac8-3949-21c9b2079b3a-4fbdfcc5 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-e2761a19-2604-6ac8-3949-21c9b2079b40-b698cd67 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-e2761a19-2604-6ac8-3949-21c9b2079b44-b698cd67 {
  justify-self: end;
}

#w-node-e2761a19-2604-6ac8-3949-21c9b2079b46-b698cd67 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-e2761a19-2604-6ac8-3949-21c9b2079b4a-b698cd67 {
  justify-self: end;
}

#w-node-_421e5928-514b-f07f-d056-d45d7cbc8801-b698cd67 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_421e5928-514b-f07f-d056-d45d7cbc8805-b698cd67 {
  justify-self: end;
}

#w-node-_3cca0be1-4dea-d1d3-370f-a49a9e72b8ca-4fbdfcc5,
#w-node-_3cca0be1-4dea-d1d3-370f-a49a9e72b8df-4fbdfcc5,
#w-node-_3cca0be1-4dea-d1d3-370f-a49a9e72b8e5-4fbdfcc5 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_3cca0be1-4dea-d1d3-370f-a49a9e72b8eb-b698cd67 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_3cca0be1-4dea-d1d3-370f-a49a9e72b8ef-b698cd67 {
  justify-self: end;
}

#w-node-_3cca0be1-4dea-d1d3-370f-a49a9e72b8f1-b698cd67 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_3cca0be1-4dea-d1d3-370f-a49a9e72b8f5-b698cd67 {
  justify-self: end;
}

#w-node-_633ab21d-09ed-ca88-050f-3671804c769e-b698cd67 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_633ab21d-09ed-ca88-050f-3671804c76a2-b698cd67 {
  justify-self: end;
}

#w-node-_4c2dbcba-b5d3-5379-cc06-c89a53d7d814-4fbdfcc5,
#w-node-_4c2dbcba-b5d3-5379-cc06-c89a53d7d829-4fbdfcc5,
#w-node-_4c2dbcba-b5d3-5379-cc06-c89a53d7d82f-4fbdfcc5 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_4c2dbcba-b5d3-5379-cc06-c89a53d7d835-b698cd67 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_4c2dbcba-b5d3-5379-cc06-c89a53d7d839-b698cd67 {
  justify-self: end;
}

#w-node-_4c2dbcba-b5d3-5379-cc06-c89a53d7d83b-b698cd67 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_4c2dbcba-b5d3-5379-cc06-c89a53d7d83f-b698cd67 {
  justify-self: end;
}

#w-node-_2c30a158-1e42-82d9-1af8-48d9077b18b0-b698cd67 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_2c30a158-1e42-82d9-1af8-48d9077b18b4-b698cd67 {
  justify-self: end;
}

#w-node-_70040483-8dcc-7578-abc0-ea60672b899b-4fbdfcc5,
#w-node-_70040483-8dcc-7578-abc0-ea60672b89b0-4fbdfcc5,
#w-node-_70040483-8dcc-7578-abc0-ea60672b89b6-4fbdfcc5 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_70040483-8dcc-7578-abc0-ea60672b89bc-b698cd67 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_70040483-8dcc-7578-abc0-ea60672b89c0-b698cd67 {
  justify-self: end;
}

#w-node-_70040483-8dcc-7578-abc0-ea60672b89c2-b698cd67 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_70040483-8dcc-7578-abc0-ea60672b89c6-b698cd67 {
  justify-self: end;
}

#w-node-_0694500f-bedc-5788-fb90-d9dcfd36647c-b698cd67 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_0694500f-bedc-5788-fb90-d9dcfd366480-b698cd67 {
  justify-self: end;
}

#w-node-_563e143e-842a-8d3a-6f7e-83c846816ee4-4fbdfcc5,
#w-node-_563e143e-842a-8d3a-6f7e-83c846816ef9-4fbdfcc5,
#w-node-_563e143e-842a-8d3a-6f7e-83c846816eff-4fbdfcc5 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_563e143e-842a-8d3a-6f7e-83c846816f05-b698cd67 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_563e143e-842a-8d3a-6f7e-83c846816f09-b698cd67 {
  justify-self: end;
}

#w-node-_563e143e-842a-8d3a-6f7e-83c846816f0b-b698cd67 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_563e143e-842a-8d3a-6f7e-83c846816f0f-b698cd67 {
  justify-self: end;
}

#w-node-ef5f171f-e864-6601-0c09-272cc35a882d-b698cd67 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-ef5f171f-e864-6601-0c09-272cc35a8831-b698cd67 {
  justify-self: end;
}

#w-node-_7dde2507-75b0-95b7-0cc2-73e83f16eafd-4fbdfcc5,
#w-node-_7dde2507-75b0-95b7-0cc2-73e83f16eb12-4fbdfcc5,
#w-node-_7dde2507-75b0-95b7-0cc2-73e83f16eb18-4fbdfcc5 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_7dde2507-75b0-95b7-0cc2-73e83f16eb1e-b698cd67 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_7dde2507-75b0-95b7-0cc2-73e83f16eb22-b698cd67 {
  justify-self: end;
}

#w-node-_7dde2507-75b0-95b7-0cc2-73e83f16eb24-b698cd67 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_7dde2507-75b0-95b7-0cc2-73e83f16eb28-b698cd67 {
  justify-self: end;
}

#w-node-_2c7f0667-ac3a-cc57-f045-61e2b49818bc-b698cd67 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_2c7f0667-ac3a-cc57-f045-61e2b49818c0-b698cd67 {
  justify-self: end;
}

#w-node-_5507ce02-06d4-7b40-863d-6b243efe3ce0-4fbdfcc5,
#w-node-_5507ce02-06d4-7b40-863d-6b243efe3cf5-4fbdfcc5,
#w-node-_5507ce02-06d4-7b40-863d-6b243efe3cfb-4fbdfcc5 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_5507ce02-06d4-7b40-863d-6b243efe3d01-b698cd67 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_5507ce02-06d4-7b40-863d-6b243efe3d05-b698cd67 {
  justify-self: end;
}

#w-node-_5507ce02-06d4-7b40-863d-6b243efe3d07-b698cd67 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_5507ce02-06d4-7b40-863d-6b243efe3d0b-b698cd67 {
  justify-self: end;
}

#w-node-_1a3ba9e1-b2d9-8369-c138-3f86fed035b6-b698cd67 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_1a3ba9e1-b2d9-8369-c138-3f86fed035ba-b698cd67 {
  justify-self: end;
}

#w-node-_6d571408-313b-0bca-3723-f995e1a83d62-4fbdfcc5,
#w-node-_6d571408-313b-0bca-3723-f995e1a83d77-4fbdfcc5,
#w-node-_6d571408-313b-0bca-3723-f995e1a83d7d-4fbdfcc5 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_6d571408-313b-0bca-3723-f995e1a83d83-b698cd67 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_6d571408-313b-0bca-3723-f995e1a83d87-b698cd67 {
  justify-self: end;
}

#w-node-_6d571408-313b-0bca-3723-f995e1a83d89-b698cd67 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_6d571408-313b-0bca-3723-f995e1a83d8d-b698cd67 {
  justify-self: end;
}

#w-node-_2e3f6734-55a0-d8bb-6041-afad76ce26b3-b698cd67 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_2e3f6734-55a0-d8bb-6041-afad76ce26b7-b698cd67 {
  justify-self: end;
}

#w-node-_5119e738-b6ac-f640-b693-9443f2da8347-4fbdfcc5,
#w-node-_5119e738-b6ac-f640-b693-9443f2da835c-4fbdfcc5,
#w-node-_5119e738-b6ac-f640-b693-9443f2da8362-4fbdfcc5 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_5119e738-b6ac-f640-b693-9443f2da8368-b698cd67 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_5119e738-b6ac-f640-b693-9443f2da836c-b698cd67 {
  justify-self: end;
}

#w-node-_5119e738-b6ac-f640-b693-9443f2da836e-b698cd67 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_5119e738-b6ac-f640-b693-9443f2da8372-b698cd67 {
  justify-self: end;
}

#w-node-_8e7fa301-3301-8a2c-acc7-ce130173c987-b698cd67 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_8e7fa301-3301-8a2c-acc7-ce130173c98b-b698cd67 {
  justify-self: end;
}

#w-node-f268be9e-4baf-3b74-a8d6-20075cc720d6-4fbdfcc5,
#w-node-f268be9e-4baf-3b74-a8d6-20075cc720eb-4fbdfcc5,
#w-node-f268be9e-4baf-3b74-a8d6-20075cc720f1-4fbdfcc5 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-f268be9e-4baf-3b74-a8d6-20075cc720f7-b698cd67 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-f268be9e-4baf-3b74-a8d6-20075cc720fb-b698cd67 {
  justify-self: end;
}

#w-node-f268be9e-4baf-3b74-a8d6-20075cc720fd-b698cd67 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-f268be9e-4baf-3b74-a8d6-20075cc72101-b698cd67 {
  justify-self: end;
}

#w-node-cd31577e-f2af-2685-d7f5-217b8836b3b0-b698cd67 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-cd31577e-f2af-2685-d7f5-217b8836b3b4-b698cd67 {
  justify-self: end;
}

#w-node-e2761a19-2604-6ac8-3949-21c9b2079d1f-b698cd67 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-e3f0646f-f842-68a3-88c1-a4c3ea82f531-b698cd67 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: start;
}

#w-node-e3f0646f-f842-68a3-88c1-a4c3ea82f533-b698cd67 {
  justify-self: end;
}

#w-node-_0aafa275-0150-c1de-3c86-3833e1f6fc9f-b698cd67 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_0aafa275-0150-c1de-3c86-3833e1f6fcc2-b698cd67 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: start;
}

#w-node-_0aafa275-0150-c1de-3c86-3833e1f6fcc4-b698cd67 {
  justify-self: end;
}

#w-node-c0a970ad-840c-44c3-186e-bf8060ab3c70-b698cd67 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-c0a970ad-840c-44c3-186e-bf8060ab3c93-b698cd67 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: start;
}

#w-node-c0a970ad-840c-44c3-186e-bf8060ab3c95-b698cd67 {
  justify-self: end;
}

#w-node-c6a18eaa-8e69-947c-bb4f-e64dd539439b-b698cd67 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-c6a18eaa-8e69-947c-bb4f-e64dd53943be-b698cd67 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: start;
}

#w-node-c6a18eaa-8e69-947c-bb4f-e64dd53943c0-b698cd67 {
  justify-self: end;
}

#w-node-_29aed449-08e9-b9b4-a91c-75c13a6225d2-b698cd67 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_29aed449-08e9-b9b4-a91c-75c13a6225f5-b698cd67 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: start;
}

#w-node-_29aed449-08e9-b9b4-a91c-75c13a6225f7-b698cd67 {
  justify-self: end;
}

#w-node-_79caeb3a-daea-abe8-711b-0730a91e2bd8-b698cd67 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_79caeb3a-daea-abe8-711b-0730a91e2bfb-b698cd67 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: start;
}

#w-node-_79caeb3a-daea-abe8-711b-0730a91e2bfd-b698cd67 {
  justify-self: end;
}

#w-node-_64c04fb0-968c-b00f-3234-cfab143030e5-b698cd68 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  align-self: start;
}

#w-node-_19cb91f2-eaca-5584-6779-158ddc41cb3c-b698cd68 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-c5b8fc72-ebd0-6001-abeb-d48948eec65a-4fbdfcc6,
#w-node-_9943e89e-9575-0da3-02ce-635175d6bd59-4fbdfcc8 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  align-self: start;
}

#w-node-a0554008-07bd-3f5c-ae68-e0e118d8a2a7-b698cd6a {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_3e7975ed-f07d-8e42-2a45-505a97214a24-b698cd6a {
  grid-area: span 1 / span 1 / span 1 / span 1;
  align-self: start;
}

#w-node-ff9d2cbe-c43f-6f3b-3d34-62b9924b1ddc-b698cd6a {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_9c054e7c-d54d-9ff8-b472-eef6c1dd6fd6-b698cd6a {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-d40ddd40-f9e5-5b78-e519-ee7c29620c29-b698cd6a {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_2c487a51-143d-7a2d-9a3a-7998460def48-b698cd6a {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-e76bcb63-fa49-94de-8df4-2b0b09bc48ee-4fbdfcc8,
#w-node-_4a99517b-aa65-2030-7fd3-3a2e6b4ff08c-4fbdfcc8 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-e36d02ec-3964-39a3-fa78-2194a8f4fb0d-b698cd6a {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_45e09296-28a7-9b56-affc-cc4e30b00f99-4fbdfcc8,
#w-node-_45e09296-28a7-9b56-affc-cc4e30b00f9d-4fbdfcc8,
#w-node-_45e09296-28a7-9b56-affc-cc4e30b00f9e-4fbdfcc8 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_45e09296-28a7-9b56-affc-cc4e30b00fa3-b698cd6a {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_8d1be5ff-5946-9546-20d9-dd951933f787-4fbdfcc8,
#w-node-_8d1be5ff-5946-9546-20d9-dd951933f78b-4fbdfcc8,
#w-node-_8d1be5ff-5946-9546-20d9-dd951933f78c-4fbdfcc8 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_8d1be5ff-5946-9546-20d9-dd951933f791-b698cd6a {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-e7f21fd3-4d6f-899c-da7c-b1e95a3e1486-b698cd6a {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-e7f21fd3-4d6f-899c-da7c-b1e95a3e1488-b698cd6a {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-e7f21fd3-4d6f-899c-da7c-b1e95a3e1490-4fbdfcc8,
#w-node-e7f21fd3-4d6f-899c-da7c-b1e95a3e1492-4fbdfcc8 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-e7f21fd3-4d6f-899c-da7c-b1e95a3e1494-b698cd6a {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_08ad51d8-c915-4ac3-c85c-befd22514173-4fbdfcc8,
#w-node-_08ad51d8-c915-4ac3-c85c-befd22514177-4fbdfcc8,
#w-node-_08ad51d8-c915-4ac3-c85c-befd22514178-4fbdfcc8 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_08ad51d8-c915-4ac3-c85c-befd2251417f-b698cd6a {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_9c5ae7b8-0b82-e359-5bc9-14e548e4c647-4fbdfcc8,
#w-node-_9c5ae7b8-0b82-e359-5bc9-14e548e4c64b-4fbdfcc8,
#w-node-_9c5ae7b8-0b82-e359-5bc9-14e548e4c64c-4fbdfcc8 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_9c5ae7b8-0b82-e359-5bc9-14e548e4c653-b698cd6a {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-a473b318-4d89-5230-336a-ad4459b8b7b9-4fbdfcc8,
#w-node-_34257bcb-659f-c72e-9b64-54006c55cf2a-4fbdfcc9,
#w-node-_34257bcb-659f-c72e-9b64-54006c55cf3e-4fbdfcc9,
#w-node-_34257bcb-659f-c72e-9b64-54006c55cf48-4fbdfcc9,
#w-node-_34257bcb-659f-c72e-9b64-54006c55cf4f-4fbdfcc9 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_34257bcb-659f-c72e-9b64-54006c55cf54-b698cd6b {
  justify-self: end;
}

#w-node-_34257bcb-659f-c72e-9b64-54006c55cf56-b698cd6b {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: start;
}

#w-node-_34257bcb-659f-c72e-9b64-54006c55cf5b-b698cd6b {
  justify-self: end;
}

#w-node-_34257bcb-659f-c72e-9b64-54006c55cf64-b698cd6b {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_34257bcb-659f-c72e-9b64-54006c55cf65-b698cd6b {
  justify-self: end;
}

#w-node-_69e9c055-99b3-10bc-97bb-b1cd35e334c9-4fbdfcc9,
#w-node-_69e9c055-99b3-10bc-97bb-b1cd35e334dd-4fbdfcc9,
#w-node-_69e9c055-99b3-10bc-97bb-b1cd35e334e3-4fbdfcc9,
#w-node-_69e9c055-99b3-10bc-97bb-b1cd35e334e9-4fbdfcc9 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_69e9c055-99b3-10bc-97bb-b1cd35e334ed-b698cd6b {
  justify-self: end;
}

#w-node-_69e9c055-99b3-10bc-97bb-b1cd35e334ef-b698cd6b {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: start;
}

#w-node-_69e9c055-99b3-10bc-97bb-b1cd35e334f3-b698cd6b {
  justify-self: end;
}

#w-node-_71a9cff6-1adb-70e5-372a-f44acd039924-b698cd6b {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_71a9cff6-1adb-70e5-372a-f44acd039928-b698cd6b {
  justify-self: end;
}

#w-node-_930cfbac-fa8d-cae2-ed15-049c78fae155-4fbdfcc9,
#w-node-_930cfbac-fa8d-cae2-ed15-049c78fae169-4fbdfcc9,
#w-node-_930cfbac-fa8d-cae2-ed15-049c78fae16f-4fbdfcc9,
#w-node-_930cfbac-fa8d-cae2-ed15-049c78fae175-4fbdfcc9 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_930cfbac-fa8d-cae2-ed15-049c78fae179-b698cd6b {
  justify-self: end;
}

#w-node-_930cfbac-fa8d-cae2-ed15-049c78fae17b-b698cd6b {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: start;
}

#w-node-_930cfbac-fa8d-cae2-ed15-049c78fae17f-b698cd6b {
  justify-self: end;
}

#w-node-_90e3e44a-b21b-081a-92f9-74a0015cbf84-b698cd6b {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_90e3e44a-b21b-081a-92f9-74a0015cbf88-b698cd6b {
  justify-self: end;
}

#w-node-_05c3a6a4-51ba-3b74-39c4-eed43ea4ee6b-4fbdfcc9,
#w-node-_05c3a6a4-51ba-3b74-39c4-eed43ea4ee7f-4fbdfcc9,
#w-node-_05c3a6a4-51ba-3b74-39c4-eed43ea4ee85-4fbdfcc9,
#w-node-_05c3a6a4-51ba-3b74-39c4-eed43ea4ee8b-4fbdfcc9 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_05c3a6a4-51ba-3b74-39c4-eed43ea4ee8f-b698cd6b {
  justify-self: end;
}

#w-node-_05c3a6a4-51ba-3b74-39c4-eed43ea4ee91-b698cd6b {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: start;
}

#w-node-_05c3a6a4-51ba-3b74-39c4-eed43ea4ee95-b698cd6b {
  justify-self: end;
}

#w-node-c8a0730a-fc52-38b7-837f-90861aa6cb80-b698cd6b {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-c8a0730a-fc52-38b7-837f-90861aa6cb84-b698cd6b {
  justify-self: end;
}

#w-node-_7984efe6-1fa3-c8ca-da17-7378b804cabc-4fbdfcc9,
#w-node-_7984efe6-1fa3-c8ca-da17-7378b804cad0-4fbdfcc9,
#w-node-_7984efe6-1fa3-c8ca-da17-7378b804cad6-4fbdfcc9,
#w-node-_7984efe6-1fa3-c8ca-da17-7378b804cadc-4fbdfcc9 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_7984efe6-1fa3-c8ca-da17-7378b804cae0-b698cd6b {
  justify-self: end;
}

#w-node-_7984efe6-1fa3-c8ca-da17-7378b804cae2-b698cd6b {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: start;
}

#w-node-_7984efe6-1fa3-c8ca-da17-7378b804cae6-b698cd6b {
  justify-self: end;
}

#w-node-_17432bcd-1dc2-3ab9-a5c7-68baec0b2821-b698cd6b {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_17432bcd-1dc2-3ab9-a5c7-68baec0b2825-b698cd6b {
  justify-self: end;
}

#w-node-_2cf6b632-794d-d98b-932c-fc4287d2ce15-4fbdfcc9,
#w-node-_2cf6b632-794d-d98b-932c-fc4287d2ce29-4fbdfcc9,
#w-node-_2cf6b632-794d-d98b-932c-fc4287d2ce2f-4fbdfcc9,
#w-node-_2cf6b632-794d-d98b-932c-fc4287d2ce35-4fbdfcc9 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_2cf6b632-794d-d98b-932c-fc4287d2ce39-b698cd6b {
  justify-self: end;
}

#w-node-_2cf6b632-794d-d98b-932c-fc4287d2ce3b-b698cd6b {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: start;
}

#w-node-_2cf6b632-794d-d98b-932c-fc4287d2ce3f-b698cd6b {
  justify-self: end;
}

#w-node-_5df39431-f2e0-f931-5350-f3bf580b8edd-b698cd6b {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_5df39431-f2e0-f931-5350-f3bf580b8ee1-b698cd6b {
  justify-self: end;
}

#w-node-_144e30ca-dbbb-f633-cb4f-59c7bb8fc424-4fbdfcc9,
#w-node-_144e30ca-dbbb-f633-cb4f-59c7bb8fc438-4fbdfcc9,
#w-node-_144e30ca-dbbb-f633-cb4f-59c7bb8fc43e-4fbdfcc9,
#w-node-_144e30ca-dbbb-f633-cb4f-59c7bb8fc444-4fbdfcc9 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_144e30ca-dbbb-f633-cb4f-59c7bb8fc448-b698cd6b {
  justify-self: end;
}

#w-node-_144e30ca-dbbb-f633-cb4f-59c7bb8fc44a-b698cd6b {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: start;
}

#w-node-_144e30ca-dbbb-f633-cb4f-59c7bb8fc44e-b698cd6b {
  justify-self: end;
}

#w-node-bb85620d-8c31-2dbc-d678-5abd1d3e3a57-b698cd6b {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-bb85620d-8c31-2dbc-d678-5abd1d3e3a5b-b698cd6b {
  justify-self: end;
}

#w-node-_21b03758-6ef8-a252-c2d4-23285e871527-4fbdfcc9,
#w-node-_21b03758-6ef8-a252-c2d4-23285e87153b-4fbdfcc9,
#w-node-_21b03758-6ef8-a252-c2d4-23285e871541-4fbdfcc9,
#w-node-_21b03758-6ef8-a252-c2d4-23285e871547-4fbdfcc9 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_21b03758-6ef8-a252-c2d4-23285e87154b-b698cd6b {
  justify-self: end;
}

#w-node-_21b03758-6ef8-a252-c2d4-23285e87154d-b698cd6b {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: start;
}

#w-node-_21b03758-6ef8-a252-c2d4-23285e871551-b698cd6b {
  justify-self: end;
}

#w-node-a36c4e69-1366-6647-419b-d649ba3ff0ee-b698cd6b {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-a36c4e69-1366-6647-419b-d649ba3ff0f2-b698cd6b {
  justify-self: end;
}

#w-node-_4d081466-47da-4b31-f041-1b78a39ba672-4fbdfcc9,
#w-node-_4d081466-47da-4b31-f041-1b78a39ba686-4fbdfcc9,
#w-node-_4d081466-47da-4b31-f041-1b78a39ba68c-4fbdfcc9,
#w-node-_4d081466-47da-4b31-f041-1b78a39ba692-4fbdfcc9 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_4d081466-47da-4b31-f041-1b78a39ba696-b698cd6b {
  justify-self: end;
}

#w-node-_4d081466-47da-4b31-f041-1b78a39ba698-b698cd6b {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: start;
}

#w-node-_4d081466-47da-4b31-f041-1b78a39ba69c-b698cd6b {
  justify-self: end;
}

#w-node-ec46a5bf-a8f1-5878-75ca-6652b80c1e54-b698cd6b {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-ec46a5bf-a8f1-5878-75ca-6652b80c1e58-b698cd6b {
  justify-self: end;
}

#w-node-dbf632a3-a813-b733-8570-65bee07cb500-4fbdfcc9,
#w-node-dbf632a3-a813-b733-8570-65bee07cb514-4fbdfcc9,
#w-node-dbf632a3-a813-b733-8570-65bee07cb51a-4fbdfcc9,
#w-node-dbf632a3-a813-b733-8570-65bee07cb520-4fbdfcc9 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-dbf632a3-a813-b733-8570-65bee07cb524-b698cd6b {
  justify-self: end;
}

#w-node-dbf632a3-a813-b733-8570-65bee07cb526-b698cd6b {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: start;
}

#w-node-dbf632a3-a813-b733-8570-65bee07cb52a-b698cd6b {
  justify-self: end;
}

#w-node-_11577d1b-a794-5062-56bf-61f5b2ee1dfe-b698cd6b {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_11577d1b-a794-5062-56bf-61f5b2ee1e02-b698cd6b {
  justify-self: end;
}

#w-node-_73d60317-7c91-364d-3bab-f9ce2e600699-b698cd6b {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_1ac8a53e-c59c-e95f-3ced-1fb04b6aebe6-b698cd6b {
  justify-self: end;
}

#w-node-ba4477dc-ba32-f314-8dce-c6b7b6b7ff58-b698cd6b {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-ba4477dc-ba32-f314-8dce-c6b7b6b7ff82-b698cd6b {
  justify-self: end;
}

#w-node-_997bb82d-5604-f9a2-bff8-beeeb25221ff-b698cd6b {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_997bb82d-5604-f9a2-bff8-beeeb2522229-b698cd6b {
  justify-self: end;
}

#w-node-a031c96c-d1a8-5513-3543-84c2eef0d219-b698cd6b {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-a031c96c-d1a8-5513-3543-84c2eef0d243-b698cd6b {
  justify-self: end;
}

#w-node-_9c367eaa-85f4-73a1-6388-bdac47a15db8-b698cd6b {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_9c367eaa-85f4-73a1-6388-bdac47a15de2-b698cd6b {
  justify-self: end;
}

#w-node-f5f4ea72-cda2-93c3-f958-5849d8918a71-b698cd6b {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-f5f4ea72-cda2-93c3-f958-5849d8918a9b-b698cd6b {
  justify-self: end;
}

#w-node-_3ffa6b5f-21c2-e1fa-bff9-8dfa676dd3ca-4fbdfccc,
#w-node-fecc455a-926f-e566-fbbc-ed44a00476b1-4fbdfccc,
#w-node-bf6a9bfd-612b-93f6-ce76-10bf1c4b98a5-4fbdfccc,
#w-node-_0a938404-74da-405b-1c20-fc73576439f6-4fbdfccc,
#w-node-_2a1a0cb9-0be8-2409-af4b-a3a3d0d6a497-4fbdfccc,
#w-node-_2e226165-5e9d-fc17-4c58-79b5301d5aa5-4fbdfccc,
#w-node-ee9f97c9-0fe1-b93f-7083-5a4ce6c50785-4fbdfccc,
#w-node-ee9f97c9-0fe1-b93f-7083-5a4ce6c50787-4fbdfccc,
#w-node-_541e4670-a4d7-6498-adff-682c5287660e-4fbdfccc,
#w-node-_541e4670-a4d7-6498-adff-682c5287661c-4fbdfccc,
#w-node-_541e4670-a4d7-6498-adff-682c5287661e-4fbdfccc,
#w-node-_541e4670-a4d7-6498-adff-682c52876621-4fbdfccc,
#w-node-_541e4670-a4d7-6498-adff-682c52876629-4fbdfccc,
#w-node-_541e4670-a4d7-6498-adff-682c5287662b-4fbdfccc,
#w-node-_541e4670-a4d7-6498-adff-682c5287662e-4fbdfccc,
#w-node-_541e4670-a4d7-6498-adff-682c52876630-4fbdfccc,
#w-node-e61469ea-ea85-7a6d-8d3a-2187e8024a24-4fbdfccc,
#w-node-e61469ea-ea85-7a6d-8d3a-2187e8024a32-4fbdfccc,
#w-node-e61469ea-ea85-7a6d-8d3a-2187e8024a34-4fbdfccc,
#w-node-e61469ea-ea85-7a6d-8d3a-2187e8024a37-4fbdfccc,
#w-node-e61469ea-ea85-7a6d-8d3a-2187e8024a3f-4fbdfccc,
#w-node-e61469ea-ea85-7a6d-8d3a-2187e8024a41-4fbdfccc,
#w-node-e61469ea-ea85-7a6d-8d3a-2187e8024a44-4fbdfccc,
#w-node-e61469ea-ea85-7a6d-8d3a-2187e8024a46-4fbdfccc {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_8a1cc60a-e4d7-ca0e-c57e-4b5825ab2481-b698cd6e {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_52a8df8a-1e33-213c-9d85-3a9582c59972-4fbdfccc,
#w-node-_8a1cc60a-e4d7-ca0e-c57e-4b5825ab24a7-4fbdfccc,
#w-node-_8a1cc60a-e4d7-ca0e-c57e-4b5825ab24ac-4fbdfccc,
#w-node-_8a1cc60a-e4d7-ca0e-c57e-4b5825ab24b1-4fbdfccc {
  justify-self: end;
}

#w-node-_1e4d1eee-334f-bf7e-3508-e00622a152a5-4fbdfccc,
#w-node-_1e4d1eee-334f-bf7e-3508-e00622a152ac-4fbdfccc {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_1e4d1eee-334f-bf7e-3508-e00622a152b8-4fbdfccc,
#w-node-_1e4d1eee-334f-bf7e-3508-e00622a152be-4fbdfccc,
#w-node-_1e4d1eee-334f-bf7e-3508-e00622a152c7-4fbdfccc {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_1e4d1eee-334f-bf7e-3508-e00622a152cb-b698cd6e {
  justify-self: end;
}

#w-node-_1e4d1eee-334f-bf7e-3508-e00622a152cd-b698cd6e {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_1e4d1eee-334f-bf7e-3508-e00622a152d1-b698cd6e {
  justify-self: end;
}

#w-node-a37e5fe0-3d55-57f3-6e59-78275ab8e69c-4fbdfccc,
#w-node-a37e5fe0-3d55-57f3-6e59-78275ab8e6a3-4fbdfccc {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-a37e5fe0-3d55-57f3-6e59-78275ab8e6af-4fbdfccc,
#w-node-a37e5fe0-3d55-57f3-6e59-78275ab8e6b5-4fbdfccc,
#w-node-a37e5fe0-3d55-57f3-6e59-78275ab8e6be-4fbdfccc {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-a37e5fe0-3d55-57f3-6e59-78275ab8e6c2-b698cd6e {
  justify-self: end;
}

#w-node-a37e5fe0-3d55-57f3-6e59-78275ab8e6c4-b698cd6e {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-a37e5fe0-3d55-57f3-6e59-78275ab8e6c8-b698cd6e {
  justify-self: end;
}

#w-node-_76005460-0363-6817-6362-2277b4fdccfd-4fbdfccc,
#w-node-_76005460-0363-6817-6362-2277b4fdcd04-4fbdfccc {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_76005460-0363-6817-6362-2277b4fdcd10-4fbdfccc,
#w-node-_76005460-0363-6817-6362-2277b4fdcd16-4fbdfccc,
#w-node-_76005460-0363-6817-6362-2277b4fdcd1f-4fbdfccc {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_76005460-0363-6817-6362-2277b4fdcd23-b698cd6e {
  justify-self: end;
}

#w-node-_76005460-0363-6817-6362-2277b4fdcd25-b698cd6e {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_76005460-0363-6817-6362-2277b4fdcd29-b698cd6e {
  justify-self: end;
}

#w-node-_8a1cc60a-e4d7-ca0e-c57e-4b5825ab24b7-4fbdfccc,
#w-node-_8a1cc60a-e4d7-ca0e-c57e-4b5825ab24ca-4fbdfccc {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_8a1cc60a-e4d7-ca0e-c57e-4b5825ab24d0-4fbdfccc,
#w-node-_8a1cc60a-e4d7-ca0e-c57e-4b5825ab24d6-4fbdfccc,
#w-node-_8a1cc60a-e4d7-ca0e-c57e-4b5825ab24dc-4fbdfccc {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_8a1cc60a-e4d7-ca0e-c57e-4b5825ab24e0-b698cd6e {
  justify-self: end;
}

#w-node-_31f1e8d8-f5df-c664-de23-7ebb36aae9a1-b698cd6e {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_31f1e8d8-f5df-c664-de23-7ebb36aae9a5-b698cd6e {
  justify-self: end;
}

#w-node-bf76143f-c5d2-81db-b861-7e82608375c2-4fbdfccc,
#w-node-bf76143f-c5d2-81db-b861-7e82608375c9-4fbdfccc {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-bf76143f-c5d2-81db-b861-7e82608375d5-4fbdfccc,
#w-node-bf76143f-c5d2-81db-b861-7e82608375db-4fbdfccc,
#w-node-bf76143f-c5d2-81db-b861-7e82608375e4-4fbdfccc {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-bf76143f-c5d2-81db-b861-7e82608375e8-b698cd6e {
  justify-self: end;
}

#w-node-bf76143f-c5d2-81db-b861-7e82608375ea-b698cd6e {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-bf76143f-c5d2-81db-b861-7e82608375ee-b698cd6e {
  justify-self: end;
}

#w-node-_9052c7b4-f522-a908-d55e-fef77efa4926-4fbdfccc,
#w-node-_9052c7b4-f522-a908-d55e-fef77efa492d-4fbdfccc {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_9052c7b4-f522-a908-d55e-fef77efa4939-4fbdfccc,
#w-node-_9052c7b4-f522-a908-d55e-fef77efa493f-4fbdfccc,
#w-node-_9052c7b4-f522-a908-d55e-fef77efa4948-4fbdfccc {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_9052c7b4-f522-a908-d55e-fef77efa494c-b698cd6e {
  justify-self: end;
}

#w-node-_9052c7b4-f522-a908-d55e-fef77efa494e-b698cd6e {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_9052c7b4-f522-a908-d55e-fef77efa4952-b698cd6e {
  justify-self: end;
}

#w-node-_30dfb1ee-0c82-d829-fd4e-de30b7e56117-4fbdfccc,
#w-node-_30dfb1ee-0c82-d829-fd4e-de30b7e5611e-4fbdfccc {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_30dfb1ee-0c82-d829-fd4e-de30b7e5612a-4fbdfccc,
#w-node-_30dfb1ee-0c82-d829-fd4e-de30b7e56130-4fbdfccc,
#w-node-_30dfb1ee-0c82-d829-fd4e-de30b7e56139-4fbdfccc {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_30dfb1ee-0c82-d829-fd4e-de30b7e5613d-b698cd6e {
  justify-self: end;
}

#w-node-_30dfb1ee-0c82-d829-fd4e-de30b7e5613f-b698cd6e {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_30dfb1ee-0c82-d829-fd4e-de30b7e56143-b698cd6e {
  justify-self: end;
}

#w-node-_3712285c-4a2c-abd6-d832-ab5b6c8d3c64-4fbdfccc,
#w-node-c856f2b2-f69f-7557-511a-8bbc83cc725d-4fbdfccc {
  grid-area: span 1 / span 1 / span 1 / span 1;
  align-self: stretch;
}

#w-node-_1e62bfcc-c639-3190-1fbc-33880e7d6feb-b698cd6e {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_55b41203-dccc-6d6c-c347-2c53ed9cd693-4fbdfccc,
#w-node-ad3f36ea-e0eb-f27c-2d47-a032db97c061-4fbdfccc {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-b866899f-9396-9bb6-55ef-79b07a812f64-b698cd6e {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_9e805cd0-ef5b-f7fe-60b8-30046208b735-b698cd6e {
  grid-area: span 1 / span 1 / span 1 / span 1;
  align-self: center;
  justify-self: end;
}

#w-node-_1735d2e5-0037-a242-8b0c-ca7d39fd0bac-b698cd6e {
  align-self: center;
  justify-self: end;
}

#w-node-_5dfd40a1-26ca-159f-dd05-0af1f8080ed0-b698cd6e {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_5dfd40a1-26ca-159f-dd05-0af1f8080ed8-b698cd6e {
  grid-area: span 1 / span 1 / span 1 / span 1;
  align-self: center;
  justify-self: end;
}

#w-node-_5dfd40a1-26ca-159f-dd05-0af1f8080eda-b698cd6e {
  align-self: center;
  justify-self: end;
}

#w-node-_6d5cb390-b42a-f538-899c-3d0f293d9059-b698cd6e {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_6d5cb390-b42a-f538-899c-3d0f293d9061-b698cd6e {
  grid-area: span 1 / span 1 / span 1 / span 1;
  align-self: center;
  justify-self: end;
}

#w-node-_6d5cb390-b42a-f538-899c-3d0f293d9063-b698cd6e {
  align-self: center;
  justify-self: end;
}

#w-node-_9b893dad-2654-badd-e30f-685de07ed95a-b698cd6e {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_9b893dad-2654-badd-e30f-685de07ed962-b698cd6e {
  grid-area: span 1 / span 1 / span 1 / span 1;
  align-self: center;
  justify-self: end;
}

#w-node-_9b893dad-2654-badd-e30f-685de07ed964-b698cd6e {
  align-self: center;
  justify-self: end;
}

#w-node-fb3e1784-0554-0f34-8e85-bc09999118d1-4fbdfce3,
#w-node-fb3e1784-0554-0f34-8e85-bc09999118d3-4fbdfce3,
#w-node-fb3e1784-0554-0f34-8e85-bc09999118f7-4fbdfce3,
#w-node-fb3e1784-0554-0f34-8e85-bc09999118f9-4fbdfce3,
#w-node-fb3e1784-0554-0f34-8e85-bc099991191d-4fbdfce3,
#w-node-fb3e1784-0554-0f34-8e85-bc099991191f-4fbdfce3 {
  align-self: stretch;
}

#w-node-d5100e1f-bf9f-2340-8620-ebc6a987cb83-4fbdfce4,
#w-node-d5100e1f-bf9f-2340-8620-ebc6a987cb9b-4fbdfce4,
#w-node-ec82c8f5-2a97-d7e7-4426-8d55dacd204a-4fbdfce5,
#w-node-_17ba4ce7-22ee-2eeb-3d49-1edb1abdab7e-4fbdfce5 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  align-self: start;
}

#w-node-_4f243ca6-044c-60d4-3d15-a5463f565c59-4fbdfce6,
#w-node-_43c1fb2e-af10-e6a2-7d0a-e279a75e0000-4fbdfce6 {
  align-self: center;
}

#w-node-_91243996-f3e7-6212-7e17-a5f0993fd94e-b698cddd {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_16430eb4-b670-3c59-d4e7-975c831679a1-4fbdfd03,
#w-node-aa457b9e-a292-a0dd-3112-2c0e886c8704-4fbdfd03 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: stretch;
}

#w-node-_932d2677-0544-0771-3519-962dbc05f149-b698cddd {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-eeec6a21-7ff1-1c06-4ec8-81f509a7af81-b698cddd {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-d1b55e49-7d13-d87c-452b-0464c1760e5e-b698cddd {
  justify-self: end;
}

#w-node-d1b55e49-7d13-d87c-452b-0464c1760e69-4fbdfd03,
#w-node-d1b55e49-7d13-d87c-452b-0464c1760e6b-4fbdfd03,
#w-node-e675714a-c27e-cae7-f5c2-24c619d905ca-4fbdfd03,
#w-node-_1c469c10-8849-ffc4-9154-e2c00af68308-4fbdfd03 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-d24ab2b1-297e-ba35-cc2a-2ecce182810a-b698cddd {
  align-self: start;
  justify-self: end;
}

#w-node-_100514ca-cd9b-2af3-3ea7-326fa4f6dd80-4fbdfd03,
#w-node-_100514ca-cd9b-2af3-3ea7-326fa4f6dd82-4fbdfd03 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_100514ca-cd9b-2af3-3ea7-326fa4f6dd83-b698cddd {
  align-self: start;
  justify-self: end;
}

#w-node-b896111e-d21a-9b4f-ceda-d3b6442ffb65-4fbdfd03,
#w-node-b896111e-d21a-9b4f-ceda-d3b6442ffb67-4fbdfd03 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-b896111e-d21a-9b4f-ceda-d3b6442ffb68-b698cddd {
  align-self: start;
  justify-self: end;
}

#w-node-a40e56b0-ea5c-5fbb-2434-f224cd37942e-4fbdfd03,
#w-node-a40e56b0-ea5c-5fbb-2434-f224cd379441-4fbdfd03,
#w-node-a40e56b0-ea5c-5fbb-2434-f224cd379447-4fbdfd03,
#w-node-a40e56b0-ea5c-5fbb-2434-f224cd37944d-4fbdfd03 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-a40e56b0-ea5c-5fbb-2434-f224cd379451-b698cddd {
  justify-self: end;
}

#w-node-a40e56b0-ea5c-5fbb-2434-f224cd379453-b698cddd {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-a40e56b0-ea5c-5fbb-2434-f224cd379454-b698cddd {
  justify-self: end;
}

#w-node-_694f3f70-19c5-1b7b-827a-6cea25fe3635-4fbdfd03,
#w-node-_694f3f70-19c5-1b7b-827a-6cea25fe364a-4fbdfd03,
#w-node-_694f3f70-19c5-1b7b-827a-6cea25fe3650-4fbdfd03,
#w-node-_694f3f70-19c5-1b7b-827a-6cea25fe3656-4fbdfd03 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_694f3f70-19c5-1b7b-827a-6cea25fe365a-b698cddd {
  justify-self: end;
}

#w-node-a61542d2-58a0-9095-449b-3ba12e3a4293-b698cddd {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-a61542d2-58a0-9095-449b-3ba12e3a4297-b698cddd {
  justify-self: end;
}

#w-node-_99d10040-0cc7-aaed-b1ba-fecf497b0b3c-4fbdfd03,
#w-node-_99d10040-0cc7-aaed-b1ba-fecf497b0b51-4fbdfd03,
#w-node-_99d10040-0cc7-aaed-b1ba-fecf497b0b57-4fbdfd03,
#w-node-_99d10040-0cc7-aaed-b1ba-fecf497b0b5d-4fbdfd03 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_99d10040-0cc7-aaed-b1ba-fecf497b0b61-b698cddd {
  justify-self: end;
}

#w-node-_157c5205-d585-6a06-b3c2-df4a0b325471-b698cddd {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_157c5205-d585-6a06-b3c2-df4a0b325475-b698cddd {
  justify-self: end;
}

#w-node-_83b5fd92-6cc3-c5af-3863-d92a231ae6cf-4fbdfd03,
#w-node-_83b5fd92-6cc3-c5af-3863-d92a231ae6e4-4fbdfd03,
#w-node-_83b5fd92-6cc3-c5af-3863-d92a231ae6ea-4fbdfd03,
#w-node-_83b5fd92-6cc3-c5af-3863-d92a231ae6f0-4fbdfd03 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_83b5fd92-6cc3-c5af-3863-d92a231ae6f4-b698cddd {
  justify-self: end;
}

#w-node-_46d6ad6a-c7af-f54f-ebfb-f670dbe3f5fa-b698cddd {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_46d6ad6a-c7af-f54f-ebfb-f670dbe3f5fe-b698cddd {
  justify-self: end;
}

#w-node-_6adf1ad2-cae6-fc05-fa13-ff78c421e6c8-4fbdfd03,
#w-node-_6adf1ad2-cae6-fc05-fa13-ff78c421e6dd-4fbdfd03,
#w-node-_6adf1ad2-cae6-fc05-fa13-ff78c421e6e3-4fbdfd03,
#w-node-_6adf1ad2-cae6-fc05-fa13-ff78c421e6e9-4fbdfd03 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_6adf1ad2-cae6-fc05-fa13-ff78c421e6ed-b698cddd {
  justify-self: end;
}

#w-node-_0ad46cfd-4e45-ff2a-0faf-97d0642a7ab0-b698cddd {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_0ad46cfd-4e45-ff2a-0faf-97d0642a7ab4-b698cddd {
  justify-self: end;
}

#w-node-_3b11266e-5f61-326c-d1a0-1176334534cf-4fbdfd03,
#w-node-_3b11266e-5f61-326c-d1a0-1176334534e4-4fbdfd03,
#w-node-_3b11266e-5f61-326c-d1a0-1176334534ea-4fbdfd03,
#w-node-_3b11266e-5f61-326c-d1a0-1176334534f0-4fbdfd03 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_3b11266e-5f61-326c-d1a0-1176334534f4-b698cddd {
  justify-self: end;
}

#w-node-ed65369f-c0bd-5ae8-5153-7e2364f93d42-b698cddd {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-ed65369f-c0bd-5ae8-5153-7e2364f93d46-b698cddd {
  justify-self: end;
}

#w-node-_51895925-f773-ae4f-9461-09b062f32e76-4fbdfd03,
#w-node-_51895925-f773-ae4f-9461-09b062f32e8b-4fbdfd03,
#w-node-_51895925-f773-ae4f-9461-09b062f32e91-4fbdfd03,
#w-node-_51895925-f773-ae4f-9461-09b062f32e97-4fbdfd03 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_51895925-f773-ae4f-9461-09b062f32e9b-b698cddd {
  justify-self: end;
}

#w-node-_02334b72-2943-ab8b-c77d-65a88d3b5131-b698cddd {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_02334b72-2943-ab8b-c77d-65a88d3b5135-b698cddd {
  justify-self: end;
}

#w-node-_29b80bd2-b313-15e3-90ef-f007c805be07-b698cddd {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-eb9e1049-c5df-e0b7-9321-d9b16a291126-b698cde2 {
  align-self: stretch;
}

#w-node-a7073a08-a4e8-7ad0-d8be-ae8643566f90-4fbdfd0d,
#w-node-_20c52c54-03fb-a5d3-db22-99205302e8ca-4fbdfd0d,
#w-node-ebf43ecd-a8f2-c55d-7955-2ec34cbe99ce-4fbdfd0d,
#w-node-_9fec5c66-5a4a-07b2-3bd7-de912cc68885-4fbdfd0d,
#w-node-e7470d1d-8f81-965e-cd0d-3fcb65a63803-4fbdfd0d,
#w-node-_9e7e0ce2-2a69-fd1c-ecc3-2d784e00fb36-4fbdfd0d,
#w-node-_1ab2e17f-dace-a003-d20a-e9b80c7d3fcb-4fbdfd0d,
#w-node-b503e17a-be42-1685-3eac-799e956a3f68-4fbdfd0d,
#w-node-_9988e8de-fd58-5cf3-7412-bbf838f2c32a-4fbdfd0d,
#w-node-_05363ea2-486a-680f-fb4b-2be72284c89c-4fbdfd0d,
#w-node-_8466a1c8-8e73-ff78-e6e0-b3ae74cf6f73-4fbdfd0d,
#w-node-_8466a1c8-8e73-ff78-e6e0-b3ae74cf6f87-4fbdfd0d,
#w-node-_8466a1c8-8e73-ff78-e6e0-b3ae74cf6fa5-4fbdfd0d,
#w-node-_86fe26e4-6f81-66e1-ab74-e9c15ee760b0-4fbdfd0d,
#w-node-_86fe26e4-6f81-66e1-ab74-e9c15ee760ba-4fbdfd0d,
#w-node-_0f9f089f-6c25-b121-23f4-417efa32ab10-4fbdfd0e {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_0f9f089f-6c25-b121-23f4-417efa32ab15-4fbdfd0e,
#w-node-_0f9f089f-6c25-b121-23f4-417efa32ab16-4fbdfd0e {
  justify-self: end;
}

#w-node-_0f9f089f-6c25-b121-23f4-417efa32ab21-4fbdfd0e,
#w-node-_0f9f089f-6c25-b121-23f4-417efa32ab23-4fbdfd0e {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-e3d9ba4e-1374-af80-4dcc-cfe1f4a18184-b698cde8 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  align-self: start;
}

#w-node-_7ecdc42a-ea29-df15-411b-b25ca70535b4-b698cde8 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-f13fd3c6-e4df-83a1-f7ef-34a445789eb5-b698cde8 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  align-self: end;
  justify-self: end;
}

#w-node-d0a15ca3-599e-ac30-aa6d-3a3fb247fdcb-4fbdfd0e,
#w-node-_9894a2fb-5c88-ff27-4403-62dc085808e2-4fbdfd0e,
#w-node-_82b61bb6-41d2-b9ea-2786-5df5fe10474e-4fbdfd0e {
  justify-self: end;
}

#w-node-_208086f4-d013-9d24-6671-17c95bf5465f-b698cde8 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_208086f4-d013-9d24-6671-17c95bf54672-4fbdfd0e,
#w-node-_208086f4-d013-9d24-6671-17c95bf54678-4fbdfd0e,
#w-node-_208086f4-d013-9d24-6671-17c95bf5467e-4fbdfd0e {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_208086f4-d013-9d24-6671-17c95bf54682-b698cde8 {
  justify-self: end;
}

#w-node-_8a9bedef-4ac7-b192-0e23-8dd76c12b37a-b698cde8 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_8a9bedef-4ac7-b192-0e23-8dd76c12b381-4fbdfd0e,
#w-node-_8a9bedef-4ac7-b192-0e23-8dd76c12b388-4fbdfd0e,
#w-node-_8a9bedef-4ac7-b192-0e23-8dd76c12b38f-4fbdfd0e {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_8a9bedef-4ac7-b192-0e23-8dd76c12b394-b698cde8 {
  justify-self: end;
}

#w-node-ec383a66-a098-7d96-93ab-aa0265f93f89-b698cde8 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-ec383a66-a098-7d96-93ab-aa0265f93f90-4fbdfd0e,
#w-node-ec383a66-a098-7d96-93ab-aa0265f93f97-4fbdfd0e,
#w-node-ec383a66-a098-7d96-93ab-aa0265f93f9e-4fbdfd0e {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-ec383a66-a098-7d96-93ab-aa0265f93fa3-b698cde8 {
  justify-self: end;
}

#w-node-_988ea4fd-cec9-6e45-02bc-627de4d2d7c4-b698cde8 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  align-self: start;
}

#w-node-_83ddeac4-0e5c-92aa-c90d-2f739ec1e2e6-4fbdfd0e,
#w-node-_58c7d53d-71c5-c1ef-f503-eb8310400bcd-4fbdfd0e,
#w-node-c3264261-ee09-c2af-f7f6-687a45878fd8-4fbdfd0e,
#w-node-_4ecca81a-0ae5-2d56-455a-1741faf99e17-4fbdfd0e,
#w-node-_0337b234-5ba1-1509-7473-1cca8d29b0d8-4fbdfd0e,
#w-node-cba12da5-978a-4038-820c-6951096da839-4fbdfd22 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_1f903f57-7a6c-b2f2-38ba-e004e972201f-4fbdfd22,
#w-node-_4debbe78-d89c-ac7d-acb5-b37b4d21542f-4fbdfd22 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_57b2ae6a-3613-1ee6-6214-1e67ae32b600-b698cdec {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-c21977b5-d6d4-0e15-8d14-90df7a0c3a0f-4fbdfd24,
#w-node-_57b2ae6a-3613-1ee6-6214-1e67ae32b606-4fbdfd24 {
  justify-self: end;
}

#w-node-c21977b5-d6d4-0e15-8d14-90df7a0c3a1c-b698cdec {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_57b2ae6a-3613-1ee6-6214-1e67ae32b61b-b698cdec {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_57b2ae6a-3613-1ee6-6214-1e67ae32b651-4fbdfd24,
#w-node-_57b2ae6a-3613-1ee6-6214-1e67ae32b664-4fbdfd24,
#w-node-_57b2ae6a-3613-1ee6-6214-1e67ae32b66a-4fbdfd24,
#w-node-_57b2ae6a-3613-1ee6-6214-1e67ae32b670-4fbdfd24 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_57b2ae6a-3613-1ee6-6214-1e67ae32b674-b698cdec {
  justify-self: end;
}

#w-node-_57b2ae6a-3613-1ee6-6214-1e67ae32b67c-b698cdec {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_57b2ae6a-3613-1ee6-6214-1e67ae32b67d-b698cdec {
  justify-self: end;
}

#w-node-_38424513-3fde-6e39-c516-0c46493bfec6-4fbdfd24,
#w-node-_38424513-3fde-6e39-c516-0c46493bfed9-4fbdfd24,
#w-node-_38424513-3fde-6e39-c516-0c46493bfedf-4fbdfd24,
#w-node-_38424513-3fde-6e39-c516-0c46493bfee5-4fbdfd24 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_38424513-3fde-6e39-c516-0c46493bfee9-b698cdec {
  justify-self: end;
}

#w-node-dd4f0576-7ecd-3589-54df-8858977b78fe-b698cdec {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-dd4f0576-7ecd-3589-54df-8858977b7902-b698cdec {
  justify-self: end;
}

#w-node-f093319b-d3f6-4526-c96b-66a8efa5cde3-4fbdfd24,
#w-node-f093319b-d3f6-4526-c96b-66a8efa5cdf6-4fbdfd24,
#w-node-f093319b-d3f6-4526-c96b-66a8efa5cdfc-4fbdfd24,
#w-node-f093319b-d3f6-4526-c96b-66a8efa5ce02-4fbdfd24 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-f093319b-d3f6-4526-c96b-66a8efa5ce06-b698cdec {
  justify-self: end;
}

#w-node-a7bc03cc-eff2-a8cb-f716-99d5f795bc3e-b698cdec {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-a7bc03cc-eff2-a8cb-f716-99d5f795bc42-b698cdec {
  justify-self: end;
}

#w-node-df18a4f7-a5e5-1c49-45f8-2df9b138f78c-4fbdfd24,
#w-node-df18a4f7-a5e5-1c49-45f8-2df9b138f79f-4fbdfd24,
#w-node-df18a4f7-a5e5-1c49-45f8-2df9b138f7a5-4fbdfd24,
#w-node-df18a4f7-a5e5-1c49-45f8-2df9b138f7ab-4fbdfd24 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-df18a4f7-a5e5-1c49-45f8-2df9b138f7af-b698cdec {
  justify-self: end;
}

#w-node-a67f04be-df02-1478-7710-7bcf0cbbd226-b698cdec {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-a67f04be-df02-1478-7710-7bcf0cbbd22a-b698cdec {
  justify-self: end;
}

#w-node-b94f1e31-e705-fa5c-38bc-dfc63f28c723-4fbdfd24,
#w-node-b94f1e31-e705-fa5c-38bc-dfc63f28c736-4fbdfd24,
#w-node-b94f1e31-e705-fa5c-38bc-dfc63f28c73c-4fbdfd24,
#w-node-b94f1e31-e705-fa5c-38bc-dfc63f28c742-4fbdfd24 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-b94f1e31-e705-fa5c-38bc-dfc63f28c746-b698cdec {
  justify-self: end;
}

#w-node-_33d63660-796d-91f2-68f8-00b1e824b170-b698cdec {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_33d63660-796d-91f2-68f8-00b1e824b174-b698cdec {
  justify-self: end;
}

#w-node-_9b546f70-0d51-8f05-590a-418495b776fe-4fbdfd24,
#w-node-_9b546f70-0d51-8f05-590a-418495b77711-4fbdfd24,
#w-node-_9b546f70-0d51-8f05-590a-418495b77717-4fbdfd24,
#w-node-_9b546f70-0d51-8f05-590a-418495b7771d-4fbdfd24 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_9b546f70-0d51-8f05-590a-418495b77721-b698cdec {
  justify-self: end;
}

#w-node-dfbd6785-b7cb-dc1a-1daa-42d34cbe0b64-b698cdec {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-dfbd6785-b7cb-dc1a-1daa-42d34cbe0b68-b698cdec {
  justify-self: end;
}

#w-node-_1120177b-9949-7226-69a6-635039853011-4fbdfd24,
#w-node-_1120177b-9949-7226-69a6-635039853024-4fbdfd24,
#w-node-_1120177b-9949-7226-69a6-63503985302a-4fbdfd24,
#w-node-_1120177b-9949-7226-69a6-635039853030-4fbdfd24 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_1120177b-9949-7226-69a6-635039853034-b698cdec {
  justify-self: end;
}

#w-node-_404c8fd2-39a4-dbf6-20a6-8180dd18e084-b698cdec {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_404c8fd2-39a4-dbf6-20a6-8180dd18e088-b698cdec {
  justify-self: end;
}

#w-node-acdc6c13-80f1-b621-3152-f885c1be0815-4fbdfd24,
#w-node-acdc6c13-80f1-b621-3152-f885c1be0828-4fbdfd24,
#w-node-acdc6c13-80f1-b621-3152-f885c1be082e-4fbdfd24,
#w-node-acdc6c13-80f1-b621-3152-f885c1be0834-4fbdfd24 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-acdc6c13-80f1-b621-3152-f885c1be0838-b698cdec {
  justify-self: end;
}

#w-node-_238484cb-d624-8114-8b91-3529fb0318c1-b698cdec {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_238484cb-d624-8114-8b91-3529fb0318c5-b698cdec {
  justify-self: end;
}

#w-node-_57b2ae6a-3613-1ee6-6214-1e67ae32b85e-b698cdec {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-cf81034b-8229-f5e8-36ec-66105f00a256-4fbdfd25,
#w-node-cf81034b-8229-f5e8-36ec-66105f00a258-4fbdfd25 {
  justify-self: start;
}

#w-node-cf81034b-8229-f5e8-36ec-66105f00a25a-b698cded {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-cf81034b-8229-f5e8-36ec-66105f00a25e-4fbdfd25,
#w-node-cf81034b-8229-f5e8-36ec-66105f00a260-4fbdfd25 {
  justify-self: end;
}

#w-node-cf81034b-8229-f5e8-36ec-66105f00a262-b698cded {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-cf81034b-8229-f5e8-36ec-66105f00a268-b698cded {
  justify-self: start;
}

#w-node-cf81034b-8229-f5e8-36ec-66105f00a26a-4fbdfd25,
#w-node-cf81034b-8229-f5e8-36ec-66105f00a26e-4fbdfd25 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-cf81034b-8229-f5e8-36ec-66105f00a270-b698cded {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-cf81034b-8229-f5e8-36ec-66105f00a276-4fbdfd25,
#w-node-cf81034b-8229-f5e8-36ec-66105f00a27a-4fbdfd25 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-cf81034b-8229-f5e8-36ec-66105f00a27e-b698cded {
  justify-self: end;
}

#w-node-cf81034b-8229-f5e8-36ec-66105f00a281-b698cded {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_9062fb9d-2c0c-a92e-4b4b-3520e2c7cf85-4fbdfd25,
#w-node-_9062fb9d-2c0c-a92e-4b4b-3520e2c7cf87-4fbdfd25 {
  justify-self: start;
}

#w-node-_9062fb9d-2c0c-a92e-4b4b-3520e2c7cf89-b698cded {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_9062fb9d-2c0c-a92e-4b4b-3520e2c7cf8d-4fbdfd25,
#w-node-_9062fb9d-2c0c-a92e-4b4b-3520e2c7cf8f-4fbdfd25 {
  justify-self: end;
}

#w-node-_9062fb9d-2c0c-a92e-4b4b-3520e2c7cf91-b698cded {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_9062fb9d-2c0c-a92e-4b4b-3520e2c7cf97-b698cded {
  justify-self: start;
}

#w-node-_9062fb9d-2c0c-a92e-4b4b-3520e2c7cf99-4fbdfd25,
#w-node-_9062fb9d-2c0c-a92e-4b4b-3520e2c7cf9d-4fbdfd25 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_9062fb9d-2c0c-a92e-4b4b-3520e2c7cf9f-b698cded {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_9062fb9d-2c0c-a92e-4b4b-3520e2c7cfa5-4fbdfd25,
#w-node-_9062fb9d-2c0c-a92e-4b4b-3520e2c7cfa9-4fbdfd25 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_9062fb9d-2c0c-a92e-4b4b-3520e2c7cfad-b698cded {
  justify-self: end;
}

#w-node-_9062fb9d-2c0c-a92e-4b4b-3520e2c7cfb0-b698cded {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_4656dbcb-364a-dff3-6208-8b5e42e66af9-4fbdfd25,
#w-node-_4656dbcb-364a-dff3-6208-8b5e42e66afb-4fbdfd25 {
  justify-self: start;
}

#w-node-_4656dbcb-364a-dff3-6208-8b5e42e66afd-b698cded {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_4656dbcb-364a-dff3-6208-8b5e42e66b01-4fbdfd25,
#w-node-_4656dbcb-364a-dff3-6208-8b5e42e66b03-4fbdfd25 {
  justify-self: end;
}

#w-node-_4656dbcb-364a-dff3-6208-8b5e42e66b05-b698cded {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_4656dbcb-364a-dff3-6208-8b5e42e66b0b-b698cded {
  justify-self: start;
}

#w-node-_4656dbcb-364a-dff3-6208-8b5e42e66b0d-4fbdfd25,
#w-node-_4656dbcb-364a-dff3-6208-8b5e42e66b11-4fbdfd25 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_4656dbcb-364a-dff3-6208-8b5e42e66b13-b698cded {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_4656dbcb-364a-dff3-6208-8b5e42e66b19-4fbdfd25,
#w-node-_4656dbcb-364a-dff3-6208-8b5e42e66b1d-4fbdfd25 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_4656dbcb-364a-dff3-6208-8b5e42e66b21-b698cded {
  justify-self: end;
}

#w-node-_4656dbcb-364a-dff3-6208-8b5e42e66b24-b698cded {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_8e5c1b65-1234-ed3e-cda4-35db575be3cc-4fbdfd25,
#w-node-_8e5c1b65-1234-ed3e-cda4-35db575be3ce-4fbdfd25 {
  justify-self: start;
}

#w-node-_8e5c1b65-1234-ed3e-cda4-35db575be3d0-b698cded {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_8e5c1b65-1234-ed3e-cda4-35db575be3d4-4fbdfd25,
#w-node-_8e5c1b65-1234-ed3e-cda4-35db575be3d6-4fbdfd25 {
  justify-self: end;
}

#w-node-_8e5c1b65-1234-ed3e-cda4-35db575be3d8-b698cded {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_8e5c1b65-1234-ed3e-cda4-35db575be3de-b698cded {
  justify-self: start;
}

#w-node-_8e5c1b65-1234-ed3e-cda4-35db575be3e0-4fbdfd25,
#w-node-_8e5c1b65-1234-ed3e-cda4-35db575be3e4-4fbdfd25 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_8e5c1b65-1234-ed3e-cda4-35db575be3e6-b698cded {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_8e5c1b65-1234-ed3e-cda4-35db575be3ec-4fbdfd25,
#w-node-_8e5c1b65-1234-ed3e-cda4-35db575be3f0-4fbdfd25 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_8e5c1b65-1234-ed3e-cda4-35db575be3f4-b698cded {
  justify-self: end;
}

#w-node-_8e5c1b65-1234-ed3e-cda4-35db575be3f7-b698cded {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-b2230516-9f02-9017-73fe-f90093254bb3-4fbdfd25,
#w-node-b2230516-9f02-9017-73fe-f90093254bb5-4fbdfd25 {
  justify-self: start;
}

#w-node-b2230516-9f02-9017-73fe-f90093254bb7-b698cded {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-b2230516-9f02-9017-73fe-f90093254bbb-4fbdfd25,
#w-node-b2230516-9f02-9017-73fe-f90093254bbd-4fbdfd25 {
  justify-self: end;
}

#w-node-b2230516-9f02-9017-73fe-f90093254bbf-b698cded {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-b2230516-9f02-9017-73fe-f90093254bc5-b698cded {
  justify-self: start;
}

#w-node-b2230516-9f02-9017-73fe-f90093254bc7-4fbdfd25,
#w-node-b2230516-9f02-9017-73fe-f90093254bcb-4fbdfd25 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-b2230516-9f02-9017-73fe-f90093254bcd-b698cded {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-b2230516-9f02-9017-73fe-f90093254bd3-4fbdfd25,
#w-node-b2230516-9f02-9017-73fe-f90093254bd7-4fbdfd25 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-b2230516-9f02-9017-73fe-f90093254bdb-b698cded {
  justify-self: end;
}

#w-node-b2230516-9f02-9017-73fe-f90093254bde-b698cded {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_1e334858-db48-fc9a-0dc5-29b43e10c2ab-4fbdfd26,
#w-node-_3af44571-cabf-a812-e97a-5b3dbd2e5a4e-4fbdfd26,
#w-node-bd1cb75d-298f-0ba6-65e2-f62fccac9c88-4fbdfd26,
#w-node-_66703de3-b875-8f10-7509-191eadf91d10-4fbdfd2a,
#w-node-_66703de3-b875-8f10-7509-191eadf91d18-4fbdfd2a,
#w-node-_66703de3-b875-8f10-7509-191eadf91d20-4fbdfd2a,
#w-node-_1a05ee08-4197-030e-d75f-61f9c119f22f-4fbdfd2a,
#w-node-c0363dd2-b659-f050-91a2-80ec21db2a00-4fbdfd2d {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-c218ea9d-0cb5-9252-b6b0-bf9dd784b9b3-b698ce33 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-d20bc9d7-0404-ea30-9e94-58b8fea2444c-b698ce33 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  align-self: stretch;
}

#w-node-eed17123-9e8d-e866-3842-b19f30346ce2-4fbdfd2d,
#w-node-_25d565e0-89f4-8c9e-f86d-943686bdfd3f-4fbdfd2d {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_999e439e-4c5b-49b5-2146-31ddd2fc7980-b698ce33 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_6006025a-3715-41dc-5f9d-62fdbe3b929b-4fbdfd2d,
#w-node-f7d46745-1988-bfe2-2913-8e93c4ce1909-4fbdfd2d,
#w-node-fb522813-86c1-0691-5c93-2e1c29d24aa8-4fbdfd2d {
  justify-self: end;
}

#w-node-_23ca9d70-9d09-ec5f-f99a-d77d31a71f78-4fbdfd2d,
#w-node-_24ce6461-51f0-7f58-da33-0a8907b71f8a-4fbdfd2d,
#w-node-c18262ad-5a54-3c74-35ac-bb6703cb2173-4fbdfd2d {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_763591cc-a75a-e92c-f040-4c51ded8db02-b698ce33 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_2bedb7a5-d7fb-fad9-4f34-78846b698ae6-b698ce33 {
  justify-self: end;
}

#w-node-d8b47277-6b87-2d11-0844-086c50294eb3-b698ce33 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-ab23bf48-2948-43c0-1f46-14c396e5aa6c-b698ce33 {
  justify-self: end;
}

#w-node-_7bf84708-9216-e02d-5ccb-f8015425bc0d-b698ce33 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_58412eb8-f67b-0f5f-ffa4-e96f2819d293-b698ce33 {
  justify-self: end;
}

#w-node-_8a25921a-78a0-e7bb-32ad-2bdf225b5c9e-b698ce33 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_89172ff6-3f41-d8cc-5d29-6d9d1b8ace94-b698ce33 {
  justify-self: end;
}

#w-node-_805cc7d9-80f6-7021-7f33-016eb8cd1ca0-4fbdfd2d,
#w-node-_805cc7d9-80f6-7021-7f33-016eb8cd1cb3-4fbdfd2d,
#w-node-_805cc7d9-80f6-7021-7f33-016eb8cd1cbc-4fbdfd2d {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_805cc7d9-80f6-7021-7f33-016eb8cd1cc2-b698ce33 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_805cc7d9-80f6-7021-7f33-016eb8cd1cc6-b698ce33 {
  justify-self: end;
}

#w-node-_805cc7d9-80f6-7021-7f33-016eb8cd1cc8-b698ce33 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_805cc7d9-80f6-7021-7f33-016eb8cd1ccc-b698ce33 {
  justify-self: end;
}

#w-node-_805cc7d9-80f6-7021-7f33-016eb8cd1cce-b698ce33 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_805cc7d9-80f6-7021-7f33-016eb8cd1cd2-b698ce33 {
  justify-self: end;
}

#w-node-b2842d5f-ce77-a68b-0ecb-0c268bdbd172-b698ce33 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-b2842d5f-ce77-a68b-0ecb-0c268bdbd176-b698ce33 {
  justify-self: end;
}

#w-node-_69faeb56-606e-5ee3-cea2-ca038027c786-4fbdfd2d,
#w-node-_69faeb56-606e-5ee3-cea2-ca038027c799-4fbdfd2d,
#w-node-_69faeb56-606e-5ee3-cea2-ca038027c7a2-4fbdfd2d {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_69faeb56-606e-5ee3-cea2-ca038027c7a8-b698ce33 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_69faeb56-606e-5ee3-cea2-ca038027c7ac-b698ce33 {
  justify-self: end;
}

#w-node-_69faeb56-606e-5ee3-cea2-ca038027c7ae-b698ce33 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_69faeb56-606e-5ee3-cea2-ca038027c7b2-b698ce33 {
  justify-self: end;
}

#w-node-_69faeb56-606e-5ee3-cea2-ca038027c7b4-b698ce33 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_69faeb56-606e-5ee3-cea2-ca038027c7b8-b698ce33 {
  justify-self: end;
}

#w-node-_4ff49463-01fd-50ef-8218-7dffbb355183-b698ce33 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_4ff49463-01fd-50ef-8218-7dffbb355187-b698ce33 {
  justify-self: end;
}

#w-node-_065b5ffa-ee0a-bcc6-1b8b-263c7c31a876-4fbdfd2d,
#w-node-_065b5ffa-ee0a-bcc6-1b8b-263c7c31a889-4fbdfd2d,
#w-node-_065b5ffa-ee0a-bcc6-1b8b-263c7c31a892-4fbdfd2d {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_065b5ffa-ee0a-bcc6-1b8b-263c7c31a898-b698ce33 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_065b5ffa-ee0a-bcc6-1b8b-263c7c31a89c-b698ce33 {
  justify-self: end;
}

#w-node-_065b5ffa-ee0a-bcc6-1b8b-263c7c31a89e-b698ce33 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_065b5ffa-ee0a-bcc6-1b8b-263c7c31a8a2-b698ce33 {
  justify-self: end;
}

#w-node-_065b5ffa-ee0a-bcc6-1b8b-263c7c31a8a4-b698ce33 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_065b5ffa-ee0a-bcc6-1b8b-263c7c31a8a8-b698ce33 {
  justify-self: end;
}

#w-node-bdc3929b-5d8e-496b-2924-52a5c0ad9982-b698ce33 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-bdc3929b-5d8e-496b-2924-52a5c0ad9986-b698ce33 {
  justify-self: end;
}

#w-node-_43178c69-0e31-53ed-61e7-c3c7949d5d4b-b698ce33 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_95feeb96-0d21-6cce-dac3-e44c802142c9-b698ce33 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  align-self: stretch;
}

#w-node-_95feeb96-0d21-6cce-dac3-e44c802142cd-b698ce33 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-fb60185b-ce41-07c7-2e76-bf915ab939ee-4fbdfd2f,
#w-node-_8ec02b06-ac5a-5c76-3f8d-1a22534b140e-4fbdfd2f,
#w-node-_1dad73fe-2790-f09d-3b36-76c35e12fa97-4fbdfd2f,
#w-node-_2341d8e7-69c1-7a2b-a60b-a53d81b369a6-4fbdfd2f,
#w-node-c35eb0eb-9254-5b51-1ebc-9a9900adf64a-4fbdfd2f,
#w-node-_788ad052-fc35-aee4-25d1-c7789ca0545b-4fbdfd2f,
#w-node-d824fe58-9d11-82a3-5bd0-f6415558c652-4fbdfd30 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_1300441d-e335-9bc1-14e1-f279e41757a3-4fbdfd30,
#w-node-db472e46-da12-253f-4134-249038f964e1-4fbdfd30 {
  justify-self: end;
}

#w-node-_1300441d-e335-9bc1-14e1-f279e41757ad-4fbdfd30,
#w-node-_1300441d-e335-9bc1-14e1-f279e41757af-4fbdfd30,
#w-node-_15bcd904-cca9-3107-d162-2cf33da79d40-4fbdfd30,
#w-node-_189a64fb-05bb-b2aa-4063-67cbe8bd4d4f-4fbdfd30,
#w-node-_80250c7f-9d0b-c675-8fdf-c4c1d780000b-4fbdfd30,
#w-node-_98d9c2d1-c1b4-226d-dbcc-f9bf7d5abdc9-4fbdfd30 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_9e0982af-7013-efb2-9f4a-6038634a187a-b698ce36 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-ee74cfef-a4b6-95e5-1b61-2e60cce252f7-4fbdfd30,
#w-node-_42657933-fa9c-6e29-0562-e217a4a98906-4fbdfd30 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_343314ff-e1a8-6c04-2c43-a03ddc6426c6-b698ce36 {
  align-self: end;
  justify-self: end;
}

#w-node-_36308f34-03f8-8bf4-73a9-3d940f79128f-4fbdfd30,
#w-node-_36308f34-03f8-8bf4-73a9-3d940f791291-4fbdfd30 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_36308f34-03f8-8bf4-73a9-3d940f791296-b698ce36 {
  align-self: end;
  justify-self: end;
}

#w-node-b0e00774-229d-3cc3-1106-346a087c649b-4fbdfd30,
#w-node-b0e00774-229d-3cc3-1106-346a087c649d-4fbdfd30 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-b0e00774-229d-3cc3-1106-346a087c64a2-b698ce36 {
  align-self: end;
  justify-self: end;
}

#w-node-_3254a631-302e-b14f-4a98-005e8a612242-b698ce36 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  align-self: stretch;
}

#w-node-_8af7a147-f590-4f49-47d6-21e56df9c287-b698ce36 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_1ddcb99f-332b-3b7b-cf16-ec4fc0b0f67f-b698ce36 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  align-self: stretch;
}

#w-node-_1ddcb99f-332b-3b7b-cf16-ec4fc0b0f683-b698ce36 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_61914fa1f3028ad34c1c480b000000000014-b698ce38 {
  align-self: stretch;
}

#w-node-_661f4815-cba2-f67e-b086-3fe5130cd881-4fbdfd37,
#w-node-_5ac118d7-087a-ea35-279b-3661ad6e2681-4fbdfd37,
#w-node-e541ba15-a0fa-24ea-6c9c-84d44a5fd1c3-4fbdfd37,
#w-node-_279cfba9-b646-2d40-ca1b-bbc2a5221cab-4fbdfd37,
#w-node-_101651b9-fecd-1638-3b2c-e3daaba2b669-4fbdfd37,
#w-node-a8a84206-2e08-c2f2-d49a-f15eaf311e7f-4fbdfd37,
#w-node-_4fd5a778-8e25-a751-8696-ada4aa649daa-4fbdfd37,
#w-node-_4fd5a778-8e25-a751-8696-ada4aa649dae-4fbdfd37,
#w-node-_4fd5a778-8e25-a751-8696-ada4aa649db2-4fbdfd37,
#w-node-_4fd5a778-8e25-a751-8696-ada4aa649db6-4fbdfd37,
#w-node-_4fd5a778-8e25-a751-8696-ada4aa649dba-4fbdfd37,
#w-node-_4fd5a778-8e25-a751-8696-ada4aa649dbe-4fbdfd37 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-bf95a1b5-f761-a012-1249-ecb066431f3d-4fbdfd37,
#w-node-b7c3c57d-3ae1-2bea-a43c-06e6672bb293-4fbdfd37 {
  align-self: start;
}

#w-node-da9dd25f-79f9-ecda-8d19-258a891eb39c-4fbdfd37,
#w-node-_71cf2731-429f-3dff-28e1-36ab93ba945a-4fbdfd37 {
  grid-area: span 1 / span 2 / span 1 / span 2;
}

#w-node-_861cb716-7849-94b1-b590-4f0a7eda974f-4fbdfd37,
#w-node-_861cb716-7849-94b1-b590-4f0a7eda9751-4fbdfd37,
#w-node-_2889c33b-4707-7ba1-64dc-969bc05c9a1c-4fbdfd37,
#w-node-_2889c33b-4707-7ba1-64dc-969bc05c9a1e-4fbdfd37,
#w-node-_5bfa5d5e-9e06-8590-cdab-d2641b214f8d-4fbdfd37,
#w-node-_9739db67-7d92-16c7-4a41-cf18a31386fa-4fbdfd37,
#w-node-_60f7dcc7-c39a-fee0-221e-ff9ee29bd456-4fbdfd37,
#w-node-_9cde44af-74cb-7407-9cb1-7b719fd64cc7-4fbdfd37,
#w-node-_6a91bfcb-32e0-30cf-38d1-6d79d2bbb15c-4fbdfd37,
#w-node-_4314e904-76a1-d85a-57e5-d4205aeba71c-4fbdfd37,
#w-node-_396e61f4-c3ed-4611-253b-e3785c42ffc1-4fbdfd37,
#w-node-_0877f30f-24c9-46a8-a6d3-94415903a8b4-4fbdfd37,
#w-node-cd6fb518-e376-b60a-fe87-a7a346c58c94-4fbdfd37,
#w-node-_43c37172-bebb-15ee-d1fb-3851c2650841-4fbdfd37,
#w-node-_198ac7c4-cc45-0100-183a-ecf65e2b97cc-4fbdfd37,
#w-node-_25881dc4-416b-5a8b-3a45-22575002aaae-4fbdfd37,
#w-node-_0d207a5f-b700-a617-9618-5d4e76975634-4fbdfd37,
#w-node-_054a18f4-b946-a64e-773c-0c9dfef2f56b-4fbdfd37,
#w-node-_01b19b5a-3ded-6e83-9c42-32c294e6c4ad-4fbdfd37,
#w-node-_5a071418-f4c0-d51d-cff9-ef5a2a64ecc5-4fbdfd37,
#w-node-_6d219918-61a1-9210-526f-f5e92686ebdf-4fbdfd37 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_98d1ba24-9208-1e07-c8d0-1f65793348e1-b698ce3e {
  grid-area: span 1 / span 1 / span 1 / span 1;
  align-self: start;
}

#w-node-a34e7ba7-3e02-3a04-23f9-e59bc3a53c7d-b698ce3e {
  grid-area: span 1 / span 2 / span 1 / span 2;
}

#w-node-a34e7ba7-3e02-3a04-23f9-e59bc3a53c81-b698ce3e {
  justify-self: start;
}

#w-node-_97da9f87-cf32-e1ea-b2b7-99645d1888c9-b698ce3e {
  grid-area: span 1 / span 1 / span 1 / span 1;
  align-self: start;
}

#w-node-_3c881e18-f3f6-4af4-a3f7-7f583ffaa431-4fbdfd38,
#w-node-_43ad3a69-4f38-f7af-2d80-9a6d2d5ef47f-4fbdfd38,
#w-node-_953dbbf6-dadc-e281-2abf-2623a2181f6c-4fbdfd38,
#w-node-_9a36722d-8aa5-34a7-299c-a8e21ca3d0e7-4fbdfd39 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_9a36722d-8aa5-34a7-299c-a8e21ca3d0ec-4fbdfd39,
#w-node-_9a36722d-8aa5-34a7-299c-a8e21ca3d0ed-4fbdfd39 {
  justify-self: end;
}

#w-node-_9a36722d-8aa5-34a7-299c-a8e21ca3d0f8-4fbdfd39,
#w-node-_9a36722d-8aa5-34a7-299c-a8e21ca3d0fa-4fbdfd39 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_14b86d06-d1f9-96aa-7488-aa61ceda5d65-b698ce3f {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_281f1de6-6d74-81d3-48d1-dc46a9ec68b0-4fbdfd39,
#w-node-_281f1de6-6d74-81d3-48d1-dc46a9ec68b5-4fbdfd39 {
  justify-self: end;
}

#w-node-_281f1de6-6d74-81d3-48d1-dc46a9ec68bb-4fbdfd39,
#w-node-_281f1de6-6d74-81d3-48d1-dc46a9ec68d0-4fbdfd39,
#w-node-_281f1de6-6d74-81d3-48d1-dc46a9ec68d6-4fbdfd39 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_281f1de6-6d74-81d3-48d1-dc46a9ec68dc-b698ce3f {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_281f1de6-6d74-81d3-48d1-dc46a9ec68e0-b698ce3f {
  justify-self: end;
}

#w-node-_281f1de6-6d74-81d3-48d1-dc46a9ec68e2-b698ce3f {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_281f1de6-6d74-81d3-48d1-dc46a9ec68e6-b698ce3f {
  justify-self: end;
}

#w-node-_281f1de6-6d74-81d3-48d1-dc46a9ec68e8-b698ce3f {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_281f1de6-6d74-81d3-48d1-dc46a9ec68e9-b698ce3f {
  justify-self: end;
}

#w-node-c42d42f3-750f-ea7b-b174-0ed32a7c26d4-4fbdfd39,
#w-node-c42d42f3-750f-ea7b-b174-0ed32a7c26e7-4fbdfd39,
#w-node-c42d42f3-750f-ea7b-b174-0ed32a7c26ed-4fbdfd39 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-c42d42f3-750f-ea7b-b174-0ed32a7c26f3-b698ce3f {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-c42d42f3-750f-ea7b-b174-0ed32a7c26f7-b698ce3f {
  justify-self: end;
}

#w-node-c42d42f3-750f-ea7b-b174-0ed32a7c26f9-b698ce3f {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-c42d42f3-750f-ea7b-b174-0ed32a7c26fd-b698ce3f {
  justify-self: end;
}

#w-node-cf361d89-7532-afb8-10f8-0ef5e18cf977-b698ce3f {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-cf361d89-7532-afb8-10f8-0ef5e18cf97b-b698ce3f {
  justify-self: end;
}

#w-node-_6b645a76-dff8-7f21-df2c-1791393c9bbc-4fbdfd39,
#w-node-_6b645a76-dff8-7f21-df2c-1791393c9bcf-4fbdfd39,
#w-node-_6b645a76-dff8-7f21-df2c-1791393c9bd5-4fbdfd39 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_6b645a76-dff8-7f21-df2c-1791393c9bdb-b698ce3f {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_6b645a76-dff8-7f21-df2c-1791393c9bdf-b698ce3f {
  justify-self: end;
}

#w-node-_6b645a76-dff8-7f21-df2c-1791393c9be1-b698ce3f {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_6b645a76-dff8-7f21-df2c-1791393c9be5-b698ce3f {
  justify-self: end;
}

#w-node-c9b26df2-3e69-486f-5fa4-ae424f464912-b698ce3f {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-c9b26df2-3e69-486f-5fa4-ae424f464916-b698ce3f {
  justify-self: end;
}

#w-node-_2bdfb484-989f-d1f5-1e24-8ad31a84e2fe-4fbdfd39,
#w-node-_2bdfb484-989f-d1f5-1e24-8ad31a84e311-4fbdfd39,
#w-node-_2bdfb484-989f-d1f5-1e24-8ad31a84e317-4fbdfd39 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_2bdfb484-989f-d1f5-1e24-8ad31a84e31d-b698ce3f {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_2bdfb484-989f-d1f5-1e24-8ad31a84e321-b698ce3f {
  justify-self: end;
}

#w-node-_2bdfb484-989f-d1f5-1e24-8ad31a84e323-b698ce3f {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_2bdfb484-989f-d1f5-1e24-8ad31a84e327-b698ce3f {
  justify-self: end;
}

#w-node-f4d0157d-7da4-af30-d94e-043dd843671d-b698ce3f {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-f4d0157d-7da4-af30-d94e-043dd8436721-b698ce3f {
  justify-self: end;
}

#w-node-_514d680b-45fe-6e3c-9323-acdc6145f4e6-4fbdfd39,
#w-node-_514d680b-45fe-6e3c-9323-acdc6145f4f9-4fbdfd39,
#w-node-_514d680b-45fe-6e3c-9323-acdc6145f4ff-4fbdfd39 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_514d680b-45fe-6e3c-9323-acdc6145f505-b698ce3f {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_514d680b-45fe-6e3c-9323-acdc6145f509-b698ce3f {
  justify-self: end;
}

#w-node-_514d680b-45fe-6e3c-9323-acdc6145f50b-b698ce3f {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_514d680b-45fe-6e3c-9323-acdc6145f50f-b698ce3f {
  justify-self: end;
}

#w-node-_6bd7f1ec-e800-f266-f4f9-65c8ddee86a6-b698ce3f {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_6bd7f1ec-e800-f266-f4f9-65c8ddee86aa-b698ce3f {
  justify-self: end;
}

#w-node-_921ff977-8c59-3c5a-8292-e78ebe851f18-4fbdfd39,
#w-node-_921ff977-8c59-3c5a-8292-e78ebe851f2b-4fbdfd39,
#w-node-_921ff977-8c59-3c5a-8292-e78ebe851f31-4fbdfd39 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_921ff977-8c59-3c5a-8292-e78ebe851f37-b698ce3f {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_921ff977-8c59-3c5a-8292-e78ebe851f3b-b698ce3f {
  justify-self: end;
}

#w-node-_921ff977-8c59-3c5a-8292-e78ebe851f3d-b698ce3f {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_921ff977-8c59-3c5a-8292-e78ebe851f41-b698ce3f {
  justify-self: end;
}

#w-node-_37cce26a-4d4f-4d08-ccbd-b616f31b312c-b698ce3f {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_37cce26a-4d4f-4d08-ccbd-b616f31b3130-b698ce3f {
  justify-self: end;
}

#w-node-e1d8c388-ff78-561c-b426-3acf5298ffa8-4fbdfd39,
#w-node-e1d8c388-ff78-561c-b426-3acf5298ffbb-4fbdfd39,
#w-node-e1d8c388-ff78-561c-b426-3acf5298ffc1-4fbdfd39 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-e1d8c388-ff78-561c-b426-3acf5298ffc7-b698ce3f {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-e1d8c388-ff78-561c-b426-3acf5298ffcb-b698ce3f {
  justify-self: end;
}

#w-node-e1d8c388-ff78-561c-b426-3acf5298ffcd-b698ce3f {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-e1d8c388-ff78-561c-b426-3acf5298ffd1-b698ce3f {
  justify-self: end;
}

#w-node-_494dda40-f466-307a-9a61-ddefaed25c16-b698ce3f {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_494dda40-f466-307a-9a61-ddefaed25c1a-b698ce3f {
  justify-self: end;
}

#w-node-b4f989fc-6667-c324-2d42-dbbfa7b8d636-4fbdfd39,
#w-node-b4f989fc-6667-c324-2d42-dbbfa7b8d649-4fbdfd39,
#w-node-b4f989fc-6667-c324-2d42-dbbfa7b8d64f-4fbdfd39 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-b4f989fc-6667-c324-2d42-dbbfa7b8d655-b698ce3f {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-b4f989fc-6667-c324-2d42-dbbfa7b8d659-b698ce3f {
  justify-self: end;
}

#w-node-b4f989fc-6667-c324-2d42-dbbfa7b8d65b-b698ce3f {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-b4f989fc-6667-c324-2d42-dbbfa7b8d65f-b698ce3f {
  justify-self: end;
}

#w-node-b066dee4-592b-a207-b815-f3a4e642bb6c-b698ce3f {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-b066dee4-592b-a207-b815-f3a4e642bb70-b698ce3f {
  justify-self: end;
}

#w-node-e0baab68-088e-06fc-4951-7d92fe5594c8-4fbdfd39,
#w-node-e0baab68-088e-06fc-4951-7d92fe5594db-4fbdfd39,
#w-node-e0baab68-088e-06fc-4951-7d92fe5594e1-4fbdfd39 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-e0baab68-088e-06fc-4951-7d92fe5594e7-b698ce3f {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-e0baab68-088e-06fc-4951-7d92fe5594eb-b698ce3f {
  justify-self: end;
}

#w-node-e0baab68-088e-06fc-4951-7d92fe5594ed-b698ce3f {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-e0baab68-088e-06fc-4951-7d92fe5594f1-b698ce3f {
  justify-self: end;
}

#w-node-_1ed0d109-94f7-a91f-7766-e52cfaeb18c3-b698ce3f {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_1ed0d109-94f7-a91f-7766-e52cfaeb18c7-b698ce3f {
  justify-self: end;
}

#w-node-_2418ec92-1453-c3ba-4da2-2702b99a8154-4fbdfd39,
#w-node-_2418ec92-1453-c3ba-4da2-2702b99a8167-4fbdfd39,
#w-node-_2418ec92-1453-c3ba-4da2-2702b99a816d-4fbdfd39 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_2418ec92-1453-c3ba-4da2-2702b99a8173-b698ce3f {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_2418ec92-1453-c3ba-4da2-2702b99a8177-b698ce3f {
  justify-self: end;
}

#w-node-_2418ec92-1453-c3ba-4da2-2702b99a8179-b698ce3f {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_2418ec92-1453-c3ba-4da2-2702b99a817d-b698ce3f {
  justify-self: end;
}

#w-node-_9cc5d557-d23e-1863-5d76-f83ad05938cf-b698ce3f {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_9cc5d557-d23e-1863-5d76-f83ad05938d3-b698ce3f {
  justify-self: end;
}

#w-node-_221bdebd-e5b8-afcf-ad5e-51ba74255f52-b698ce3f {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_502c6fce-7626-e345-9fb7-d6425074349d-b698ce40 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_502c6fce-7626-e345-9fb7-d642507434a2-4fbdfd3a,
#w-node-_502c6fce-7626-e345-9fb7-d642507434a3-4fbdfd3a {
  justify-self: end;
}

#w-node-_502c6fce-7626-e345-9fb7-d642507434ae-4fbdfd3a,
#w-node-_502c6fce-7626-e345-9fb7-d642507434b0-4fbdfd3a,
#w-node-_3be7d660-1663-08ea-1f00-9e8e3aee8626-4fbdfd3a,
#w-node-_03a27dec-54f3-16d5-80ff-dd3724cb8350-4fbdfd3a,
#w-node-d76ccfec-255a-7fb0-60bc-d5536036a332-4fbdfd3a {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_4b82f21c-1eac-01f5-4546-90af3c718bf8-b698ce40 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  align-self: stretch;
}

#w-node-_4b82f21c-1eac-01f5-4546-90af3c718bfc-b698ce40 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_8d1ccb67-8ee3-3af2-8dba-cadd3809848d-b698ce40 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  align-self: stretch;
}

#w-node-_8d1ccb67-8ee3-3af2-8dba-cadd38098491-b698ce40 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_624cb87c-4531-6c27-9ae1-1defcf5fbfde-4fbdfd3a,
#w-node-_94557e78-edf6-730d-5455-0ff1be084f81-4fbdfd3a,
#w-node-d0f99bc7-6da9-2caf-4d6b-780ae9dcf69a-4fbdfd3a,
#w-node-a8bcf042-9a61-b18f-a9f3-f68b4543baf1-4fbdfd3a {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-e21a51bf-25a8-9c75-579d-a12721269515-b698ce40 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_87ee1f64-9371-abbe-481f-36c61bd0d2dd-4fbdfd3a,
#w-node-_2114f455-29de-e066-929b-225b466a9448-4fbdfd3a,
#w-node-_48ce5b2e-b17a-e8e9-90fd-af6153edb73c-4fbdfd3a,
#w-node-_66519dec-1cdf-36a1-15ba-d547bb55ffe1-4fbdfd3a {
  grid-area: span 1 / span 1 / span 1 / span 1;
  align-self: stretch;
}

#w-node-a9cdd199-faaa-a142-6213-1e3cec75769f-b698ce53 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-a9cdd199-faaa-a142-6213-1e3cec7576a4-4fbdfd3b,
#w-node-a9cdd199-faaa-a142-6213-1e3cec7576a5-4fbdfd3b {
  justify-self: end;
}

#w-node-a9cdd199-faaa-a142-6213-1e3cec7576b0-4fbdfd3b,
#w-node-a9cdd199-faaa-a142-6213-1e3cec7576b2-4fbdfd3b {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-d861cb6f-8e46-7c3c-1807-a17650a4527e-4fbdfd3b,
#w-node-_94dfc761-cee6-1d7a-f83c-51ea630b2ed6-4fbdfd3b {
  align-self: center;
}

#w-node-_72f13dfb-e280-c815-4fb5-9e393010f146-b698ce53 {
  grid-area: span 1 / span 2 / span 1 / span 2;
}

#w-node-d4360aaa-31ca-8556-0901-c8506f70703d-4fbdfd3b,
#w-node-d4360aaa-31ca-8556-0901-c8506f707049-4fbdfd3b {
  align-self: center;
}

#w-node-d4360aaa-31ca-8556-0901-c8506f707056-b698ce53 {
  grid-area: span 1 / span 2 / span 1 / span 2;
}

#w-node-_76eddbf3-2988-a4f5-5f71-379af7414520-4fbdfd3b,
#w-node-_76eddbf3-2988-a4f5-5f71-379af741452c-4fbdfd3b {
  align-self: center;
}

#w-node-_76eddbf3-2988-a4f5-5f71-379af7414539-b698ce53 {
  grid-area: span 1 / span 2 / span 1 / span 2;
}

#w-node-_58609445-feab-7dc2-7faa-eb9138a84e39-4fbdfd3b,
#w-node-_58609445-feab-7dc2-7faa-eb9138a84e45-4fbdfd3b {
  align-self: center;
}

#w-node-_58609445-feab-7dc2-7faa-eb9138a84e52-b698ce53 {
  grid-area: span 1 / span 2 / span 1 / span 2;
}

#w-node-_58609445-feab-7dc2-7faa-eb9138a84e5b-4fbdfd3b,
#w-node-_58609445-feab-7dc2-7faa-eb9138a84e67-4fbdfd3b {
  align-self: center;
}

#w-node-_58609445-feab-7dc2-7faa-eb9138a84e74-b698ce53 {
  grid-area: span 1 / span 2 / span 1 / span 2;
}

#w-node-_58609445-feab-7dc2-7faa-eb9138a84e7d-4fbdfd3b,
#w-node-_58609445-feab-7dc2-7faa-eb9138a84e89-4fbdfd3b {
  align-self: center;
}

#w-node-_58609445-feab-7dc2-7faa-eb9138a84e96-b698ce53 {
  grid-area: span 1 / span 2 / span 1 / span 2;
}

#w-node-_364f5dd5-3363-3fb7-aa7b-dcd2d903cefa-4fbdfd46 {
  justify-self: end;
}

#w-node-_364f5dd5-3363-3fb7-aa7b-dcd2d903cf04-4fbdfd46,
#w-node-_364f5dd5-3363-3fb7-aa7b-dcd2d903cf06-4fbdfd46 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-f668b2c7-fa5c-21d0-b1f1-d60daa6008b8-4fbdfd46 {
  grid-area: span 1 / span 2 / span 1 / span 2;
}

#w-node-_111bb980-3e4c-df81-fb84-b1e70f33c830-4fbdfd46 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_984e4706-97da-3ec3-ffb7-5db245e3c059-4fbdfd46,
#w-node-_984e4706-97da-3ec3-ffb7-5db245e3c05e-4fbdfd46 {
  justify-self: end;
}

#w-node-_984e4706-97da-3ec3-ffb7-5db245e3c06e-4fbdfd46 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_984e4706-97da-3ec3-ffb7-5db245e3c082-4fbdfd46,
#w-node-_984e4706-97da-3ec3-ffb7-5db245e3c088-4fbdfd46,
#w-node-_984e4706-97da-3ec3-ffb7-5db245e3c09a-4fbdfd46 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_984e4706-97da-3ec3-ffb7-5db245e3c09b-4fbdfd46 {
  justify-self: end;
}

#w-node-_5aba21fe-57a3-186f-eaa2-d07319f0ff09-4fbdfd46 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_5aba21fe-57a3-186f-eaa2-d07319f0ff21-4fbdfd46,
#w-node-_5aba21fe-57a3-186f-eaa2-d07319f0ff27-4fbdfd46,
#w-node-b88b86c6-209e-8d16-9dad-b3242be81dfb-4fbdfd46 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-b88b86c6-209e-8d16-9dad-b3242be81dff-4fbdfd46 {
  justify-self: end;
}

#w-node-_9b7e9262-0f56-a667-e0e9-10e0029e7dc0-4fbdfd46 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_9b7e9262-0f56-a667-e0e9-10e0029e7dd8-4fbdfd46,
#w-node-_9b7e9262-0f56-a667-e0e9-10e0029e7dde-4fbdfd46,
#w-node-a460906d-8fde-65d8-c884-baa941c0b0a2-4fbdfd46 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-a460906d-8fde-65d8-c884-baa941c0b0a6-4fbdfd46 {
  justify-self: end;
}

#w-node-be7849f3-00e3-d789-cc49-ff50b31dc870-4fbdfd46 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-be7849f3-00e3-d789-cc49-ff50b31dc888-4fbdfd46,
#w-node-be7849f3-00e3-d789-cc49-ff50b31dc88e-4fbdfd46,
#w-node-_19ddf657-c461-c405-c13f-da330e059b01-4fbdfd46 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_19ddf657-c461-c405-c13f-da330e059b05-4fbdfd46 {
  justify-self: end;
}

#w-node-a78ea6d3-7c17-b8ac-126b-916a1987fd95-4fbdfd46 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-a78ea6d3-7c17-b8ac-126b-916a1987fdad-4fbdfd46,
#w-node-a78ea6d3-7c17-b8ac-126b-916a1987fdb3-4fbdfd46,
#w-node-d8cd8bca-8f55-9d03-12b8-e8a6d4307cca-4fbdfd46 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-d8cd8bca-8f55-9d03-12b8-e8a6d4307cce-4fbdfd46,
#w-node-aa0655c1-c613-261c-c779-b8690b8e16b0-4fbdfd46 {
  justify-self: end;
}

#w-node-aa0655c1-c613-261c-c779-b8690b8e16b4-4fbdfd46 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-aa0655c1-c613-261c-c779-b8690b8e16c9-4fbdfd46 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-aa0655c1-c613-261c-c779-b8690b8e16ce-4fbdfd46 {
  justify-self: end;
}

#w-node-_5cbc44a4-63ea-d6d4-38de-09040ba579f5-4fbdfd46 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_5cbc44a4-63ea-d6d4-38de-09040ba579fc-4fbdfd46 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_5cbc44a4-63ea-d6d4-38de-09040ba57a01-4fbdfd46 {
  justify-self: end;
}

#w-node-b9b9053d-ed05-a1d3-e2b8-6727b10b337f-4fbdfd46,
#w-node-b9b9053d-ed05-a1d3-e2b8-6727b10b3381-4fbdfd46 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_69c98ed1-b18c-221a-12db-5a6ebf27dfd9-4fbdfd46,
#w-node-_69c98ed1-b18c-221a-12db-5a6ebf27dfdd-4fbdfd46 {
  grid-area: span 1 / span 2 / span 1 / span 2;
}

#w-node-ae951db6-4433-d1fb-0163-f68f8fd55c0f-4fbdfd46,
#w-node-ae951db6-4433-d1fb-0163-f68f8fd55c14-4fbdfd46 {
  justify-self: end;
}

#w-node-ae951db6-4433-d1fb-0163-f68f8fd55c1f-4fbdfd46 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-ae951db6-4433-d1fb-0163-f68f8fd55c34-4fbdfd46,
#w-node-ae951db6-4433-d1fb-0163-f68f8fd55c3a-4fbdfd46,
#w-node-ae951db6-4433-d1fb-0163-f68f8fd55c46-4fbdfd46 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-ae951db6-4433-d1fb-0163-f68f8fd55c47-4fbdfd46 {
  justify-self: end;
}

#w-node-_0d7b3f7a-7c4e-d31b-e66a-23d21198dd3e-4fbdfd46 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_0d7b3f7a-7c4e-d31b-e66a-23d21198dd51-4fbdfd46,
#w-node-_0d7b3f7a-7c4e-d31b-e66a-23d21198dd57-4fbdfd46,
#w-node-_93d1e810-3959-d679-2d0d-e08ba4305a64-4fbdfd46 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_93d1e810-3959-d679-2d0d-e08ba4305a68-4fbdfd46 {
  justify-self: end;
}

#w-node-_99eccd30-39ad-38ef-3543-cd997d2be300-4fbdfd46 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_99eccd30-39ad-38ef-3543-cd997d2be313-4fbdfd46,
#w-node-_99eccd30-39ad-38ef-3543-cd997d2be319-4fbdfd46,
#w-node-_18df25ea-3a6b-793a-b66b-71253d81910f-4fbdfd46 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_18df25ea-3a6b-793a-b66b-71253d819113-4fbdfd46 {
  justify-self: end;
}

#w-node-_69d9e290-dfaa-8e0d-9b80-0d2e002cad84-4fbdfd46 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_69d9e290-dfaa-8e0d-9b80-0d2e002cad97-4fbdfd46,
#w-node-_69d9e290-dfaa-8e0d-9b80-0d2e002cad9d-4fbdfd46,
#w-node-ce45e422-c880-b46d-c411-5e05c4258139-4fbdfd46 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-ce45e422-c880-b46d-c411-5e05c425813d-4fbdfd46 {
  justify-self: end;
}

#w-node-d1d9e6c5-5dcc-8c62-849c-b00737de3a87-4fbdfd46 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-d1d9e6c5-5dcc-8c62-849c-b00737de3a9a-4fbdfd46,
#w-node-d1d9e6c5-5dcc-8c62-849c-b00737de3aa0-4fbdfd46,
#w-node-_79fd3a95-0d07-304f-1cec-47820b9fe694-4fbdfd46 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_79fd3a95-0d07-304f-1cec-47820b9fe698-4fbdfd46 {
  justify-self: end;
}

#w-node-_58137467-e186-fe5d-d0bb-7e1200870c2f-4fbdfd46 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_58137467-e186-fe5d-d0bb-7e1200870c42-4fbdfd46,
#w-node-_58137467-e186-fe5d-d0bb-7e1200870c48-4fbdfd46,
#w-node-_31002672-988d-f0a4-aefc-541521409e83-4fbdfd46 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_31002672-988d-f0a4-aefc-541521409e87-4fbdfd46 {
  justify-self: end;
}

#w-node-_255d957c-4f0d-edc6-58e9-f588ce2d584f-4fbdfd46 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_255d957c-4f0d-edc6-58e9-f588ce2d5862-4fbdfd46,
#w-node-_255d957c-4f0d-edc6-58e9-f588ce2d5868-4fbdfd46,
#w-node-ac6fd559-4953-759d-7dc1-c55358ee6f9a-4fbdfd46 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-ac6fd559-4953-759d-7dc1-c55358ee6f9e-4fbdfd46 {
  justify-self: end;
}

#w-node-_067f2384-b354-adcc-6b97-6efa498cd675-4fbdfd46 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_067f2384-b354-adcc-6b97-6efa498cd688-4fbdfd46,
#w-node-_067f2384-b354-adcc-6b97-6efa498cd68e-4fbdfd46,
#w-node-b8e09acb-0479-38d5-c461-394839eed4f5-4fbdfd46 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-b8e09acb-0479-38d5-c461-394839eed4f9-4fbdfd46 {
  justify-self: end;
}

#w-node-ae951db6-4433-d1fb-0163-f68f8fd55d74-4fbdfd46 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_360cb5a5-8ca1-5a38-d306-19aa0e897477-4fbdfd46,
#w-node-bb2447fa-75de-7635-5798-c3c63b105a7b-4fbdfd46 {
  justify-self: end;
}

#w-node-_360cb5a5-8ca1-5a38-d306-19aa0e897481-4fbdfd46 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_360cb5a5-8ca1-5a38-d306-19aa0e897494-4fbdfd46,
#w-node-_360cb5a5-8ca1-5a38-d306-19aa0e89749a-4fbdfd46 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-a7f336b1-56ce-9e29-6e39-9cd6272580ac-4fbdfd46 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-a7f336b1-56ce-9e29-6e39-9cd6272580b9-4fbdfd46,
#w-node-a7f336b1-56ce-9e29-6e39-9cd6272580bf-4fbdfd46 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_423dc18b-c9df-bc36-fdbe-0cb2ec6c4558-4fbdfd46 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_423dc18b-c9df-bc36-fdbe-0cb2ec6c4565-4fbdfd46,
#w-node-_423dc18b-c9df-bc36-fdbe-0cb2ec6c456b-4fbdfd46 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_5750802e-8600-1b29-03ec-e389223cb5aa-4fbdfd46 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_5750802e-8600-1b29-03ec-e389223cb5b7-4fbdfd46,
#w-node-_5750802e-8600-1b29-03ec-e389223cb5bd-4fbdfd46 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_100675e2-5b14-17f9-fdbc-1f8c192ac61d-4fbdfd46 {
  justify-self: end;
}

#w-node-_100675e2-5b14-17f9-fdbc-1f8c192ac627-4fbdfd46,
#w-node-_100675e2-5b14-17f9-fdbc-1f8c192ac629-4fbdfd46,
#w-node-_2225c158-2a6d-9f0b-19c1-f813e411996f-4fbdfd46,
#w-node-_2225c158-2a6d-9f0b-19c1-f813e4119982-4fbdfd46 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_2225c158-2a6d-9f0b-19c1-f813e4119994-4fbdfd46 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_2225c158-2a6d-9f0b-19c1-f813e4119995-4fbdfd46 {
  justify-self: end;
}

#w-node-_6bf4bb0c-0009-9152-f228-52ee1ec4eeee-4fbdfd46,
#w-node-_6bf4bb0c-0009-9152-f228-52ee1ec4ef01-4fbdfd46 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_8f16fcbc-c19e-97c0-9fe0-75dc9e76fe24-4fbdfd46 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_8f16fcbc-c19e-97c0-9fe0-75dc9e76fe28-4fbdfd46 {
  justify-self: end;
}

#w-node-_27927f5e-c56e-138b-ced6-d8963e91ae9a-4fbdfd46,
#w-node-_27927f5e-c56e-138b-ced6-d8963e91aead-4fbdfd46 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_3c8a649e-34d2-86e4-e3df-4d09d9f63ad9-4fbdfd46 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_3c8a649e-34d2-86e4-e3df-4d09d9f63add-4fbdfd46 {
  justify-self: end;
}

#w-node-_091ca6e4-080e-b1a8-810f-bcb409d5ccd4-4fbdfd46,
#w-node-_091ca6e4-080e-b1a8-810f-bcb409d5cce7-4fbdfd46 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_5a19b7b1-f0e1-e3b5-8ef6-397df994d99f-4fbdfd46 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_5a19b7b1-f0e1-e3b5-8ef6-397df994d9a3-4fbdfd46 {
  justify-self: end;
}

#w-node-_151ae6ab-3a1e-47ff-3e55-250115f128dd-4fbdfd46,
#w-node-_151ae6ab-3a1e-47ff-3e55-250115f128f0-4fbdfd46 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_1337e8d2-27a4-6c96-6648-7027058f9b88-4fbdfd46 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_1337e8d2-27a4-6c96-6648-7027058f9b8c-4fbdfd46 {
  justify-self: end;
}

#w-node-c5e8ffab-892f-bc71-6b37-b935b377b745-4fbdfd47,
#w-node-c5e8ffab-892f-bc71-6b37-b935b377b747-4fbdfd47,
#w-node-_545166d2-4e98-58de-de0d-ea424025b829-4fbdfd47,
#w-node-_545166d2-4e98-58de-de0d-ea424025b82b-4fbdfd47,
#w-node-_2ebf2916-7705-9990-cb47-1e6eb1e5399f-4fbdfd47,
#w-node-_2ebf2916-7705-9990-cb47-1e6eb1e539a1-4fbdfd47 {
  align-self: stretch;
}

#w-node-_0c6e600c-0849-60e6-5d9b-94ef3767cf0a-b698ce6e {
  grid-area: span 1 / span 1 / span 1 / span 1;
  align-self: start;
}

#w-node-d6ce525a-dc9e-e6c4-84b8-742213eee571-b698ce6e {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_28407451-a244-4872-4416-29ae188ecbdd-b698ce6e {
  align-self: end;
  justify-self: end;
}

#w-node-_6afad5de-8035-5085-dc40-c4a6b1f04f5e-b698ce6e {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-dcede86a-f23b-20f5-d51f-83b3846cbf26-b698ce6e {
  align-self: start;
  justify-self: end;
}

#w-node-aacd826e-267a-7136-d484-c754df3e93af-b698ce6e {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-aacd826e-267a-7136-d484-c754df3e93b5-b698ce6e {
  align-self: start;
  justify-self: end;
}

#w-node-_199d1a10-a17b-63e6-1279-543de0f4fe59-b698ce6e {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_199d1a10-a17b-63e6-1279-543de0f4fe5f-4fbdfd48,
#w-node-_2ee26518-0148-b031-065b-e02a6ce460a9-4fbdfd48 {
  align-self: start;
  justify-self: end;
}

#w-node-_025b8a42-6583-9e15-2e39-e85b04a11e99-4fbdfd48,
#w-node-_471297b1-1238-a5c9-8550-6acb7bf5db2b-4fbdfd48 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_0cf77ca0-6d47-c5a5-f7f0-b996470331d5-b698ce6e {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_44fcf55c-a979-497c-d8ff-6f01962ecd26-b698ce6e {
  align-self: start;
  justify-self: end;
}

#w-node-e2c07af9-825e-96d0-c94c-a08aa91160ca-b698ce6e {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_44fcf55c-a979-497c-d8ff-6f01962ecd3b-b698ce6e {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-dd6daf1e-b7a0-ca47-f619-439237fd7d30-b698ce6e {
  grid-area: span 1 / span 1 / span 1 / span 1;
  align-self: start;
}

#w-node-_364f5dd5-3363-3fb7-aa7b-dcd2d903cefa-b698ce6f {
  justify-self: end;
}

#w-node-_364f5dd5-3363-3fb7-aa7b-dcd2d903cf04-b698ce6f, #w-node-_364f5dd5-3363-3fb7-aa7b-dcd2d903cf06-b698ce6f {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-f668b2c7-fa5c-21d0-b1f1-d60daa6008b8-b698ce6f {
  grid-area: span 1 / span 2 / span 1 / span 2;
}

#w-node-_111bb980-3e4c-df81-fb84-b1e70f33c830-b698ce6f {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_984e4706-97da-3ec3-ffb7-5db245e3c059-b698ce6f, #w-node-_984e4706-97da-3ec3-ffb7-5db245e3c05e-b698ce6f {
  justify-self: end;
}

#w-node-_984e4706-97da-3ec3-ffb7-5db245e3c06e-b698ce6f {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_984e4706-97da-3ec3-ffb7-5db245e3c082-b698ce6f, #w-node-_984e4706-97da-3ec3-ffb7-5db245e3c088-b698ce6f, #w-node-_984e4706-97da-3ec3-ffb7-5db245e3c09a-b698ce6f {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_984e4706-97da-3ec3-ffb7-5db245e3c09b-b698ce6f {
  justify-self: end;
}

#w-node-_5aba21fe-57a3-186f-eaa2-d07319f0ff09-b698ce6f {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_5aba21fe-57a3-186f-eaa2-d07319f0ff21-b698ce6f, #w-node-_5aba21fe-57a3-186f-eaa2-d07319f0ff27-b698ce6f, #w-node-b88b86c6-209e-8d16-9dad-b3242be81dfb-b698ce6f {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-b88b86c6-209e-8d16-9dad-b3242be81dff-b698ce6f {
  justify-self: end;
}

#w-node-_9b7e9262-0f56-a667-e0e9-10e0029e7dc0-b698ce6f {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_9b7e9262-0f56-a667-e0e9-10e0029e7dd8-b698ce6f, #w-node-_9b7e9262-0f56-a667-e0e9-10e0029e7dde-b698ce6f, #w-node-a460906d-8fde-65d8-c884-baa941c0b0a2-b698ce6f {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-a460906d-8fde-65d8-c884-baa941c0b0a6-b698ce6f {
  justify-self: end;
}

#w-node-be7849f3-00e3-d789-cc49-ff50b31dc870-b698ce6f {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-be7849f3-00e3-d789-cc49-ff50b31dc888-b698ce6f, #w-node-be7849f3-00e3-d789-cc49-ff50b31dc88e-b698ce6f, #w-node-_19ddf657-c461-c405-c13f-da330e059b01-b698ce6f {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_19ddf657-c461-c405-c13f-da330e059b05-b698ce6f {
  justify-self: end;
}

#w-node-a78ea6d3-7c17-b8ac-126b-916a1987fd95-b698ce6f {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-a78ea6d3-7c17-b8ac-126b-916a1987fdad-b698ce6f, #w-node-a78ea6d3-7c17-b8ac-126b-916a1987fdb3-b698ce6f, #w-node-d8cd8bca-8f55-9d03-12b8-e8a6d4307cca-b698ce6f {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-d8cd8bca-8f55-9d03-12b8-e8a6d4307cce-b698ce6f, #w-node-aa0655c1-c613-261c-c779-b8690b8e16b0-b698ce6f {
  justify-self: end;
}

#w-node-aa0655c1-c613-261c-c779-b8690b8e16b4-b698ce6f {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-aa0655c1-c613-261c-c779-b8690b8e16c9-b698ce6f {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-aa0655c1-c613-261c-c779-b8690b8e16ce-b698ce6f {
  justify-self: end;
}

#w-node-_5cbc44a4-63ea-d6d4-38de-09040ba579f5-b698ce6f {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_5cbc44a4-63ea-d6d4-38de-09040ba579fc-b698ce6f {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_5cbc44a4-63ea-d6d4-38de-09040ba57a01-b698ce6f {
  justify-self: end;
}

#w-node-b9b9053d-ed05-a1d3-e2b8-6727b10b337f-b698ce6f, #w-node-b9b9053d-ed05-a1d3-e2b8-6727b10b3381-b698ce6f {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_69c98ed1-b18c-221a-12db-5a6ebf27dfd9-b698ce6f, #w-node-_69c98ed1-b18c-221a-12db-5a6ebf27dfdd-b698ce6f {
  grid-area: span 1 / span 2 / span 1 / span 2;
}

#w-node-ae951db6-4433-d1fb-0163-f68f8fd55c0f-b698ce6f, #w-node-ae951db6-4433-d1fb-0163-f68f8fd55c14-b698ce6f {
  justify-self: end;
}

#w-node-ae951db6-4433-d1fb-0163-f68f8fd55c1f-b698ce6f {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-ae951db6-4433-d1fb-0163-f68f8fd55c34-b698ce6f, #w-node-ae951db6-4433-d1fb-0163-f68f8fd55c3a-b698ce6f, #w-node-ae951db6-4433-d1fb-0163-f68f8fd55c46-b698ce6f {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-ae951db6-4433-d1fb-0163-f68f8fd55c47-b698ce6f {
  justify-self: end;
}

#w-node-_0d7b3f7a-7c4e-d31b-e66a-23d21198dd3e-b698ce6f {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_0d7b3f7a-7c4e-d31b-e66a-23d21198dd51-b698ce6f, #w-node-_0d7b3f7a-7c4e-d31b-e66a-23d21198dd57-b698ce6f, #w-node-_93d1e810-3959-d679-2d0d-e08ba4305a64-b698ce6f {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_93d1e810-3959-d679-2d0d-e08ba4305a68-b698ce6f {
  justify-self: end;
}

#w-node-_99eccd30-39ad-38ef-3543-cd997d2be300-b698ce6f {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_99eccd30-39ad-38ef-3543-cd997d2be313-b698ce6f, #w-node-_99eccd30-39ad-38ef-3543-cd997d2be319-b698ce6f, #w-node-_18df25ea-3a6b-793a-b66b-71253d81910f-b698ce6f {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_18df25ea-3a6b-793a-b66b-71253d819113-b698ce6f {
  justify-self: end;
}

#w-node-_69d9e290-dfaa-8e0d-9b80-0d2e002cad84-b698ce6f {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_69d9e290-dfaa-8e0d-9b80-0d2e002cad97-b698ce6f, #w-node-_69d9e290-dfaa-8e0d-9b80-0d2e002cad9d-b698ce6f, #w-node-ce45e422-c880-b46d-c411-5e05c4258139-b698ce6f {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-ce45e422-c880-b46d-c411-5e05c425813d-b698ce6f {
  justify-self: end;
}

#w-node-d1d9e6c5-5dcc-8c62-849c-b00737de3a87-b698ce6f {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-d1d9e6c5-5dcc-8c62-849c-b00737de3a9a-b698ce6f, #w-node-d1d9e6c5-5dcc-8c62-849c-b00737de3aa0-b698ce6f, #w-node-_79fd3a95-0d07-304f-1cec-47820b9fe694-b698ce6f {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_79fd3a95-0d07-304f-1cec-47820b9fe698-b698ce6f {
  justify-self: end;
}

#w-node-_58137467-e186-fe5d-d0bb-7e1200870c2f-b698ce6f {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_58137467-e186-fe5d-d0bb-7e1200870c42-b698ce6f, #w-node-_58137467-e186-fe5d-d0bb-7e1200870c48-b698ce6f, #w-node-_31002672-988d-f0a4-aefc-541521409e83-b698ce6f {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_31002672-988d-f0a4-aefc-541521409e87-b698ce6f {
  justify-self: end;
}

#w-node-_255d957c-4f0d-edc6-58e9-f588ce2d584f-b698ce6f {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_255d957c-4f0d-edc6-58e9-f588ce2d5862-b698ce6f, #w-node-_255d957c-4f0d-edc6-58e9-f588ce2d5868-b698ce6f, #w-node-ac6fd559-4953-759d-7dc1-c55358ee6f9a-b698ce6f {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-ac6fd559-4953-759d-7dc1-c55358ee6f9e-b698ce6f {
  justify-self: end;
}

#w-node-_067f2384-b354-adcc-6b97-6efa498cd675-b698ce6f {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_067f2384-b354-adcc-6b97-6efa498cd688-b698ce6f, #w-node-_067f2384-b354-adcc-6b97-6efa498cd68e-b698ce6f, #w-node-b8e09acb-0479-38d5-c461-394839eed4f5-b698ce6f {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-b8e09acb-0479-38d5-c461-394839eed4f9-b698ce6f {
  justify-self: end;
}

#w-node-ae951db6-4433-d1fb-0163-f68f8fd55d74-b698ce6f {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_360cb5a5-8ca1-5a38-d306-19aa0e897477-b698ce6f, #w-node-bb2447fa-75de-7635-5798-c3c63b105a7b-b698ce6f {
  justify-self: end;
}

#w-node-_360cb5a5-8ca1-5a38-d306-19aa0e897481-b698ce6f {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_360cb5a5-8ca1-5a38-d306-19aa0e897494-b698ce6f, #w-node-_360cb5a5-8ca1-5a38-d306-19aa0e89749a-b698ce6f {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-a7f336b1-56ce-9e29-6e39-9cd6272580ac-b698ce6f {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-a7f336b1-56ce-9e29-6e39-9cd6272580b9-b698ce6f, #w-node-a7f336b1-56ce-9e29-6e39-9cd6272580bf-b698ce6f {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_423dc18b-c9df-bc36-fdbe-0cb2ec6c4558-b698ce6f {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_423dc18b-c9df-bc36-fdbe-0cb2ec6c4565-b698ce6f, #w-node-_423dc18b-c9df-bc36-fdbe-0cb2ec6c456b-b698ce6f {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_5750802e-8600-1b29-03ec-e389223cb5aa-b698ce6f {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_5750802e-8600-1b29-03ec-e389223cb5b7-b698ce6f, #w-node-_5750802e-8600-1b29-03ec-e389223cb5bd-b698ce6f {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_100675e2-5b14-17f9-fdbc-1f8c192ac61d-b698ce6f {
  justify-self: end;
}

#w-node-_100675e2-5b14-17f9-fdbc-1f8c192ac627-b698ce6f, #w-node-_100675e2-5b14-17f9-fdbc-1f8c192ac629-b698ce6f, #w-node-_2225c158-2a6d-9f0b-19c1-f813e411996f-b698ce6f, #w-node-_2225c158-2a6d-9f0b-19c1-f813e4119982-b698ce6f {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_2225c158-2a6d-9f0b-19c1-f813e4119994-b698ce6f {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_2225c158-2a6d-9f0b-19c1-f813e4119995-b698ce6f {
  justify-self: end;
}

#w-node-_6bf4bb0c-0009-9152-f228-52ee1ec4eeee-b698ce6f, #w-node-_6bf4bb0c-0009-9152-f228-52ee1ec4ef01-b698ce6f {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_8f16fcbc-c19e-97c0-9fe0-75dc9e76fe24-b698ce6f {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_8f16fcbc-c19e-97c0-9fe0-75dc9e76fe28-b698ce6f {
  justify-self: end;
}

#w-node-_27927f5e-c56e-138b-ced6-d8963e91ae9a-b698ce6f, #w-node-_27927f5e-c56e-138b-ced6-d8963e91aead-b698ce6f {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_3c8a649e-34d2-86e4-e3df-4d09d9f63ad9-b698ce6f {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_3c8a649e-34d2-86e4-e3df-4d09d9f63add-b698ce6f {
  justify-self: end;
}

#w-node-_091ca6e4-080e-b1a8-810f-bcb409d5ccd4-b698ce6f, #w-node-_091ca6e4-080e-b1a8-810f-bcb409d5cce7-b698ce6f {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_5a19b7b1-f0e1-e3b5-8ef6-397df994d99f-b698ce6f {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_5a19b7b1-f0e1-e3b5-8ef6-397df994d9a3-b698ce6f {
  justify-self: end;
}

#w-node-_151ae6ab-3a1e-47ff-3e55-250115f128dd-b698ce6f, #w-node-_151ae6ab-3a1e-47ff-3e55-250115f128f0-b698ce6f {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_1337e8d2-27a4-6c96-6648-7027058f9b88-b698ce6f {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_1337e8d2-27a4-6c96-6648-7027058f9b8c-b698ce6f {
  justify-self: end;
}

#w-node-a483a448-a7fa-9a42-d96f-0fe0f71da0e5-b698ce70, #w-node-d0185b4c-4b20-7e9e-261c-87ab9ef0fc42-b698ce70 {
  align-self: stretch;
}

#w-node-f933fc83-48d8-dc4b-3d25-f0418badd1f5-b698ce71, #w-node-_17afa965-0c45-a129-d17e-1ebfbe8b901d-b698ce71, #w-node-_382ea143-3f54-5f67-6e63-ce8f814b74fb-b698ce71 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_90225e52-22b6-6b84-6756-85adedd22e5a-b698ce71 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  align-self: end;
  justify-self: end;
}

#w-node-_42b51557-e300-659e-ae67-b767f2c5cc74-b698ce71 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  align-self: start;
}

#w-node-_8bee5a80-4497-80ee-21da-dc2e1534a9ed-b698ce71, #w-node-_5479fa3f-156b-01ff-a6e3-85d27373a2de-b698ce71, #w-node-_60dcb89e-04c2-8302-048e-4dcb0422e19b-b698ce71 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_3dcd3c8b-5d43-a32b-1b48-c0ff3762051d-b698ce71 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  align-self: start;
}

#w-node-ccbfc242-454c-7e4f-500d-72951115d83c-b698ce71, #w-node-_477ecf8e-2ddf-e0d7-709e-0e2622b43fa8-b698ce71 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_477ecf8e-2ddf-e0d7-709e-0e2622b43fae-b698ce71 {
  align-self: start;
  justify-self: end;
}

#w-node-_477ecf8e-2ddf-e0d7-709e-0e2622b43fb7-b698ce71 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_477ecf8e-2ddf-e0d7-709e-0e2622b43fbd-b698ce71 {
  align-self: start;
  justify-self: end;
}

#w-node-_477ecf8e-2ddf-e0d7-709e-0e2622b43fc6-b698ce71 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_477ecf8e-2ddf-e0d7-709e-0e2622b43fcc-b698ce71, #w-node-_477ecf8e-2ddf-e0d7-709e-0e2622b43fdc-b698ce71 {
  align-self: start;
  justify-self: end;
}

#w-node-_477ecf8e-2ddf-e0d7-709e-0e2622b43fe6-b698ce71, #w-node-_477ecf8e-2ddf-e0d7-709e-0e2622b43fe7-b698ce71 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_477ecf8e-2ddf-e0d7-709e-0e2622b43ff1-b698ce71 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_477ecf8e-2ddf-e0d7-709e-0e2622b43ffe-b698ce71 {
  align-self: start;
  justify-self: end;
}

#w-node-_477ecf8e-2ddf-e0d7-709e-0e2622b44008-b698ce71 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_477ecf8e-2ddf-e0d7-709e-0e2622b44012-b698ce71 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4b27-b698ce85 {
  grid-area: 1 / 1 / 2 / 2;
  align-self: start;
}

#w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4b41-b698ce85 {
  align-self: start;
}

#w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4b4f-b698ce85 {
  justify-self: end;
}

#w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4b59-4fbdfd49,
#w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4b5b-4fbdfd49 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4b71-b698ce85 {
  grid-area: span 1 / span 2 / span 1 / span 2;
}

#w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4bad-b698ce85 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4bc9-4fbdfd49,
#w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4bce-4fbdfd49 {
  justify-self: end;
}

#w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4bd4-b698ce85 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4bec-4fbdfd49,
#w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4bf2-4fbdfd49,
#w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4bf8-4fbdfd49 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4bf9-b698ce85 {
  justify-self: end;
}

#w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4c00-b698ce85 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4c18-4fbdfd49,
#w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4c1e-4fbdfd49,
#w-node-b63fa71f-32a9-b587-261f-ad89e722b8c8-4fbdfd49 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-b63fa71f-32a9-b587-261f-ad89e722b8cc-b698ce85 {
  justify-self: end;
}

#w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4c2c-b698ce85 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4c44-4fbdfd49,
#w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4c4a-4fbdfd49,
#w-node-_846c6a5e-0178-e1a3-ae75-4d13d131b81d-4fbdfd49 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_846c6a5e-0178-e1a3-ae75-4d13d131b821-b698ce85 {
  justify-self: end;
}

#w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4c58-b698ce85 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4c70-4fbdfd49,
#w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4c76-4fbdfd49,
#w-node-_384db549-561b-df30-e097-c5d7d2e3e2bd-4fbdfd49 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_384db549-561b-df30-e097-c5d7d2e3e2c1-b698ce85 {
  justify-self: end;
}

#w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4c84-b698ce85 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4c9c-4fbdfd49,
#w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4ca2-4fbdfd49,
#w-node-abc793d2-da2c-d8ed-f27d-a4d0b587eb81-4fbdfd49 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-abc793d2-da2c-d8ed-f27d-a4d0b587eb85-4fbdfd49,
#w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4cd2-4fbdfd49 {
  justify-self: end;
}

#w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4cd6-b698ce85 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4cdd-b698ce85 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4ce2-b698ce85 {
  justify-self: end;
}

#w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4ce5-b698ce85 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4cec-b698ce85 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4cf1-b698ce85 {
  justify-self: end;
}

#w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4d3c-4fbdfd49,
#w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4d3e-4fbdfd49 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4d4a-4fbdfd49,
#w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4d4e-4fbdfd49 {
  grid-area: span 1 / span 2 / span 1 / span 2;
}

#w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4d86-4fbdfd49,
#w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4d8b-4fbdfd49 {
  justify-self: end;
}

#w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4d91-b698ce85 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4da4-4fbdfd49,
#w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4daa-4fbdfd49,
#w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4db0-4fbdfd49 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4db1-b698ce85 {
  justify-self: end;
}

#w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4db5-b698ce85 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4dc8-4fbdfd49,
#w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4dce-4fbdfd49,
#w-node-_9f2e0b9a-bf03-0d1c-8271-acc5a3b2a14f-4fbdfd49 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_9f2e0b9a-bf03-0d1c-8271-acc5a3b2a153-b698ce85 {
  justify-self: end;
}

#w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4dd9-b698ce85 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4dec-4fbdfd49,
#w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4df2-4fbdfd49,
#w-node-b87f8250-e094-9864-e028-60fe94e54489-4fbdfd49 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-b87f8250-e094-9864-e028-60fe94e5448d-b698ce85 {
  justify-self: end;
}

#w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4dfd-b698ce85 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4e10-4fbdfd49,
#w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4e16-4fbdfd49,
#w-node-dd0f888d-3537-e5e1-5d21-2ee31ed12619-4fbdfd49 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-dd0f888d-3537-e5e1-5d21-2ee31ed1261d-b698ce85 {
  justify-self: end;
}

#w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4e21-b698ce85 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4e34-4fbdfd49,
#w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4e3a-4fbdfd49,
#w-node-fe5bbaa7-9e5f-4ee1-761d-2a746a455ed5-4fbdfd49 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-fe5bbaa7-9e5f-4ee1-761d-2a746a455ed9-b698ce85 {
  justify-self: end;
}

#w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4e45-b698ce85 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4e58-4fbdfd49,
#w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4e5e-4fbdfd49,
#w-node-_68621530-aa95-3351-8559-d20a461199bb-4fbdfd49 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_68621530-aa95-3351-8559-d20a461199bf-b698ce85 {
  justify-self: end;
}

#w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4e69-b698ce85 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4e7c-4fbdfd49,
#w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4e82-4fbdfd49,
#w-node-_36fc4f72-6673-f302-0806-8ef785ce50b9-4fbdfd49 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_36fc4f72-6673-f302-0806-8ef785ce50bd-b698ce85 {
  justify-self: end;
}

#w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4e8d-b698ce85 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4ea0-4fbdfd49,
#w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4ea6-4fbdfd49,
#w-node-_7abf0029-e3f3-e87e-81f0-53444d17c947-4fbdfd49 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_7abf0029-e3f3-e87e-81f0-53444d17c94b-b698ce85 {
  justify-self: end;
}

#w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4eb6-b698ce85 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4ec3-4fbdfd49,
#w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4ec5-4fbdfd49 {
  justify-self: end;
}

#w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4ec8-b698ce85 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4ed5-4fbdfd49,
#w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4edb-4fbdfd49 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4ee2-b698ce85 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4eef-4fbdfd49,
#w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4ef5-4fbdfd49 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4efc-b698ce85 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4f09-4fbdfd49,
#w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4f0f-4fbdfd49 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4f16-b698ce85 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4f23-4fbdfd49,
#w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4f29-4fbdfd49 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4f34-b698ce85 {
  justify-self: end;
}

#w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4f3e-4fbdfd49,
#w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4f40-4fbdfd49,
#w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4f5d-4fbdfd49,
#w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4f70-4fbdfd49 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4f7a-b698ce85 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4f7b-b698ce85 {
  justify-self: end;
}

#w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4f82-4fbdfd49,
#w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4f95-4fbdfd49 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_15bcbbb4-35ec-fd6c-494e-921535e91bcb-b698ce85 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_15bcbbb4-35ec-fd6c-494e-921535e91bcf-b698ce85 {
  justify-self: end;
}

#w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4fa7-4fbdfd49,
#w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4fba-4fbdfd49 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_30aaa3d5-4207-2cd9-6611-2537fec3ce8c-b698ce85 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_30aaa3d5-4207-2cd9-6611-2537fec3ce90-b698ce85 {
  justify-self: end;
}

#w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4fcc-4fbdfd49,
#w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4fdf-4fbdfd49 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_1e1cc072-4df3-9cd1-0da2-c23f2de16c0f-b698ce85 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_1e1cc072-4df3-9cd1-0da2-c23f2de16c13-b698ce85 {
  justify-self: end;
}

#w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4ff1-4fbdfd49,
#w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d5004-4fbdfd49 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-bfd0edf0-6a9d-495c-eef5-f1a60413a7ab-b698ce85 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-bfd0edf0-6a9d-495c-eef5-f1a60413a7af-b698ce85 {
  justify-self: end;
}

#w-node-a483a448-a7fa-9a42-d96f-0fe0f71da0e5-4fbdfd4a,
#w-node-d0185b4c-4b20-7e9e-261c-87ab9ef0fc42-4fbdfd4a {
  align-self: stretch;
}

#w-node-f933fc83-48d8-dc4b-3d25-f0418badd1f5-4fbdfd4b,
#w-node-_17afa965-0c45-a129-d17e-1ebfbe8b901d-4fbdfd4b,
#w-node-_382ea143-3f54-5f67-6e63-ce8f814b74fb-4fbdfd4b {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_90225e52-22b6-6b84-6756-85adedd22e5a-4fbdfd4b {
  grid-area: span 1 / span 1 / span 1 / span 1;
  align-self: end;
  justify-self: end;
}

#w-node-_42b51557-e300-659e-ae67-b767f2c5cc74-4fbdfd4b {
  grid-area: span 1 / span 1 / span 1 / span 1;
  align-self: start;
}

#w-node-_8bee5a80-4497-80ee-21da-dc2e1534a9ed-4fbdfd4b,
#w-node-_5479fa3f-156b-01ff-a6e3-85d27373a2de-4fbdfd4b,
#w-node-_60dcb89e-04c2-8302-048e-4dcb0422e19b-4fbdfd4b {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-cd5e2dc9-a3b8-fff9-ea31-7da474aaf9f9-b698ce87 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  align-self: start;
}

#w-node-ccbfc242-454c-7e4f-500d-72951115d83c-4fbdfd4b,
#w-node-_477ecf8e-2ddf-e0d7-709e-0e2622b43fa8-4fbdfd4b {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-cd5e2dc9-a3b8-fff9-ea31-7da474aafa38-b698ce87 {
  align-self: start;
  justify-self: end;
}

#w-node-cd5e2dc9-a3b8-fff9-ea31-7da474aafa41-b698ce87 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-cd5e2dc9-a3b8-fff9-ea31-7da474aafa47-b698ce87 {
  align-self: start;
  justify-self: end;
}

#w-node-cd5e2dc9-a3b8-fff9-ea31-7da474aafa50-b698ce87 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_477ecf8e-2ddf-e0d7-709e-0e2622b43fcc-4fbdfd4b,
#w-node-_477ecf8e-2ddf-e0d7-709e-0e2622b43fdc-4fbdfd4b {
  align-self: start;
  justify-self: end;
}

#w-node-_477ecf8e-2ddf-e0d7-709e-0e2622b43fe6-4fbdfd4b,
#w-node-_477ecf8e-2ddf-e0d7-709e-0e2622b43fe7-4fbdfd4b {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-cd5e2dc9-a3b8-fff9-ea31-7da474aafa7b-b698ce87 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-cd5e2dc9-a3b8-fff9-ea31-7da474aafa88-b698ce87 {
  align-self: start;
  justify-self: end;
}

#w-node-cd5e2dc9-a3b8-fff9-ea31-7da474aafa92-b698ce87 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-cd5e2dc9-a3b8-fff9-ea31-7da474aafa9c-b698ce87 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_0cdfc3e1-b742-ba9e-b896-1be8b409683a-b698ce88 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  align-self: start;
}

#w-node-_0f6ef97d-c33e-10c2-1a33-a4930288597f-b698ce88 {
  justify-self: end;
}

#w-node-_0f6ef97d-c33e-10c2-1a33-a49302885989-4fbdfd4c,
#w-node-_0f6ef97d-c33e-10c2-1a33-a4930288598b-4fbdfd4c {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_0f6ef97d-c33e-10c2-1a33-a493028859a1-b698ce88 {
  grid-area: span 1 / span 2 / span 1 / span 2;
}

#w-node-_0f6ef97d-c33e-10c2-1a33-a493028859dd-b698ce88 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_3db82d41-5a67-657a-90c3-6c38de0cf6bc-b698ce88 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  align-self: start;
}

#w-node-_0f6ef97d-c33e-10c2-1a33-a493028859f9-4fbdfd4c,
#w-node-_0f6ef97d-c33e-10c2-1a33-a493028859fe-4fbdfd4c {
  justify-self: end;
}

#w-node-_0f6ef97d-c33e-10c2-1a33-a49302885a04-b698ce88 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_0f6ef97d-c33e-10c2-1a33-a49302885a1c-4fbdfd4c,
#w-node-_0f6ef97d-c33e-10c2-1a33-a49302885a22-4fbdfd4c,
#w-node-_0f6ef97d-c33e-10c2-1a33-a49302885a28-4fbdfd4c {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_0f6ef97d-c33e-10c2-1a33-a49302885a29-b698ce88 {
  justify-self: end;
}

#w-node-_0f6ef97d-c33e-10c2-1a33-a49302885a30-b698ce88 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_0f6ef97d-c33e-10c2-1a33-a49302885a48-4fbdfd4c,
#w-node-_0f6ef97d-c33e-10c2-1a33-a49302885a4e-4fbdfd4c,
#w-node-_0dbfe403-7154-be21-06dc-fadbb0f5452f-4fbdfd4c {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_0dbfe403-7154-be21-06dc-fadbb0f54533-b698ce88 {
  justify-self: end;
}

#w-node-_0f6ef97d-c33e-10c2-1a33-a49302885a5c-b698ce88 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_0f6ef97d-c33e-10c2-1a33-a49302885a74-4fbdfd4c,
#w-node-_0f6ef97d-c33e-10c2-1a33-a49302885a7a-4fbdfd4c,
#w-node-c7ae7c27-bd86-0a03-82bd-5cc8120c0d62-4fbdfd4c {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-c7ae7c27-bd86-0a03-82bd-5cc8120c0d66-b698ce88 {
  justify-self: end;
}

#w-node-_0f6ef97d-c33e-10c2-1a33-a49302885a88-b698ce88 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_0f6ef97d-c33e-10c2-1a33-a49302885aa0-4fbdfd4c,
#w-node-_0f6ef97d-c33e-10c2-1a33-a49302885aa6-4fbdfd4c,
#w-node-_4fa9d836-a3d6-1070-a816-9a57a9639b29-4fbdfd4c {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_4fa9d836-a3d6-1070-a816-9a57a9639b2d-b698ce88 {
  justify-self: end;
}

#w-node-_0f6ef97d-c33e-10c2-1a33-a49302885ab4-b698ce88 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_0f6ef97d-c33e-10c2-1a33-a49302885acc-4fbdfd4c,
#w-node-_0f6ef97d-c33e-10c2-1a33-a49302885ad2-4fbdfd4c,
#w-node-_3a95f871-4236-4fee-bb4c-573867afa72e-4fbdfd4c {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_3a95f871-4236-4fee-bb4c-573867afa732-b698ce88 {
  justify-self: end;
}

#w-node-bae20f24-aa81-1cc5-e669-449ed41523b8-b698ce88 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  align-self: start;
}

#w-node-_0f6ef97d-c33e-10c2-1a33-a49302885b02-b698ce88 {
  justify-self: end;
}

#w-node-_0f6ef97d-c33e-10c2-1a33-a49302885b06-b698ce88 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_0f6ef97d-c33e-10c2-1a33-a49302885b0d-b698ce88 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_0f6ef97d-c33e-10c2-1a33-a49302885b12-b698ce88 {
  justify-self: end;
}

#w-node-_0f6ef97d-c33e-10c2-1a33-a49302885b15-b698ce88 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_0f6ef97d-c33e-10c2-1a33-a49302885b1c-b698ce88 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_0f6ef97d-c33e-10c2-1a33-a49302885b21-b698ce88 {
  justify-self: end;
}

#w-node-_0f6ef97d-c33e-10c2-1a33-a49302885b6c-4fbdfd4c,
#w-node-_0f6ef97d-c33e-10c2-1a33-a49302885b6e-4fbdfd4c {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_0f6ef97d-c33e-10c2-1a33-a49302885b7a-4fbdfd4c,
#w-node-_0f6ef97d-c33e-10c2-1a33-a49302885b7e-4fbdfd4c {
  grid-area: span 1 / span 2 / span 1 / span 2;
}

#w-node-_0f6ef97d-c33e-10c2-1a33-a49302885bb6-4fbdfd4c,
#w-node-_0f6ef97d-c33e-10c2-1a33-a49302885bbb-4fbdfd4c {
  justify-self: end;
}

#w-node-_0f6ef97d-c33e-10c2-1a33-a49302885bc1-b698ce88 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_0f6ef97d-c33e-10c2-1a33-a49302885bd4-4fbdfd4c,
#w-node-_0f6ef97d-c33e-10c2-1a33-a49302885bda-4fbdfd4c,
#w-node-_0f6ef97d-c33e-10c2-1a33-a49302885be0-4fbdfd4c {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_0f6ef97d-c33e-10c2-1a33-a49302885be1-b698ce88 {
  justify-self: end;
}

#w-node-_0f6ef97d-c33e-10c2-1a33-a49302885be5-b698ce88 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_0f6ef97d-c33e-10c2-1a33-a49302885bf8-4fbdfd4c,
#w-node-_0f6ef97d-c33e-10c2-1a33-a49302885bfe-4fbdfd4c,
#w-node-_6f01c053-8511-738e-5f67-d3f4461a2c41-4fbdfd4c {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_6f01c053-8511-738e-5f67-d3f4461a2c45-b698ce88 {
  justify-self: end;
}

#w-node-_0f6ef97d-c33e-10c2-1a33-a49302885c09-b698ce88 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_0f6ef97d-c33e-10c2-1a33-a49302885c1c-4fbdfd4c,
#w-node-_0f6ef97d-c33e-10c2-1a33-a49302885c22-4fbdfd4c,
#w-node-e92aaff4-7fd0-6734-c0b4-24178384195f-4fbdfd4c {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-e92aaff4-7fd0-6734-c0b4-241783841963-b698ce88 {
  justify-self: end;
}

#w-node-_0f6ef97d-c33e-10c2-1a33-a49302885c2d-b698ce88 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_0f6ef97d-c33e-10c2-1a33-a49302885c40-4fbdfd4c,
#w-node-_0f6ef97d-c33e-10c2-1a33-a49302885c46-4fbdfd4c,
#w-node-d71b419b-227a-5aa3-3068-976ad5526ee4-4fbdfd4c {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-d71b419b-227a-5aa3-3068-976ad5526ee8-b698ce88 {
  justify-self: end;
}

#w-node-_0f6ef97d-c33e-10c2-1a33-a49302885c51-b698ce88 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_0f6ef97d-c33e-10c2-1a33-a49302885c64-4fbdfd4c,
#w-node-_0f6ef97d-c33e-10c2-1a33-a49302885c6a-4fbdfd4c,
#w-node-_41395ce6-c414-a88f-c809-0d17e852640f-4fbdfd4c {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_41395ce6-c414-a88f-c809-0d17e8526413-b698ce88 {
  justify-self: end;
}

#w-node-_0f6ef97d-c33e-10c2-1a33-a49302885c75-b698ce88 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_0f6ef97d-c33e-10c2-1a33-a49302885c88-4fbdfd4c,
#w-node-_0f6ef97d-c33e-10c2-1a33-a49302885c8e-4fbdfd4c,
#w-node-e18d4cd2-e49e-fe0f-5c3f-9ac78a13ca78-4fbdfd4c {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-e18d4cd2-e49e-fe0f-5c3f-9ac78a13ca7c-b698ce88 {
  justify-self: end;
}

#w-node-_0f6ef97d-c33e-10c2-1a33-a49302885c99-b698ce88 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_0f6ef97d-c33e-10c2-1a33-a49302885cac-4fbdfd4c,
#w-node-_0f6ef97d-c33e-10c2-1a33-a49302885cb2-4fbdfd4c,
#w-node-_7108c041-855e-01a5-c965-925ee487875a-4fbdfd4c {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_7108c041-855e-01a5-c965-925ee487875e-b698ce88 {
  justify-self: end;
}

#w-node-_0f6ef97d-c33e-10c2-1a33-a49302885cbd-b698ce88 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_0f6ef97d-c33e-10c2-1a33-a49302885cd0-4fbdfd4c,
#w-node-_0f6ef97d-c33e-10c2-1a33-a49302885cd6-4fbdfd4c,
#w-node-f2eb2dcd-ac8e-9370-6cfa-6550a14bbc05-4fbdfd4c {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-f2eb2dcd-ac8e-9370-6cfa-6550a14bbc09-b698ce88 {
  justify-self: end;
}

#w-node-_0f6ef97d-c33e-10c2-1a33-a49302885ce6-b698ce88 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_4e9d72f3-8d84-eec9-3099-2a7faaa3af28-b698ce88 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  align-self: start;
}

#w-node-_0f6ef97d-c33e-10c2-1a33-a49302885cf3-4fbdfd4c,
#w-node-_0f6ef97d-c33e-10c2-1a33-a49302885cf5-4fbdfd4c {
  justify-self: end;
}

#w-node-_0f6ef97d-c33e-10c2-1a33-a49302885cf8-b698ce88 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_0f6ef97d-c33e-10c2-1a33-a49302885d05-4fbdfd4c,
#w-node-_0f6ef97d-c33e-10c2-1a33-a49302885d0b-4fbdfd4c {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_0f6ef97d-c33e-10c2-1a33-a49302885d12-b698ce88 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_0f6ef97d-c33e-10c2-1a33-a49302885d1f-4fbdfd4c,
#w-node-_0f6ef97d-c33e-10c2-1a33-a49302885d25-4fbdfd4c {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_0f6ef97d-c33e-10c2-1a33-a49302885d2c-b698ce88 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_0f6ef97d-c33e-10c2-1a33-a49302885d39-4fbdfd4c,
#w-node-_0f6ef97d-c33e-10c2-1a33-a49302885d3f-4fbdfd4c {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_0f6ef97d-c33e-10c2-1a33-a49302885d46-b698ce88 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_0f6ef97d-c33e-10c2-1a33-a49302885d53-4fbdfd4c,
#w-node-_0f6ef97d-c33e-10c2-1a33-a49302885d59-4fbdfd4c {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-b02550b6-a7cd-8ebd-8ac4-46a3abb36b21-b698ce88 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  align-self: start;
}

#w-node-_0f6ef97d-c33e-10c2-1a33-a49302885d64-b698ce88 {
  justify-self: end;
}

#w-node-_0f6ef97d-c33e-10c2-1a33-a49302885d6e-4fbdfd4c,
#w-node-_0f6ef97d-c33e-10c2-1a33-a49302885d70-4fbdfd4c,
#w-node-_0f6ef97d-c33e-10c2-1a33-a49302885d8d-4fbdfd4c,
#w-node-_0f6ef97d-c33e-10c2-1a33-a49302885da0-4fbdfd4c {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_0f6ef97d-c33e-10c2-1a33-a49302885daa-b698ce88 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_0f6ef97d-c33e-10c2-1a33-a49302885dab-b698ce88 {
  justify-self: end;
}

#w-node-_0f6ef97d-c33e-10c2-1a33-a49302885db2-4fbdfd4c,
#w-node-_0f6ef97d-c33e-10c2-1a33-a49302885dc5-4fbdfd4c {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_3b5ba3cf-c942-ae4e-5da2-1204c40205cf-b698ce88 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_3b5ba3cf-c942-ae4e-5da2-1204c40205d3-b698ce88 {
  justify-self: end;
}

#w-node-_0f6ef97d-c33e-10c2-1a33-a49302885dd7-4fbdfd4c,
#w-node-_0f6ef97d-c33e-10c2-1a33-a49302885dea-4fbdfd4c {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_1c96d4fa-118a-77a6-cd65-624c689652c0-b698ce88 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_1c96d4fa-118a-77a6-cd65-624c689652c4-b698ce88 {
  justify-self: end;
}

#w-node-_0f6ef97d-c33e-10c2-1a33-a49302885dfc-4fbdfd4c,
#w-node-_0f6ef97d-c33e-10c2-1a33-a49302885e0f-4fbdfd4c {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_6d6c6b2a-3a22-5eb3-1cc6-7e58af88b4e7-b698ce88 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_6d6c6b2a-3a22-5eb3-1cc6-7e58af88b4eb-b698ce88 {
  justify-self: end;
}

#w-node-_0f6ef97d-c33e-10c2-1a33-a49302885e21-4fbdfd4c,
#w-node-_0f6ef97d-c33e-10c2-1a33-a49302885e34-4fbdfd4c {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-e0c621e2-20a2-fee3-23e4-fef566aecd65-b698ce88 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-e0c621e2-20a2-fee3-23e4-fef566aecd69-4fbdfd4c {
  justify-self: end;
}

#w-node-_41790987-deba-84f2-d7d4-71e16072959c-4fbdfd4d,
#w-node-_41790987-deba-84f2-d7d4-71e16072959e-4fbdfd4d {
  align-self: stretch;
}

#w-node-cd5e2dc9-a3b8-fff9-ea31-7da474aaf9df-4fbdfd4e {
  grid-area: span 1 / span 1 / span 1 / span 1;
  align-self: start;
}

#w-node-_6bbec157-fe4b-5742-146a-734227372e49-4fbdfd4e,
#w-node-_58981037-e47c-48aa-2bad-fe7356a33abb-4fbdfd4e,
#w-node-a40d19ac-b908-ac55-dda5-120f576595d9-4fbdfd4e,
#w-node-eb4dbc8d-5ef9-9396-388c-b13566170997-4fbdfd4e,
#w-node-f3a9d8a5-c870-fcab-518a-5506ff9c203b-4fbdfd4e {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-cd5e2dc9-a3b8-fff9-ea31-7da474aaf9f9-4fbdfd4e {
  grid-area: span 1 / span 1 / span 1 / span 1;
  align-self: start;
}

#w-node-cd5e2dc9-a3b8-fff9-ea31-7da474aaf9fa-4fbdfd4e,
#w-node-cd5e2dc9-a3b8-fff9-ea31-7da474aafa32-4fbdfd4e {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-cd5e2dc9-a3b8-fff9-ea31-7da474aafa38-4fbdfd4e {
  align-self: start;
  justify-self: end;
}

#w-node-cd5e2dc9-a3b8-fff9-ea31-7da474aafa41-4fbdfd4e {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-cd5e2dc9-a3b8-fff9-ea31-7da474aafa47-4fbdfd4e {
  align-self: start;
  justify-self: end;
}

#w-node-cd5e2dc9-a3b8-fff9-ea31-7da474aafa50-4fbdfd4e {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-cd5e2dc9-a3b8-fff9-ea31-7da474aafa56-4fbdfd4e,
#w-node-cd5e2dc9-a3b8-fff9-ea31-7da474aafa66-4fbdfd4e {
  align-self: start;
  justify-self: end;
}

#w-node-cd5e2dc9-a3b8-fff9-ea31-7da474aafa70-4fbdfd4e,
#w-node-cd5e2dc9-a3b8-fff9-ea31-7da474aafa71-4fbdfd4e {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-cd5e2dc9-a3b8-fff9-ea31-7da474aafa7b-4fbdfd4e {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-cd5e2dc9-a3b8-fff9-ea31-7da474aafa88-4fbdfd4e {
  align-self: start;
  justify-self: end;
}

#w-node-cd5e2dc9-a3b8-fff9-ea31-7da474aafa92-4fbdfd4e {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-cd5e2dc9-a3b8-fff9-ea31-7da474aafa9c-4fbdfd4e {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

@media screen and (max-width: 991px) {

  #w-node-da9dd25f-79f9-ecda-8d19-258a891eb39c-4fbdfd37,
  #w-node-_71cf2731-429f-3dff-28e1-36ab93ba945a-4fbdfd37 {
    grid-column: span 1 / span 1;
  }
}

@media screen and (max-width: 767px) {

  #w-node-fecb9bc8-0afa-83bb-a64d-73a33c6910e7-4fbdfc36,
  #w-node-fecb9bc8-0afa-83bb-a64d-73a33c6910ee-4fbdfc36,
  #w-node-fecb9bc8-0afa-83bb-a64d-73a33c6910f5-4fbdfc36,
  #w-node-_73cbd300-2d0d-8106-725d-8db356af75fc-4fbdfc36,
  #w-node-a7c9158e-a0cd-4cde-e1be-2ff68a188c6b-4fbdfc36 {
    justify-self: end;
  }

  #w-node-e2761a19-2604-6ac8-3949-21c9b2079b38-4fbdfcc5,
  #w-node-e2761a19-2604-6ac8-3949-21c9b2079b3e-4fbdfcc5 {
    align-self: center;
  }

  #w-node-e2761a19-2604-6ac8-3949-21c9b2079b40-b698cd67 {
    justify-self: auto;
  }

  #w-node-e2761a19-2604-6ac8-3949-21c9b2079b44-b698cd67 {
    align-self: center;
    justify-self: auto;
  }

  #w-node-e2761a19-2604-6ac8-3949-21c9b2079b46-b698cd67 {
    justify-self: auto;
  }

  #w-node-e2761a19-2604-6ac8-3949-21c9b2079b4a-b698cd67 {
    align-self: center;
    justify-self: auto;
  }

  #w-node-_421e5928-514b-f07f-d056-d45d7cbc8805-b698cd67 {
    justify-self: auto;
  }

  #w-node-_3cca0be1-4dea-d1d3-370f-a49a9e72b8e3-4fbdfcc5,
  #w-node-_3cca0be1-4dea-d1d3-370f-a49a9e72b8e9-4fbdfcc5 {
    align-self: center;
  }

  #w-node-_3cca0be1-4dea-d1d3-370f-a49a9e72b8eb-b698cd67 {
    justify-self: auto;
  }

  #w-node-_3cca0be1-4dea-d1d3-370f-a49a9e72b8ef-b698cd67 {
    align-self: center;
    justify-self: auto;
  }

  #w-node-_3cca0be1-4dea-d1d3-370f-a49a9e72b8f1-b698cd67 {
    justify-self: auto;
  }

  #w-node-_3cca0be1-4dea-d1d3-370f-a49a9e72b8f5-b698cd67 {
    align-self: center;
    justify-self: auto;
  }

  #w-node-_633ab21d-09ed-ca88-050f-3671804c76a2-b698cd67 {
    justify-self: auto;
  }

  #w-node-_4c2dbcba-b5d3-5379-cc06-c89a53d7d82d-4fbdfcc5,
  #w-node-_4c2dbcba-b5d3-5379-cc06-c89a53d7d833-4fbdfcc5 {
    align-self: center;
  }

  #w-node-_4c2dbcba-b5d3-5379-cc06-c89a53d7d835-b698cd67 {
    justify-self: auto;
  }

  #w-node-_4c2dbcba-b5d3-5379-cc06-c89a53d7d839-b698cd67 {
    align-self: center;
    justify-self: auto;
  }

  #w-node-_4c2dbcba-b5d3-5379-cc06-c89a53d7d83b-b698cd67 {
    justify-self: auto;
  }

  #w-node-_4c2dbcba-b5d3-5379-cc06-c89a53d7d83f-b698cd67 {
    align-self: center;
    justify-self: auto;
  }

  #w-node-_2c30a158-1e42-82d9-1af8-48d9077b18b4-b698cd67 {
    justify-self: auto;
  }

  #w-node-_70040483-8dcc-7578-abc0-ea60672b89b4-4fbdfcc5,
  #w-node-_70040483-8dcc-7578-abc0-ea60672b89ba-4fbdfcc5 {
    align-self: center;
  }

  #w-node-_70040483-8dcc-7578-abc0-ea60672b89bc-b698cd67 {
    justify-self: auto;
  }

  #w-node-_70040483-8dcc-7578-abc0-ea60672b89c0-b698cd67 {
    align-self: center;
    justify-self: auto;
  }

  #w-node-_70040483-8dcc-7578-abc0-ea60672b89c2-b698cd67 {
    justify-self: auto;
  }

  #w-node-_70040483-8dcc-7578-abc0-ea60672b89c6-b698cd67 {
    align-self: center;
    justify-self: auto;
  }

  #w-node-_0694500f-bedc-5788-fb90-d9dcfd366480-b698cd67 {
    justify-self: auto;
  }

  #w-node-_563e143e-842a-8d3a-6f7e-83c846816efd-4fbdfcc5,
  #w-node-_563e143e-842a-8d3a-6f7e-83c846816f03-4fbdfcc5 {
    align-self: center;
  }

  #w-node-_563e143e-842a-8d3a-6f7e-83c846816f05-b698cd67 {
    justify-self: auto;
  }

  #w-node-_563e143e-842a-8d3a-6f7e-83c846816f09-b698cd67 {
    align-self: center;
    justify-self: auto;
  }

  #w-node-_563e143e-842a-8d3a-6f7e-83c846816f0b-b698cd67 {
    justify-self: auto;
  }

  #w-node-_563e143e-842a-8d3a-6f7e-83c846816f0f-b698cd67 {
    align-self: center;
    justify-self: auto;
  }

  #w-node-ef5f171f-e864-6601-0c09-272cc35a8831-b698cd67 {
    justify-self: auto;
  }

  #w-node-_7dde2507-75b0-95b7-0cc2-73e83f16eb16-4fbdfcc5,
  #w-node-_7dde2507-75b0-95b7-0cc2-73e83f16eb1c-4fbdfcc5 {
    align-self: center;
  }

  #w-node-_7dde2507-75b0-95b7-0cc2-73e83f16eb1e-b698cd67 {
    justify-self: auto;
  }

  #w-node-_7dde2507-75b0-95b7-0cc2-73e83f16eb22-b698cd67 {
    align-self: center;
    justify-self: auto;
  }

  #w-node-_7dde2507-75b0-95b7-0cc2-73e83f16eb24-b698cd67 {
    justify-self: auto;
  }

  #w-node-_7dde2507-75b0-95b7-0cc2-73e83f16eb28-b698cd67 {
    align-self: center;
    justify-self: auto;
  }

  #w-node-_2c7f0667-ac3a-cc57-f045-61e2b49818c0-b698cd67 {
    justify-self: auto;
  }

  #w-node-_5507ce02-06d4-7b40-863d-6b243efe3cf9-4fbdfcc5,
  #w-node-_5507ce02-06d4-7b40-863d-6b243efe3cff-4fbdfcc5 {
    align-self: center;
  }

  #w-node-_5507ce02-06d4-7b40-863d-6b243efe3d01-b698cd67 {
    justify-self: auto;
  }

  #w-node-_5507ce02-06d4-7b40-863d-6b243efe3d05-b698cd67 {
    align-self: center;
    justify-self: auto;
  }

  #w-node-_5507ce02-06d4-7b40-863d-6b243efe3d07-b698cd67 {
    justify-self: auto;
  }

  #w-node-_5507ce02-06d4-7b40-863d-6b243efe3d0b-b698cd67 {
    align-self: center;
    justify-self: auto;
  }

  #w-node-_1a3ba9e1-b2d9-8369-c138-3f86fed035ba-b698cd67 {
    justify-self: auto;
  }

  #w-node-_6d571408-313b-0bca-3723-f995e1a83d7b-4fbdfcc5,
  #w-node-_6d571408-313b-0bca-3723-f995e1a83d81-4fbdfcc5 {
    align-self: center;
  }

  #w-node-_6d571408-313b-0bca-3723-f995e1a83d83-b698cd67 {
    justify-self: auto;
  }

  #w-node-_6d571408-313b-0bca-3723-f995e1a83d87-b698cd67 {
    align-self: center;
    justify-self: auto;
  }

  #w-node-_6d571408-313b-0bca-3723-f995e1a83d89-b698cd67 {
    justify-self: auto;
  }

  #w-node-_6d571408-313b-0bca-3723-f995e1a83d8d-b698cd67 {
    align-self: center;
    justify-self: auto;
  }

  #w-node-_2e3f6734-55a0-d8bb-6041-afad76ce26b7-b698cd67 {
    justify-self: auto;
  }

  #w-node-_5119e738-b6ac-f640-b693-9443f2da8360-4fbdfcc5,
  #w-node-_5119e738-b6ac-f640-b693-9443f2da8366-4fbdfcc5 {
    align-self: center;
  }

  #w-node-_5119e738-b6ac-f640-b693-9443f2da8368-b698cd67 {
    justify-self: auto;
  }

  #w-node-_5119e738-b6ac-f640-b693-9443f2da836c-b698cd67 {
    align-self: center;
    justify-self: auto;
  }

  #w-node-_5119e738-b6ac-f640-b693-9443f2da836e-b698cd67 {
    justify-self: auto;
  }

  #w-node-_5119e738-b6ac-f640-b693-9443f2da8372-b698cd67 {
    align-self: center;
    justify-self: auto;
  }

  #w-node-_8e7fa301-3301-8a2c-acc7-ce130173c98b-b698cd67 {
    justify-self: auto;
  }

  #w-node-f268be9e-4baf-3b74-a8d6-20075cc720ef-4fbdfcc5,
  #w-node-f268be9e-4baf-3b74-a8d6-20075cc720f5-4fbdfcc5 {
    align-self: center;
  }

  #w-node-f268be9e-4baf-3b74-a8d6-20075cc720f7-b698cd67 {
    justify-self: auto;
  }

  #w-node-f268be9e-4baf-3b74-a8d6-20075cc720fb-b698cd67 {
    align-self: center;
    justify-self: auto;
  }

  #w-node-f268be9e-4baf-3b74-a8d6-20075cc720fd-b698cd67 {
    justify-self: auto;
  }

  #w-node-f268be9e-4baf-3b74-a8d6-20075cc72101-b698cd67 {
    align-self: center;
    justify-self: auto;
  }

  #w-node-cd31577e-f2af-2685-d7f5-217b8836b3b4-b698cd67 {
    justify-self: auto;
  }

  #w-node-e3533208-256f-8015-a4d9-1340a0713630-4fbdfcc9,
  #w-node-_34257bcb-659f-c72e-9b64-54006c55cf4d-4fbdfcc9 {
    align-self: center;
  }

  #w-node-_34257bcb-659f-c72e-9b64-54006c55cf4f-b698cd6b {
    justify-self: auto;
  }

  #w-node-_34257bcb-659f-c72e-9b64-54006c55cf54-b698cd6b {
    align-self: center;
    justify-self: auto;
  }

  #w-node-_34257bcb-659f-c72e-9b64-54006c55cf56-b698cd6b {
    justify-self: auto;
  }

  #w-node-_34257bcb-659f-c72e-9b64-54006c55cf5b-b698cd6b {
    align-self: center;
    justify-self: auto;
  }

  #w-node-_34257bcb-659f-c72e-9b64-54006c55cf65-b698cd6b {
    justify-self: auto;
  }

  #w-node-_69e9c055-99b3-10bc-97bb-b1cd35e334e1-4fbdfcc9,
  #w-node-_69e9c055-99b3-10bc-97bb-b1cd35e334e7-4fbdfcc9 {
    align-self: center;
  }

  #w-node-_69e9c055-99b3-10bc-97bb-b1cd35e334e9-b698cd6b {
    justify-self: auto;
  }

  #w-node-_69e9c055-99b3-10bc-97bb-b1cd35e334ed-b698cd6b {
    align-self: center;
    justify-self: auto;
  }

  #w-node-_69e9c055-99b3-10bc-97bb-b1cd35e334ef-b698cd6b {
    justify-self: auto;
  }

  #w-node-_69e9c055-99b3-10bc-97bb-b1cd35e334f3-b698cd6b {
    align-self: center;
    justify-self: auto;
  }

  #w-node-_71a9cff6-1adb-70e5-372a-f44acd039928-b698cd6b {
    justify-self: auto;
  }

  #w-node-_930cfbac-fa8d-cae2-ed15-049c78fae16d-4fbdfcc9,
  #w-node-_930cfbac-fa8d-cae2-ed15-049c78fae173-4fbdfcc9 {
    align-self: center;
  }

  #w-node-_930cfbac-fa8d-cae2-ed15-049c78fae175-b698cd6b {
    justify-self: auto;
  }

  #w-node-_930cfbac-fa8d-cae2-ed15-049c78fae179-b698cd6b {
    align-self: center;
    justify-self: auto;
  }

  #w-node-_930cfbac-fa8d-cae2-ed15-049c78fae17b-b698cd6b {
    justify-self: auto;
  }

  #w-node-_930cfbac-fa8d-cae2-ed15-049c78fae17f-b698cd6b {
    align-self: center;
    justify-self: auto;
  }

  #w-node-_90e3e44a-b21b-081a-92f9-74a0015cbf88-b698cd6b {
    justify-self: auto;
  }

  #w-node-_05c3a6a4-51ba-3b74-39c4-eed43ea4ee83-4fbdfcc9,
  #w-node-_05c3a6a4-51ba-3b74-39c4-eed43ea4ee89-4fbdfcc9 {
    align-self: center;
  }

  #w-node-_05c3a6a4-51ba-3b74-39c4-eed43ea4ee8b-b698cd6b {
    justify-self: auto;
  }

  #w-node-_05c3a6a4-51ba-3b74-39c4-eed43ea4ee8f-b698cd6b {
    align-self: center;
    justify-self: auto;
  }

  #w-node-_05c3a6a4-51ba-3b74-39c4-eed43ea4ee91-b698cd6b {
    justify-self: auto;
  }

  #w-node-_05c3a6a4-51ba-3b74-39c4-eed43ea4ee95-b698cd6b {
    align-self: center;
    justify-self: auto;
  }

  #w-node-c8a0730a-fc52-38b7-837f-90861aa6cb84-b698cd6b {
    justify-self: auto;
  }

  #w-node-_7984efe6-1fa3-c8ca-da17-7378b804cad4-4fbdfcc9,
  #w-node-_7984efe6-1fa3-c8ca-da17-7378b804cada-4fbdfcc9 {
    align-self: center;
  }

  #w-node-_7984efe6-1fa3-c8ca-da17-7378b804cadc-b698cd6b {
    justify-self: auto;
  }

  #w-node-_7984efe6-1fa3-c8ca-da17-7378b804cae0-b698cd6b {
    align-self: center;
    justify-self: auto;
  }

  #w-node-_7984efe6-1fa3-c8ca-da17-7378b804cae2-b698cd6b {
    justify-self: auto;
  }

  #w-node-_7984efe6-1fa3-c8ca-da17-7378b804cae6-b698cd6b {
    align-self: center;
    justify-self: auto;
  }

  #w-node-_17432bcd-1dc2-3ab9-a5c7-68baec0b2825-b698cd6b {
    justify-self: auto;
  }

  #w-node-_2cf6b632-794d-d98b-932c-fc4287d2ce2d-4fbdfcc9,
  #w-node-_2cf6b632-794d-d98b-932c-fc4287d2ce33-4fbdfcc9 {
    align-self: center;
  }

  #w-node-_2cf6b632-794d-d98b-932c-fc4287d2ce35-b698cd6b {
    justify-self: auto;
  }

  #w-node-_2cf6b632-794d-d98b-932c-fc4287d2ce39-b698cd6b {
    align-self: center;
    justify-self: auto;
  }

  #w-node-_2cf6b632-794d-d98b-932c-fc4287d2ce3b-b698cd6b {
    justify-self: auto;
  }

  #w-node-_2cf6b632-794d-d98b-932c-fc4287d2ce3f-b698cd6b {
    align-self: center;
    justify-self: auto;
  }

  #w-node-_5df39431-f2e0-f931-5350-f3bf580b8ee1-b698cd6b {
    justify-self: auto;
  }

  #w-node-_144e30ca-dbbb-f633-cb4f-59c7bb8fc43c-4fbdfcc9,
  #w-node-_144e30ca-dbbb-f633-cb4f-59c7bb8fc442-4fbdfcc9 {
    align-self: center;
  }

  #w-node-_144e30ca-dbbb-f633-cb4f-59c7bb8fc444-b698cd6b {
    justify-self: auto;
  }

  #w-node-_144e30ca-dbbb-f633-cb4f-59c7bb8fc448-b698cd6b {
    align-self: center;
    justify-self: auto;
  }

  #w-node-_144e30ca-dbbb-f633-cb4f-59c7bb8fc44a-b698cd6b {
    justify-self: auto;
  }

  #w-node-_144e30ca-dbbb-f633-cb4f-59c7bb8fc44e-b698cd6b {
    align-self: center;
    justify-self: auto;
  }

  #w-node-bb85620d-8c31-2dbc-d678-5abd1d3e3a5b-b698cd6b {
    justify-self: auto;
  }

  #w-node-_21b03758-6ef8-a252-c2d4-23285e87153f-4fbdfcc9,
  #w-node-_21b03758-6ef8-a252-c2d4-23285e871545-4fbdfcc9 {
    align-self: center;
  }

  #w-node-_21b03758-6ef8-a252-c2d4-23285e871547-b698cd6b {
    justify-self: auto;
  }

  #w-node-_21b03758-6ef8-a252-c2d4-23285e87154b-b698cd6b {
    align-self: center;
    justify-self: auto;
  }

  #w-node-_21b03758-6ef8-a252-c2d4-23285e87154d-b698cd6b {
    justify-self: auto;
  }

  #w-node-_21b03758-6ef8-a252-c2d4-23285e871551-b698cd6b {
    align-self: center;
    justify-self: auto;
  }

  #w-node-a36c4e69-1366-6647-419b-d649ba3ff0f2-b698cd6b {
    justify-self: auto;
  }

  #w-node-_4d081466-47da-4b31-f041-1b78a39ba68a-4fbdfcc9,
  #w-node-_4d081466-47da-4b31-f041-1b78a39ba690-4fbdfcc9 {
    align-self: center;
  }

  #w-node-_4d081466-47da-4b31-f041-1b78a39ba692-b698cd6b {
    justify-self: auto;
  }

  #w-node-_4d081466-47da-4b31-f041-1b78a39ba696-b698cd6b {
    align-self: center;
    justify-self: auto;
  }

  #w-node-_4d081466-47da-4b31-f041-1b78a39ba698-b698cd6b {
    justify-self: auto;
  }

  #w-node-_4d081466-47da-4b31-f041-1b78a39ba69c-b698cd6b {
    align-self: center;
    justify-self: auto;
  }

  #w-node-ec46a5bf-a8f1-5878-75ca-6652b80c1e58-b698cd6b {
    justify-self: auto;
  }

  #w-node-dbf632a3-a813-b733-8570-65bee07cb518-4fbdfcc9,
  #w-node-dbf632a3-a813-b733-8570-65bee07cb51e-4fbdfcc9 {
    align-self: center;
  }

  #w-node-dbf632a3-a813-b733-8570-65bee07cb520-b698cd6b {
    justify-self: auto;
  }

  #w-node-dbf632a3-a813-b733-8570-65bee07cb524-b698cd6b {
    align-self: center;
    justify-self: auto;
  }

  #w-node-dbf632a3-a813-b733-8570-65bee07cb526-b698cd6b {
    justify-self: auto;
  }

  #w-node-dbf632a3-a813-b733-8570-65bee07cb52a-b698cd6b {
    align-self: center;
    justify-self: auto;
  }

  #w-node-_11577d1b-a794-5062-56bf-61f5b2ee1e02-b698cd6b {
    justify-self: auto;
  }

  #w-node-_1e4d1eee-334f-bf7e-3508-e00622a152bc-b698cd6e {
    align-self: center;
  }

  #w-node-_1e4d1eee-334f-bf7e-3508-e00622a152be-b698cd6e {
    justify-self: auto;
  }

  #w-node-_1e4d1eee-334f-bf7e-3508-e00622a152c2-b698cd6e {
    align-self: center;
  }

  #w-node-_1e4d1eee-334f-bf7e-3508-e00622a152c7-b698cd6e {
    justify-self: auto;
  }

  #w-node-_1e4d1eee-334f-bf7e-3508-e00622a152cb-b698cd6e {
    align-self: center;
    justify-self: start;
  }

  #w-node-_1e4d1eee-334f-bf7e-3508-e00622a152cd-b698cd6e {
    justify-self: auto;
  }

  #w-node-_1e4d1eee-334f-bf7e-3508-e00622a152d1-b698cd6e {
    align-self: center;
    justify-self: start;
  }

  #w-node-a37e5fe0-3d55-57f3-6e59-78275ab8e6b3-b698cd6e {
    align-self: center;
  }

  #w-node-a37e5fe0-3d55-57f3-6e59-78275ab8e6b5-b698cd6e {
    justify-self: auto;
  }

  #w-node-a37e5fe0-3d55-57f3-6e59-78275ab8e6b9-b698cd6e {
    align-self: center;
  }

  #w-node-a37e5fe0-3d55-57f3-6e59-78275ab8e6be-b698cd6e {
    justify-self: auto;
  }

  #w-node-a37e5fe0-3d55-57f3-6e59-78275ab8e6c2-b698cd6e {
    align-self: center;
    justify-self: start;
  }

  #w-node-a37e5fe0-3d55-57f3-6e59-78275ab8e6c4-b698cd6e {
    justify-self: auto;
  }

  #w-node-a37e5fe0-3d55-57f3-6e59-78275ab8e6c8-b698cd6e {
    align-self: center;
    justify-self: start;
  }

  #w-node-_76005460-0363-6817-6362-2277b4fdcd14-b698cd6e {
    align-self: center;
  }

  #w-node-_76005460-0363-6817-6362-2277b4fdcd16-b698cd6e {
    justify-self: auto;
  }

  #w-node-_76005460-0363-6817-6362-2277b4fdcd1a-b698cd6e {
    align-self: center;
  }

  #w-node-_76005460-0363-6817-6362-2277b4fdcd1f-b698cd6e {
    justify-self: auto;
  }

  #w-node-_76005460-0363-6817-6362-2277b4fdcd23-b698cd6e {
    align-self: center;
    justify-self: start;
  }

  #w-node-_76005460-0363-6817-6362-2277b4fdcd25-b698cd6e {
    justify-self: auto;
  }

  #w-node-_76005460-0363-6817-6362-2277b4fdcd29-b698cd6e {
    align-self: center;
    justify-self: start;
  }

  #w-node-_8a1cc60a-e4d7-ca0e-c57e-4b5825ab24d4-b698cd6e {
    align-self: center;
  }

  #w-node-_8a1cc60a-e4d7-ca0e-c57e-4b5825ab24d6-b698cd6e {
    justify-self: auto;
  }

  #w-node-cc86f621-7253-77f3-2121-ad226000c3b0-b698cd6e {
    align-self: center;
  }

  #w-node-_8a1cc60a-e4d7-ca0e-c57e-4b5825ab24dc-b698cd6e {
    justify-self: auto;
  }

  #w-node-_8a1cc60a-e4d7-ca0e-c57e-4b5825ab24e0-b698cd6e {
    align-self: center;
    justify-self: start;
  }

  #w-node-_31f1e8d8-f5df-c664-de23-7ebb36aae9a1-b698cd6e {
    justify-self: auto;
  }

  #w-node-_31f1e8d8-f5df-c664-de23-7ebb36aae9a5-b698cd6e {
    align-self: center;
    justify-self: start;
  }

  #w-node-bf76143f-c5d2-81db-b861-7e82608375d9-b698cd6e {
    align-self: center;
  }

  #w-node-bf76143f-c5d2-81db-b861-7e82608375db-b698cd6e {
    justify-self: auto;
  }

  #w-node-bf76143f-c5d2-81db-b861-7e82608375df-b698cd6e {
    align-self: center;
  }

  #w-node-bf76143f-c5d2-81db-b861-7e82608375e4-b698cd6e {
    justify-self: auto;
  }

  #w-node-bf76143f-c5d2-81db-b861-7e82608375e8-b698cd6e {
    align-self: center;
    justify-self: start;
  }

  #w-node-bf76143f-c5d2-81db-b861-7e82608375ea-b698cd6e {
    justify-self: auto;
  }

  #w-node-bf76143f-c5d2-81db-b861-7e82608375ee-b698cd6e {
    align-self: center;
    justify-self: start;
  }

  #w-node-_9052c7b4-f522-a908-d55e-fef77efa493d-b698cd6e {
    align-self: center;
  }

  #w-node-_9052c7b4-f522-a908-d55e-fef77efa493f-b698cd6e {
    justify-self: auto;
  }

  #w-node-_9052c7b4-f522-a908-d55e-fef77efa4943-b698cd6e {
    align-self: center;
  }

  #w-node-_9052c7b4-f522-a908-d55e-fef77efa4948-b698cd6e {
    justify-self: auto;
  }

  #w-node-_9052c7b4-f522-a908-d55e-fef77efa494c-b698cd6e {
    align-self: center;
    justify-self: start;
  }

  #w-node-_9052c7b4-f522-a908-d55e-fef77efa494e-b698cd6e {
    justify-self: auto;
  }

  #w-node-_9052c7b4-f522-a908-d55e-fef77efa4952-b698cd6e {
    align-self: center;
    justify-self: start;
  }

  #w-node-_30dfb1ee-0c82-d829-fd4e-de30b7e5612e-b698cd6e {
    align-self: center;
  }

  #w-node-_30dfb1ee-0c82-d829-fd4e-de30b7e56130-b698cd6e {
    justify-self: auto;
  }

  #w-node-_30dfb1ee-0c82-d829-fd4e-de30b7e56134-b698cd6e {
    align-self: center;
  }

  #w-node-_30dfb1ee-0c82-d829-fd4e-de30b7e56139-b698cd6e {
    justify-self: auto;
  }

  #w-node-_30dfb1ee-0c82-d829-fd4e-de30b7e5613d-b698cd6e {
    align-self: center;
    justify-self: start;
  }

  #w-node-_30dfb1ee-0c82-d829-fd4e-de30b7e5613f-b698cd6e {
    justify-self: auto;
  }

  #w-node-_30dfb1ee-0c82-d829-fd4e-de30b7e56143-b698cd6e {
    align-self: center;
    justify-self: start;
  }

  #w-node-_1735d2e5-0037-a242-8b0c-ca7d39fd0bac-4fbdfccc,
  #w-node-_5dfd40a1-26ca-159f-dd05-0af1f8080eda-4fbdfccc,
  #w-node-_6d5cb390-b42a-f538-899c-3d0f293d9063-4fbdfccc,
  #w-node-_9b893dad-2654-badd-e30f-685de07ed964-4fbdfccc {
    align-self: center;
  }

  #w-node-_932d2677-0544-0771-3519-962dbc05f149-b698cddd {
    justify-self: auto;
  }

  #w-node-a40e56b0-ea5c-5fbb-2434-f224cd379445-4fbdfd03,
  #w-node-a40e56b0-ea5c-5fbb-2434-f224cd37944b-4fbdfd03 {
    align-self: center;
  }

  #w-node-a40e56b0-ea5c-5fbb-2434-f224cd37944d-b698cddd {
    justify-self: auto;
  }

  #w-node-a40e56b0-ea5c-5fbb-2434-f224cd379451-b698cddd {
    align-self: center;
    justify-self: auto;
  }

  #w-node-a40e56b0-ea5c-5fbb-2434-f224cd379454-b698cddd {
    justify-self: auto;
  }

  #w-node-_694f3f70-19c5-1b7b-827a-6cea25fe364e-4fbdfd03,
  #w-node-_694f3f70-19c5-1b7b-827a-6cea25fe3654-4fbdfd03 {
    align-self: center;
  }

  #w-node-_694f3f70-19c5-1b7b-827a-6cea25fe3656-b698cddd {
    justify-self: auto;
  }

  #w-node-_694f3f70-19c5-1b7b-827a-6cea25fe365a-b698cddd {
    align-self: center;
    justify-self: auto;
  }

  #w-node-a61542d2-58a0-9095-449b-3ba12e3a4297-b698cddd {
    justify-self: auto;
  }

  #w-node-_99d10040-0cc7-aaed-b1ba-fecf497b0b55-4fbdfd03,
  #w-node-_99d10040-0cc7-aaed-b1ba-fecf497b0b5b-4fbdfd03 {
    align-self: center;
  }

  #w-node-_99d10040-0cc7-aaed-b1ba-fecf497b0b5d-b698cddd {
    justify-self: auto;
  }

  #w-node-_99d10040-0cc7-aaed-b1ba-fecf497b0b61-b698cddd {
    align-self: center;
    justify-self: auto;
  }

  #w-node-_157c5205-d585-6a06-b3c2-df4a0b325475-b698cddd {
    justify-self: auto;
  }

  #w-node-_83b5fd92-6cc3-c5af-3863-d92a231ae6e8-4fbdfd03,
  #w-node-_83b5fd92-6cc3-c5af-3863-d92a231ae6ee-4fbdfd03 {
    align-self: center;
  }

  #w-node-_83b5fd92-6cc3-c5af-3863-d92a231ae6f0-b698cddd {
    justify-self: auto;
  }

  #w-node-_83b5fd92-6cc3-c5af-3863-d92a231ae6f4-b698cddd {
    align-self: center;
    justify-self: auto;
  }

  #w-node-_46d6ad6a-c7af-f54f-ebfb-f670dbe3f5fe-b698cddd {
    justify-self: auto;
  }

  #w-node-_6adf1ad2-cae6-fc05-fa13-ff78c421e6e1-4fbdfd03,
  #w-node-_6adf1ad2-cae6-fc05-fa13-ff78c421e6e7-4fbdfd03 {
    align-self: center;
  }

  #w-node-_6adf1ad2-cae6-fc05-fa13-ff78c421e6e9-b698cddd {
    justify-self: auto;
  }

  #w-node-_6adf1ad2-cae6-fc05-fa13-ff78c421e6ed-b698cddd {
    align-self: center;
    justify-self: auto;
  }

  #w-node-_0ad46cfd-4e45-ff2a-0faf-97d0642a7ab4-b698cddd {
    justify-self: auto;
  }

  #w-node-_3b11266e-5f61-326c-d1a0-1176334534e8-4fbdfd03,
  #w-node-_3b11266e-5f61-326c-d1a0-1176334534ee-4fbdfd03 {
    align-self: center;
  }

  #w-node-_3b11266e-5f61-326c-d1a0-1176334534f0-b698cddd {
    justify-self: auto;
  }

  #w-node-_3b11266e-5f61-326c-d1a0-1176334534f4-b698cddd {
    align-self: center;
    justify-self: auto;
  }

  #w-node-ed65369f-c0bd-5ae8-5153-7e2364f93d46-b698cddd {
    justify-self: auto;
  }

  #w-node-_51895925-f773-ae4f-9461-09b062f32e8f-4fbdfd03,
  #w-node-_51895925-f773-ae4f-9461-09b062f32e95-4fbdfd03 {
    align-self: center;
  }

  #w-node-_51895925-f773-ae4f-9461-09b062f32e97-b698cddd {
    justify-self: auto;
  }

  #w-node-_51895925-f773-ae4f-9461-09b062f32e9b-b698cddd {
    align-self: center;
    justify-self: auto;
  }

  #w-node-_02334b72-2943-ab8b-c77d-65a88d3b5135-b698cddd {
    justify-self: auto;
  }

  #w-node-e601ae55-56c7-94ff-aead-0be2c43af9bc-4fbdfd0e,
  #w-node-_208086f4-d013-9d24-6671-17c95bf54676-4fbdfd0e,
  #w-node-_208086f4-d013-9d24-6671-17c95bf5467c-4fbdfd0e {
    align-self: center;
  }

  #w-node-_208086f4-d013-9d24-6671-17c95bf5467e-b698cde8 {
    justify-self: auto;
  }

  #w-node-_208086f4-d013-9d24-6671-17c95bf54682-b698cde8 {
    align-self: center;
    justify-self: auto;
  }

  #w-node-_8a9bedef-4ac7-b192-0e23-8dd76c12b37f-4fbdfd0e,
  #w-node-_8a9bedef-4ac7-b192-0e23-8dd76c12b386-4fbdfd0e,
  #w-node-_8a9bedef-4ac7-b192-0e23-8dd76c12b38d-4fbdfd0e {
    align-self: center;
  }

  #w-node-_8a9bedef-4ac7-b192-0e23-8dd76c12b38f-b698cde8 {
    justify-self: auto;
  }

  #w-node-_8a9bedef-4ac7-b192-0e23-8dd76c12b394-b698cde8 {
    align-self: center;
    justify-self: auto;
  }

  #w-node-ec383a66-a098-7d96-93ab-aa0265f93f8e-4fbdfd0e,
  #w-node-ec383a66-a098-7d96-93ab-aa0265f93f95-4fbdfd0e,
  #w-node-ec383a66-a098-7d96-93ab-aa0265f93f9c-4fbdfd0e {
    align-self: center;
  }

  #w-node-ec383a66-a098-7d96-93ab-aa0265f93f9e-b698cde8 {
    justify-self: auto;
  }

  #w-node-ec383a66-a098-7d96-93ab-aa0265f93fa3-b698cde8 {
    align-self: center;
    justify-self: auto;
  }

  #w-node-_57b2ae6a-3613-1ee6-6214-1e67ae32b668-4fbdfd24,
  #w-node-_57b2ae6a-3613-1ee6-6214-1e67ae32b66e-4fbdfd24 {
    align-self: center;
  }

  #w-node-_57b2ae6a-3613-1ee6-6214-1e67ae32b670-b698cdec {
    justify-self: auto;
  }

  #w-node-_57b2ae6a-3613-1ee6-6214-1e67ae32b674-b698cdec {
    align-self: center;
    justify-self: auto;
  }

  #w-node-_57b2ae6a-3613-1ee6-6214-1e67ae32b67d-b698cdec {
    justify-self: auto;
  }

  #w-node-_38424513-3fde-6e39-c516-0c46493bfedd-4fbdfd24,
  #w-node-_38424513-3fde-6e39-c516-0c46493bfee3-4fbdfd24 {
    align-self: center;
  }

  #w-node-_38424513-3fde-6e39-c516-0c46493bfee5-b698cdec {
    justify-self: auto;
  }

  #w-node-_38424513-3fde-6e39-c516-0c46493bfee9-b698cdec {
    align-self: center;
    justify-self: auto;
  }

  #w-node-dd4f0576-7ecd-3589-54df-8858977b7902-b698cdec {
    justify-self: auto;
  }

  #w-node-f093319b-d3f6-4526-c96b-66a8efa5cdfa-4fbdfd24,
  #w-node-f093319b-d3f6-4526-c96b-66a8efa5ce00-4fbdfd24 {
    align-self: center;
  }

  #w-node-f093319b-d3f6-4526-c96b-66a8efa5ce02-b698cdec {
    justify-self: auto;
  }

  #w-node-f093319b-d3f6-4526-c96b-66a8efa5ce06-b698cdec {
    align-self: center;
    justify-self: auto;
  }

  #w-node-a7bc03cc-eff2-a8cb-f716-99d5f795bc42-b698cdec {
    justify-self: auto;
  }

  #w-node-df18a4f7-a5e5-1c49-45f8-2df9b138f7a3-4fbdfd24,
  #w-node-df18a4f7-a5e5-1c49-45f8-2df9b138f7a9-4fbdfd24 {
    align-self: center;
  }

  #w-node-df18a4f7-a5e5-1c49-45f8-2df9b138f7ab-b698cdec {
    justify-self: auto;
  }

  #w-node-df18a4f7-a5e5-1c49-45f8-2df9b138f7af-b698cdec {
    align-self: center;
    justify-self: auto;
  }

  #w-node-a67f04be-df02-1478-7710-7bcf0cbbd22a-b698cdec {
    justify-self: auto;
  }

  #w-node-b94f1e31-e705-fa5c-38bc-dfc63f28c73a-4fbdfd24,
  #w-node-b94f1e31-e705-fa5c-38bc-dfc63f28c740-4fbdfd24 {
    align-self: center;
  }

  #w-node-b94f1e31-e705-fa5c-38bc-dfc63f28c742-b698cdec {
    justify-self: auto;
  }

  #w-node-b94f1e31-e705-fa5c-38bc-dfc63f28c746-b698cdec {
    align-self: center;
    justify-self: auto;
  }

  #w-node-_33d63660-796d-91f2-68f8-00b1e824b174-b698cdec {
    justify-self: auto;
  }

  #w-node-_9b546f70-0d51-8f05-590a-418495b77715-4fbdfd24,
  #w-node-_9b546f70-0d51-8f05-590a-418495b7771b-4fbdfd24 {
    align-self: center;
  }

  #w-node-_9b546f70-0d51-8f05-590a-418495b7771d-b698cdec {
    justify-self: auto;
  }

  #w-node-_9b546f70-0d51-8f05-590a-418495b77721-b698cdec {
    align-self: center;
    justify-self: auto;
  }

  #w-node-dfbd6785-b7cb-dc1a-1daa-42d34cbe0b68-b698cdec {
    justify-self: auto;
  }

  #w-node-_1120177b-9949-7226-69a6-635039853028-4fbdfd24,
  #w-node-_1120177b-9949-7226-69a6-63503985302e-4fbdfd24 {
    align-self: center;
  }

  #w-node-_1120177b-9949-7226-69a6-635039853030-b698cdec {
    justify-self: auto;
  }

  #w-node-_1120177b-9949-7226-69a6-635039853034-b698cdec {
    align-self: center;
    justify-self: auto;
  }

  #w-node-_404c8fd2-39a4-dbf6-20a6-8180dd18e088-b698cdec {
    justify-self: auto;
  }

  #w-node-acdc6c13-80f1-b621-3152-f885c1be082c-4fbdfd24,
  #w-node-acdc6c13-80f1-b621-3152-f885c1be0832-4fbdfd24 {
    align-self: center;
  }

  #w-node-acdc6c13-80f1-b621-3152-f885c1be0834-b698cdec {
    justify-self: auto;
  }

  #w-node-acdc6c13-80f1-b621-3152-f885c1be0838-b698cdec {
    align-self: center;
    justify-self: auto;
  }

  #w-node-_238484cb-d624-8114-8b91-3529fb0318c5-b698cdec {
    justify-self: auto;
  }

  #w-node-df73bb82-7f80-339e-4517-c39437f5310d-b698ce33 {
    align-self: center;
  }

  #w-node-_763591cc-a75a-e92c-f040-4c51ded8db02-b698ce33 {
    justify-self: auto;
  }

  #w-node-_2bedb7a5-d7fb-fad9-4f34-78846b698ae6-b698ce33 {
    align-self: center;
    justify-self: auto;
  }

  #w-node-d8b47277-6b87-2d11-0844-086c50294eb3-b698ce33 {
    justify-self: auto;
  }

  #w-node-ab23bf48-2948-43c0-1f46-14c396e5aa6c-b698ce33 {
    align-self: center;
    justify-self: auto;
  }

  #w-node-_7bf84708-9216-e02d-5ccb-f8015425bc0d-b698ce33 {
    justify-self: auto;
  }

  #w-node-_58412eb8-f67b-0f5f-ffa4-e96f2819d293-b698ce33 {
    align-self: center;
    justify-self: auto;
  }

  #w-node-_89172ff6-3f41-d8cc-5d29-6d9d1b8ace94-b698ce33 {
    justify-self: auto;
  }

  #w-node-_805cc7d9-80f6-7021-7f33-016eb8cd1cc0-b698ce33 {
    align-self: center;
  }

  #w-node-_805cc7d9-80f6-7021-7f33-016eb8cd1cc2-b698ce33 {
    justify-self: auto;
  }

  #w-node-_805cc7d9-80f6-7021-7f33-016eb8cd1cc6-b698ce33 {
    align-self: center;
    justify-self: auto;
  }

  #w-node-_805cc7d9-80f6-7021-7f33-016eb8cd1cc8-b698ce33 {
    justify-self: auto;
  }

  #w-node-_805cc7d9-80f6-7021-7f33-016eb8cd1ccc-b698ce33 {
    align-self: center;
    justify-self: auto;
  }

  #w-node-_805cc7d9-80f6-7021-7f33-016eb8cd1cce-b698ce33 {
    justify-self: auto;
  }

  #w-node-_805cc7d9-80f6-7021-7f33-016eb8cd1cd2-b698ce33 {
    align-self: center;
    justify-self: auto;
  }

  #w-node-b2842d5f-ce77-a68b-0ecb-0c268bdbd176-b698ce33 {
    justify-self: auto;
  }

  #w-node-_69faeb56-606e-5ee3-cea2-ca038027c7a6-b698ce33 {
    align-self: center;
  }

  #w-node-_69faeb56-606e-5ee3-cea2-ca038027c7a8-b698ce33 {
    justify-self: auto;
  }

  #w-node-_69faeb56-606e-5ee3-cea2-ca038027c7ac-b698ce33 {
    align-self: center;
    justify-self: auto;
  }

  #w-node-_69faeb56-606e-5ee3-cea2-ca038027c7ae-b698ce33 {
    justify-self: auto;
  }

  #w-node-_69faeb56-606e-5ee3-cea2-ca038027c7b2-b698ce33 {
    align-self: center;
    justify-self: auto;
  }

  #w-node-_69faeb56-606e-5ee3-cea2-ca038027c7b4-b698ce33 {
    justify-self: auto;
  }

  #w-node-_69faeb56-606e-5ee3-cea2-ca038027c7b8-b698ce33 {
    align-self: center;
    justify-self: auto;
  }

  #w-node-_4ff49463-01fd-50ef-8218-7dffbb355187-b698ce33 {
    justify-self: auto;
  }

  #w-node-_065b5ffa-ee0a-bcc6-1b8b-263c7c31a896-b698ce33 {
    align-self: center;
  }

  #w-node-_065b5ffa-ee0a-bcc6-1b8b-263c7c31a898-b698ce33 {
    justify-self: auto;
  }

  #w-node-_065b5ffa-ee0a-bcc6-1b8b-263c7c31a89c-b698ce33 {
    align-self: center;
    justify-self: auto;
  }

  #w-node-_065b5ffa-ee0a-bcc6-1b8b-263c7c31a89e-b698ce33 {
    justify-self: auto;
  }

  #w-node-_065b5ffa-ee0a-bcc6-1b8b-263c7c31a8a2-b698ce33 {
    align-self: center;
    justify-self: auto;
  }

  #w-node-_065b5ffa-ee0a-bcc6-1b8b-263c7c31a8a4-b698ce33 {
    justify-self: auto;
  }

  #w-node-_065b5ffa-ee0a-bcc6-1b8b-263c7c31a8a8-b698ce33 {
    align-self: center;
    justify-self: auto;
  }

  #w-node-bdc3929b-5d8e-496b-2924-52a5c0ad9986-b698ce33 {
    justify-self: auto;
  }

  #w-node-e3a90f75-d470-7097-bbbe-55a933809da3-4fbdfd37,
  #w-node-_4d23d9c3-32aa-001b-0596-e5a65931aef9-4fbdfd37,
  #w-node-_5d0cf41d-1172-6000-505c-5abc2306569e-4fbdfd37,
  #w-node-ae36a11d-9aa6-9b99-1c4e-812c3ccf1b6f-4fbdfd37,
  #w-node-_529f5180-557e-0011-a8c7-d413bd690ec5-4fbdfd37,
  #w-node-_529f5180-557e-0011-a8c7-d413bd690ed0-4fbdfd37,
  #w-node-_529f5180-557e-0011-a8c7-d413bd690edb-4fbdfd37,
  #w-node-_529f5180-557e-0011-a8c7-d413bd690ee6-4fbdfd37,
  #w-node-_3e7a3750-203b-4f65-b2ff-7193010c307e-4fbdfd37,
  #w-node-_820331d6-c4e7-e22c-17e6-307c4ba0cf79-4fbdfd37,
  #w-node-_2cc935a5-ba4b-b138-1cd0-3ca62c07d263-4fbdfd37,
  #w-node-_2cc935a5-ba4b-b138-1cd0-3ca62c07d26e-4fbdfd37,
  #w-node-_2cc935a5-ba4b-b138-1cd0-3ca62c07d279-4fbdfd37,
  #w-node-cd97ed09-7ee7-f868-9f24-92008cbffbf9-4fbdfd37,
  #w-node-_678fa4ae-1fca-b02f-fd27-9efe550463d6-4fbdfd37,
  #w-node-_053b3848-5278-b0d0-565f-4e26607311a1-4fbdfd37,
  #w-node-_8638ecc7-fc7a-8770-ed2b-943d852abcf3-4fbdfd37,
  #w-node-_1e711fa1-14c2-3bf9-4b2d-5fcd43fb2f8f-4fbdfd37,
  #w-node-_7ac24606-9265-f886-fb09-baae98aef364-4fbdfd37,
  #w-node-_7ac24606-9265-f886-fb09-baae98aef378-4fbdfd37,
  #w-node-_7ac24606-9265-f886-fb09-baae98aef38c-4fbdfd37,
  #w-node-_7ac24606-9265-f886-fb09-baae98aef3a0-4fbdfd37,
  #w-node-_7ac24606-9265-f886-fb09-baae98aef3b4-4fbdfd37,
  #w-node-d7e62f01-1c68-6af4-0f0b-aa76e3a3f0a9-4fbdfd37,
  #w-node-d7e62f01-1c68-6af4-0f0b-aa76e3a3f0bd-4fbdfd37,
  #w-node-fdedfdaf-07eb-26ce-ec5a-44f9429fbce6-4fbdfd37,
  #w-node-e5098d5c-99e5-a310-1707-51b579e0852f-4fbdfd37 {
    order: -9999;
  }

  #w-node-_281f1de6-6d74-81d3-48d1-dc46a9ec68d4-4fbdfd39,
  #w-node-_281f1de6-6d74-81d3-48d1-dc46a9ec68da-4fbdfd39 {
    align-self: center;
  }

  #w-node-_281f1de6-6d74-81d3-48d1-dc46a9ec68dc-b698ce3f {
    justify-self: auto;
  }

  #w-node-_281f1de6-6d74-81d3-48d1-dc46a9ec68e0-b698ce3f {
    align-self: center;
    justify-self: auto;
  }

  #w-node-_281f1de6-6d74-81d3-48d1-dc46a9ec68e2-b698ce3f {
    justify-self: auto;
  }

  #w-node-_281f1de6-6d74-81d3-48d1-dc46a9ec68e6-b698ce3f {
    align-self: center;
    justify-self: start;
  }

  #w-node-_281f1de6-6d74-81d3-48d1-dc46a9ec68e9-b698ce3f {
    justify-self: auto;
  }

  #w-node-c42d42f3-750f-ea7b-b174-0ed32a7c26eb-4fbdfd39,
  #w-node-c42d42f3-750f-ea7b-b174-0ed32a7c26f1-4fbdfd39 {
    align-self: center;
  }

  #w-node-c42d42f3-750f-ea7b-b174-0ed32a7c26f3-b698ce3f {
    justify-self: auto;
  }

  #w-node-c42d42f3-750f-ea7b-b174-0ed32a7c26f7-b698ce3f {
    align-self: center;
    justify-self: auto;
  }

  #w-node-c42d42f3-750f-ea7b-b174-0ed32a7c26f9-b698ce3f {
    justify-self: auto;
  }

  #w-node-c42d42f3-750f-ea7b-b174-0ed32a7c26fd-b698ce3f {
    align-self: center;
    justify-self: start;
  }

  #w-node-cf361d89-7532-afb8-10f8-0ef5e18cf97b-b698ce3f {
    justify-self: auto;
  }

  #w-node-_6b645a76-dff8-7f21-df2c-1791393c9bd3-4fbdfd39,
  #w-node-_6b645a76-dff8-7f21-df2c-1791393c9bd9-4fbdfd39 {
    align-self: center;
  }

  #w-node-_6b645a76-dff8-7f21-df2c-1791393c9bdb-b698ce3f {
    justify-self: auto;
  }

  #w-node-_6b645a76-dff8-7f21-df2c-1791393c9bdf-b698ce3f {
    align-self: center;
    justify-self: auto;
  }

  #w-node-_6b645a76-dff8-7f21-df2c-1791393c9be1-b698ce3f {
    justify-self: auto;
  }

  #w-node-_6b645a76-dff8-7f21-df2c-1791393c9be5-b698ce3f {
    align-self: center;
    justify-self: start;
  }

  #w-node-c9b26df2-3e69-486f-5fa4-ae424f464916-b698ce3f {
    justify-self: auto;
  }

  #w-node-_2bdfb484-989f-d1f5-1e24-8ad31a84e315-4fbdfd39,
  #w-node-_2bdfb484-989f-d1f5-1e24-8ad31a84e31b-4fbdfd39 {
    align-self: center;
  }

  #w-node-_2bdfb484-989f-d1f5-1e24-8ad31a84e31d-b698ce3f {
    justify-self: auto;
  }

  #w-node-_2bdfb484-989f-d1f5-1e24-8ad31a84e321-b698ce3f {
    align-self: center;
    justify-self: auto;
  }

  #w-node-_2bdfb484-989f-d1f5-1e24-8ad31a84e323-b698ce3f {
    justify-self: auto;
  }

  #w-node-_2bdfb484-989f-d1f5-1e24-8ad31a84e327-b698ce3f {
    align-self: center;
    justify-self: start;
  }

  #w-node-f4d0157d-7da4-af30-d94e-043dd8436721-b698ce3f {
    justify-self: auto;
  }

  #w-node-_514d680b-45fe-6e3c-9323-acdc6145f4fd-4fbdfd39,
  #w-node-_514d680b-45fe-6e3c-9323-acdc6145f503-4fbdfd39 {
    align-self: center;
  }

  #w-node-_514d680b-45fe-6e3c-9323-acdc6145f505-b698ce3f {
    justify-self: auto;
  }

  #w-node-_514d680b-45fe-6e3c-9323-acdc6145f509-b698ce3f {
    align-self: center;
    justify-self: auto;
  }

  #w-node-_514d680b-45fe-6e3c-9323-acdc6145f50b-b698ce3f {
    justify-self: auto;
  }

  #w-node-_514d680b-45fe-6e3c-9323-acdc6145f50f-b698ce3f {
    align-self: center;
    justify-self: start;
  }

  #w-node-_6bd7f1ec-e800-f266-f4f9-65c8ddee86aa-b698ce3f {
    justify-self: auto;
  }

  #w-node-_921ff977-8c59-3c5a-8292-e78ebe851f2f-4fbdfd39,
  #w-node-_921ff977-8c59-3c5a-8292-e78ebe851f35-4fbdfd39 {
    align-self: center;
  }

  #w-node-_921ff977-8c59-3c5a-8292-e78ebe851f37-b698ce3f {
    justify-self: auto;
  }

  #w-node-_921ff977-8c59-3c5a-8292-e78ebe851f3b-b698ce3f {
    align-self: center;
    justify-self: auto;
  }

  #w-node-_921ff977-8c59-3c5a-8292-e78ebe851f3d-b698ce3f {
    justify-self: auto;
  }

  #w-node-_921ff977-8c59-3c5a-8292-e78ebe851f41-b698ce3f {
    align-self: center;
    justify-self: start;
  }

  #w-node-_37cce26a-4d4f-4d08-ccbd-b616f31b3130-b698ce3f {
    justify-self: auto;
  }

  #w-node-e1d8c388-ff78-561c-b426-3acf5298ffbf-4fbdfd39,
  #w-node-e1d8c388-ff78-561c-b426-3acf5298ffc5-4fbdfd39 {
    align-self: center;
  }

  #w-node-e1d8c388-ff78-561c-b426-3acf5298ffc7-b698ce3f {
    justify-self: auto;
  }

  #w-node-e1d8c388-ff78-561c-b426-3acf5298ffcb-b698ce3f {
    align-self: center;
    justify-self: auto;
  }

  #w-node-e1d8c388-ff78-561c-b426-3acf5298ffcd-b698ce3f {
    justify-self: auto;
  }

  #w-node-e1d8c388-ff78-561c-b426-3acf5298ffd1-b698ce3f {
    align-self: center;
    justify-self: start;
  }

  #w-node-_494dda40-f466-307a-9a61-ddefaed25c1a-b698ce3f {
    justify-self: auto;
  }

  #w-node-b4f989fc-6667-c324-2d42-dbbfa7b8d64d-4fbdfd39,
  #w-node-b4f989fc-6667-c324-2d42-dbbfa7b8d653-4fbdfd39 {
    align-self: center;
  }

  #w-node-b4f989fc-6667-c324-2d42-dbbfa7b8d655-b698ce3f {
    justify-self: auto;
  }

  #w-node-b4f989fc-6667-c324-2d42-dbbfa7b8d659-b698ce3f {
    align-self: center;
    justify-self: auto;
  }

  #w-node-b4f989fc-6667-c324-2d42-dbbfa7b8d65b-b698ce3f {
    justify-self: auto;
  }

  #w-node-b4f989fc-6667-c324-2d42-dbbfa7b8d65f-b698ce3f {
    align-self: center;
    justify-self: start;
  }

  #w-node-b066dee4-592b-a207-b815-f3a4e642bb70-b698ce3f {
    justify-self: auto;
  }

  #w-node-e0baab68-088e-06fc-4951-7d92fe5594df-4fbdfd39,
  #w-node-e0baab68-088e-06fc-4951-7d92fe5594e5-4fbdfd39 {
    align-self: center;
  }

  #w-node-e0baab68-088e-06fc-4951-7d92fe5594e7-b698ce3f {
    justify-self: auto;
  }

  #w-node-e0baab68-088e-06fc-4951-7d92fe5594eb-b698ce3f {
    align-self: center;
    justify-self: auto;
  }

  #w-node-e0baab68-088e-06fc-4951-7d92fe5594ed-b698ce3f {
    justify-self: auto;
  }

  #w-node-e0baab68-088e-06fc-4951-7d92fe5594f1-b698ce3f {
    align-self: center;
    justify-self: start;
  }

  #w-node-_1ed0d109-94f7-a91f-7766-e52cfaeb18c7-b698ce3f {
    justify-self: auto;
  }

  #w-node-_2418ec92-1453-c3ba-4da2-2702b99a816b-4fbdfd39,
  #w-node-_2418ec92-1453-c3ba-4da2-2702b99a8171-4fbdfd39 {
    align-self: center;
  }

  #w-node-_2418ec92-1453-c3ba-4da2-2702b99a8173-b698ce3f {
    justify-self: auto;
  }

  #w-node-_2418ec92-1453-c3ba-4da2-2702b99a8177-b698ce3f {
    align-self: center;
    justify-self: auto;
  }

  #w-node-_2418ec92-1453-c3ba-4da2-2702b99a8179-b698ce3f {
    justify-self: auto;
  }

  #w-node-_2418ec92-1453-c3ba-4da2-2702b99a817d-b698ce3f {
    align-self: center;
    justify-self: start;
  }

  #w-node-_9cc5d557-d23e-1863-5d76-f83ad05938d3-b698ce3f {
    justify-self: auto;
  }

  #w-node-d861cb6f-8e46-7c3c-1807-a17650a4527e-4fbdfd3b,
  #w-node-d4360aaa-31ca-8556-0901-c8506f70703d-4fbdfd3b,
  #w-node-_76eddbf3-2988-a4f5-5f71-379af7414520-4fbdfd3b,
  #w-node-_58609445-feab-7dc2-7faa-eb9138a84e39-4fbdfd3b,
  #w-node-_58609445-feab-7dc2-7faa-eb9138a84e5b-4fbdfd3b,
  #w-node-_58609445-feab-7dc2-7faa-eb9138a84e7d-4fbdfd3b {
    align-self: start;
  }

  #w-node-_364f5dd5-3363-3fb7-aa7b-dcd2d903cefa-b698ce6f {
    justify-self: auto;
  }

  #w-node-f668b2c7-fa5c-21d0-b1f1-d60daa6008b8-b698ce6f {
    grid-column: span 1 / span 1;
  }

  #w-node-_984e4706-97da-3ec3-ffb7-5db245e3c086-b698ce6f {
    align-self: center;
    justify-self: start;
  }

  #w-node-_984e4706-97da-3ec3-ffb7-5db245e3c08c-b698ce6f {
    align-self: center;
  }

  #w-node-_984e4706-97da-3ec3-ffb7-5db245e3c09b-b698ce6f {
    justify-self: auto;
  }

  #w-node-_5aba21fe-57a3-186f-eaa2-d07319f0ff25-b698ce6f {
    align-self: center;
    justify-self: start;
  }

  #w-node-_5aba21fe-57a3-186f-eaa2-d07319f0ff2b-b698ce6f {
    align-self: center;
  }

  #w-node-b88b86c6-209e-8d16-9dad-b3242be81dff-b698ce6f {
    justify-self: auto;
  }

  #w-node-_9b7e9262-0f56-a667-e0e9-10e0029e7ddc-b698ce6f {
    align-self: center;
    justify-self: start;
  }

  #w-node-_9b7e9262-0f56-a667-e0e9-10e0029e7de2-b698ce6f {
    align-self: center;
  }

  #w-node-a460906d-8fde-65d8-c884-baa941c0b0a6-b698ce6f {
    justify-self: auto;
  }

  #w-node-be7849f3-00e3-d789-cc49-ff50b31dc88c-b698ce6f {
    align-self: center;
    justify-self: start;
  }

  #w-node-be7849f3-00e3-d789-cc49-ff50b31dc892-b698ce6f {
    align-self: center;
  }

  #w-node-_19ddf657-c461-c405-c13f-da330e059b05-b698ce6f {
    justify-self: auto;
  }

  #w-node-a78ea6d3-7c17-b8ac-126b-916a1987fdb1-b698ce6f {
    align-self: center;
    justify-self: start;
  }

  #w-node-a78ea6d3-7c17-b8ac-126b-916a1987fdb7-b698ce6f {
    align-self: center;
  }

  #w-node-d8cd8bca-8f55-9d03-12b8-e8a6d4307cce-b698ce6f {
    justify-self: auto;
  }

  #w-node-aa0655c1-c613-261c-c779-b8690b8e16b9-b698ce6f {
    align-self: center;
  }

  #w-node-aa0655c1-c613-261c-c779-b8690b8e16c9-b698ce6f {
    justify-self: auto;
  }

  #w-node-aa0655c1-c613-261c-c779-b8690b8e16ce-b698ce6f {
    align-self: center;
    justify-self: auto;
  }

  #w-node-_5cbc44a4-63ea-d6d4-38de-09040ba579fa-b698ce6f {
    align-self: center;
  }

  #w-node-_5cbc44a4-63ea-d6d4-38de-09040ba579fc-b698ce6f {
    justify-self: auto;
  }

  #w-node-_5cbc44a4-63ea-d6d4-38de-09040ba57a01-b698ce6f {
    align-self: center;
    justify-self: auto;
  }

  #w-node-_69c98ed1-b18c-221a-12db-5a6ebf27dfd9-4fbdfd46,
  #w-node-_69c98ed1-b18c-221a-12db-5a6ebf27dfdd-4fbdfd46 {
    grid-column: span 1 / span 1;
  }

  #w-node-ae951db6-4433-d1fb-0163-f68f8fd55c38-4fbdfd46,
  #w-node-ae951db6-4433-d1fb-0163-f68f8fd55c3e-4fbdfd46 {
    align-self: center;
  }

  #w-node-ae951db6-4433-d1fb-0163-f68f8fd55c47-b698ce6f {
    justify-self: auto;
  }

  #w-node-_0d7b3f7a-7c4e-d31b-e66a-23d21198dd55-4fbdfd46,
  #w-node-_0d7b3f7a-7c4e-d31b-e66a-23d21198dd5b-4fbdfd46 {
    align-self: center;
  }

  #w-node-_93d1e810-3959-d679-2d0d-e08ba4305a68-b698ce6f {
    justify-self: auto;
  }

  #w-node-_99eccd30-39ad-38ef-3543-cd997d2be317-4fbdfd46,
  #w-node-_99eccd30-39ad-38ef-3543-cd997d2be31d-4fbdfd46 {
    align-self: center;
  }

  #w-node-_18df25ea-3a6b-793a-b66b-71253d819113-b698ce6f {
    justify-self: auto;
  }

  #w-node-_69d9e290-dfaa-8e0d-9b80-0d2e002cad9b-4fbdfd46,
  #w-node-_69d9e290-dfaa-8e0d-9b80-0d2e002cada1-4fbdfd46 {
    align-self: center;
  }

  #w-node-ce45e422-c880-b46d-c411-5e05c425813d-b698ce6f {
    justify-self: auto;
  }

  #w-node-d1d9e6c5-5dcc-8c62-849c-b00737de3a9e-4fbdfd46,
  #w-node-d1d9e6c5-5dcc-8c62-849c-b00737de3aa4-4fbdfd46 {
    align-self: center;
  }

  #w-node-_79fd3a95-0d07-304f-1cec-47820b9fe698-b698ce6f {
    justify-self: auto;
  }

  #w-node-_58137467-e186-fe5d-d0bb-7e1200870c46-4fbdfd46,
  #w-node-_58137467-e186-fe5d-d0bb-7e1200870c4c-4fbdfd46 {
    align-self: center;
  }

  #w-node-_31002672-988d-f0a4-aefc-541521409e87-b698ce6f {
    justify-self: auto;
  }

  #w-node-_255d957c-4f0d-edc6-58e9-f588ce2d5866-4fbdfd46,
  #w-node-_255d957c-4f0d-edc6-58e9-f588ce2d586c-4fbdfd46 {
    align-self: center;
  }

  #w-node-ac6fd559-4953-759d-7dc1-c55358ee6f9e-b698ce6f {
    justify-self: auto;
  }

  #w-node-_067f2384-b354-adcc-6b97-6efa498cd68c-4fbdfd46,
  #w-node-_067f2384-b354-adcc-6b97-6efa498cd692-4fbdfd46 {
    align-self: center;
  }

  #w-node-b8e09acb-0479-38d5-c461-394839eed4f9-b698ce6f {
    justify-self: auto;
  }

  #w-node-_40756ccb-ce8a-4af4-6bf1-a6205230971b-4fbdfd46,
  #w-node-_47ed8a4a-16d1-75da-8f36-4a649a199cae-4fbdfd46,
  #w-node-a7f336b1-56ce-9e29-6e39-9cd6272580bd-4fbdfd46,
  #w-node-_2d9df47b-dd25-cddd-0598-8d35f558a679-4fbdfd46,
  #w-node-_5debdc09-4ef1-ede4-74bb-e5fa1ad61485-4fbdfd46,
  #w-node-_11d7366f-8629-bb4c-5201-00ec29571612-4fbdfd46,
  #w-node-_5750802e-8600-1b29-03ec-e389223cb5bb-4fbdfd46,
  #w-node-efb07ff1-bc10-b58a-ddd9-041c59178561-4fbdfd46,
  #w-node-d2982071-83e2-9eb2-f77b-8d4feac545c7-4fbdfd46 {
    align-self: center;
  }

  #w-node-_2225c158-2a6d-9f0b-19c1-f813e4119995-b698ce6f {
    justify-self: auto;
  }

  #w-node-_6bf4bb0c-0009-9152-f228-52ee1ec4ef05-b698ce6f {
    align-self: center;
  }

  #w-node-_8f16fcbc-c19e-97c0-9fe0-75dc9e76fe28-b698ce6f {
    justify-self: auto;
  }

  #w-node-_27927f5e-c56e-138b-ced6-d8963e91aeb1-b698ce6f {
    align-self: center;
  }

  #w-node-_3c8a649e-34d2-86e4-e3df-4d09d9f63add-b698ce6f {
    justify-self: auto;
  }

  #w-node-_091ca6e4-080e-b1a8-810f-bcb409d5cceb-b698ce6f {
    align-self: center;
  }

  #w-node-_5a19b7b1-f0e1-e3b5-8ef6-397df994d9a3-b698ce6f {
    justify-self: auto;
  }

  #w-node-_151ae6ab-3a1e-47ff-3e55-250115f128f4-b698ce6f {
    align-self: center;
  }

  #w-node-_1337e8d2-27a4-6c96-6648-7027058f9b8c-4fbdfd46,
  #w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4b4f-4fbdfd49 {
    justify-self: auto;
  }

  #w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4b71-b698ce85 {
    grid-column: span 1 / span 1;
  }

  #w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4bf0-b698ce85 {
    align-self: center;
    justify-self: start;
  }

  #w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4bf6-b698ce85 {
    align-self: center;
  }

  #w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4bf9-b698ce85 {
    justify-self: auto;
  }

  #w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4c1c-b698ce85 {
    align-self: center;
    justify-self: start;
  }

  #w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4c22-b698ce85 {
    align-self: center;
  }

  #w-node-b63fa71f-32a9-b587-261f-ad89e722b8cc-b698ce85 {
    justify-self: auto;
  }

  #w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4c48-b698ce85 {
    align-self: center;
    justify-self: start;
  }

  #w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4c4e-b698ce85 {
    align-self: center;
  }

  #w-node-_846c6a5e-0178-e1a3-ae75-4d13d131b821-b698ce85 {
    justify-self: auto;
  }

  #w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4c74-b698ce85 {
    align-self: center;
    justify-self: start;
  }

  #w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4c7a-b698ce85 {
    align-self: center;
  }

  #w-node-_384db549-561b-df30-e097-c5d7d2e3e2c1-b698ce85 {
    justify-self: auto;
  }

  #w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4ca0-b698ce85 {
    align-self: center;
    justify-self: start;
  }

  #w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4ca6-b698ce85 {
    align-self: center;
  }

  #w-node-abc793d2-da2c-d8ed-f27d-a4d0b587eb85-b698ce85 {
    justify-self: auto;
  }

  #w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4cdb-b698ce85 {
    align-self: center;
  }

  #w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4cdd-b698ce85 {
    justify-self: auto;
  }

  #w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4ce2-b698ce85 {
    align-self: center;
    justify-self: auto;
  }

  #w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4cea-b698ce85 {
    align-self: center;
  }

  #w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4cec-b698ce85 {
    justify-self: auto;
  }

  #w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4cf1-b698ce85 {
    align-self: center;
    justify-self: auto;
  }

  #w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4d4a-4fbdfd49,
  #w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4d4e-4fbdfd49 {
    grid-column: span 1 / span 1;
  }

  #w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4da8-4fbdfd49,
  #w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4dae-4fbdfd49 {
    align-self: center;
  }

  #w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4db1-b698ce85 {
    justify-self: auto;
  }

  #w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4dcc-4fbdfd49,
  #w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4dd2-4fbdfd49 {
    align-self: center;
  }

  #w-node-_9f2e0b9a-bf03-0d1c-8271-acc5a3b2a153-b698ce85 {
    justify-self: auto;
  }

  #w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4df0-4fbdfd49,
  #w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4df6-4fbdfd49 {
    align-self: center;
  }

  #w-node-b87f8250-e094-9864-e028-60fe94e5448d-b698ce85 {
    justify-self: auto;
  }

  #w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4e14-4fbdfd49,
  #w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4e1a-4fbdfd49 {
    align-self: center;
  }

  #w-node-dd0f888d-3537-e5e1-5d21-2ee31ed1261d-b698ce85 {
    justify-self: auto;
  }

  #w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4e38-4fbdfd49,
  #w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4e3e-4fbdfd49 {
    align-self: center;
  }

  #w-node-fe5bbaa7-9e5f-4ee1-761d-2a746a455ed9-b698ce85 {
    justify-self: auto;
  }

  #w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4e5c-4fbdfd49,
  #w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4e62-4fbdfd49 {
    align-self: center;
  }

  #w-node-_68621530-aa95-3351-8559-d20a461199bf-b698ce85 {
    justify-self: auto;
  }

  #w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4e80-4fbdfd49,
  #w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4e86-4fbdfd49 {
    align-self: center;
  }

  #w-node-_36fc4f72-6673-f302-0806-8ef785ce50bd-b698ce85 {
    justify-self: auto;
  }

  #w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4ea4-4fbdfd49,
  #w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4eaa-4fbdfd49 {
    align-self: center;
  }

  #w-node-_7abf0029-e3f3-e87e-81f0-53444d17c94b-b698ce85 {
    justify-self: auto;
  }

  #w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4ed9-4fbdfd49,
  #w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4edf-4fbdfd49,
  #w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4ef3-4fbdfd49,
  #w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4ef9-4fbdfd49,
  #w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4f0d-4fbdfd49,
  #w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4f13-4fbdfd49,
  #w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4f27-4fbdfd49,
  #w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4f2d-4fbdfd49,
  #w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4f74-4fbdfd49 {
    align-self: center;
  }

  #w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4f7b-b698ce85 {
    justify-self: auto;
  }

  #w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4f99-b698ce85 {
    align-self: center;
  }

  #w-node-_15bcbbb4-35ec-fd6c-494e-921535e91bcf-b698ce85 {
    justify-self: auto;
  }

  #w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4fbe-b698ce85 {
    align-self: center;
  }

  #w-node-_30aaa3d5-4207-2cd9-6611-2537fec3ce90-b698ce85 {
    justify-self: auto;
  }

  #w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4fe3-b698ce85 {
    align-self: center;
  }

  #w-node-_1e1cc072-4df3-9cd1-0da2-c23f2de16c13-b698ce85 {
    justify-self: auto;
  }

  #w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d5008-b698ce85 {
    align-self: center;
  }

  #w-node-bfd0edf0-6a9d-495c-eef5-f1a60413a7af-4fbdfd49,
  #w-node-_0f6ef97d-c33e-10c2-1a33-a4930288597f-4fbdfd4c {
    justify-self: auto;
  }

  #w-node-_0f6ef97d-c33e-10c2-1a33-a493028859a1-b698ce88 {
    grid-column: span 1 / span 1;
  }

  #w-node-_0f6ef97d-c33e-10c2-1a33-a49302885a20-b698ce88 {
    align-self: center;
    justify-self: start;
  }

  #w-node-_0f6ef97d-c33e-10c2-1a33-a49302885a26-b698ce88 {
    align-self: center;
  }

  #w-node-_0f6ef97d-c33e-10c2-1a33-a49302885a29-b698ce88 {
    justify-self: auto;
  }

  #w-node-_0f6ef97d-c33e-10c2-1a33-a49302885a4c-b698ce88 {
    align-self: center;
    justify-self: start;
  }

  #w-node-_0f6ef97d-c33e-10c2-1a33-a49302885a52-b698ce88 {
    align-self: center;
  }

  #w-node-_0dbfe403-7154-be21-06dc-fadbb0f54533-b698ce88 {
    justify-self: auto;
  }

  #w-node-_0f6ef97d-c33e-10c2-1a33-a49302885a78-b698ce88 {
    align-self: center;
    justify-self: start;
  }

  #w-node-_0f6ef97d-c33e-10c2-1a33-a49302885a7e-b698ce88 {
    align-self: center;
  }

  #w-node-c7ae7c27-bd86-0a03-82bd-5cc8120c0d66-b698ce88 {
    justify-self: auto;
  }

  #w-node-_0f6ef97d-c33e-10c2-1a33-a49302885aa4-b698ce88 {
    align-self: center;
    justify-self: start;
  }

  #w-node-_0f6ef97d-c33e-10c2-1a33-a49302885aaa-b698ce88 {
    align-self: center;
  }

  #w-node-_4fa9d836-a3d6-1070-a816-9a57a9639b2d-b698ce88 {
    justify-self: auto;
  }

  #w-node-_0f6ef97d-c33e-10c2-1a33-a49302885ad0-b698ce88 {
    align-self: center;
    justify-self: start;
  }

  #w-node-_0f6ef97d-c33e-10c2-1a33-a49302885ad6-b698ce88 {
    align-self: center;
  }

  #w-node-_3a95f871-4236-4fee-bb4c-573867afa732-b698ce88 {
    justify-self: auto;
  }

  #w-node-_0f6ef97d-c33e-10c2-1a33-a49302885b0b-b698ce88 {
    align-self: center;
  }

  #w-node-_0f6ef97d-c33e-10c2-1a33-a49302885b0d-b698ce88 {
    justify-self: auto;
  }

  #w-node-_0f6ef97d-c33e-10c2-1a33-a49302885b12-b698ce88 {
    align-self: center;
    justify-self: auto;
  }

  #w-node-_0f6ef97d-c33e-10c2-1a33-a49302885b1a-b698ce88 {
    align-self: center;
  }

  #w-node-_0f6ef97d-c33e-10c2-1a33-a49302885b1c-b698ce88 {
    justify-self: auto;
  }

  #w-node-_0f6ef97d-c33e-10c2-1a33-a49302885b21-b698ce88 {
    align-self: center;
    justify-self: auto;
  }

  #w-node-_0f6ef97d-c33e-10c2-1a33-a49302885b7a-4fbdfd4c,
  #w-node-_0f6ef97d-c33e-10c2-1a33-a49302885b7e-4fbdfd4c {
    grid-column: span 1 / span 1;
  }

  #w-node-_0f6ef97d-c33e-10c2-1a33-a49302885bd8-4fbdfd4c,
  #w-node-_0f6ef97d-c33e-10c2-1a33-a49302885bde-4fbdfd4c {
    align-self: center;
  }

  #w-node-_0f6ef97d-c33e-10c2-1a33-a49302885be1-b698ce88 {
    justify-self: auto;
  }

  #w-node-_0f6ef97d-c33e-10c2-1a33-a49302885bfc-4fbdfd4c,
  #w-node-_0f6ef97d-c33e-10c2-1a33-a49302885c02-4fbdfd4c {
    align-self: center;
  }

  #w-node-_6f01c053-8511-738e-5f67-d3f4461a2c45-b698ce88 {
    justify-self: auto;
  }

  #w-node-_0f6ef97d-c33e-10c2-1a33-a49302885c20-4fbdfd4c,
  #w-node-_0f6ef97d-c33e-10c2-1a33-a49302885c26-4fbdfd4c {
    align-self: center;
  }

  #w-node-e92aaff4-7fd0-6734-c0b4-241783841963-b698ce88 {
    justify-self: auto;
  }

  #w-node-_0f6ef97d-c33e-10c2-1a33-a49302885c44-4fbdfd4c,
  #w-node-_0f6ef97d-c33e-10c2-1a33-a49302885c4a-4fbdfd4c {
    align-self: center;
  }

  #w-node-d71b419b-227a-5aa3-3068-976ad5526ee8-b698ce88 {
    justify-self: auto;
  }

  #w-node-_0f6ef97d-c33e-10c2-1a33-a49302885c68-4fbdfd4c,
  #w-node-_0f6ef97d-c33e-10c2-1a33-a49302885c6e-4fbdfd4c {
    align-self: center;
  }

  #w-node-_41395ce6-c414-a88f-c809-0d17e8526413-b698ce88 {
    justify-self: auto;
  }

  #w-node-_0f6ef97d-c33e-10c2-1a33-a49302885c8c-4fbdfd4c,
  #w-node-_0f6ef97d-c33e-10c2-1a33-a49302885c92-4fbdfd4c {
    align-self: center;
  }

  #w-node-e18d4cd2-e49e-fe0f-5c3f-9ac78a13ca7c-b698ce88 {
    justify-self: auto;
  }

  #w-node-_0f6ef97d-c33e-10c2-1a33-a49302885cb0-4fbdfd4c,
  #w-node-_0f6ef97d-c33e-10c2-1a33-a49302885cb6-4fbdfd4c {
    align-self: center;
  }

  #w-node-_7108c041-855e-01a5-c965-925ee487875e-b698ce88 {
    justify-self: auto;
  }

  #w-node-_0f6ef97d-c33e-10c2-1a33-a49302885cd4-4fbdfd4c,
  #w-node-_0f6ef97d-c33e-10c2-1a33-a49302885cda-4fbdfd4c {
    align-self: center;
  }

  #w-node-f2eb2dcd-ac8e-9370-6cfa-6550a14bbc09-b698ce88 {
    justify-self: auto;
  }

  #w-node-_0f6ef97d-c33e-10c2-1a33-a49302885d09-4fbdfd4c,
  #w-node-_0f6ef97d-c33e-10c2-1a33-a49302885d0f-4fbdfd4c,
  #w-node-_0f6ef97d-c33e-10c2-1a33-a49302885d23-4fbdfd4c,
  #w-node-_0f6ef97d-c33e-10c2-1a33-a49302885d29-4fbdfd4c,
  #w-node-_0f6ef97d-c33e-10c2-1a33-a49302885d3d-4fbdfd4c,
  #w-node-_0f6ef97d-c33e-10c2-1a33-a49302885d43-4fbdfd4c,
  #w-node-_0f6ef97d-c33e-10c2-1a33-a49302885d57-4fbdfd4c,
  #w-node-_0f6ef97d-c33e-10c2-1a33-a49302885d5d-4fbdfd4c,
  #w-node-_0f6ef97d-c33e-10c2-1a33-a49302885da4-4fbdfd4c {
    align-self: center;
  }

  #w-node-_0f6ef97d-c33e-10c2-1a33-a49302885dab-b698ce88 {
    justify-self: auto;
  }

  #w-node-_0f6ef97d-c33e-10c2-1a33-a49302885dc9-b698ce88 {
    align-self: center;
  }

  #w-node-_3b5ba3cf-c942-ae4e-5da2-1204c40205d3-b698ce88 {
    justify-self: auto;
  }

  #w-node-_0f6ef97d-c33e-10c2-1a33-a49302885dee-b698ce88 {
    align-self: center;
  }

  #w-node-_1c96d4fa-118a-77a6-cd65-624c689652c4-b698ce88 {
    justify-self: auto;
  }

  #w-node-_0f6ef97d-c33e-10c2-1a33-a49302885e13-b698ce88 {
    align-self: center;
  }

  #w-node-_6d6c6b2a-3a22-5eb3-1cc6-7e58af88b4eb-b698ce88 {
    justify-self: auto;
  }

  #w-node-_0f6ef97d-c33e-10c2-1a33-a49302885e38-b698ce88 {
    align-self: center;
  }

  #w-node-e0c621e2-20a2-fee3-23e4-fef566aecd69-b698ce88 {
    justify-self: auto;
  }
}

@media screen and (max-width: 479px) {

  #w-node-_50c39f46-7d08-bc08-02d5-67e10df54be3-4fbdfcbd,
  #w-node-_50c39f46-7d08-bc08-02d5-67e10df54c32-4fbdfcbd,
  #w-node-ab65a4c2-4183-9e83-c954-9f60156d732e-4fbdfcbd,
  #w-node-_69b65eee-d5d6-6c9f-6eca-76327e42d8f5-4fbdfcbd,
  #w-node-_6feb9651-d900-c08c-c36e-0d671655095e-4fbdfcbd,
  #w-node-_6feb9651-d900-c08c-c36e-0d6716550975-4fbdfcbd,
  #w-node-cc2ca80f-30ab-8def-27be-6e890e8715bd-4fbdfcbd,
  #w-node-_201e90b1-680d-a287-cbb0-7929d464a944-4fbdfcbd,
  #w-node-_9975c6b2-045f-bd47-7f76-cd29a5560f79-4fbdfcbd,
  #w-node-c1d6bd61-f145-c532-ef5b-d773446c6dff-4fbdfcc0,
  #w-node-_0f9f089f-6c25-b121-23f4-417efa32ab15-4fbdfd0e {
    justify-self: auto;
  }

  #w-node-c21977b5-d6d4-0e15-8d14-90df7a0c3a0f-b698cdec {
    justify-self: stretch;
  }

  #w-node-c218ea9d-0cb5-9252-b6b0-bf9dd784b9b3-4fbdfd2d,
  #w-node-_95feeb96-0d21-6cce-dac3-e44c802142cd-4fbdfd2d,
  #w-node-_1300441d-e335-9bc1-14e1-f279e41757a3-4fbdfd30,
  #w-node-_9e0982af-7013-efb2-9f4a-6038634a187a-4fbdfd30,
  #w-node-_8af7a147-f590-4f49-47d6-21e56df9c287-4fbdfd30,
  #w-node-_1ddcb99f-332b-3b7b-cf16-ec4fc0b0f683-4fbdfd30 {
    justify-self: auto;
  }

  #w-node-a34e7ba7-3e02-3a04-23f9-e59bc3a53c7d-b698ce3e {
    grid-column: span 1 / span 1;
  }

  #w-node-_9a36722d-8aa5-34a7-299c-a8e21ca3d0ec-4fbdfd39,
  #w-node-_502c6fce-7626-e345-9fb7-d642507434a2-4fbdfd3a,
  #w-node-_4b82f21c-1eac-01f5-4546-90af3c718bfc-4fbdfd3a,
  #w-node-_8d1ccb67-8ee3-3af2-8dba-cadd38098491-4fbdfd3a,
  #w-node-a9cdd199-faaa-a142-6213-1e3cec7576a4-4fbdfd3b {
    justify-self: auto;
  }

  #w-node-b5fac2a3-9335-cb9d-9890-b01a2aa832dd-b698ce6e {
    grid-area: span 1 / span 2 / span 1 / span 2;
  }

  #w-node-dcede86a-f23b-20f5-d51f-83b3846cbf26-b698ce6e {
    grid-area: span 1 / span 2 / span 1 / span 2;
    justify-self: auto;
  }

  #w-node-aacd826e-267a-7136-d484-c754df3e93ad-b698ce6e {
    grid-area: span 1 / span 2 / span 1 / span 2;
  }

  #w-node-aacd826e-267a-7136-d484-c754df3e93b5-b698ce6e {
    grid-area: span 1 / span 2 / span 1 / span 2;
    justify-self: auto;
  }

  #w-node-_199d1a10-a17b-63e6-1279-543de0f4fe57-b698ce6e {
    grid-area: span 1 / span 2 / span 1 / span 2;
  }

  #w-node-_199d1a10-a17b-63e6-1279-543de0f4fe5f-b698ce6e {
    grid-area: span 1 / span 2 / span 1 / span 2;
    justify-self: auto;
  }

  #w-node-f668b2c7-fa5c-21d0-b1f1-d60daa6008b8-b698ce6f {
    grid-column: span 1 / span 1;
  }

  #w-node-_100675e2-5b14-17f9-fdbc-1f8c192ac61d-b698ce6f {
    justify-self: auto;
  }

  #w-node-_477ecf8e-2ddf-e0d7-709e-0e2622b43fa6-b698ce71 {
    grid-area: span 1 / span 2 / span 1 / span 2;
  }

  #w-node-_477ecf8e-2ddf-e0d7-709e-0e2622b43fae-b698ce71 {
    grid-area: span 1 / span 2 / span 1 / span 2;
    justify-self: auto;
  }

  #w-node-_477ecf8e-2ddf-e0d7-709e-0e2622b43fb5-b698ce71 {
    grid-area: span 1 / span 2 / span 1 / span 2;
  }

  #w-node-_477ecf8e-2ddf-e0d7-709e-0e2622b43fbd-b698ce71 {
    grid-area: span 1 / span 2 / span 1 / span 2;
    justify-self: auto;
  }

  #w-node-_477ecf8e-2ddf-e0d7-709e-0e2622b43fc4-b698ce71 {
    grid-area: span 1 / span 2 / span 1 / span 2;
  }

  #w-node-_477ecf8e-2ddf-e0d7-709e-0e2622b43fcc-b698ce71 {
    grid-area: span 1 / span 2 / span 1 / span 2;
    justify-self: auto;
  }

  #w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4b71-b698ce85 {
    grid-column: span 1 / span 1;
  }

  #w-node-_1deec7fa-ed7c-7cf5-71b0-9e1ae78d4f34-b698ce85 {
    justify-self: auto;
  }

  #w-node-cd5e2dc9-a3b8-fff9-ea31-7da474aafa30-b698ce87 {
    grid-area: span 1 / span 2 / span 1 / span 2;
  }

  #w-node-cd5e2dc9-a3b8-fff9-ea31-7da474aafa38-b698ce87 {
    grid-area: span 1 / span 2 / span 1 / span 2;
    justify-self: auto;
  }

  #w-node-cd5e2dc9-a3b8-fff9-ea31-7da474aafa3f-b698ce87 {
    grid-area: span 1 / span 2 / span 1 / span 2;
  }

  #w-node-cd5e2dc9-a3b8-fff9-ea31-7da474aafa47-b698ce87 {
    grid-area: span 1 / span 2 / span 1 / span 2;
    justify-self: auto;
  }

  #w-node-cd5e2dc9-a3b8-fff9-ea31-7da474aafa4e-b698ce87 {
    grid-area: span 1 / span 2 / span 1 / span 2;
  }

  #w-node-cd5e2dc9-a3b8-fff9-ea31-7da474aafa56-b698ce87 {
    grid-area: span 1 / span 2 / span 1 / span 2;
    justify-self: auto;
  }

  #w-node-_0f6ef97d-c33e-10c2-1a33-a493028859a1-b698ce88 {
    grid-column: span 1 / span 1;
  }

  #w-node-_0f6ef97d-c33e-10c2-1a33-a49302885d64-b698ce88 {
    justify-self: auto;
  }
}


@font-face {
  font-family: 'Social Icons Font';
  src: url('../fonts/social-icon-font.woff2') format('woff2'), url('../fonts/social-icon-font.eot') format('embedded-opentype'), url('../fonts/social-icon-font.woff') format('woff'), url('../fonts/social-icon-font.ttf') format('truetype'), url('../fonts/social-icon-font.svg') format('svg');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Line Square Icons';
  src: url('../fonts/line-square-icons.woff2') format('woff2'), url('../fonts/line-square-icons.eot') format('embedded-opentype'), url('../fonts/line-square-icons.woff') format('woff'), url('../fonts/line-square-icons.ttf') format('truetype'), url('../fonts/line-square-icons.svg') format('svg');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Filled Icons';
  src: url('../fonts/filled-icon-font.woff2') format('woff2'), url('../fonts/filled-icon-font.eot') format('embedded-opentype'), url('../fonts/filled-icon-font.woff') format('woff'), url('../fonts/filled-icon-font.ttf') format('truetype'), url('../fonts/filled-icon-font.svg') format('svg');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Line Rounded Icons';
  src: url('../fonts/line-rounded-icons.woff2') format('woff2'), url('../fonts/line-rounded-icons.eot') format('embedded-opentype'), url('../fonts/line-rounded-icons.woff') format('woff'), url('../fonts/line-rounded-icons.ttf') format('truetype'), url('../fonts/line-rounded-icons.svg') format('svg');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Dashboardly Custom Icons';
  src: url('../fonts/dashboardlycustomicons.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}