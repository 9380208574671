.coding-exercise-page {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    font-family: 'Arial', sans-serif;
    background: #f5f5f5;
    height: 100vh;
  }
  
  .left-panel {
    width: 40%;
  }
  
  .right-panel {
    width: 58%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .code-editor {
    width: 100%;
    height: 400px; /* Adjust height as needed */
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 10px;
    font-family: 'Consolas', 'Monaco', 'Lucida Console', monospace;
    font-size: 14px;
    resize: none;
  }
  
  .exercise-description,
  .example,
  .requirements {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 10px;
    margin-bottom: 20px;
  }

  .examples-container {
    margin-bottom: 20px;
  }
  
  .example + .example {
    margin-top: 10px;
  }
  
  .requirement {
    padding: 5px 0;
  }
  .submit-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .submit-button:hover {
    background-color: #0056b3;
  }
  
  .bottom-panel {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .controls-row {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    margin-bottom: 10px; /* Add space between controls and the editor */
  }

.language-selector {
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 4px;
    border: 1px solid #ddd;
    background-color: white;
    font-size: 14px;
    width: 200px;
    margin-right: 10px;
  }
  